const styles = {
    btnDashboard:{
        // backgroundColor: "#FF0000",
        width: "85px",
        borderWidth: "1px",
        height: "30px",
        borderRadius: "10px",
        fontFamily:'MontserratRegular',
        fontWeight:500,
        fontStyle:'normal',
        fontSize:'12px',
        display:'flex',
        lineHeight:'20px',
        letterSpacing:'0.5px',
        color:'white',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        padding:'auto',
        margin:'auto',
     },
      activeTab: {
        border:1,
    paddingBottom: 2,
    borderBottomWidth: 6,
    borderBottomColor: "#FF0000",
    borderTopColor:'#FEFEFE',
    borderRightColor:'#FEFEFE',
    borderLeftColor:'#FEFEFE',
  },
    header1: {
        fontFamily: 'MontserratRegular', 
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontSize: '36px', 
        fontWeight: '600',
        margin: '36px auto', 
        width: '350px', 
        lineHeight: '0px'
    },

    header2: {
        fontFamily: 'MontserratRegular', 
        fontWeight: '400',
        margin: '24px auto ', 
        lineHeight: '10px',
    },

    textField: {
        margin: '10px auto', width: '350px',
    },

    subscript: { 
        color: '#0008C1',
        fontSize: '10px' ,
        margin: '10px auto',
        width: '350px',
        lineHeight: '0px'
    },

    basicButton: {
        fontWeight: '600',
        width: '350px', 
        margin: '10px auto',
        backgroundColor: '#3457D5'
    },
    
    btn: {
        // width: '150px',
        height: '40px',
        background: '#3457D5',
        borderRadius: '5px',
        margin: 'auto 0px auto 10px'
    },
    title: {
        color: '#252525',
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        lineHeight: '60px',
        fontSize: '30px',
        color: '#252525',
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '80px',
        fontSize: '24px',
        textShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        margin: '20px auto',     
        width: '85%',
        fontFamily: "MontserratRegular",
        fontWeight: "500",
        fontSize: "20px",
        color: "#414042",

    },    
    subheading:{
        fontStyle: 'normal',
        fontSize: '16px',
        textShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        width: '85%',
        fontFamily: "MontserratRegular",
        fontWeight: "500",
        fontSize: "20px",
        color: "#252525",
    },
    title2: {
        color: '#252525',
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '60px',
        fontSize: '30px',
        textShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        margin: '10px', 
        // width: '85%',
    },

    title3: {
        color: '#252525',
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '60px',
        fontSize: '20px',
        textShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        margin: '10px', 
        // width: '85%',
    },

    subTitle: {
        color: '#252525',
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '60px',
        fontSize: '20px',
        textShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        margin: 'auto auto',  
    }
}


const navbarStyle = {
    title: {
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '48px',
        textAlign: 'center',
        color: '#252525'
    },

    border: {
        background: '#FEFEFE',
        border: '2px solid #252525',
        borderRadius: '10px'
    },

    text: {
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '30px',
        textAlign: 'center',
        color: '#252525'
    },

    underlineText: {
        textDecorationLine: 'underline'
    }
}

const landingPage = {
    title: {
        color: '#252525',
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '60px',
        textShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
        width: '85%',
    },

    text: {
        width: '84%',
        fontFamily: 'MontserratRegular',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '27px',
        color: '#000000',
        margin: '-25px auto 0px auto'
    }
}

const dashboard = {
    blocks: {
        display: 'flex',
        background: '#F0F8FF',
        margin: '15px'
    },

    cardTextTitle: {
        fontSize: "16px", 
        color: "#252525", 
        fontWeight: "600",
        fontFamily: "MontserratRegular",
        margin: "20px 0px 0px 20px"
    },

    iconTitle:{
        margin: "0px 14px",
        fontSize: "16px",
    },

    img: {
        height: "20px", 
        width: "20px", 
    },
}

export default styles;
export {navbarStyle, landingPage, dashboard};