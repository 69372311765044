import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Input,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { React, useEffect, useState } from "react";
import styles from "../../styles/global";
import mblStyles from "../../styles/mblglobal";
// import cartIcon from '../../assets/navbar/cartIcon.svg'
// import AccountCircle from '@mui/icons-material/AccountCircle';
import TimerIcon from "@mui/icons-material/Timer";
import { useMediaQuery } from "@mui/material";
import plusicon from "./plusicon.png";
import deleteimg from "../../../src/images/delete.png";
import { CheckBox } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import EventMemberConfiguration from "../GroupManagement/EventMemberConfiguration";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { gql, useQuery, useMutation } from "@apollo/client";
import axios from "axios";

const GET_ALL_STAFF = gql`
  query getAllStaff($_eq: String = "staff") {
    profile_details(where: { category: { _eq: $_eq } }) {
      first_name
      user_id
    }
  }
`;

const GET_FACILITATORS_ROLE = gql`
  query GetFacilitatorRole {
    facilitator_roles {
      created_at
      created_by
      description
      id
      name
    }
  }
`;

const UPDATE_EVENT_MASTER = gql`
  mutation MyMutation7(
    $_eq: bigint
    $closure_date: date
    $metadata: jsonb
    $publish_to: String
    $conevene_meeting_id: bigint
  ) {
    update_event_master(
      where: { id: { _eq: $_eq } }
      _set: {
        closure_date: $closure_date
        metadata: $metadata
        publish_to: $publish_to
        conevene_meeting_id:$conevene_meeting_id
      }
    ) {
      returning {
        cover_img_path
        closure_date
        description
        end_time
        id
        is_recurring
        location
        metadata
        name
        parent_id
        publish_to
        recurrance_frequency
        recurrance_end_time
        recurrance_end_date
        recurrance_start_date
        recurrance_start_time
        start_time
        conevene_meeting_id 
      }
    }
  }
`;

const INSERT_FACILITATOR_QUERY = gql`
  mutation EventFaculty(
    $event_id: bigint = ""
    $faculty_type_id: bigint = ""
    $user_id: uuid = ""
  ) {
    insert_event_faculty(
      objects: {
        event_id: $event_id
        faculty_type_id: $faculty_type_id
        user_id: $user_id
      }
    ) {
      returning {
        id
        user_id
        faculty_type_id
        event_id
      }
    }
  }
`;

export default function MoreDetailForm(props) {
  const [eventMode, setEventMode] = useState("standalone");
  const [showBox, setShowBox] = useState(false);
  const newData = props.data;
  const newDate = props.date;
  console.log(props, "newdata");

  const handleEventModeChange = (event) => {
    setEventMode(event.target.value);
    setShowBox(event.target.value === "group");
  };
  const [formData, setFormData] = useState(newData);
  const matches = useMediaQuery("(max-width:1000px)");

  const handleChange = (key, value) => {
    console.log(key, value);
    setFormData((formData) => {
      return {
        ...formData,
        [key]: value,
      };
    });
    props.setData((formData) => {
      return {
        ...formData,
        [key]: value,
      };
    });
  };
  console.log("formdata", formData);
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate,'fffffffffffffffffffffffff');
    setFormData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
    props.setDate((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const inputProps = {
    style: { borderColor: "red" },
  };

  const handleNext = () => {
    // console.log(props.data);
    // console.log(formData);

    // console.log('inside next tttttt')

    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const [staff, setStaff] = useState([]);
  // console.log("staff data", staff);

  const [groupType, setgroupType] = useState([]);

  const [facilitatorRole, setfacilitatorRole] = useState([]);
  const {
    loading: staffLoading,

    error: staffError,

    data: staffData,
  } = useQuery(GET_ALL_STAFF);

  // console.log("saffdata it is loading",staffData);

  const {
    loading: facilitatorRoleLoading,

    error: facilitatorRoleError,

    data: facilitatorRoleData,
  } = useQuery(GET_FACILITATORS_ROLE);

  useEffect(() => {
    setStaff(staffData?.profile_details);

    setfacilitatorRole(facilitatorRoleData?.facilitator_roles);
  }, [staffData, facilitatorRoleData]);
  const [numFields, setNumFields] = useState(0);

  const handleSelectChange = (event) => {
    setFormData({ ...formData, selectRole: event.target.value });
    console.log("Selected Role:", event.target.value);
  };

  const handleDeleteRow = () => {
    setNumFields((prevNumFields) => prevNumFields - 1);
  };

  const fields = [];
  for (let i = 0; i < numFields; i++) {
    fields.push(
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <FormControl
          sx={{
            width: "40%",
            minWidth: "320px",
            mt: 1,
            marginLeft: 1.5,
            mb: 1,
            fontFamily: "MontserratRegular",
            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              fontFamily: "MontserratRegular",
              color: " #999999",
              fontSize: "14px",
              lineHeight: " 20px",
            },
          }}
        >
          <InputLabel id="demo-simple-select-label" size="small">
            Select Roles
          </InputLabel>
          <Select
            fullWidth="true"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.selectRole}
            size="small"
            label="select Roles "
            // handleChange("selectRoles", e.target.value);
            onChange={(e) => {
              handleChange("selectRoles", e.target.value);
            }}
          >
            {facilitatorRole?.map((item, index) => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: "40%",
            minWidth: "320px",
            mt: 1,
            marginLeft: 2,
            mb: 1,
            fontFamily: "Montserrat",
            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              fontFamily: "MontserratRegular",
              color: " #999999",
              fontSize: "14px",
              lineHeight: " 20px",
            },
          }}
        >
          <InputLabel
            id="demo-simple-select-label"
            size="small"
            sx={{
              fontFamily: "MontserratRegular",
              color: " #999999",
              fontSize: "14px",
              lineHeight: " 20px",
            }}
          >
            Assign Facilitators
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.assignFacillator}
            size="small"
            label=" Assign Facilitators"
            onChange={(e) => {
              handleChange("assignFacillator", e.target.value);
            }}
          >
            {staff?.map((item, index) => {
              return (
                <MenuItem value={item.user_id}>{item.first_name}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button onClick={handleDeleteRow}>
          <img src={deleteimg} alt="plusicon" height={30} width={30} />
        </Button>
      </Box>
    );
  }

  const publishto = [
    { label: "Lead" },
    { label: "Prospect" },
    { label: "Suspect" },
    { label: "Member" },
    { label: "All" },
  ];

  const [eventID, seteventID] = useState(localStorage.getItem("event_id"));

  const [UpdateEventMetadata, { loading, error, isError, isSuccess }] =
    useMutation(UPDATE_EVENT_MASTER, {
      onError: (error) => {
        console.error("Mutation Error:", error);
      },

      onCompleted: (data) => {
        // handleNext();
        // props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
      },
    });

  if (isSuccess) {
    console.log("this is error pratik");
  }

  const [tempMax, settempMax] = useState(0);
  // const [eventID, seteventID] = useState();

  // seteventID(localStorage.getItem("event_id"), eventID);

  // console.log("event is outside function",eventID);
  const [errors, setErrors] = useState({});

  const startTimeString = props?.startTimeConvene?.startTime;
  const endTimeString = props?.startTimeConvene?.endTime;
  
  const addData = async () => {
    // console.log("yesssssssssssjdjjdjdjdjjd")
    const newErrors = {};
    // Validate name
    if (!formData.duration) {
      newErrors.duration = " Duration is required";
    }
    if (!formData.attendanceCapacity) {
      newErrors.attendanceCapacity = "Attendance Capacity is required";
    }
    if (!formData.publish_to) {
      newErrors.publish_to = "Publish to is required";
    }

    // if (!data.start_date) {
    //   newErrors.start_date = "Start date is required";

    // }

    setErrors(newErrors);

    let idFromLocalStorage = localStorage.getItem("event_id");

    let id = idFromLocalStorage ? parseInt(idFromLocalStorage, 10) : null;

    const displayName = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const OrganizationID = 121225;
    const UniqueID = "userunique121";
    const MeetingName = newData.eventname_em;
    const MaxMembersJoined = formData.attendanceCapacity;
    const StartDate = props.date.startDate;
    const EndDate = props.date.endDate;
    console.log(StartDate,EndDate,'endd')
    const formattedStartDate = StartDate?.replace(/\//g, '-');
    const formattedEndDate = EndDate?.replace(/\//g, '-');
    console.log(formattedStartDate,'start', formattedEndDate)
    console.log(props.data, "props");

    if (isNaN(id)) {
      console.error("Invalid 'event_id' in localStorage:", idFromLocalStorage);

      return;
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        if (!props.data.callmode) {
              console.log('CallMode is false. Skipping API call.');
              return;
            }
            const apiUrl = "https://portalapi.convenevc.com/api/customerMeetings/";
            const postData = {
              UniqueID: UniqueID,
              OrganizationID: OrganizationID,
              MeetingName: MeetingName,
              MaxMembersJoined: MaxMembersJoined,
              MeetingCreatorName: displayName,
              StartDate: formattedStartDate,
              EndDate: formattedEndDate,
              StartTime: startTimeString,
              EndTime: endTimeString,
            };
        
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                // Add any other headers if needed
              },
              body: JSON.stringify(postData),

            });
            const responseData = await response.json();
            const { data } = await UpdateEventMetadata({
              variables: {
                _eq: localStorage.getItem("event_id"),
                closure_date: formData["pageExpire"],
                publish_to: formData["publish_to"],
    
                metadata: {
                  duration: formData["duration"],
                  max_attendance: formData["attendanceCapacity"],
                  page_expi_date: formData["pageExpire"]
                    ? formData["pageExpire"]
                    : newDate["pageExpire"],
                  associated_group_id: "10",
                },
                publish_to: formData["publish_to"],
                conevene_meeting_id: responseData?.meetingDetails?.CustomerMeetingID
              },
            });
            console.log(responseData,'api response')
          } catch (error) {
            console.error("GraphQL Mutation Error:", error.message);
          }

      try {
       
        // console.log("event configurations data", UpdateEventMetadataData);
        if (
          formData.assignFacillator &&
          formData.selectRoles &&
          numFields === 0
        ) {
          // console.log("yessssssss inside bro",formData.assignFacillator)
          handleInsert(formData.assignFacillator, formData.selectRoles);
        }
        if (
          formData.assignFacillator &&
          formData.selectRoles &&
          numFields + 1 === tempMax
        ) {
          // console.log("yessssssss inside bro",formData.assignFacillator)
          handleInsert(formData.assignFacillator, formData.selectRoles);
        }
      } catch (error) {
        // console.error("GraphQL Mutation Error:", error.message);
      }
    }
    handleNext();
    // console.log("durationssssssssssssss", formData["publish_to"]);
    // }
  };
  const [EventFaculty, { error: EventFacultyerror }] = useMutation(
    INSERT_FACILITATOR_QUERY,
    {
      onError: (EventFacultyerror) => {
        // console.error("Mutation Error:", EventFacultyerror.message);
      },
      onCompleted: (data) => {
        // Check if 'insert_event_faculty.returning' exists before accessing its properties
        if (
          data.insert_event_faculty?.returning &&
          data.insert_event_faculty.returning.length > 0
        ) {
        } else {
          console.error(
            "Mutation Error: 'returning' property not found or empty"
          );
        }
      },
    }
  );
  // console.log("event id loacl",localStorage.getItem("event_id"))

  const handleInsert = async (assignFacillator, selectRoles) => {
    let test = assignFacillator;
    let roles = selectRoles;
    console.log(test, "test and role", roles);
    try {
      const { data: insertedData } = await EventFaculty({
        variables: {
          user_id: test,
          faculty_type_id: roles,
          event_id: localStorage.getItem("event_id"),
        },
      });
      test = "";
      roles = "";

      console.log("instered event header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
    // }
  };

  const addDropdown = async () => {
    setNumFields(numFields + 1);
    settempMax(numFields + 2);
    console.log("adding dropdown", numFields + 1);
    if (formData.assignFacillator && formData.selectRoles && numFields === 0) {
      console.log("yessssssss inside bro", formData.assignFacillator);
      handleInsert(formData.assignFacillator, formData.selectRoles);
    }

    if (numFields > 0) {
      console.log("yessssssss inside bro", formData.assignFacillator);
      handleInsert(formData.assignFacillator, formData.selectRoles);
    }
    // handleInsert();
    console.log("yess");
  };

  // customermeeting api
  // const handlenext = async () => {
  //   try {
  //     if (!CallMode) {
  //       console.log('CallMode is false. Skipping API call.');
  //       return; // Exit the function if CallMode is false
  //     }
  //     const apiUrl = "https://portalapi.convenevc.com/api/customerMeetings/";
  //     const postData = {
  //       UniqueID: UniqueID,
  //       OrganizationID: OrganizationID,
  //       MeetingName: MeetingName,
  //       MaxMembersJoined: MaxMembersJoined,
  //       MeetingCreatorName: MeetingCreatorName,
  //       StartDate: StartDate,
  //       EndDate: EndDate,
  //       StartTime: formattedstartTime,
  //       EndTime: formattedendTime,
  //     };
  
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // Add any other headers if needed
  //       },
  //       body: JSON.stringify(postData),
  //     });
  
  //     if (response.ok) {
  //       // Handle successful API response
  //       const responseData = await response.json();
  //       console.log('API Response:', responseData);
  
  //       const { data: insertedData } = await insertEventHeaderInfo({
  //         variables: {
  //           _eq: route.params.eventid,
  //           closure_date: data["startDate"],
  //           publish_to: data["publishTo"],
  //           metadata: {
  //             duration: data["duration"],
  //             max_attendance: data["maxAttendance"],
  //             page_expi_date: data["startDate"],
  //             associated_group_id: "10",
  //           },
  //           publish_to: data["publishTo"],
  //           conevene_meeting_id:responseData?.meetingDetails?.CustomerMeetingID
  //         },
  //       });
  //     } else {
  //       // Handle error in API response
  //       console.error('API Error:', response.statusText);
  //     }
  //   } catch (error) {
  //     // Handle GraphQL mutation error
  //     console.error('GraphQL Mutation Error:', error.message);
  //   }
  // };

  return (
    // main code starts here

    <Box sx={{ display: "flex", maxWidth: "800px", flexWrap: "wrap" }}>
      <TextField
        sx={{
          width: "360px",

          m: 1,

          minWidth: "160px",

          "& .MuiInputBase-root": {
            fontFamily: "MontserratRegular",
          },

          "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
            fontFamily: "MontserratRegular",

            color: " #999999",

            fontSize: "14px",

            lineHeight: " 20px",
          },
        }}
        required
        size="small"
        value={formData.duration}
        onChange={(e) => {
          handleChange("duration", e.target.value);
        }}
        error={!!errors.duration}
        helperText={errors.duration}
        id="standard-required"
        label="Duration"
      />

      <TextField
        sx={{
          width: "360px",

          m: 1,

          minWidth: "160px",

          "& .MuiInputBase-root": {
            fontFamily: "MontserratRegular",
          },

          "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
            fontFamily: "MontserratRegular",

            color: " #999999",

            fontSize: "14px",

            lineHeight: " 20px",
          },
        }}
        required
        size="small"
        value={formData.attendanceCapacity}
        onChange={(e) => {
          handleChange("attendanceCapacity", e.target.value);
        }}
        error={!!errors.attendanceCapacity}
        helperText={errors.attendanceCapacity}
        id="standard-required"
        label="Max Attendance Capacity"
      />

      <FormControl
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography sx={{ fontFamily: "MontserratRegular", ml: 1, mr: 2 }}>
          It is
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={eventMode}
          onChange={handleEventModeChange}
          sx={{
            margin: 1,
            "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
              color: "#FF0000",
            },
          }}
        >
          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },

              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",

                color: "#999999",

                fontSize: 14,
              },
            }}
            value="group"
            control={<Radio />}
            label="Group Event"
          />

          <FormControlLabel
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "FF0000",
              },

              "& .css-ahj2mt-MuiTypography-root": {
                fontFamily: "MontserratRegular",

                color: "#999999",

                fontSize: 14,
              },
            }}
            value="standalone"
            control={<Radio />}
            label="Standalone Event"
          />
        </RadioGroup>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          // inputFormat="MM/DD/YYYY"

          format="YYYY/MM/DD"
          size="small"
          // label="Page Expiration Date"
          label={
            newDate?.pageExpire ? newDate?.pageExpire : "Page expiration date"
          }
          sx={{
            mt: 2,

            ml: 1,

            width: "360px",

            "& .MuiInputBase-root": {
              fontFamily: "MontserratRegular",

              height: "40px",
            },

            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
              fontFamily: "MontserratRegular",

              color: "#999999",

              fontSize: "14px",

              lineHeight: "10px",
            },
          }}
          onChange={(e) => {
            handleDateChange("pageExpire", e);
          }}
          value={formData.pageExpire}
          renderInput={(params) => (
            <TextField size="small" {...params} placeholder="Event Name" />
          )}
        />
      </LocalizationProvider>
      <Box sx={{ width: "95%" }}>
        {showBox && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={groupType}
            getOptionLabel={(option) => option.name}
            value={formData.associatedGroup}
            onChange={(e, value) => handleChange("associatedGroup", value)}
            size="small"
            sx={{
              width: "360px",

              m: 1,

              minWidth: "160px",

              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },

              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",

                color: " #999999",

                fontSize: "14px",

                lineHeight: " 20px",
              },
            }}
            renderInput={(params) => (
              <TextField {...params} label="Associated Groups" />
            )}
          />
        )}

        <Typography sx={{ fontFamily: "MontserratRegular", ml: 1 }}>
          Assign Facilitators
        </Typography>

        <Box
          sx={{
            ml: 5,

            "@media (max-width:768px)": {
              ml: 1,
            },
          }}
        >
          <Box
            sx={[
              { width: "100%", display: "flex", flexDirection: "row" },

              matches && { flexWrap: "wrap" },
            ]}
          >
            <FormControl
              sx={{
                width: "40%",

                minWidth: "320px",

                mt: 1,

                marginLeft: 1,

                mb: 1,

                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",

                  width: "320px",
                },

                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",

                  color: " #999999",

                  fontSize: "14px",

                  lineHeight: " 20px",
                },
              }}
            >
              <InputLabel id="demo-simple-select-label" size="small">
                Select Roles
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}

                value={formData.selectRoles}
                size="small"
                label="Select Roles "
                // onChange={(e)=>setSelectRole(e.target.value)}

                onChange={(e) => {
                  handleChange("selectRoles", e.target.value);
                }}
              >
                {facilitatorRole?.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <FormControl
              sx={{
                width: "40%",

                minWidth: "320px",

                mt: 1,

                mb: 1,

                marginLeft: "20px",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                  width: "320px",
                },
                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                },
                "@media (max-width:1000px)": {
                  marginLeft: "7px",
                  width: "320px",
                },
              }}
            >
              <InputLabel size="small">Assign Facilitators</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.assignFacillator}
                size="small"
                label=" Assign Facilitators"
                //   onChange={handleChange}

                onChange={(e) => {
                  handleChange("assignFacillator", e.target.value);
                }}
              >
                {staff?.map((item, index) => {
                  return (
                    <MenuItem value={item.user_id}>{item.first_name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Box
              sx={{
                display: "flex",

                width: "20%",

                justifyContent: "flex-end",

                alignItems: "center",
              }}
            >
              <Button onClick={addDropdown}>
                <img src={plusicon} alt="plusicon" height={30} width={30} />
              </Button>
            </Box>
          </Box>

          {fields}
        </Box>
      </Box>

      <Box
        sx={{
          width: "46%",

          "@media (max-width:768px)": {
            ml: 1,
          },
        }}
      >
        <Typography
          sx={{ fontFamily: "MontserratRegular", ml: "6px", mb: "5px" }}
        >
          Event Visibility
        </Typography>

        <Box
          sx={{
            display: "flex",

            flexDirection: "row",

            justifyContent: "flex-end",

            "@media (max-width : 768px)": {
              justifyContent: "inherit",
            },
          }}
        >
          <FormControl
            sx={{
              width: "50%",

              minWidth: "320px",

              mt: 1,

              marginLeft: 1,

              mb: 1,

              fontFamily: "MontserratRegular",

              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",

                width: "320px",
              },

              "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",

                color: " #999999",

                fontSize: "14px",

                lineHeight: " 20px",
              },
            }}
          >
            <InputLabel id="demo-simple-select-label" size="small">
              Publish To
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="publish-to"
              size="small"
              label="Publish to "
              value={formData.publish_to}
              required
              onChange={(e) => {
                handleChange("publish_to", e.target.value);
              }}
              error={!!errors.publish_to}
              helperText={errors.publish_to}
            >
              {publishto.map((item, index) => {
                return <MenuItem value={item.label}>{item.label}</MenuItem>;
              })}

              {/* <CheckBox label="Online" /> */}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",

          flexDirection: "row",

          width: "95%",

          justifyContent: "flex-end",

          "@media (max-width:768px)": {
            width: "50%",

            marginTop: "80px",
          },
        }}
      >
        <Button
          onClick={addData}
          variant="contained"
          sx={{
            ml: 2,

            width: "100px",

            color: "#FFFFFF",

            backgroundColor: "#FF0000",

            "&:hover": {
              backgroundColor: "#FF0000",
            },

            height: 30,
          }}

          // sx={[styles.btn, styles.textField]}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
