import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Styles from "../styles/GroupManagement";
import Navbar from "../components/Navbar";
import styles from "../styles/global";
import sortby from "../images/EventManagement1/sortby.png";
import GroupManagementCard from "../components/GroupManagement/GroupManagementCard";
import { useNavigate } from "react-router";
import { gql, useQuery } from "@apollo/client";
import { useLocation } from 'react-router-dom';

const GET_GROUP_QUERY = gql`
  query getAllGroups {
    group_master(where: {status: {_eq: "isactive"}}) {
      activation_date
      attendance_capacity
      category_id
      closure_date
      deactivation_date
      decription
      id
      cover_img_path
      level_up_group
      name
      price
      publish_to
      type
    }
  }
`;
const GET_PERMISSION_QUERY = gql`
query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
  role_master(where: {access_name: {_eq: $_eq}, _and: {roleprofile: {user_id: {_eq: $_eq1}, _and: {user: {defaultRole: {_eq: $_eq2}}}}}}) {
    access_name
    description
    id
    roles
    status
    roleprofile {
      user {
        defaultRole
      }
    }
  }
}

`;
export default function GroupManagement(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [group, setGroup] = useState([]);
  const [openPop, setOpenPop] = useState(false);
  const { loading, error, data , refetch } = useQuery(GET_GROUP_QUERY);
  // console.log("data of all groups" , data)
// console.log("Group management",data.group_master[4].cover_img_path);
  const Submit = (
    id,
    name,
    description,
    activation_date,
    deactivation_date,
    cover_img_path
  ) => {
    navigate("/groupmanagement2", {
      state: {
        name,
        id,
        description,
        activation_date,
        deactivation_date,
        cover_img_path,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setGroup(data.group_master);
    }
  }, [data]);
  if(location?.state?.isPublished){
    refetch()
  }
  const openPopUp = () => {
    setOpenPop(!openPop);
  };
  const {  data:permsission  } = useQuery(GET_PERMISSION_QUERY, {
    variables: {
      _eq:localStorage.getItem("roleaccess"),
      _eq1:localStorage.getItem("id")
    },
    onError:(error) =>{
      // console.log(error , "this is error")
    },
    onCompleted:(data)=>{
      // console.log("permission status :", data)
    }
  }); 
  const roll = localStorage.getItem('role')
  return (
    <Box sx={Styles.mainContainer}>
      {/*........................ navbar */}
      <Navbar />
      {/*........................ ends navbar */}

      <Box
        sx={[
          Styles.topContainer,
          { boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)" },
        ]}
      >
        <Typography sx={Styles.largeTxt}>Group Management</Typography>
        {
  (    (permsission?.role_master[0]?.roles?.addgroup && roll === "staff")   || roll==="admin") &&
    
        <Box
          sx={[
            styles.btnDashboard,
            {
              marginX: "5px",
              color: "#252525",
              border: "1px solid #252525",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "FF0000",
                color: "white",
                borderColor: "FF0000",
              },
            },
          ]}
          onClick={() => {
            navigate("/groupManagementvs");
          }}
        >
          Add Group
        </Box>
}
        {/* ...................btn........... */}
      </Box>

      <Box sx={[Styles.topContainer, { height: "7vh", minWidth: "336px" }]}>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "MontserratRegular",
            marginLeft: "2%",
            marginY: "auto",
            // fontWeight: "bold",
          }}
        >
          All Groups
        </Typography>
       
      </Box>

      {/* .....................card container */}
      <Box sx={Styles.cardContainer}>
        {group.map((item) => (
          <Box
            key={item.id}
            onClick={() =>
              Submit(
                item.id,
                item.name,
                item.decription,
                item.activation_date,
                item.deactivation_date,
                item.cover_img_path
              )
            }
          >
            <GroupManagementCard
              img={item.cover_img_path}
              title={item.name}
              desc={item.decription}
              startDate={item.activation_date}
              endDate={item.deactivation_date}
              id={item.id}
            />
          </Box>
        ))}
      </Box>
      {/* ..............card container end  */}
    </Box>
  );
}