import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
//import AddMemberToGroup from "./AddMemberToGroup";
import MembersCard from "./MembersCard";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_USER_ROLE = gql`
query GetUserRoleById($id: uuid = "") {
  users(where: {id: {_eq: $id}}) {
    defaultRole
  }
}
`;
const REMOVE_USER_GROUPS = gql`
mutation RemoveMapUsrGrps($group_id: bigint = "", $user_id: uuid = "") {
  delete_user_groups(where: {_and: {group_id: {_eq: $group_id}, user_id: {_eq: $user_id}}}) {
    returning {
      group_id
      id
      user_id
    }
  }
}`;

const GET_MEMBERS_DATA =gql`
query bookedMembers33($_eq: bigint = "") {
  user_events(where: {event_id: {_eq: $_eq}, _and: {users: {defaultRole: {_eq: "user"}}}}, distinct_on: userid) {
    users {
      displayName
      defaultRole
      email
      phoneNumber
      avatarUrl
    }
  }
}
`;

export default function BookedMembers(props) {
  // console.log("id of event from booked event  ",props.id);

  // console.log('first id is ',props.members[0]?.group_id)
  const [openpop, setOpenpop] = useState(false);
  const [card, setCard] = React.useState([]);
  const [temp,settemp]=useState([]);
  // console.log("refreshdata",temp)
   const handleMember=()=>{ 
    console.log("only for popup")
   
    setOpenpop(!openpop);
    }
    const {  data:memberdata,refetch } = useQuery(GET_MEMBERS_DATA, {
      variables: { 
        _eq: props?.id,
       },
    })
    useEffect(() => {
      // setCard(props.members);
      // setCard([...props.members, ...temp]);
     console.log("props.members",memberdata?.user_events)
   
        setCard(memberdata?.user_events);
     
    }, [memberdata]);
 

  

  const[roles , setRoles] = useState()
  const { loading, error, data } = useQuery(GET_USER_ROLE, {
    variables: { 
      id: localStorage.getItem('id'),
     },
  })
  useEffect(()=>{
    // console.log("data is every thing " , data)
    setRoles(data?.defaultRole)
    // console.log("chechking users : ", props.members)

  },[data])
  const [removeEventGroups,] = useMutation( REMOVE_USER_GROUPS,
    {
      onCompleted: (uid) =>(data) => {
        // console.log("group infor updated : ", data) 
      alert(`Removed  Event`)
    
     refetch();
      }
,

      refetchQueries: [
        // Provide the name of the query you want to refetch after deletion
        // For example:
        { query: GET_MEMBERS_DATA },
      ],
    })
  
  const deleteEvents = async (uid, gid) => {
    const confirmation = window.confirm(`Are you sure want to remove  member?`);
  
    if (confirmation) {
      // Perform the deletion logic
      try {
        const { data:insertedData } = await removeEventGroups({
          variables: {
            user_id:uid,
            group_id:gid
          },
        });
        // console.log("delted event from  group",insertedData)
          // Remove the deleted event from the events array in state
          // setCard(prevEvents => prevEvents.filter(event => event.id !== uid));
          setCard(prevEvents => prevEvents.filter(event => event.user_id !== uid));
          refetch();
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
      // ...
      
    }
 
  

  };

  return (
    <>
    <Box sx={{display:'flex' , flexDirection:'column' , width:'100%'}}  >

    <div style={styles.div}>
      {card?.map((item, index) => (
        <>
        {(props.user==="admin" || roles === "staff") &&
        <img src={require("../../images/cross.png")}  style={{ width: 25,height:25,cursor:"pointer"}} ></img>
      }
        <MembersCard name={item?.users?.displayName} email={item?.users?.email} phone={item?.users?.phoneNumber} img={item?.users?.avatarUrl}/>
        </>
      ))}

    </div>
    </Box>
    </>
  );
}

const styles = {
  div: {
    width: "100%",
    marginTop: "0px",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    "& .track-horizontal": {
      backgroundColor: 'blue',
      width: '100%',
      left: ' 0px',
      bottom: ' 0px',
      height: '10px !important',
    },

  },
  addbox: {
    display: "flex",
    position: "relative",
    // backgroundColor:"red",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  container: {
    width: "420px",
    minWidth: "420px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "130px",
    marginTop: "10px",
    margin: "10px",
    overflow: "hidden",
  },
  imgContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    //   height:"20px"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "40px",
    width: "100%",
  },
  titleTxt: {
    marginLeft: "10px",

    fontFamily: "MontserratRegular",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#252525",
    lineHeight: "20px",
  },
  desc: {
    fontFamily: "MontserratRegular",
    fontSize: "9px",
    textAlign: "left",
    fontWeight: "400",
    color: "#414042",
    lineHeight: "14.63px",
  },
  dateTime: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  baseTxt: {
    marginBottom: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "15px",
    // fontWeight: "500",
    color: "#999999",
    lineHeight: "18px",
  },
};