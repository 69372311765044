import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import styles from "../../styles/global";
import logolight from "../../images/logolight.png";
import { useState } from "react";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { useChangePassword } from "@nhost/react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
const UPDATEPASSWORD = gql`
  mutation ChangePassword(
    $passwordHash: String
    $email: citext
    $newPassword: String
  ) {
    updateUsers(
      where: { passwordHash: { _eq: $passwordHash }, email: { _eq: $email } }
      _set: { passwordHash: $newPassword }
    ) {
      returning {
        email
      }
    }
  }
`;
const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontSize: "10px",
    fontWeight: "bold",
    borderRadius:"10px"
  },
});

export default function ChangePassword(props) {
  //   const { onClose, selectedValue, open } = props;
  const [open, setOpen] = useState(true);
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({
    capitalLetter: "",
    specialCharacter: "",
    numericCharacter: "",
    minLength: "",
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  console.log("email", localStorage.getItem("email"));

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });

    validatePassword(key, value);

    if (key === "confirmpassword" && value !== data.newpassword) {
      // setError("Password does not match");
    } else {
      setError("");
    }

    if (key === "oldpassword" && value === oldPassword) {
      setShow(true);
    }
  };

  const validatePassword = (key, value) => {
    const updatedErrors = { ...errors };

    if (key === "newpassword") {
      const hasCapitalLetter = /[A-Z]/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*]/.test(value);
      const hasNumericCharacter = /[0-9]/.test(value);
      const hasMinLength = value.length >= 8;

      // updatedErrors.capitalLetter = hasCapitalLetter
      //   ? ""
      //   : "Password must contain at least one capital letter";
      // updatedErrors.specialCharacter = hasSpecialCharacter
      //   ? ""
      //   : "Password must contain at least one special character (!@#$%^&*)";
      // updatedErrors.numericCharacter = hasNumericCharacter
      //   ? ""
      //   : "Password must contain at least one numeric character";
      // updatedErrors.minLength = hasMinLength
      //   ? ""
      //   : "Password must be at least 8 characters long";

      setErrors(updatedErrors);
    }
  };

  console.log(data);

  const [updatepass] = useMutation(UPDATEPASSWORD, {
    onCompleted: (data) => {
      console.log("object", data);
    },
    onError: (error) => {
      console.log(error, "updating password error");
    },
  });
const { changePassword, isLoading, isSuccess, isError } =
    useChangePassword();
  const handleSave = async (id) => {
    if (data.newpassword === data.confirmpassword) {
      await changePassword(data.newpassword);
      alert("Password has been changed");
      handleClose();
    } else {
      // Display an error message if passwords don't match
      alert("Old Password and New Password does not match");
    }
    // try {
    //   const { data: newData } = await updatepass({
    //     variables: {
    //       passwordHash: data["oldpassword"],
    //       newPassword: data["newpassword"],
    //       email: localStorage.getItem("email"),
    //     },
    //   });
    //   console.log("Password updated successfully!");

    //   props.setOpen(false);
    // } catch (error) {
    //   console.error("GraphQL Mutation Error:", error.message);
    // }
    // .....................api ends here........................................
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = (field) => {
    switch (field) {
      case 'oldpassword':
        setShowOldPassword((prevShowPassword) => !prevShowPassword);
        break;
      case 'newpassword':
        setShowNewPassword((prevShowPassword) => !prevShowPassword);
        break;
      default:
        break;
    }
  };
  // ..............................fetch old pasword..........................................
  const [oldPassword, setOldPassword] = useState("");
  const [show, setShow] = useState(false);
  console.log("old password: ", oldPassword);

  // ...................................fetch password ..................................................

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // width: "643px",
            overflow: "hidden",
            borderRadius:"10px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              backgroundColor: "#FF0000",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img style={{ marginLeft: "10px" }} src={logolight} alt="img" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "543px",
              justifyContent: "center",
              "@media (max-width:768px)": {
                justifyContent: "center",
                width: "480px",
              },
            }}
          >
         
    <TextField
      variant="outlined"
      sx={Styles.textField}
      placeholder="Old Password"
      id="oldpassword"
      type={showOldPassword ? 'text' : 'password'}
      value={data.oldpassword}
      inputProps={{
        style: {
          height: '10px',
          marginTop: '10px',
        },
      }}
      onChange={(e) => handleChange('oldpassword', e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleTogglePasswordVisibility('oldpassword')}>
              {showOldPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />


    <TextField
      variant="outlined"
      sx={Styles.textField}
      placeholder="New Password"
      id="newpassword"
      type={showNewPassword ? 'text' : 'password'}
      value={data.newpassword}
      inputProps={{
        style: {
          height: '10px',
          marginTop: '10px',
        },
      }}
      onChange={(e) => handleChange('newpassword', e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleTogglePasswordVisibility('newpassword')}>
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />

<TextField
      variant="outlined"
      sx={Styles.textField}
      placeholder="Confirm Password"
      id="confirmpassword"
      type={showNewPassword ? 'text' : 'password'}
      value={data.confirmpassword}
      inputProps={{
        style: {
          height: '10px',
          marginTop: '10px',
        },
      }}
      onChange={(e) => handleChange('confirmpassword', e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleTogglePasswordVisibility('newpassword')}>
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />

            {/* <TextField
              variant="outlined"
              sx={Styles.textField}
              label="New Password"
              id="oldpassword"
              value={data.oldpassword}
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
              onChange={(e) => handleChange("oldpassword", e.target.value)}
            />

            <TextField
              variant="outlined"
              sx={Styles.textField}
              label="Confirm Password"
              id="newpassword"
              value={data.newpassword}
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
              onChange={(e) => handleChange("newpassword", e.target.value)}
            /> */}

            {show ? (
              <>
                {/* <TextField
                  sx={Styles.textField}
                  label="New Password"
                  id="newpassword"
                  value={data.newpassword}
                  inputProps={{
                    style: {
                      height: "10px",
                      marginTop: "10px",
                    },
                  }}
                  onChange={(e) => handleChange("newpassword", e.target.value)}
                /> */}

                {errors.capitalLetter && (
                  <Box sx={Styles.errorBox}>
                    <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                      {errors.capitalLetter}
                    </Typography>
                  </Box>
                )}
                {errors.specialCharacter && (
                  <Box sx={Styles.errorBox}>
                    <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                      {errors.specialCharacter}
                    </Typography>
                  </Box>
                )}
                {errors.numericCharacter && (
                  <Box sx={Styles.errorBox}>
                    <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                      {errors.numericCharacter}
                    </Typography>
                  </Box>
                )}

                {errors.minLength && (
                  <Box sx={Styles.errorBox}>
                    <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                      {errors.minLength}
                    </Typography>
                  </Box>
                )}

                {/* <TextField
                  sx={Styles.textField}
                  label="Confirm Password"
                  id="confirmpassword"
                  value={data.confirmpassword}
                  inputProps={{
                    style: {
                      height: "10px",
                      marginTop: "10px",
                    },
                  }}
                  onChange={(e) =>
                    handleChange("confirmpassword", e.target.value)
                  }
                /> */}
              </>
            ) : (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                  {/* Old password doesn't match */}
                </Typography>
              </Box>
            )}

            {error && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "FF0000" }}>{error}</Typography>
              </Box>
            )}
          </Box>

          <Box sx={Styles.buttonBox}>
            <Box
              sx={[
                styles.btnDashboard,
                {
                  width: "160px",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  // backgroundColor: "#999999",
                  backgroundColor:"#FF0000",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              // disabled={
              //   data.newpassword === data.oldpassword 
              // }
              onClick={handleSave}
            >
              Change Password
            </Box>
            <Box
              sx={[
                styles.btnDashboard,
                {
                  backgroundColor: "#ffffff",
                  color: "#999999",
                  border: "1px solid #999999",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              onClick={handleClose}
            >
              Cancel
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

const Styles = {
  textField: {
    margin: "8px",
    width: "400px",
    justifyContent: "center",
    "& fieldset": { border: "none" },
    ".MuiInputBase-root": {
      border: "1px solid #999999",
      borderRadius: "10px",
    },
    ".MuiOutlinedInput-root": {
      height: "50px",
    },
    "@media (max-width:768px)": {
      width: "320px",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    width: "",
    width: "465px",

    justifyContent: "flex-end",
    "@media (max-width:768px)": {
      width: "400px",
    },
  },
  errorBox: {
    width: "72%",
    display: "flex",
    justifyContent: "flex-start",
  },
};