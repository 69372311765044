import React from "react";

export default function BtnWhite(props) {
  const { btn_title } = props;
  return (
    <>
      <button className="btnContainer" style={style.btnCotainer}>
        {btn_title}
      </button>
    </>
  );
}
const style = {
  btnCotainer: {
    backgroundColor:'white',
    width: "100px",
    height: "30px",
    borderRadius: "10px",
    border: "none",
    fontFamily:'MontserratRegular',
    fontWeight:500,
    color:'#999999',
    justifyContent:'center',
    alignItems:'center',
    borderColor:'#999999',
    borderWidth: "1px",
    borderStyle: "solid",
    marginRight: "20px",
  },
};
