import { React, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import eventmanagement from "../images/AdminDashboard/Event Management.png";
import groupmanagement from "../images/AdminDashboard/Groups.png";
import usermanagement from "../images/AdminDashboard/User.png";
import feedmanagement from "../images/AdminDashboard/Feed.png";
import ststemConfiguration from "../images/AdminDashboard/Configuration.png";
import schedule from "../images/AdminDashboard/Schedule.png";
import sort from "../images/AdminDashboard/Sort.png";
import tasks from "../images/AdminDashboard/Tasks.png";
import styles from "../styles/global";
// import AddNewTask from "../components/Popup/NewTask";
import DashboardCard from "../components/AdminDashboard1/DashboardCard";
import TaskCard from "../components/AdminDashboard1/TaskCard";
import { useNavigate } from "react-router";
import NewTask from "../components/Popup/NewTask";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { gql, useQuery } from "@apollo/client";
import SystemConfig from "./SystemConfig";
import { useUserId } from "@nhost/react";

const GET_EVENTS_QUERY = gql`
  query getEvents {
    event_master(
      where: { parent_id: { _is_null: true }, status: { _eq: "isactive" } }
    ) {
      activation_date
      category_id
      closure_date
      created_at
      deactivation_date
      description
      id
      name
      cover_img_path
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      start_time
      end_time
      type_id
      updated_at
      location
    }
  }
`;

const GET_TASK_QUERY = gql`
  query GetAllTasks {
    task_master(where: { status: { _nilike: "deleted" } }) {
      description
      event_id
      file_path
      group_id
      id
      staff_id
      status
      target_date
      task_name
      user {
        displayName
        profile_detail {
          first_name
        }
      }
    }
  }
`;
const GET_USER_ROLE = gql`
  query getuserrole($_eq: uuid = "") {
    profile_details(where: { user_id: { _eq: $_eq } }) {
      roles
    }
  }
`;
const GET_PERMISSION_EVENT_MANAGMENT = gql`
  query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
    role_master(
      where: {
        access_name: { _eq: $_eq }
        _and: {
          roleprofile: {
            user_id: { _eq: $_eq1 }
            _and: { user: { defaultRole: { _eq: $_eq2 } } }
          }
        }
      }
    ) {
      access_name
      description
      id
      roles
      status
      roleprofile {
        user {
          defaultRole
        }
      }
    }
  }
`;
export default function AdminDashboard1(props) {
  const [EventsData, setEventsData] = useState([]);
  const userId = useUserId();
  const [refreshTask, setRefreshTask] = useState(false);
  const [CalendarId, SetCalendarId] = useState("");

  const [isUser, setisUser] = useState(true);

  const [EventName, setEventName] = useState("");

  // const { loading, error1, data } = useQuery(GET_EVENTS_QUERY);

  // const { loading, error1, data } = useQuery(GET_TASK_QUERY);

  const {
    loading: eventsLoading,

    error: eventsError,

    data: eventsData,
  } = useQuery(GET_EVENTS_QUERY);

  const {
    loading: tasksLoading,
    error: tasksError,
    data: tasksData,
    refetch: taskrefetch,
  } = useQuery(GET_TASK_QUERY);

  useEffect(() => {
    // console.log("task for events", eventsData?.event_master);

    setEventsData(eventsData?.event_master);
    SetCalendarId(eventsData?.event_master[0]?.id);
    settasks(tasksData?.task_master);
  }, [eventsData, tasksData]);
  let roletempname = "";
  const { data: roleprofile } = useQuery(GET_USER_ROLE, {
    variables: {
      _eq: userId,
    },
    onError: (error) => {
      // console.log("this is error for role" , error)
    },
    onCompleted: (data) => {
      console.log("on complete data", data);
      localStorage.setItem("roleaccess", data?.profile_details[0]?.roles);
      roletempname = data?.profile_details[0]?.roles;
      refetch();
    },
    // skip: !id
  });
  const roll = localStorage.getItem("role");

  const { data: permsissionevntmngmnt, refetch } = useQuery(
    GET_PERMISSION_EVENT_MANAGMENT,
    {
      variables: {
        _eq: roleprofile?.profile_details[0]?.roles,
        _eq1: userId,
      },
      onError: (error) => {
        console.log(error, "this is error");
      },
      onCompleted: (data) => {
        // console.log("permission status for event :", data.role_master[0].roles.evntmngmnt)
      },
    }
  );

  useEffect(() => {
    if (refreshTask) {
      taskrefetch();
    }
  }, [refreshTask]);
  // console.log("events data in schedule",EventsData[0].id)
  // console.log("id for cal", CalendarId);
  const navigate = useNavigate();
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);
  const [view, setView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [selectedEventId, setSelectedEventId] = useState(null);
  const calendarStyle = {
    fontFamily: "MontserratRegular",
    color: "#252525",
    "& .rbc-calendar": {
      fontFamily: "MontserratRegular",
      backgroundColor: "red",
      "& ..rbc-event": { backgroundColor: "#FF0000" },
      // height:"80px"
    },
    "& ..rbc-event, .rbc-day-slot .rbc-background-event": {
      backgroundColor: "#FF0000",
    },
  };

  const handleEventClick = (
    event,
    id,
    name,
    recurrance_start_date,
    recurrance_end_date,
    location,
    deac,
    imgUrl,
    event_category,
    start_time,
    end_time,
    recurrance_frequency
  ) => {
    setisUser(false);
    let membership1 = true;
    console.log("it is calendar id ::", id);
    navigate("/eventmanagement2", {
      state: {
        isUser: isUser,
        id,
        event,
        membership1,
        name,
        recurrance_start_date,
        recurrance_end_date,
        location,
        deac,
        imgUrl,
        event_category,
        start_time,
        end_time,
        recurrance_frequency,
      },
    });
    setEventName(name);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.backgroundColor || "#FF0000"; // Set a default background color if none is provided
    const style = {
      backgroundColor,
      borderRadius: "10px",
      opacity: isSelected ? 1 : 0.9,
      color: "white",
      border: "0",
      display: "block",
      height: "100%",
      marginTop: "0px",
      paddingTop: "0px",
      fontSize: "14px",
      fontFamily: "MontserratRegular",
    };
    return {
      style,
    };
  };

  const onViewChange = (view) => {
    setView(view);
  };

  const onNavigate = (date) => {
    setDate(date);
  };

  const openNewTask = () => {
    setNewTaskOpen(!newTaskOpen);
  };

  const [Tasks, settasks] = useState([]);
  useEffect(() => {
    const eventsArr = EventsData?.map((event) => ({
      id: event.id,
      title: event.name,
      start: new Date(event.recurrance_start_date),
      end: new Date(event.recurrance_end_date),
      location: event.location,
      description:event.description,
      imgUrl: event.cover_img_path,
      start_time:event.start_time,
      end_time:event.end_time
    }));
    setEvents(eventsArr);
  }, [EventsData]);

  return (
    <Box sx={Styles.mainContainer}>
      <Box>
        {/*........................ navbar */}
        <Navbar />
        {/*........................ ends navbar */}
        {/* ........................ upper cards */}
        <Box sx={Styles.upperCards}>
          {((permsissionevntmngmnt?.role_master[0]?.roles?.evntmngmnt &&
            roll === "staff") ||
            roll === "admin") && (
            <Box
              sx={Styles.boxContainer}
              onClick={() => {
                navigate("/eventmanagement1");
              }}
            >
              <Box sx={Styles.imgContainer}>
                <img
                  src={eventmanagement}
                  alt="event management"
                  width={75}
                  height={75}
                />
              </Box>

              <Box sx={Styles.textContainer}>
                <Typography
                  sx={{ fontFamily: "MontserratRegular", fontWeight: "600" }}
                >
                  Event Management
                </Typography>
              </Box>
            </Box>
          )}
          {((permsissionevntmngmnt?.role_master[0]?.roles?.grpmngmnt &&
            roll === "staff") ||
            roll === "admin") && (
            <Box
              sx={Styles.boxContainer}
              onClick={() => {
                navigate("/groupmanagement");
              }}
            >
              <Box sx={Styles.imgContainer}>
                <img
                  src={groupmanagement}
                  alt="group management"
                  width={75}
                  height={75}
                />
              </Box>

              <Box sx={Styles.textContainer}>
                <Typography
                  sx={{ fontFamily: "MontserratRegular", fontWeight: "600" }}
                >
                  Group Management
                </Typography>
              </Box>
            </Box>
          )}
          {((permsissionevntmngmnt?.role_master[0]?.roles?.usrmngmnt &&
            roll === "staff") ||
            roll === "admin") && (
            <Box
              sx={Styles.boxContainer}
              onClick={() => {
                navigate("/usermanagement");
              }}
            >
              <Box sx={Styles.imgContainer}>
                <img
                  src={usermanagement}
                  alt="user management"
                  width={75}
                  height={75}
                />
              </Box>

              <Box sx={Styles.textContainer}>
                <Typography
                  sx={{ fontFamily: "MontserratRegular", fontWeight: "600" }}
                >
                  User Management
                </Typography>
              </Box>
            </Box>
          )}
          {((permsissionevntmngmnt?.role_master[0]?.roles?.fdmngmnt &&
            roll === "staff") ||
            roll === "admin") && (
            <Box
              sx={Styles.boxContainer}
              onClick={() => {
                navigate("/feedmanagement");
              }}
            >
              <Box sx={Styles.imgContainer}>
                <img
                  src={feedmanagement}
                  alt="feed management"
                  width={75}
                  height={75}
                />
              </Box>

              <Box sx={Styles.textContainer}>
                <Typography
                  sx={{ fontFamily: "MontserratRegular", fontWeight: "600" }}
                >
                  Feed Management
                </Typography>
              </Box>
            </Box>
          )}
          {((permsissionevntmngmnt?.role_master[0]?.roles?.systmconfig &&
            roll === "staff") ||
            roll === "admin") && (
            <Box
              sx={Styles.boxContainer}
              onClick={() => {
                navigate("/systemconfig");
              }}
            >
              <Box sx={Styles.imgContainer}>
                <img
                  src={ststemConfiguration}
                  alt="System Configuration"
                  width={75}
                  height={75}
                />
              </Box>

              <Box sx={Styles.textContainer}>
                <Typography
                  sx={{ fontFamily: "MontserratRegular", fontWeight: "600" }}
                >
                  System Configuration
                </Typography>
              </Box>
            </Box>
          )}
          {/* <NewTask /> */}
        </Box>

        {/* ........................ end upper cards */}

        {/*........................ lower cards */}

        <Box sx={Styles.bottomCards}>
          {/* ...................first table */}

          <Box sx={[Styles.tableContainer, { marginRight: "10px" }]}>
            <Box sx={Styles.tableTop}>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",

                  marginLeft: "2px",

                  padding: "4px",
                }}
              >
                <img src={schedule} alt="schedule" width="20px" height="20px" />

                <Typography
                  sx={{
                    color: "white",

                    fontFamily: "MontserratRegular",

                    marginLeft: "4px",
                  }}
                >
                  Schedule
                </Typography>
              </Box>

              <Box sx={{ marginRight: "10px" }}>
                {/* <img src={sort} alt="sort" width="20px" height="20px" /> */}
              </Box>
            </Box>

            <Box sx={[Styles.tableBottom, { overflowY: "hidden" }]}>
              <Calendar
                ref={calendarRef}
                localizer={localizer}
                events={events}
                view={view}
                onView={onViewChange}
                date={date}
                onNavigate={onNavigate}
                style={calendarStyle}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={(event) =>
                  handleEventClick(
                    event,
                    event.id,
                    event.title,
                    event.start,
                    event.end,
                    event.location,
                    event.description,
                    event.imgUrl,
                    event.event_category,
                    event.start_time,
                    event.end_time,
                    event.recurrance_frequency
                  )
                }
              />
            </Box>
          </Box>

          {/*...................end first table */}

          {/*...................second table */}
          {((permsissionevntmngmnt?.role_master[0]?.roles?.addtask &&
            roll === "staff") ||
            roll === "admin") && (
            <Box sx={[Styles.tableContainer, { marginLeft: "10px" }]}>
              <Box sx={[Styles.tableTop, { backgroundColor: "#999999" }]}>
                <Box
                  sx={{
                    display: "flex",

                    flexDirection: "row",

                    marginLeft: "2px",

                    padding: "4px",
                  }}
                >
                  <img src={tasks} alt="tasks" width="20px" height="20px" />

                  <Typography
                    sx={{
                      color: "white",

                      fontFamily: "MontserratRegular",

                      marginLeft: "4px",
                    }}
                  >
                    Tasks
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",

                    flexDirection: "row",

                    marginRight: "10px",

                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={[
                      styles.btnDashboard,

                      {
                        marginX: "5px",

                        fontSize: "14px",

                        backgroundColor: "#999999",

                        border: "1px solid white",

                        "&:hover": { cursor: "pointer" },
                      },
                    ]}
                    onClick={openNewTask}
                  >
                    Add Task
                  </Box>

                  {/* <img src={sort} alt="sort" width="20px" height="20px" /> */}
                </Box>
              </Box>

              <Box sx={Styles.tableBottom}>
                {Tasks?.map((item, index) => (
                  <TaskCard
                    id={item.id}
                    item={item}
                    title={item.task_name}
                    desc={item.description}
                    status={item.status}
                    assignee={item.user?.profile_detail.first_name}
                    date={item.target_date}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>

        {/*........................ ends lower cards */}

        {newTaskOpen && (
          <NewTask
            open={newTaskOpen}
            setNewTaskOpen={setNewTaskOpen}
            setRefreshTask={setRefreshTask}
          />
        )}
      </Box>

      {/* <Outlet /> */}

      {/* <Routes>

        <Route path="systemconfig" element={<SystemConfig />} />

      </Routes> */}
    </Box>
  );
}

const Styles = {
  mainContainer: {
    width: "100%",

    height: "100vh",

    justifyContent: "center",

    alignItems: "center",

    overflowX: "hidden",

    // backgroundColor: "lightgray",
  },

  upperCards: {
    width: "100%",

    // height: "40vh",

    display: "flex",

    flexWrap: "wrap",

    justifyContent: "center",

    alignItems: "center",

    flexDirection: "row",

    margin: "auto",

    marginTop: "15px",

    marginBottom: "15px",

    "@media (max-width:600px)": {
      margin: "0px",
    },
  },

  boxContainer: {
    height: "280px",

    width: "17.5%",

    minWidth: "240px",

    display: "flex",

    flexDirection: "column",

    justifyContent: "center",

    alignItems: "center",

    minWidth: "220px",

    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",

    "&:hover": {
      boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.25) ",
    },

    "&:active": {
      boxShadow: " 0px 0px 12px #777777",
    },

    borderRadius: "10px",

    margin: "11px",

    padding: "4px",

    cursor: "pointer",

    "@media (max-width: 600px)": {
      // apply these styles for screen sizes less than 600px

      height: "150px",

      width: "150px",
    },
  },

  imgContainer: {
    height: "80%",

    display: "flex",

    justifyContent: "center",

    alignItems: "center",

    margin: "auto",
  },

  img: {
    width: "10px",
  },

  textContainer: {
    height: "20%",

    fontFamily: "MontserratRegular",

    textAlign: "center",

    fontSize: "20px",

    margin: "10px",

    "@media (max-width: 600px)": {
      // apply these styles for screen sizes less than 600px

      fontSize: "5px",

      padding: "10px",
    },
  },

  bottomCards: {
    width: "99.6%",

    // height: "45vh",

    display: "flex",

    flexDirection: "row",

    flexWrap: "wrap",

    margin: "auto 10px auto 10px",

    // backgroundColor: "pink",
  },

  tableContainer: {
    width: "47.6%",

    minWidth: "320px",

    display: "flex",

    flexDirection: "column",

    justifyContent: "center",

    "@media (max-width:600px)": {
      justifyContent: "center",

      width: "80%",

      margin: "auto",

      marginTop: "25px",

      marginBottom: "25px",
    },
  },

  tableTop: {
    width: "100%",

    height: "45px",

    backgroundColor: "#FF0000",

    marginLeft: "2%",

    borderRadius: "10px 10px 0px 0px",

    display: "flex",

    flexDirection: "row",

    justifyContent: "space-between",

    alignItems: "center",

    "@media (max-width:600px)": {
      width: "320px",

      margin: "auto",
    },
  },

  tableBottom: {
    width: "100%",

    height: "260px",

    marginLeft: "2%",

    marginBottom: "20px",

    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",

    borderRadius: "0px 0px 10px 10px",

    display: "flex",

    flexDirection: "column",

    overflowY: "scroll",

    overflowX: "hidden",

    "@media (max-width:600px)": {
      width: "320px",

      margin: "auto",

      overflowX: "scroll",
    },

    scrollbarWidth: "auto",

    "&::-webkit-scrollbar": {
      width: "0.2em",
    },

    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
};
