import { Box, Button, Typography } from "@mui/material";
import {React,useEffect,useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { gql, useQuery,useMutation } from '@apollo/client'
import { useUserEmail } from "@nhost/react";

const GET_ALL_ROLES=gql`
query getallroles($_eq: String = "true") {
  role_master(where: {status: {_eq: $_eq}}) {
    access_name
    description
    roles
    id
    status
  }
}
`
const DELETE_ALL_ROLES=gql`
mutation updateroles($_eq: bigint = "", $status: String = "deactive") {
  update_role_master(where: {id: {_eq: $_eq}}, _set: {status: $status}) {
    returning {
      access_name
      status
    }
  }
}
`
const INSERT_LOGS_MASTER = gql`
  mutation MyMutation5(
    $content_type: String = ""
    $deleted_by: String = ""
    $deleted_frmtable: String = ""
    $deleted_from: String = ""
    $deleted_ip: String = ""
    $deleted_region: String = ""
    $deleted_time: timestamptz = ""
  ) {
    insert_logs_master(
      objects: {
        content_type: $content_type
        deleted_by: $deleted_by
        deleted_frmtable: $deleted_frmtable
        deleted_from: $deleted_from
        deleted_ip: $deleted_ip
        deleted_region: $deleted_region
        deleted_time: $deleted_time
      }
    ) {
      returning {
        content_type
        deleted_by
        deleted_frmtable
        deleted_time
      }
    }
  }
`;
const useStyles = makeStyles({
    table: {
      minWidth: 600,
      width: '100%',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '10px',
    //   borderRadius:"10px"
    },
    tableRow: {
        height: 20
      },
      tableCell: {
        padding: "0px 16px",
        height:5,
        fontFamily:"MontserratRegular"
      },
      header: {
        height: 30
      },
      headerCell:{
        padding: "5px 16px",
        height:15,
        color: "white",
        fontFamily:"MontserratRegular"
      }
  });

 

export default function RolesManagment() {
  const navigate = useNavigate();
  function handleNav() {
    navigate("/addroles");
  }
  const userName1 = useUserEmail();
  const currentTime = new Date();
  const roll = localStorage.getItem('role')
  const userDetails = useSelector((state)=>state.user.users);
  const {userName, userEmail,memberCategory,userMobile,userCity} = userDetails;
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [data, setData] = useState();
  // console.log(userDetails)
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const [existUser , setExistingUser] = useState([])
  const [profile , setProfile] = useState([])
  const { data:user  , refetch} = useQuery(GET_ALL_ROLES,{
    onCompleted:(data)=>{
      setExistingUser(data?.role_master)
      // console.log("all data" , data.role_master)
    }
  });
  useEffect(() => {
    setExistingUser(user?.role_master)
  
    },[user])

  const [deleterole] = useMutation( DELETE_ALL_ROLES,
    {
      onCompleted: (data) => {
     refetch();

        console.log("group infor updated : ", data) 
      alert(`Removed  Role ${data.update_role_master.returning[0].access_name}`)
    
      },
      refetchQueries: [
        // Provide the name of the query you want to refetch after deletion
        // For example:
         { query: GET_ALL_ROLES}
      ],
    })
  const [usersInfo , setusersInfo] = useState([])
  const [insertLogs] = useMutation(INSERT_LOGS_MASTER);

  const filteredUsers = profile.filter(user => {
    return !usersInfo.some(userInfo => userInfo.id === user.user_id);
  });
  const handleDeleteRow = async (id,role_name) => {
    try {
      const { data:insertedData } = await deleterole({
        variables: {
          _eq:id   
        },
      });
      const { data: insertedDataw } = await insertLogs({
        variables: {
          content_type: role_name,
          deleted_by: userName1,
          deleted_frmtable:"role_master",
          deleted_from:"role management",
          deleted_ip:"-",
          deleted_region:'-',
          deleted_time:currentTime,
        },
      });
        // refetch();
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

  };

  useEffect(()=>{
    refetch()
  },[])
  const [isForUpdate , setIsForUpdate] = useState(true)
  const handleApprove = async (row)=>{
// console.log("in the roles managment",row.roles)
    navigate("/addroles",{state: {id:row.id,updaterole:true,roledata:row.roles,row}})
  }

  const filtereData = existUser?.filter((row) =>
  (row.access_name && row.access_name.toLowerCase().includes(filter.toLowerCase())) ||
  (row.description && row.description.toLowerCase().includes(filter.toLowerCase())) 
);

  return (
    <Box>
      <Box sx={Styles.tableBox}>
      <div className={classes.searchContainer}>
        <TextField
          label="Search Here"
          value={filter}
          style={{ "& .MuiInputBase-root": {
            fontFamily: "MontserratRegular",
          },}}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <IconButton size="small">
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
         <Box
          sx={[
            Styles.btnDashboard,
            {
              marginX: "5px",
              color: "#252525",
              border: "1px solid #252525",
              "&:hover": {
                cursor: "pointer",
                borderColor: "FF0000",
              },
            },
          ]}
          onClick={handleNav}
        >
          Add Roles
        </Box>
      </div>
      
      <Box style={Styles.tableBox2}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header} style={{backgroundColor:"#999999",height:"20px",color:"white"}} >
            <TableRow  >
              <TableCell className={classes.headerCell}  align="center">Role Name</TableCell>
              <TableCell className={classes.headerCell} align="center">Description</TableCell>
              
              <TableCell className={classes.headerCell} align="center">Edit</TableCell>
              <TableCell className={classes.headerCell} align="center"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {filtereData?.map((row) => (
              <TableRow className={classes.tableRow} >
                <TableCell className={classes.tableCell}  align="center" component="th" scope="row">
                  {row.access_name}
                </TableCell>
                
                <TableCell className={classes.tableCell}  align="center">{row.description}</TableCell>
              

                <TableCell className={classes.tableCell} align="center">
                <IconButton onClick={() => handleApprove(row)} >
                    <img src={require('../../images/view.png')}/>
                  </IconButton>
                </TableCell>

                <TableCell className={classes.tableCell} align="center">
                  <IconButton onClick={() => handleDeleteRow(row.id,row.access_name)}>
                    <img src={require('../../images/delete.png')}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  mainContainer: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  btnDashboard:{
    // backgroundColor: "#FF0000",
    width: "85px",
    borderWidth: "1px",
    height: "30px",
    borderRadius: "10px",
    fontFamily:'MontserratRegular',
    fontWeight:500,
    fontStyle:'normal',
    fontSize:'12px',
    display:'flex',
    lineHeight:'20px',
    letterSpacing:'0.5px',
    color:'white',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    padding:'auto',
    margin:'auto',
 },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tableBox:{
    width: "100%",
    height: "540px",
    borderRadius: " 10px",
    overflowX:"hidden",
    // display:'flex',
    // // flexDirection:'row',
    "&::-webkit-scrollbar": {
        width: "0.5em",
        height: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        background: "#E1E5F8",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
  },
  tableBox2: {
    width:"98%",
    justifyContent:"center",
    alignItems: "center",
    textAlign: "center",
    margin:"auto",
    marginBottom: "15px",
  }
};