import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import edit from '../../images/editGray.png';
import deleteimg from "../../images/delete.png";



  const theme = createTheme({
    typography: {
      fontFamily: "MontserratRegular",
      marginLeft:"4px",
      color:"#999999",
      fontWeight:"bold",
    },
    
  });
  
  

 
export default function EditDeletePopUp(props) {
  const { onClose, open } = props;
  
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };


 

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <ThemeProvider theme={theme}>
    <Dialog
      sx={{
        marginTop:"0px",
        marginLeft:'78%',
        marginBottom:'18%',
      }}
      open={open}
      onClose={handleClose}

    >
    <Box sx={{display:"flex",flexDirection:"column",margin:"auto"}}>
        <Box sx={{display:"flex",flexDirection:"row",margin:"8px",alignItems:"center"}}>
            <img src={edit} alt="edit" width="20px" height="20px"/>
            <Typography sx={{marginLeft:"4px"}}>Edit Event</Typography>
        </Box>

        <Box sx={{display:"flex",flexDirection:"row",margin:"8px",alignItems:"center"}}>
            <img src={deleteimg} alt="delete" width="20px" height="20px"/>
            <Typography sx={{marginLeft:"4px"}}>Delete Event</Typography>
        </Box>


    </Box>
    </Dialog>
    </ThemeProvider>
  );
}

