import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import styles from "../../styles/global";
import mblStyles from "../../styles/mblglobal";
import HeaderInformation from "./HeaderInformation";
import EventConfirguration from "./EventConfirguration";
import UploadContent from "./UploadContent";
import AdministrativeTask from "./Table";
import Navbar from '../Navbar'
import { useNavigate } from "react-router";


export default function VerticalStepper() {
  const navigate = useNavigate();

  const matches = useMediaQuery("(max-width:1000px)");

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    console.log(data);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [data, setData] = React.useState([]);
  const [date,setDate] = React.useState(new Date());
  const [startTimeConvene,setstartTimeConvene] = React.useState(new Date());
console.log("this is data in event management vs pp :",data)
  const steps = [
    {
      label: "Header Information",
      form: (
        <HeaderInformation
          data={data}
          setData={setData}
          date={date}
          setDate={setDate}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setstartTimeConvene={setstartTimeConvene}
        />
      ),
    },
    {
      label: "Event Configuration",
      form: (
        <EventConfirguration
          data={data}
          setData={setData}
          date={date}
          setDate={setDate}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          startTimeConvene={startTimeConvene}
        />
      ), 
    },
    {
      label: "Content",
      form: (
        <UploadContent
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Administrative Task",
      form: (
        <AdministrativeTask
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <Box sx={[style.topContainer, { boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)", }]}>

        <Typography
          sx={[
            style.largeTxt,
          ]}
        >
          Event Management
        </Typography>
      </Box>

      <Box sx={[style.topContainer, { height: "7vh", minWidth: "336px" }]}>

        <Typography sx={{
          color: "#252525",
          fontFamily: "MontserratRegular",
          marginLeft: "2%",
          cursor: "pointer",
        }}
        onClick={
          () => {
            navigate("/eventmanagement1");
          }}
        
        >
          Event Management
        </Typography>

        <Typography sx={{
          color: "#252525",
          fontFamily: "MontserratRegular",
          marginLeft: "4px",
        }}>
          {'>'} Create Event
        </Typography>

      </Box>

      <Box sx={{ width: "90%", mx: 5 }}>
      <Stepper activeStep={activeStep} orientation="vertical"  sx={[
          { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {color: '#FF0000'}},
          { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed ": {color: '#FF0000'}}
        ]}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 3 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                <Typography sx={[styles.subTitle]}>{step.label}</Typography>
              </StepLabel>
              <StepContent>
                {step.form}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={[{ mt: 1, mr: 1 }]}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={[styles.btn, { mt: 1, mr: 1, width: "200px" }]}
            >
              Create Institutes
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
}
const style = {
  topContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    minWidth: '336px',
    margin: "auto",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: '2%',
  },

}