import { Box, Typography, Button } from "@mui/material";
import {React,useState} from "react";
import Navbar from "../Navbar";
import EventClosureTable from "./EventClosureTable";
import CloseEventConfirmationPopUp  from "./../Popup/CloseEventConfirmationPopUp";

export default function EventClosure() {
  let eventName = "Go Digital Stay Human";
  let groupName = "Inner Group";
  let eventCat = "Event Category 1";
  let date = "13/02/2023";
  let type = "Online";
  let time = "10:30 AM to 01:30 PM";

  const [openPopUp, setOpenPopUp] = useState(false);
  const open = () => {
    setOpenPopUp(!openPopUp);
  };

  return (
    <Box>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography sx={Styles.largeTxt}>
          Go Digital Stay Human {">"} Complete Event {">"} Attendance Report
        </Typography>
        <Box
          sx={{
            marginX: "5px",
            fontSize: "12px",
            backgroundColor: "white",
            color: "#252525",
            borderColor: "#252525",
          }}
        >
          <Button sx={Styles.btn}>
            <img
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
              src={require("../../images/download.png")}
            />{" "}
            Download
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={Styles.upperBox}>
          <Box sx={[Styles.textBox, { marginLeft: "22px" }]}>
            <Typography sx={Styles.text1}>
              Event Name :{" "}
              <Typography sx={Styles.text2}> {eventName}</Typography>
            </Typography>
            <Typography sx={Styles.text1}>
              Associated Group Name :{" "}
              <Typography sx={Styles.text2}> {groupName}</Typography>
            </Typography>
          </Box>
          <Box sx={Styles.textBox}>
            <Typography sx={Styles.text1}>
              Event Category :{" "}
              <Typography sx={Styles.text2}> {eventCat}</Typography>
            </Typography>
            <Typography sx={Styles.text1}>
              Date : <Typography sx={Styles.text2}> {date}</Typography>
            </Typography>
          </Box>
          <Box sx={Styles.textBox}>
            <Typography sx={Styles.text1}>
              Event Type : <Typography sx={Styles.text2}> {type}</Typography>
            </Typography>
            <Typography sx={Styles.text1}>
              Time : <Typography sx={Styles.text2}> {time}</Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "95%",
          margin: "auto",
          marginTop: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        
        
      </Box>
      <Box sx={Styles.tableBox}>
        <EventClosureTable />
      </Box>
      <Box
        sx={{
          width: "95%",
          margin: "auto",
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
          marginY:"10px",
        }}
      >
        
        <Box>
          <Button sx={Styles.btnRes}>Save</Button>
          <Button sx={Styles.btnSave} onClick={open}>Submit</Button>
        </Box>
      </Box>
      {openPopUp && (
        <CloseEventConfirmationPopUp
         open={openPopUp}
         setOpenPopUp={setOpenPopUp}

        />
      )}
    </Box>
  );
}
const Styles = {
  topContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
    "@media (max-width: 668px)": {
      width: "100%",
      display: "flex",
      fontSize: "14px",
    },
  },
  btn: {
    border: "1px solid #252525",
    boxShadow: "(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    height: "35px",
    marginRight: "25px",
    "&:hover": {
      backgroundColor: "#999999",
      color: "#ffffff",
    },
    "@media (max-width: 668px)": {
      right:0,
      top:104,
      position:"absolute",
     width:"130px",
     height:"25px"
    },
  },
  upperBox: {
    width: "95%",
    height: "80px",
    marginTop: "20px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: " 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    overflow: "hidden",
    flexWrap: "wrap",
    "@media (max-width: 668px)": {
      flexDirection: "column",
      overflow: "scroll",
    },
  },
  textBox: {
    width: "22.33%",
    flexDirection: "row",
    "@media (max-width: 668px)": {
      width: "70%",
      flexDirection: "row",
      marginTop: "20px",
    },
  },
  text1: {
    fontFamily: "MontserratSemiBold",
    color: "#252525",
    fontSize: "14px",
    marginBottom: "10px",
    display: "flex",
    "@media (max-width: 668px)": {
      fontSize: "12px",
    },
  },
  text2: {
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    "@media (max-width: 668px)": {
      fontSize: "12px",
    },
  },
  info: {
    width: "20px",
    height: "20px",
  },
  btnRes: {
    ml: 2,
    width: "100px",
    color: "FF0000",
    backgroundColor: "#ffffff",
    border: "1px solid #999999",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#999999",
      color: "#ffffff",
    },
    height: 30,
    fontFamily:'MontserratRegular',
  },
  btnSave: {
    ml: 2,
    width: "100px",
    color: "#FF0000",
    backgroundColor: "#ffffff",
    border: "1px solid #FF0000",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#FF0000",
      color: "#ffffff",
    },
    height: 30,
    fontFamily:'MontserratRegular',

  },
  tableBox: {
    width: "95%",
    margin: "auto",
    marginTop: "20px",
  },
};
