import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import ConfirmPasswordPopUp from "./ConfirmPasswordPopUp";
import ChangePassword from "./ChangePassword";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import {  useAccessToken, useFileUpload, useFileUploadItem, useMultipleFilesUpload,id, useSignOut, useAuthenticationStatus, useUserId } from '@nhost/react'
import { useNhostClient } from "@nhost/react";
import { useNavigate } from "react-router-dom";
import AddNewRole from "../Popup/AddNewRole";
import { gql, useQuery, useMutation } from "@apollo/client";
// import { Storage } from "@nhost/client";
import {
  createFileUploadMachine,
  FileItemRef,
  FileUploadMachine,
  FileUploadState,
  StorageUploadFileParams,
  UploadFileHandlerResult,
  uploadFilePromise
  
} from '@nhost/nhost-js'
import ReportIssue from "./ReportIssue";
import BlockedUser from "./BlockedUser";

// import fs from 'fs';


const FILE_NAME = 'special-name.pdf';
const FILE_PATH = './tests/assets/sample.pdf';

const GET_USER_BY_ID = gql`
query GetUserById($id: uuid) {
  users(where: {id: {_eq: $id}}) {
    defaultRole
    displayName
    email
    id
    emailVerified
    phoneNumber
    avatarUrl
  }
}
`;

const GET_PROFILE_DETAILS = gql`
query GetDataFromProfile($user_id: uuid) {
  profile_details(where: {user_id: {_eq: $user_id}}) {
    bio
    birth_date
    first_name
    last_name
    second_name
    user_id
    address1
    address2
    city
    postalcode
    birth_date
    category
    bio
  }
}
`;

const UPDATE_USER = gql`
mutation UpdateUserProfile($user_id: uuid, $bio: String, $birth_date: date,  $first_name: String, $last_name: String, $address1: String, $address2: String, $profile_pic_path: String, $second_name: String, $city: String = "", $postalcode: bigint = "") {
  update_profile_details(where: {user_id: {_eq: $user_id}}, _set: {bio: $bio, birth_date: $birth_date, first_name: $first_name, last_name: $last_name, profile_pic_path: $profile_pic_path, second_name: $second_name, address2: $address2, address1: $address1, city: $city, postalcode: $postalcode}) {
    returning {
      user_id
      second_name
      last_name
      first_name
      category
      birth_date
      bio
      address1
      address2
      city
    }
  }
}
`;

const UPDATE_NEXT_USER = gql`
mutation updateUser($_eq: uuid = "", $phoneNumber: String = "") {
  updateUsers(where: {id: {_eq: $_eq}}, _set: {phoneNumber: $phoneNumber}) {
    returning {
      phoneNumber
      id
    }
  }
}
`;

const UPDATE_USER_IMG = gql`
mutation MyMutation8($_eq: uuid, $avatarUrl: String) {
  updateUsers(where: {id: {_eq: $_eq}}, _set: {avatarUrl: $avatarUrl}) {
    returning {
      id
      avatarUrl
    }
  }
}
`;
export default function ProfileAdmin(props) {
  const [openpop, setOpenpop] = useState(false);
  const userId = useUserId()
  const nhost = useNhostClient()
  const { loading, error, data:profiledata } = useQuery(GET_USER_BY_ID, {
    variables: { 
      id:userId
     },
  })
  const { loading:errr, error:err, data:profileDetails} = useQuery(GET_PROFILE_DETAILS, {
    variables: { 
      user_id:userId
     },
     onCompleted:(data)=>{
      // console.log("profile data is " , data)

     }
  },)

  useEffect(()=>{
    setData(profiledata?.users[0]);

  
    setData((prev)=>{
      return {...prev , ...profileDetails?.profile_details[0]}
    })
    getImageUrl()

  },[profileDetails && profiledata])


  // console.log("image url we want :" ,profiledata?.users[0].avatarUrl)

  const [updateUser] = useMutation(UPDATE_USER,{
    onCompleted:(data)=>{
      // console.log("some data updated")
      nextuser()
    }, 
    
  })

  const [updateNextUser] = useMutation(UPDATE_NEXT_USER,{
    onCompleted:(data)=>{
      // console.log("last data updated")
    }
  })
  const [insterUserImg] = useMutation(UPDATE_USER_IMG,{
    onCompleted:(data)=>{
      // console.log("Image updated")
    }
  })

  const nextuser = async ()=>{
    try {
      const { data:insertedData } = await updateNextUser({
        variables: {
          _eq: userId,
          phoneNumber:data.phoneNumber
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  }

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id:cover_img_path,
    bucketId,
    name,
  } = useFileUpload();

  // console.log("error ouccured",isError,isUploading,progress,cancel,isUploaded)

  const [openpop1, setOpenpop1] = useState(false);
  const [openpop2, setOpenpop2] = useState(false);

  const openPopup1 = () =>{
    setOpenpop1(!openpop1);

  }

  const openPopUp = () => {
    setOpenpop(!openpop);
  };
  const openPopUp2 = () => {
    setOpenpop2(!openpop2);
  };

  const [imgId, setImgId] = useState("");
  const [errors, setErrors] = useState({});
  const handleSaveProfile = async (e) => {
    e.preventDefault();
    const newErrors = {};
    // Validate displayName
    if (!data.displayName) {
      newErrors.displayName = "Name is required";
    }
    if (!data.email) {
      newErrors.email = "Email is required";
    }
    if (!data.phoneNumber) {
      newErrors.phoneNumber = "phoneNumber is required";
    }
    if (!data.birthDate) {
      newErrors.birthDate = "Birthdate is required";
    }
    if (!data.address_line_1) {
      newErrors.address_line_1 = "address line 1 is required";
    }
    if (!data.address_line_2) {
      newErrors.address_line_2 = "address line 2 is required";
    }
    setErrors(0)
    try {
      const { data:insertedData } = await updateUser({
        variables: {
          user_id: userId,
          first_name: data["displayName"],
          birth_date: data["birthDate"],
          bio: data["bio"],
          address1: data["address1"],
          address2: data["address2"],
          city: data["city"],
          postalcode: data["postalcode"],
          // category:localStorage.getItem("member_category")
        },
      });
      // console.log("instered group header info",insertedData)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const [image, setImage] = useState(null);

  const uploadImage = async (cover_img_path) => {

    try {
      const { data:insertedData } = await insterUserImg({
        variables: {
          _eq: userId,
        avatarUrl: cover_img_path
        },
      });
      // console.log("instered group header info",insertedData)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
   

   
    // console.log("data", data1)

  }

  const accessToken = useAccessToken();

  const [imgUrl, setImgUrl] = useState("");

  const handleImageClick = () => {
    setUploadStop(true)
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
        let file = event.target.files[0]
      console.log("this is first image : ", file)

      
  await upload({ file })
    };
    input.click();
  };
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  if (isAuthenticated){
    // console.log('authentication status : ',isAuthenticated)
  }
async function getImageUrl() {
  // console.log("data.avtarurl : ",data.avatarUrl)
  // if (isUploaded) {
    nhost.storage.setAccessToken(accessToken);

    try {
      const item = await nhost.storage.getPublicUrl({
        fileId: profiledata?.users[0].avatarUrl,
      });
      // console.log("Image URL:", item);
setImage(item)

    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  
}
const [uploadStop , setUploadStop]= useState(true)
  if(isUploaded && uploadStop){ 
    uploadImage(cover_img_path)
    setUploadStop(false)
   
  } 
  const navigate = useNavigate();
  const { signOut } = useSignOut()

  const handleLogout = () => {
    localStorage.clear();
    signOut();
    navigate("/");
  }

;
  // console.log("this is image uploaded pashyaaaaaa: ",imgUrl)

  const [data, setData] = useState({});

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    // console.log(formattedDate);
    setData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const [profileImg, setProfileImg] = useState()
  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Navbar />
      {/* changpassword and profile */}
      <Box
        sx={{
          height: 50,
          border: 1,
          borderColor: "#999999",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "100%",
            mx: 4,
            overflow: "hidden",
          }}
        >
          <Typography sx={{ fontSize: 20, fontFamily: "MontserratRegular" }}>
            Profile
          </Typography>
        </Box>
        <Box
          sx={{ width: "50%", mx: 2, display: "flex", alignItems: "center", justifyContent: "flex-end" }}
        >
          <Typography sx={{ fontSize: 15, fontFamily: "MontserratRegular" }}>
            Version 1.2.0
          </Typography>

        </Box>
        <Box
          sx={{ width: "30%", mx: 5, display: "flex", alignItems: "center", justifyContent: "flex-end" }}
        >
          <IconButton onClick={handleLogout} >
            <img src={require('../../images/LogoutGray.png')} style={{ width: "30px", height: '30px' }} />
          </IconButton>

        </Box>
      </Box>

      {/* profile details */}
      
      <Box
        sx={{
          height: "50%",
          width: "95%",
          margin: "auto",
          marginTop: "25px",
          border: 1,
          borderColor: "#999999",
          borderRadius: 2,
        }}
      >
        <Box>
          <Box
            sx={{
              height: 50,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: 1,
              borderColor: "#999999",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                mx: 1,
              }}
            >
              <Typography
                sx={{ fontSize: 18, fontFamily: "MontserratRegular" }}
              >
                Profile Details
              </Typography>
            </Box>
            <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
              <Button
                onClick={handleSaveProfile}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: '#FF0000',
                  border: 1,
                  height: 30,
                  fontFamily: "MontserratRegular",
                  '&:hover': { backgroundColor: "#999999" }
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        {/* horizontal3 Boxes */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            "@media (max-width:768px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              m: 1,
              width: "30%",
              flexDirection: "column",
              "@media (max-width:768px)": {
                width: "95%",
                margin: "auto",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              size="small"
              sx={Styles.inputs}
              placeholder="Name"
              variant="outlined"
              value={data?.displayName}
              onChange={(e) => {
                handleChange("displayName", e.target.value);
              }}
              error={!!errors.displayName}
              helperText={errors.displayName}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                label="Birth Date"
                value={data?.birthDate}
                onChange={(e) => {
                  handleDateChange("birthDate", e);
                }}
                error={!!errors.birthDate}
                helperText={errors.birthDate}
                sx={{
                  m: 1,
                  minWidth: "100px",
                  // width: "420px",
                  borderRadius: "10px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                    height: "40px",
                    minWidth: "100px",
                    borderRadius: "10px",
                  },
                  "& .MuiFormControl-root MuiTextField-root css-kiy1u2-MuiFormControl-root-MuiTextField-root":
                  {
                    // height: "20px",
                    backgroundColor: "red",
                  },
                  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                    lineHeight: "10px",
                  },
                }}
              />
            </LocalizationProvider>


            <TextField
              id="outlined-basic"
              size="small"
              multiline
              sx={Styles.inputs}
              placeholder="Address line 1"
              value={data?.address1}
              variant="outlined"
              onChange={(e) => {
                handleChange("address1", e.target.value);
              }}
              error={!!errors.address_line_1}
              helperText={errors.address_line_1}
            />
            <TextField
              id="outlined-multiline-static"
              sx={Styles.inputs}
              multiline
              rows={3}
              //   defaultValue="Default Value"
              value={data?.bio}
              onChange={(e) => {
                handleChange("bio", e.target.value);
              }}
              placeholder="Bio"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              m: 1,
              width: "30%",
              flexDirection: "column",
              "@media (max-width:768px)": {
                width: "95%",
                margin: " auto",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Email Id"
              variant="outlined"
              value={data?.email}
              onChange={(e) => {
                handleChange("email", e.target.value);

              }}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Phone Number"
              variant="outlined"
              value={data?.phoneNumber}
              onChange={(e) => {
                handleChange("phoneNumber", e.target.value);
              }}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Address line 2"
              variant="outlined"
              value={data?.address2}
              onChange={(e) => {
                handleChange("address2", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="City"
              variant="outlined"
              value={data?.city}
              onChange={(e) => {
                handleChange("city", e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              sx={Styles.inputs}
              size="small"
              placeholder="Postal Code"
              variant="outlined"
              value={data?.postalcode}
              onChange={(e) => {
                handleChange("postalcode", e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              m: 1,
              width: "30%",
              flexDirection: "column",
              "@media (max-width:768px)": {
                width: "92%",
                margin: "auto",
                marginTop: "15px",
                marginBottom: "15px",
              },
            }}
          >

            <Box
              sx={{
                border: 1,
                height: "55%",
                marginTop: "10px",
                borderRadius: 2,
                borderColor: "#999999",
              }}
            >
              <Typography sx={Styles.uploadText}>Upload Photo</Typography>
              {image ? (
                <Box sx={Styles.uploaded}>
                  <img
                    style={Styles.uploadLogo}
                    src={image}
                    alt="uploaded image"
                  onClick={() => handleImageClick()}

                  />
                </Box>
              ) : (
                <img
                  style={Styles.uploadLogo}
                  src={profileImg}
                  alt="img"
                  onClick={() => handleImageClick()}
                />
              )}
            </Box>
            <Button
              // onClick={handleChangePass}
              sx={{
                color: "#FFFFFF",
                marginTop: "4%",
                backgroundColor: "#999999",
                border: 1,
                fontFamily: "MontserratRegular",
                '&:hover': { backgroundColor: "#FF0000" }
              }}
              onClick={openPopUp}
            >
              Change Password
            </Button>
          </Box>
        </Box>
        {isError && <p>Error uploading the file.</p>}
      </Box>

<Box style={{width:'100%', height:90 ,
               color:'red', borderColor:'red',display:'flex' , flexDirection:'row'}} >
             <Button
              // onClick={handleChangePass}
              sx={{
                color: "#FF0000",
                height:40,
                marginBottom: "1%",
                marginTop: "1%",
                marginLeft:'2.5%',
                // backgroundColor: "#999999",
                border: 1,
                fontFamily: "MontserratRegular",
                // '&:hover': { backgroundColor: "#FF0000" }
              }}
              onClick={openPopUp2}
            >
              Blocked Users
            </Button>      
            <Button
              // onClick={handleChangePass}
              sx={{
                color: "#FF0000",
                height:40,
                margin: "1%",
                // backgroundColor: "#999999",
                border: 1,
                fontFamily: "MontserratRegular",
                '&:hover': { color: "grey" }
              }}
              onClick={openPopup1}
            >
              Report Issue
            </Button> 

</Box>
      {/* My Relationship */}
    
      {openpop && <ChangePassword open={openpop} setOpen={setOpenpop} />}
      {openpop1 && <ReportIssue open={openpop1} setOpen={setOpenpop1} />}
      {openpop2 && <BlockedUser open={openpop2} setOpen={setOpenpop2} />}


 
    </Box>
  );
}

const Styles = {
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    height: "8vh",
  },
  mainBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  firstBox: {
    marginTop: "25px",
    width: "95%",
    height: "570px",
    border: "1px solid #999999",
    borderRadius: "10px",
  },
  headerBox: {
    width: "100%",
    borderBottom: "1px solid #999999",
    alignItems: "center",
  },
  headerText: {
    margin: "15px",
    padding: "auto",
    alignItems: "center",
    fontSize: "14px",
    color: "#252525",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  secondBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  inputs: {
    m: 1,
    "& .MuiInputBase-root": {
      fontFamily: "MontserratRegular",
      borderRadius: "10px",
    },
  },
  uploadPhoto: {
    width: "95%",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "10px",
  },
  uploadText: {
    margin: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    color: "#999999",
  },
  uploadLogo: {
    // width: "80px",
    // height:"80px",
    // marginLeft: "40%",
    cursor: "pointer",
    maxWidth: "100px", maxHeight: "100px", display: "block", margin: "0 auto" 
  },
  uploaded: {
    width: "100%",
    bottom: "0px",
  },
};
