import { Box, Button, Typography, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import FullRedButton from "../AdminFeed/FullRedButton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import {
  useNhostClient,
  useSignUpEmailPassword,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { gql, useQuery, useMutation } from "@apollo/client";

const INSERT_PROFILE_DETAIL = gql`
  mutation AddUserToProfile(
    $user_id: uuid
    $displayName: String
    $birth_date: date
  ) {
    insert_profile_details(
      objects: {
        user_id: $user_id
        first_name: $displayName
        birth_date: $birth_date
      }
    ) {
      returning {
        user_id
      }
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="right" ref={ref} {...props} />;
});

export default function SignPopUp(props) {
  const userId = useUserId();
  // const { sendEmail } = require('@nhost/node-sdk');
  const { onClose, open } = props;
  const nhost = useNhostClient();
  const [image, setImage] = useState(null);
  const [coverImageId, setCoverImageId] = useState("");
  const [coverImageId1, setCoverImageId1] = useState("");
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("testing4");
  const [lastName, setLastName] = useState("patil");
  const [email, setEmail] = useState("testingpatil4@mail.com");
  const [password, setPassword] = useState("Aw@#32@2#3");

  const {
    signUpEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
  } = useSignUpEmailPassword();

  // query: `mutation AddUserToProfile($user_id: uuid, $displayName: String,$birth_date: date) {
  //   insert_profile_details(objects: {user_id: $user_id, first_name: $displayName, birth_date: $birth_date}) {
  //     returning {
  //       user_id
  //     }
  //   }
  // }`,
  //   variables: {
  // user_id: id,
  // displayName: displayName,
  // birth_date: data["birthDate"],
  //   },
  // });

  const [insertprofiledetail] = useMutation(INSERT_PROFILE_DETAIL, {
    onCompleted: (data) => {
      console.log("some data updated");
      // nextuser()
    },
    onError: (error) => {
      console.log("error updating profile : ", error);
    },
  });
  const [errors, setErrors] = useState({});
  // const nhost = useNhostClient();
  const handleOnSubmit = async(e) => {
    console.log('mail checking')
   
    const newErrors = {};
    // Validate name
    if (!data.displayName) {
      newErrors.displayName = " Name is required";
    }
    if (!data.email) {
      newErrors.email = "email Name is required";
    }
    if (!data.phone) {
      newErrors.phone = "phone is required";
    }
    if (!data.city) {
      newErrors.city = "City is required";
    }
    setErrors(newErrors);
    e.preventDefault();
    console.log(date.birthDate);
    signUpEmailPassword(data.email, password, {
      displayName: data.displayName,
      metadata: {
        birthDate: date.birthDate,
        bio: data.bio,
        add1: data.add1,
        pincode: data.pincode,
        email: data.email,
        phone: data.phone,
        gender: data.gender,
        add2: data.add2,
        state: data.state,
      },
    });
    // window.location.reload();
    console.log("sign up button clicked", data.email, password);
  

  };
  console.log(
    "checking signup",
    isSuccess,
    isLoading,
    needsEmailVerification,"this value"
  );
  if(needsEmailVerification){

    sendemail()
  }
  async function sendemail(){
    try {
      await nhost.auth.sendVerificationEmail({ email: 'prasannajoshi3511@gmail.com' });
      console.log('Verification email sent successfully');
    } catch (error) {
      console.error('Error sending verification email:', error);
    }   console.log('mail checking2')
    // console.log(" signup error", error, isError);
  }

  const [signuptest, setSignuptest] = useState(true);
  const userDisplayName = useUserDisplayName();
  // async function insertprof(){
  //   console.log("inserting data in profile before : ",userId,userDisplayName , data["birthDate"])
  //   try {
  //     const { data:insertedData } = await insertprofiledetail({
  //       variables: {
  //         user_id: userId,
  //         displayName: userDisplayName,
  //         birth_date: data["birthDate"],
  //       },
  //     });
  //     console.log("instered group header info",insertedData)
  //   } catch (error) {
  //     console.error("GraphQL Mutation Error:", error.message);
  //   }
  // }

  if (isSuccess && signuptest) {
    console.log("inserting data in profile before : ", userId, userDisplayName);

    insertprof();
    async function insertprof() {

  
      try {
        const { data: insertedData } = await insertprofiledetail({
          variables: {
            user_id: userId,
            displayName: userDisplayName,
            birth_date: "2023-01-01",
          },
        });
        console.log("instered group header info", insertedData);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
    console.log("signup success");
    localStorage.clear();
    window.location.reload();
    navigate("/");
    setSignuptest(false);
  }

  const [date, setDate] = useState(new Date());
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD HH:mm:ss");
    console.log(formattedDate);
    setDate((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });

    handleChange("birthDate", formattedDate);
  };

  const [data, setData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    pincode: "",
    city: "",
    country: "",
    state: "",
    add1: "",
  });

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };
  const handleClose = () => {
    props.setOpenpop(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
      >
        <Box
          sx={{
            height: 60,

            backgroundColor: "#FF0000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <img
              style={{ marginLeft: 25 }}
              src={require("../../images/navLogo.png")}
            />
          </Box>
          <Button onClick={handleClose}>
            <Typography sx={{ color: "white" }}>Close</Typography>{" "}
          </Button>
        </Box>
        <Box sx={{ height: "100%", overflowX: "hidden" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
          <Box sx={Styles.mainBox}>
            <Box sx={Styles.firstBox}>
              <Box sx={Styles.secondBox}>
                <Box sx={Styles.inner1}>
                  <Typography sx={Styles.headerText}>
                    Personal Details
                  </Typography>
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="Name"
                    required
                    variant="outlined"
                    value={data.displayName}
                    onChange={(e) => {
                      handleChange("displayName", e.target.value);
                    }}
                    error={!!errors.displayName}
                    helperText={errors.displayName}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      // inputFormat="MM/DD/YYYY"
                      size="small"
                      label="Birth Date"
                      sx={[
                        Styles.textField,
                        {
                          // width: "360px",
                          "& .MuiInputBase-root": {
                            fontFamily: "MontserratRegular",
                            height: "40px",
                            color: "999999",
                          },
                          "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              fontFamily: "MontserratRegular",
                              color: "#999999",
                              fontSize: "14px",
                              lineHeight: "10px",
                            },
                        },
                      ]}
                      value={date.birthDate}
                      onChange={(e) => {
                        handleDateChange("birthDate", e);
                      }}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="filled-multiline-static"
                    placeholder="Bio"
                    multiline
                    rows={3}
                    sx={Styles.textField}
                    value={data.bio}
                    onChange={(e) => {
                      handleChange("bio", e.target.value);
                    }}
                  />

                  {/* <Typography sx={[Styles.headerText, { marginTop: "0px" }]}>
                  Configure User
                </Typography> */}
                  {/* <FormControl sx={Styles.textField}>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    InputLabelProps={{
                      sx: {
                        fontFamily: "MontserratRegular",
                      },
                    }}
                  >
                    Member Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={data.category}
                    label="Member Category"
                    size="small"
                    // multiple
                    value={data.memberCategory}
                    onChange={(e) => {
                      handleChange("memberCategory", e.target.value);
                    }}
                  >
                    {["Lead", "Prospect", "Suspect", "Member","unapproved"].map(
                      (item, index) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      }
                    )}
                  </Select>
                </FormControl> */}

                  <Typography sx={Styles.headerText}>
                    Address Details
                  </Typography>
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="Address Line 1"
                    variant="outlined"
                    value={data.add1}
                    onChange={(e) => {
                      handleChange("add1", e.target.value);
                    }}
                  />
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="Postal Code"
                    variant="outlined"
                    value={data.pincode}
                    onChange={(e) => {
                      handleChange("pincode", e.target.value);
                    }}
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                  />
                </Box>

                <Box sx={Styles.inner2}>
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="Email ID"
                    required
                    variant="outlined"
                    value={data.email}
                    onChange={(e) => {
                      handleChange("email", e.target.value);
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="Mobile Number"
                    // type="number"
                    required
                    variant="outlined"
                    value={data.phone}
                    onChange={(e) => {
                      handleChange("phone", e.target.value);
                    }}
                    inputProps={{
                      pattern: "[0-9!@#$%^&()+]",
                      title: "Only numbers and symbols are allowed",
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />

                  <RadioGroup
                    row
                    // aria-labelledby="demo-row-radio-buttons-group-label"
                    sx={{}}
                    name="row-radio-buttons-group"
                    value={data.gender}
                    onChange={(e) => {
                      handleChange("gender", e.target.value);
                    }}
                  >
                    <Typography sx={Styles.gender}>Gender</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "90%",
                        marginLeft: 2,
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "MontserratRegular",
                          },
                          "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                            { color: "#FF0000" },
                        }}
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "MontserratRegular",
                          },
                          "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                            { color: "#FF0000" },
                        }}
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "MontserratRegular",
                          },
                          "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                            { color: "#FF0000" },
                        }}
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </Box>
                  </RadioGroup>

                  {/* <FormControl sx={[Styles.textField, { marginTop: "100px" }]}>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    InputLabelProps={{
                      sx: {
                        fontFamily: "MontserratRegular",
                      },
                    }}
                  >
                    Assign Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.assignGroup}
                    label="Assign Group"
                    size="small"
                    onChange={(e) => {
                      handleChange("assignGroup", e.target.value);
                    }}
                  >
                    {["Group", "Stand Alone", "Free"].map((item, index) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
                  <TextField
                    size="small"
                    sx={[Styles.textField, { marginTop: "70px" }]}
                    id="outlined-basic"
                    placeholder="Address Line 2"
                    variant="outlined"
                    value={data.add2}
                    onChange={(e) => {
                      handleChange("add2", e.target.value);
                    }}
                  />
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="State/ Province "
                    variant="outlined"
                    value={data.state}
                    onChange={(e) => {
                      handleChange("state", e.target.value);
                    }}
                    error={!!errors.state}
                    helperText={errors.state}
                  />
                </Box>

                <Box sx={Styles.inner2}>
                  <Box style={Styles.uploadPhoto}>
                    <Typography sx={Styles.uploadText}>Upload Photo</Typography>
                    {/* {image ? (
                      <Box sx={Styles.uploaded}>
                        <img
                          style={Styles.uploadLogo}
                          src={uploadimg}
                          alt="uploaded image"
                        />
                      </Box>
                    ) : (
                      <img
                        style={Styles.uploadLogo}
                        src={image}
                        alt="uploaded image"
                        onClick={handleImageClick}
                      />
                    )} */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {image && (
                        <Box>
                          <img src={coverImageId} height={50} width={50} />
                        </Box>
                      )}
                      <Button
                        sx={{
                          width: "50px",
                          m: 2,
                          height: 30,
                          minWidth: "20px",
                          border: 1,
                          fontFamily: "MontserratRegular",
                          fontSize: "10px",
                          backgroundColor: "red",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "red",
                          },
                        }}
                        component="label"
                        placeholder="Select Image"
                        // onChange={(e) => handleImageClick(e)}
                        // onClick={handleImageClick}
                        id="standard-required"
                      >
                        Upload
                        {/* <input type="file" accept="image/*" placeholder="Select Image" hidden /> */}
                      </Button>
                    </Box>
                  </Box>
                  {/* <FormControlLabel
                  // value={}
                  sx={{
                    marginTop: "115px",
                    marginLeft: "10px",
                    fontFamily: "MontserratRegular",
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "MontserratRegular",
                    },
                    "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                      { color: "#FF0000" },
                  }}
                  control={<Checkbox defaultChecked onChange={handleCheckboxChange}   />}
                  label="Click the box to make a staff user."
                /> */}
                  <TextField
                    size="small"
                    sx={[Styles.textField, { marginTop: "125px" }]}
                    id="outlined-basic"
                    placeholder="city"
                    required
                    variant="outlined"
                    value={data.city}
                    onChange={(e) => {
                      handleChange("city", e.target.value);
                    }}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                  <TextField
                    size="small"
                    sx={Styles.textField}
                    id="outlined-basic"
                    placeholder="Country "
                    variant="outlined"
                    value={data.country}
                    onChange={(e) => {
                      handleChange("country", e.target.value);
                    }}
                    error={!!errors.country}
                    helperText={errors.country}
                  />
                  <Box
                    sx={{
                      width: "90%",
                      marginLeft: "15px",
                      marginBottom: "20px",
                    }}
                  >
                    <FullRedButton click={handleOnSubmit} title="Submit" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
const Styles = {
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // minWidth: "336px",
    margin: "auto",
    height: "8vh",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  mainBox: {
    width: "100%",
    height: "530px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "@media (max-width:768px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  firstBox: {
    //   marginTop: "25px",
    width: "95%",
    // height: "570px",
    //   border: "1px solid #999999",
    borderRadius: "10px",
    "@media (max-width:768px)": {
      width: "90%",
      height: "100%",
      margin: "auto",
    },
  },
  headerBox: {
    width: "100%",
    height: "25px",
    borderBottom: "1px solid #999999",
    alignItems: "center",
    // padding:"auto"
  },
  headerText: {
    marginTop: "5px",
    margin: "15px",
    // marginLeft:"15px",
    padding: "auto",
    alignItems: "center",
    fontSize: "14px",
    color: "#252525",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  secondBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  inner1: {
    width: "33.33%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  inner2: {
    marginTop: "51px",
    width: "33.33%",
    // height:"50px"
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  textField: {
    width: "90%",
    marginLeft: "15px",
    marginBottom: "15px",
    "& .MuiInputBase-root": {
      fontFamily: "MontserratRegular",
    },
  },
  gender: {
    fontSize: "16px",
    fontFamily: "MontserratMedium",
    // fontWeight: "bold",
    margin: "15px",
    marginLeft: "25px",
    marginRight: "55px",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  uploadPhoto: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "95%",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "10px",
    margin: "auto",
    // display:"flex",
    // justifyContent:"center",
    "@media (max-width:768px)": {
      margin: "auto",
    },
  },
  uploadText: {
    margin: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    color: "#999999",
  },
  uploadLogo: {
    width: "80px",
    marginLeft: "40%",
    cursor: "pointer",
  },
  uploaded: {
    width: "100%",
    bottom: "0px",
  },
};
