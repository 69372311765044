import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import styles from "../../styles/global";
import logolight from "../../images/logolight.png";
import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useFileUpload, useUserId } from "@nhost/react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const INSERT_NEW_TASK = gql`
mutation InsertNewTask($description: String = "", $file_path: String, $staff_id: uuid, $target_date: date = "", $task_name: String = "", $rpt_uid: uuid = "", $status: String = "inCompleted") {
  insert_task_master(objects: {description: $description, file_path: $file_path, staff_id: $staff_id, target_date: $target_date, task_name: $task_name, rpt_uid: $rpt_uid, status: $status}) {
    returning {
      description
      file_path
      id
      staff_id
      target_date
      task_name
      status
    }
  }
}
`;

const GROUP_FIND_QUERY = gql`
query MyQuery($_eq: uuid = "") {
  user_groups(where: { user_id: { _eq: $_eq } }) {
    group_id
    user_id
  }
}
`;
const GET_All_EVENT_ID = gql`
query MyQuery29($_eq: uuid = "") {
  user_events(where: {userid: {_eq: $_eq}}, distinct_on: event_id) {
    event_id
    userid
    users {
      displayName
    }
  }
}
`;
const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontSize: "10px",
    fontWeight: "bold",
    borderRadius:"10px"
  },
});

const GROUP_MEMBERS_QUERY = gql`
query MyQuery9($_eq: bigint, $_neq: uuid = "") {
  user_groups(
    where: { group_id: { _eq: $_eq }, _and: { user_id: { _neq: $_neq } } }
  ) {
    user_id
    group_id
    id
    users {
      email
      displayName
      id
    }
  }
}
`;
const EVENT_MEMBERS_QUERY = gql`
query MyQuery4($_eq: bigint) {
  user_events(where: { event_id: { _eq: $_eq } }, distinct_on: userid) {
    userid
    users {
      displayName
      id
      email
    }
  }
}
`;
const GET_All_USER_ID = gql`
query MyQuery30($_eq: bigint = "") {
  user_events(where: {event_id: {_eq: $_eq}}, distinct_on: userid) {
    userid
    users {
      displayName
    }
  }
}
`;

const GET_All_MODERATOR = gql`
query MyQuery27 {
  profile_details(where: {roles: {_eq: "Moderator"}}) {
    first_name
    user_id
  }
}
`;
export default function ReportIssue(props) {
  //   const { onClose, selectedValue, open } = props;
const [evntId, setevntId] = useState([]);
const [grpId, setGrpId] = useState([]);
const [usersId, setUsersId] = useState([]);
const [searchText, setSearchText] = useState(""); // Track the search input
const [update, setUpdate] = useState(false);
const [imgUrl, setImgUrl] = useState(null);
const [staff, setStaff] = useState([]);
const [eventId , setEventId] = useState([])
  const [open, setOpen1] = useState(true);
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({
    capitalLetter: "",
    specialCharacter: "",
    numericCharacter: "",
    minLength: "",
  });

  const types = ["User", "Content", "Other"];
  const handleClose = () => {
    props.setOpen(false);
  };
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id:cover_img_path,
    bucketId,
    name,
  } = useFileUpload();
//   console.log("email", localStorage.getItem("email"));
  const { data: moderator,refetch:moderat } = useQuery(GET_All_MODERATOR);
  useEffect(() => {
    setStaff(moderator?.profile_details);
    // seteventsAll(eventsData?.event_master);
  //   setgroupsAll(grpsData?.group_master);
    // console.log("data",moderator);
  }, [moderator?.profile_details]);
  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });

    validatePassword(key, value);

    if (key === "confirmpassword" && value !== data.newpassword) {
      setError("Password does not match");
    } else {
      setError("");
    }

    if (key === "oldpassword" && value === oldPassword) {
      setShow(true);
    }
  };
  const userId = useUserId()
  const [image, setImage] = useState(null);
  const [insertTask] = useMutation(INSERT_NEW_TASK, {
    onCompleted: (data) => {
      console.log(data, ":Success data");
    props.setOpen(false);


    },
    onError: (error) => {
      console.log("error????: ", error);
    },
  });
  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
    //   nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let image = event.target.files[0];
      setImage(URL.createObjectURL(image));
      await upload({ image })

      const fd = new FormData();

    //   fd.append("file", image);

    //   let res1 = await nhost.storage.upload({ formData: fd });

    //   nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
    //   nhost.storage.setAccessToken(res1.fileMetadata.id);
    //   const accessToken = nhost.auth.getAccessToken();

    //   const publicUrl = await nhost.storage.getPresignedUrl({
    //     fileId: res1.fileMetadata.id,
    //   });

      // setImage(publicUrl.presignedUrl.url);

    //   setCoverImageId(res1.fileMetadata.id);

      // console.log("image",publicUrl.presignedUrl.url);
    };
    input.click();
  };
  const validatePassword = (key, value) => {
    const updatedErrors = { ...errors };

    if (key === "newpassword") {
      const hasCapitalLetter = /[A-Z]/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*]/.test(value);
      const hasNumericCharacter = /[0-9]/.test(value);
      const hasMinLength = value.length >= 8;

      updatedErrors.capitalLetter = hasCapitalLetter
        ? ""
        : "Password must contain at least one capital letter";
      updatedErrors.specialCharacter = hasSpecialCharacter
        ? ""
        : "Password must contain at least one special character (!@#$%^&*)";
      updatedErrors.numericCharacter = hasNumericCharacter
        ? ""
        : "Password must contain at least one numeric character";
      updatedErrors.minLength = hasMinLength
        ? ""
        : "Password must be at least 8 characters long";

      setErrors(updatedErrors);
    }
  };

//   console.log(data);



  const handleSave = async (id) => {
    console.log('data to be inserted' , data)
    try {
        const { data: insertedData } = await insertTask({
          variables: {
            description: data["description"],
            task_name: data["taskname"],
            target_date: data["targetDate"],
            // status:"inCompleted",
            // event_id: selectedEvent,
            // group_id: selectedGroup ? selectedGroup : 0,
            staff_id: data["facilitator"],
            rpt_uid :data["rpt_uid"],
            file_path:cover_img_path?cover_img_path:'null'
          },
        });
        // navigation.goBack();
        console.log(insertedData)
        // Alert.alert( " Report Submitted");
      } catch (error) {
        console.log("error while booking event", error);
      }
    // .....................api ends here........................................
  };

  // ..............................fetch old pasword..........................................
  const [oldPassword, setOldPassword] = useState("");
  const [show, setShow] = useState(false);
  console.log("old password: ", oldPassword);
  const { data: eventsid, refetch: eveid } = useQuery(GET_All_USER_ID, {
    variables: {
      _eq: evntId,
    //   _neq: userId,
    },
    onCompleted: (data) => {
    //   setUserName(data.user_events);
    console.log("user ids  data" , data)
    },
    onError:(error)=>{
        console.log("your error is" , error)
    }
  });
  const {data: grp1Chat} = useQuery(GROUP_FIND_QUERY, {
    variables: {
      _eq: userId,
    },
    onCompleted: (data) => {
    //   console.log("this is grp find query", data?.user_groups);
      setGrpId(data?.user_groups[0]?.group_id);
    },
  });
  // ...................................fetch password ..................................................

  const { data: eventsDataid,refetch:eveReid } = useQuery(GET_All_EVENT_ID,{
    variables:{
        _eq: userId
    },
    onCompleted:(data)=>{
        // setEventId(data.user_events)
        const newEventIds = data.user_events.filter((newEvent) => {
            return !eventId.some((existingEvent) => existingEvent.userid === newEvent.userid);
          });
      
          // Add the new event IDs to the eventId array
          setEventId((prevEventIds) => [...prevEventIds, ...newEventIds]);
          setevntId(data?.user_events[0]?.id)
    }
});
const {
    loading: memberGrpLoad,
    error: memberGrpErr,
    data: memberGrpChat,
    refetch: memberGrpRefetch,
  } = useQuery(GROUP_MEMBERS_QUERY, {
    variables: {
      _eq: grpId,
      _neq: userId,
    },
    onCompleted: (data) => {
      // console.log("this is grp member query", data?.user_groups);
      setUsersId(data?.user_groups);
      // setGrpId(data?.user_groups[0]?.group_id)
      
    },
  });
  const {
    loading: memberevntLoad,
    error: memberevntErr,
    data: memberevntChat,
    refetch: memberevntRefetch,
  } = useQuery(EVENT_MEMBERS_QUERY, {
    variables: {
      _eq: eventId,
    },
    onCompleted: (data) => {
        // console.log("event users ",data.user_events)
      const filteredUserEvents = data?.user_events.filter((eventUser) => {
        const isLoggedInUser = userId === eventUser.userid;
        const isExistingUser = usersId.some(
          (groupUser) => groupUser.user_id === eventUser.userid
        );
        return !isLoggedInUser && !isExistingUser;
      });
    //   console.log(filteredUserEvents,"user from events")
      setUsersId((prevUsers) => prevUsers.concat(filteredUserEvents));
      // setGrpId(data?.user_groups[0]?.group_id)
    },
  });
  const filteredUsers = usersId?.filter((item) =>
  item?.users?.displayName.toLowerCase().includes(searchText.toLowerCase())
);
// console.log("all the users :" , filteredUsers)
// const [data, setData] = useState({});

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    // console.log(formattedDate);
    setData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // width: "643px",
            overflow: "hidden",
            borderRadius:"10px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              backgroundColor: "#FF0000",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img style={{ marginLeft: "10px" }} src={logolight} alt="img" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "543px",
              justifyContent: "center",
              "@media (max-width:768px)": {
                justifyContent: "center",
                width: "480px",
              },
            }}
          >
            <TextField
              variant="outlined"
              sx={Styles.textField}
              label="Name"
              id="taskname"
              value={data.taskname}
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
              onChange={(e) => handleChange("taskname", e.target.value)}
            />

            <TextField
              variant="outlined"
              sx={Styles.textField}
              label="Description"
              id="newpassword"
              value={data.description}
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
              onChange={(e) => handleChange("description", e.target.value)}
            />

<>

                  <FormControl sx={Styles.textField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontFamily: "MontserratRegular",
                        },
                      }}
                    >
                      Member Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.category}
                      label="Member Category"
                      size="small"
              onChange={(e) => handleChange("category", e.target.value)}

                    >
                      {types.map((item, index) => {
                        return <MenuItem value={item} >{item}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  </>
{
    data?.category=== 'User' &&
                  <>
<Typography sx={{ marginTop: "0px" }}>
                    Configure User
                  </Typography>
                  <FormControl sx={Styles.textField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontFamily: "MontserratRegular",
                        },
                      }}
                    >
                      Member Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.rpt_uid}
                      label="Member Category"
                      size="small"
              onChange={(e) => handleChange("rpt_uid", e.target.value)}

                    >
                      {filteredUsers?.map((item, index) => {
                        return <MenuItem value={item.user_id} >{item?.users?.displayName}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  </>
                }
 <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {image ? (
                    <img style={{height:100,width:100}} src={image} alt="new" />
                  ) : (
                    <img style={{height:100,width:100}} src={imgUrl} alt="upload image " />
                  )}

                  {update ? (
                    <button
                      style={Styles.uploadInput}
                      onClick={handleImageClick}
                    >
                      Upload New Image
                    </button>
                  ) : (
                    <button
                      style={Styles.uploadInput}
                      onClick={handleImageClick}
                    >
                      Upload Profile Image
                    </button>
                  )}
                </Box>
                <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                label="Birth Date"
                value={data?.targetDate}
                onChange={(e) => {
                  handleDateChange("targetDate", e);
                }}
                error={!!errors.birthDate}
                helperText={errors.birthDate}
                sx={Styles.textField}
              />
            </LocalizationProvider>
<Typography sx={{ marginTop: "0px" }}>
                    Select Moderator
                  </Typography>
                  <FormControl sx={Styles.textField}>
                    <InputLabel
                      id="demo-simple-select-label"
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontFamily: "MontserratRegular",
                        },
                      }}
                    >
                      Select Staff
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={data.facilitator}
                      label="Staff"
                      size="small"
              onChange={(e) => handleChange("facilitator", e.target.value)}

                    >
                      {staff?.map((item, index) => {
                        return <MenuItem value={item.user_id} >{item?.first_name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  </>
            

            {error && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "FF0000" }}>{error}</Typography>
              </Box>
            )}
          </Box>

          <Box sx={Styles.buttonBox}>
            <Box
              sx={[
                styles.btnDashboard,
                {
                //   width: "160px",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  backgroundColor: "#999999",
                  backgroundColor:
                    error || data.newpassword !== data.confirmpassword
                      ? "#999999"
                      : "#FF0000",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              disabled={
                error ||
                data.newpassword !== data.confirmpassword ||
                data.newpassword === null ||
                data.newpassword === "" ||
                data.confirmpassword === null ||
                data.oldpassword === null ||
                data.confirmpassword === ""
              }
              onClick={handleSave}
            >
              Save
            </Box>
            <Box
              sx={[
                styles.btnDashboard,
                {
                  backgroundColor: "#ffffff",
                  color: "#999999",
                  border: "1px solid #999999",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              onClick={handleClose}
            >
              Cancel
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

const Styles = {
  textField: {
    margin: "8px",
    width: "400px",
    justifyContent: "center",
    "& fieldset": { border: "none" },
    ".MuiInputBase-root": {
      border: "1px solid #999999",
      borderRadius: "10px",
    },
    ".MuiOutlinedInput-root": {
      height: "50px",
    },
    "@media (max-width:768px)": {
      width: "320px",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    width: "",
    width: "465px",
    justifyContent: "center",
    "@media (max-width:768px)": {
      width: "400px",
    },
  },
  errorBox: {
    width: "72%",
    display: "flex",
    justifyContent: "flex-start",
  },
};
