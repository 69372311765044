import { Box, Button, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useNavigate } from "react-router";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_USER_REQUEST_QUERY = gql`
  query MyQuery4 {
    profile_details(where: { category: { _eq: "unapproved" } }) {
      address1
      address2
      bio
      birth_date
      category
      city
      country
      first_name
      gender
      last_name
      postalcode
      profile_pic_path
      province
      second_name
      city
      country
      postcode
      user {
        id
        defaultRole
        displayName
        email
        phoneNumber
      }
    }
  }
`;
const DELETE_USER_REQUEST_MUTATION = gql`
  mutation DeleteUserRequests($id: uuid = "") {
    deleteUsers(where: { id: { _eq: $id } }) {
      returning {
        id
        email
        displayName
      }
    }
  }
`;

const ALL_USERS_QUERY = gql`
query MyQuery13($_eq: String="unapproved" ) {
  users(where: {defaultRole: {_eq: "user"}, _and: {profile_detail: {category: {_eq: $_eq}}}}) {
    displayName
    email
    phoneNumber
    id
    metadata
    profile_detail {
      address1
      address2
      bio
      birth_date
      category
      city
      country
      first_name
      postcode
      last_name
      province
      gender
      profile_pic_path
    }
  }
}
`;

const ALL_PROFILE_QUERY = gql`
  query MyQuery10 {
    profile_details {
      user_id
    }
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    width: "100%",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    //   borderRadius:"10px"
  },
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "0px 16px",
    height: 5,
    fontFamily: "MontserratRegular",
  },
  header: {
    height: 30,
  },
  headerCell: {
    padding: "5px 16px",
    height: 15,
    color: "white",
    fontFamily: "MontserratRegular",
  },
});

export default function UserRequest() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [existUser, setExistUser] = useState([]);
  const { data: user_request } = useQuery(GET_USER_REQUEST_QUERY);
  // const { data: user_req } = useQuery(FILTERED_USERS_QUERY);
  // console.log("this is filtered query",user_req?.users)

  const { data: userData } = useQuery(ALL_USERS_QUERY);
  const { data: profileData } = useQuery(ALL_PROFILE_QUERY);


  // Extract user IDs and profile user IDs
  const userIds = userData?.users.map(user => user.id);
  const profileUserIds = profileData?.profile_details.map(profile => profile.user_id);

  // Filter users whose IDs are not in profile_user_ids

// console.log(filteredUsers,"This is filtered users now");
console.log(userData,"This is filtered profile now");

  useEffect(() => {
    const filteredUsers = userData?.users;

    setExistUser(filteredUsers);
    // console.log("user request",user_request?.users)
  }, [userData]);
  // console.log("data",existUser)

  const [isForUpdate, setIsForUpdate] = useState(true);
  const handleApprove = async (id, rows) => {
    console.log("row are", rows);
    // setIsForUpdate(true)
    navigate("/adduser", { state: { id, rows, isForUpdate } });
  };
  const [deleteUserRequest] = useMutation(DELETE_USER_REQUEST_MUTATION);
  const handleDeleteRow = async (id) => {
    // setData(existUser.filter((row) => row.id !== id));
    try {
      await deleteUserRequest({ variables: { id } });
      setExistUser(existUser.filter((row) => row.id !== id));
    } catch (error) {
      console.error("Error deleting event type:", error);
    }
    let arr = [];
    for (let i = 0; i < existUser.length; i++) {
      if (existUser[i].id !== id) {
        arr.push(existUser[i]);
      }
    }
    setExistUser(arr);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filtereData = existUser?.filter(
    (row) =>
      (row.displayName &&
        row.displayName.toLowerCase().includes(filter.toLowerCase())) ||
      (row.city && row.city.toLowerCase().includes(filter.toLowerCase())) ||
      (row.email && row.email.toString().includes(filter.toLowerCase())) ||
      (row.phoneNumber &&
        row.phoneNumber.toLowerCase().includes(filter.toLowerCase()))
  );
  return (
    <Box>
      <Box sx={Styles.tableBox}>
        <div className={classes.searchContainer}>
          <TextField
            label="Search Here"
            value={filter}
            style={{
              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
              },
            }}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton size="small">
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <Box style={Styles.tableBox2}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                className={classes.header}
                style={{
                  backgroundColor: "#999999",
                  height: "20px",
                  color: "white",
                }}
              >
                <TableRow>
                  <TableCell className={classes.headerCell} align="center">
                    Name
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Email ID
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Mobile Number
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    City
                  </TableCell>
                  <TableCell
                    className={classes.headerCell}
                    align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.headerCell}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filtereData?.map((row) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      component="th"
                      scope="row"
                    >
                      {row.displayName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.email}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.phoneNumber?row.phoneNumber:row.metadata?.phone}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="center">
                      {/* sangli */}
                      {row.profile_detail?.city}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <IconButton onClick={() => handleApprove(row.id, row)}>
                        <img src={require("../../images/view.png")} />
                      </IconButton>
                    </TableCell>

                    <TableCell className={classes.tableCell} align="center">
                      <IconButton onClick={() => handleDeleteRow(row.id)}>
                        <img src={require("../../images/delete.png")} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

const Styles = {
  mainContainer: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tableBox: {
    width: "100%",
    height: "540px",
    borderRadius: " 10px",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  tableBox2: {
    width: "98%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    marginBottom: "15px",
  },
};
