import { Box, Button, Typography } from "@mui/material";
import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    width: "100%",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    //   borderRadius:"10px"
  },
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "0px 16px",
    height: 5,
    fontFamily: "MontserratRegular",
    "& .MuiInput-root":{fontFamily:"MontserratRegular"}
  },
  header: {
    height: 30,
  },
  headerCell: {
    padding: "10px 16px",
    height: 25,
    color: "#252525",
    fontFamily: "MontserratSemibold",
  },
});

const rows = [
  { id: 1, schedule:"Time : 10:00 AM to 11:00 AM  | Description : Loream ipsum dolor sit amet, "},
  { id: 2, schedule:"Time : 10:00 AM to 11:00 AM  | Description : Loream ipsum dolor sit amet, "},
  { id: 3, schedule:"Time : 10:00 AM to 11:00 AM  | Description : Loream ipsum dolor sit amet, "},
  { id: 4, schedule:"Time : 10:00 AM to 11:00 AM  | Description : Loream ipsum dolor sit amet, "},
  { id: 5, schedule:"Time : 10:00 AM to 11:00 AM  | Description : Loream ipsum dolor sit amet, "},
 
];

export default function EventClosureTable() {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState(rows);

//   const [feedback, setFeedback] = useState(""); // new state variable

//   const handleFeedbackChange = (e) => { // new function
//     setFeedback(e);
//     console.log(feedback)
//   };


const handleFeedbackChange = (id, value) => {
    const updatedData = data.map((row) => {
      if (row.id === id) {
        return { ...row, feedback: value };
      } else {
        return row;
      }
    });
    setData(updatedData);
    console.log(updatedData);
  };

  return (
    <Box>
      <Box sx={Styles.tableBox}>
        <Box style={Styles.tableBox2}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="sticky table">
              <TableHead
                className={classes.header}
                style={{
                  backgroundColor: "#ffffff",
                  height: "20px",
                  color: "white",
                  width:"50%",
                }}
              >
                <TableRow>
                  <TableCell className={classes.headerCell} align="left">
                    Sub Schedule
                  </TableCell>
                  <TableCell className={classes.headerCell} align="left">
                    Provide Feedback
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell
                      className={classes.tableCell}
                      align="left"
                      component="th"
                      scope="row"
                      ml={5}
                    >
                      {row.schedule.split("|").map((value, index) => (
                        <Box key={index}>{value.trim()}</Box>
                      ))}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="left">
                        
                    {/* <Box sx={{width:'100%', alignItems: 'flex-start'}}> */}
                    <TextField
                        fullWidth
                        multiline={true}
                        variant="standard"
                        rows={3}
                        value={row.feedback}
                        // sx={{"& .MuiInput-root":{fontFamily:"MontserratRegular"}}}
                        style={{"& .MuiInput-root":{fontFamily:"MontserratRegular"}}}
                        onChange={(e) => {
                          handleFeedbackChange(row.id, e.target.value);
                        }}
                      />
                      {/* </Box> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  mainContainer: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tableBox: {
    border: "1px solid #999999",
    width: "100%",
    height: "450px",
    borderRadius: " 10px",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.3em",
      // height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999999",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  tableBox2: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    marginBottom: "15px",
  },
};
