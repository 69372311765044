import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    users: [],
    groupHeaderInfo: {},
    groupData: [],
    allGroupInfo: [],
    groupConfig:{},
    eventHeaderInfo:{},
    allEventsData:[],
    addTask:[]
  },
  reducers: {
    // Login Page
    login: (state, action) => {
      const { email, password, loggedIn } = action.payload;
      state.user = { email, password, loggedIn };
    },
    // Profile Admin Page
    profile: (state, action) => {
      const { name, email, phone, add1, add2, bio } = action.payload;
      state.user = { name, email, phone, add1, add2, bio };
    },
    // Event Management
    events: (state, action) => {
      const { img, title, desc, date, time, address } = action.payload;
      state.user = { img, title, desc, date, time, address };
    },
    // Group Management
    groups: (state, action) => {
      const { grpimg, grptitle, grpdesc, grpstartDate, grpendDate } =
        action.payload;
      state.user = { grpimg, grptitle, grpdesc, grpstartDate, grpendDate };
    },
    // Group Creation Stepper 1
    addGroupHeaderInfo: (state, action) => {
      const { grpName, startDate,endDate, grpdesc} = action.payload;
      state.user = { grpName, startDate, endDate, grpdesc};
      // state.groupHeaderInfo.push(action.payload);
    },
    // Group Creation Stepper 2
    groupConfig: (state, action) => {
      // state.groupConfig.push(action.payload);
      const { attendance, expDate, role, facilitator, publish } =
        action.payload;
      state.user = { attendance, expDate, role, facilitator, publish };
    },
    groupContent: (state, action) => {
      // const {}
    },
    // All stepers Updated Data
    allGroupInfo: (state, action) => {
      state.allGroupInfo.push(action.payload);
      // console.log(action.payload)
    },

    addgroupData: (state, action) => {
      state.groupData = action.payload;
    },
    
    // User Management (Add User)
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    // System Configuration Pop up
    // Add Event Type
    addEventType: (state, action) => {
      const { eventType, addEventDesc } = action.payload;
      state.user = { eventType, addEventDesc };
    },
    // Add event Category
    addEventCategory: (state, action) => {
      const { eventCategory, eventCategoryDesc } = action.payload;
      state.user = { eventCategory, eventCategoryDesc };
    },
    // Add Group Type
    addGroupType: (state, action) => {
      const { groupType, groupTypeDesc } = action.payload;
      state.user = { groupType, groupTypeDesc };
    },
    // Add Group Category
    addGroupCategory: (state, action) => {
      const { groupCategory, groupCategoryDesc } = action.payload;
      state.user = { groupCategory, groupCategoryDesc };
    },
    // Facilitator Roles
    facilitatorRole: (state, action) => {
      const { facilitatorRole, facilitatorRoleDesc } = action.payload;
      state.user = { facilitatorRole, facilitatorRoleDesc };
    },
    // Feed Management
    feed: (state, action) => {
      const {
        feedName,
        feedDescription,
        feedCover,
        feedStartDate,
        feedEndDate,
      } = action.payload;
      state.user = {
        feedName,
        feedDescription,
        feedCover,
        feedStartDate,
        feedEndDate,
      };
    },
    eventHeaderInfo:(state, action) => {
      const {eventName, eventDescription, eventStartDate, eventEndDate,eventCategory,eventType,eventLocation} = action.payload;
      state.user = {eventName, eventDescription, eventStartDate, eventEndDate,eventCategory,eventType,eventLocation};
      // state.eventHeaderInfo.push(action.payload);
    },
    eventConfiguration:(state, action) => {
      const {eventDuration,maxAttendance,associatedGroup,expDate,eventFacilitatorRoll,eventFacilitator} =action.payload;
      state.user = {eventDuration, maxAttendance, associatedGroup,expDate, eventFacilitatorRoll, eventFacilitator};
    },
    eventContent:(state, action) => {
      const {eventContent} = action.payload;
      state.user = {eventContent};
    },
    allEventsData:(state, action) => {
      state.allEventsData.push(action.payload);
    },
    addTask:(state, action) => {
      state.addTask.push(action.payload);
      // const {taskName,taskCategory,taskEventName,taskGroupName,taskDesc,targetDate,assignStaff} = action.payload;
      // state.user = {taskName,taskCategory,taskEventName,taskGroupName,taskDesc,targetDate,assignStaff};
    }
    // addUser: (state, action) => {
    //     state.users.push(action.payload);
    // }
    // dashboard:(state)=>{
    //     state.user = null;
    // },ṁ
  },
});

export const {
  login,
  dashboard,
  profile,
  events,
  groups,
  addgroupData,
  addGroupHeaderInfo,
  groupConfig,
  // groupContent,
  addUser,
  addEventType,
  facilitatorRole,
  addGroupCategory,
  addGroupType,
  addEventCategory,
  allGroupInfo,
  feed,
  eventHeaderInfo,
  allEventsData,
  addTask
} = userSlice.actions;
// export const {eventHeaderInfo} = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectUsers = (state) => state.user.users;
export const selectGroupHeaderInfo = (state) => state.user.groupHeaderInfo;
export const selectGroupData = (state) => state.user.groupData;
export default userSlice.reducer;
