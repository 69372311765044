import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  Box,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";

import DeleteIcon from "@material-ui/icons/Delete";

import { FormControl, MenuItem } from "@mui/material";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import styles from "../../../src/styles/global";

import EventManagement1 from "../../pages/EventManagement1";

import { useNavigate } from "react-router";

import { gql, useQuery, useMutation } from "@apollo/client";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const initialRows = [
  // { id: 1, name: "John", date: "2023/01/01", drop: "good" },
  // { id: 2, name: "Sarah", date: "2023/01/01", drop: "good" },
  // { id: 3, name: "Mike", date: "2023/01/01", drop: "good" },
];

const GET_STAFF_QUERY = gql`
  query getAllStaff {
    profile_details(where: { user: { defaultRole: { _eq: "staff" } } }) {
      bio
      birth_date
      category
      first_name
      last_name
      profile_pic_path
      second_name
      user_id
      user {
        defaultRole
        displayName
        email
        emailVerified
        id
        phoneNumber
      }
    }
  }
`;

const INSERT_TASK_MASTER = gql`
  mutation InsertNewTask(
    $description: String
    $event_id: bigint
    $file_path: String
    $group_id: bigint
    $staff_id: uuid
    $target_date: date
    $task_name: String
  ) {
    insert_task_master(
      objects: {
        description: $description
        event_id: $event_id
        file_path: $file_path
        group_id: $group_id
        staff_id: $staff_id
        target_date: $target_date
        task_name: $task_name
      }
    ) {
      returning {
        description
        event_id
        file_path
        group_id
        id
        staff_id
        target_date
        task_name
      }
    }
  }
`;

const UPDATE_STATUS_MUTATIOPN =gql`
mutation MyMutation7($_eq: bigint = "") {
  update_event_master(where: {id: {_eq: $_eq}}, _set: {status: "isactive"}) {
    returning {
      id
      name
    }
  }
}
`;
const UPDATE_STATUS_PUBLISH=gql`
mutation MyMutation7($_eq: bigint = "",$publish_status: String = "published") {
  update_event_master(where: {id: {_eq: $_eq}}, _set: {status: "isactive",publish_status: $publish_status}) {
    returning {
      id
      name
    }
  }
}
`;

const ADMIN_EVENT_ADD=gql`
mutation MyMutation4($event_id: bigint = "", $userid: uuid = "") {
  insert_user_events(objects: {event_id: $event_id, userid: $userid}) {
    returning {
      event_id
      userid
    }
  }
}
`;
const ADMIN_INSERT_GROUP = gql`
query MyQuery6 {
  users(where: {defaultRole: {_eq: "admin"}}) {
    email
    id
  }
}
`;
function App() {
  const classes = useStyles();
  const [rows, setRows] = useState(initialRows);
  const [editing, setEditing] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [newName, setNewName] = useState("");
  const [newDesc, setnewDesc] = useState("");
  const [newDate, setNewDate] = useState("");
  const [dropdown, setDropdown] = useState("");
  const [memberId, setMemberId] = useState([]);
  // console.log("dropdown: " , dropdown)
  const [formData, setFormData] = useState({});
  console.log("desc coming", newDesc);
  const eventId = localStorage.getItem("event_id");
  const handleEdit = (row) => {
    setEditing(true);
    setEditingRow(row);
    setNewName(row.name);
    setnewDesc(row.desc);
    setNewDate(row.date);
    setDropdown(row.drop);
  };

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setFormData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const [staff, setStaff] = useState([]);
  // console.log("staffffffffffffff",staff);
  const { loading, error, isError, data } = useQuery(GET_STAFF_QUERY);
  if (isError) {
    console.log("error is ", error);
  }

  React.useEffect(() => {
    setStaff(data?.profile_details);
    // console.log("data", data?.profile_details);s
  }, [data?.profile_details]);

  const {data: adminMem} = useQuery(ADMIN_INSERT_GROUP);
  const [adminEvent] = useMutation(ADMIN_EVENT_ADD);
  const [InsertNewTask, { loading: taskLoading, error: taskError }] =
    useMutation(
      INSERT_TASK_MASTER,

      {
        onError: (taskError) => {
          console.taskError("Mutation Error:", error.message);
        },

        onCompleted: (data) => {
          console.log("pratik", data.insert_task_master.returning[0]);
        },
      }
    );

    useEffect(() => {
      if (adminMem && adminMem?.users) {
        const adminIds = adminMem?.users?.map(admin => admin.id);
        // setMemberId(adminIds);
        console.log("these are checking admin users",adminIds);
        // Call handleMembers to add admin IDs automatically
        adminIds.forEach(adminId => {
          adminEvent({
            variables:{
              event_id:eventId,
              userid:adminId
            }
          })
          // handleMembers(adminId, false); // Assuming 'true' is the default status for admins
        });
      }
    }, [adminMem]); //

    const [updateStatusEvent] = useMutation(UPDATE_STATUS_MUTATIOPN,{
      onCompleted:(data)=>{
        navigate("/EventManagement1", { state: { ispublished: true } });
      }
    })
    const [updatepublishEvent] = useMutation(UPDATE_STATUS_PUBLISH,{
      onCompleted:(data)=>{
        navigate("/EventManagement1", { state: { ispublished: true } });    
      }
    })

  const handleInsert = async () => {
    try {
      const { data: insertedData } = await InsertNewTask({
        variables: {
          event_id: localStorage.getItem("event_id"),
          description: newDesc,
          task_name: newName,
          target_date: formData["pageExpire"],
          staff_id: dropdown,
        },
      });

      console.log("instered event header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

    // }
  };

  const handleSave = async () => {
    const updatedRows = rows?.map((row) => {
      if (row.id === editingRow.id) {
        return {
          ...row,
          name: newName,
          desc: newDesc,
          date: formData.pageExpire,
          drop: dropdown,
        };
      }

      return row;
    });

    setRows(updatedRows);

    setEditing(false);

    setEditingRow(null);

    setNewName("");

    setnewDesc("");

    setNewDate("");

    setDropdown("");

    setFormData({}); // Reset formData to an empty object

    console.log(updatedRows);

    handleInsert();
  };

  const handleDelete = (row) => {
    const updatedRows = rows.filter((r) => r.id !== row.id);

    setRows(updatedRows);
  };

  const handleClick = () => {
   
    navigate("/EventManagement1", { state: { ispublished: true } });
    updatepublishEvent({
      variables:{
        _eq:eventId
      }
    })
    alert("Event Created!");
  };

  const draftEvent = ()=>{
    updateStatusEvent({
      variables:{
        _eq:eventId
      }
    })
  }

  const navigate = useNavigate();

  const handleAdd = () => {
    const newId = rows.length + 1;

    const newRow = {
      id: newId,
      name: newName,
      desc: newDesc,
      email: newDate,
      drop: dropdown,
    };

    const updatedRows = [...rows, newRow];

    setRows(updatedRows);

    setNewName("");

    setnewDesc("");

    setNewDate("");

    setDropdown("");
  };

  return (
    <div>
      <Button
        variant="contained"
        color="#FF0000"
        style={{ backgroundColor: "#FF0000", color: "white" }}
        onClick={handleAdd}
      >
        Add Row
      </Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#FF0000" }}>
            <TableRow>
              <TableCell style={{ color: "white" }}>Name</TableCell>

              <TableCell style={{ color: "white" }}>Description</TableCell>

              <TableCell style={{ color: "white" }}>Target Date</TableCell>

              <TableCell style={{ color: "white" }}>Trainer</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id} sx={{ height: "auto" }}>
                {editing && editingRow.id === row.id ? (
                  <>
                    <TableCell>
                      <TextField
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        value={newDesc}
                        onChange={(e) => setnewDesc(e.target.value)}
                      />
                    </TableCell>

                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          // inputFormat="MM/DD/YYYY"

                          format="YYYY/MM/DD"
                          size="small"
                          label="Page Expiration Date"
                          sx={{
                            mt: 2,

                            ml: 1,

                            width: "360px",

                            "& .MuiInputBase-root": {
                              fontFamily: "MontserratRegular",

                              height: "40px",
                            },

                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              fontFamily: "MontserratRegular",

                              color: "#999999",

                              fontSize: "14px",

                              lineHeight: "10px",
                            },
                          }}
                          onChange={(e) => {
                            handleDateChange("pageExpire", e);
                          }}
                          value={formData.pageExpire}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              placeholder="Event Name"
                            />
                          )}
                        />
                      </LocalizationProvider>

                      {/* <TextField

 

                        value={newDate}

 

                        onChange={(e) => setNewDate(e.target.value)}

 

                      /> */}
                    </TableCell>

                    <TableCell>
                      {/* <TextField

 

                        value={dropdown}

 

                        onChange={(e) => setDropdown(e.target.value)}

 

                      /> */}

                      <FormControl
                        //  key={dropdown}
                        fullWidth
                        sx={{
                          "& .MuiList-root": {
                            display: "flex",

                            flexDirection: "column",
                          },
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={dropdown}
                          label="Age"
                          onChange={(e) => setDropdown(e.target.value)}
                        >
                          {staff?.map((row) => (
                            // <Box style={{marginTop:20}}>
                            <MenuItem style={{ display: "flex", flexDirection: "row" }} value={row.user?.displayName}>
                              {row.user?.displayName}
                            </MenuItem>
                            // {/* </Box> */}
                          ))}
                        </Select>
                      </FormControl>

                    </TableCell>

                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{row.name}</TableCell>

                    <TableCell>{row.desc}</TableCell>

                    <TableCell>{row.date}</TableCell>

                    <TableCell>{row.drop}</TableCell>

                    <TableCell>
                      <Button onClick={() => handleEdit(row)}>
                        <EditIcon />
                      </Button>

                      <Button onClick={() => handleDelete(row)}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          margin: "20px",
        }}
      >
        <Button
          variant="contained"
          color="#FF0000"
          style={{
            backgroundColor: "#FFFFFF",
            color: "red",
            justifySelf: "flex-end",
            marginRight: "20px",
          }}
          onClick={draftEvent}
        >
          Draft Event
        </Button>
        <Button
          variant="contained"
          color="#FF0000"
          style={{
            backgroundColor: "#FF0000",
            color: "white",
            justifySelf: "flex-end",
          }}
          onClick={handleClick}
        >
          Publish Event
        </Button>
      </div>
    </div>
  );
}

export default App;