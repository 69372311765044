import { Box, Button, Typography } from "@mui/material";

import { React, useEffect, useState } from "react";

// import styles from "../../styles/global";

import Doc1 from "../EventManagementEventCreation/Doc1.png";

import plusicon from "../EventManagementEventCreation/plusicon.png";

import { useDispatch } from "react-redux";

import {
  addGroupHeaderInfo,
  allGroupInfo,
} from "../../Redux/features/UserSlice";

import { useSelector } from "react-redux";

import { useNhostClient } from "@nhost/react";

import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";

import { gql, useQuery, useMutation } from "@apollo/client";

// import { groupContent, groupHeaderInfo } from "../../Redux/features/UserSlice";

// import mblStyles from "../../styles/mblglobal";

const INSERT_COVER_IMAGE = gql`
  mutation UpdateGroupCoverImg($cover_img_path: String = "", $id: bigint = "",$status: String) {
    update_group_master(
      where: { id: { _eq: $id } }
      _set: { cover_img_path: $cover_img_path,status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

const INSERT_DOCUMENT = gql`
  mutation AddContent(
    $associated_group_id: bigint
    $file_name: String = ""
    $link: String = ""
    $published_by: String = ""
    $type: String = ""
    $uploaded_by: String = ""
  ) {
    insert_content_master(
      objects: {
        associated_group_id: $associated_group_id
        file_name: $file_name
        link: $link
        published_by: $published_by
        type: $type
        uploaded_by: $uploaded_by
      }
    ) {
      returning {
        associated_group_id

        category_id

        expiry_date

        file_name

        id

        link

        published_by

        publishing_date

        type

        upload_date

        uploaded_by
      }
    }
  }
`;

export default function GroupContent(props) {
  const newImg = props?.setImg;
  // console.log(props?.setImg)
  const img = props?.img;

  const [formData, setFormData] = useState({});
  const [image, setImage] = useState(props?.setImg);
  const [numFields, setNumFields] = useState(0);
  const [obj, setObj] = useState({});
  const nhost = useNhostClient();
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: coverImageId,
    bucketId,
    name,
  } = useFileUpload();
  // const [numFields, setNumFields] = useState(1);
  const fields = [];
  for (let i = 0; i < numFields; i++) {
    fields.push(
      <Button
        sx={{
          width: "360px",
          m: 1,
          mx: 5,
          minWidth: "360px",
          fontFamily: "MontserratRegular",
          color: "black",
          border: 1,
        }}
        component="label"
        onChange={(e) => onChangeDocs2(e)}
        id="standard-required"
      >
        {" "}
        Choose File{" "}
        <input
          type="file"
          accept=".docx , .pdf ,.doc , .csv"
          placeholder="Select Image"
          hidden
        />
      </Button>
    );
  }

  const [insertCoverImage] = useMutation(INSERT_COVER_IMAGE, {
    onCompleted: (data) => {
      console.log("last data updated euuuu");
    },
  });

  const uploadImage = async (coverImageId) => {
    try {
      const { data: insertedData } = await insertCoverImage({
        variables: {
          id: localStorage.getItem("group_id"),

          cover_img_path: coverImageId,
          status:'isactive',
        },
      });
      // console.log("instered group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const handleNext = () => {
    newImg(coverImageId);
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [coverimage, setCoverImage] = useState();

  const [iscoverimage, setiscoverimage] = useState(false);

  const [isdocument, setisdocument] = useState(false);

  const [isdocument2, setisdocument2] = useState(false);

// const nhost = useNhostClient();
const [imgUrl ,setImgUrl] = useState("");
// console.log("these are images",imgUrl);
useEffect(() => {
  async function getImage() {
    try {
      const item = await nhost.storage.getPublicUrl({
        fileId: img,
      });
      // console.log("Image URL:", item);
      setImgUrl(item);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  }
if(img){

  getImage();
}
}, [props]);

  const addData = (e) => {
    console.log(formData);

    // props.setData([...props.data, formData]);

    console.log(props.data);

    var isValid = /\.jpe?g$/i.test(setCoverImage);

    if (!isValid) {
      handleNext();
    } else {
      alert("enter cover image");
    }

    // handleNext();
  };

  const [coverImageid, setCoverImageid] = useState(false);

  const handleImageClick = () => {
    const input = document.createElement("input");

    input.type = "file";

    input.accept = "image/*";

    input.onchange = async (event) => {
      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)

      let file = event.target.files[0];

      await upload({ file });
      console.log("for creating image url",URL.createObjectURL(file))

      setImage(URL.createObjectURL(file));

      console.log("image id inside : ", coverImageId);

      setCoverImageid(true);

      // const fd = new FormData()

      // fd.append('file', image)

      // let res1 = await nhost.storage.upload({ formData: fd })

      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)

      // nhost.storage.setAccessToken(res1.fileMetadata.id)

      // const accessToken = nhost.auth.getAccessToken()

      // uploadImage(res1.fileMetadata.id)
    };

    input.click();
  };

  const [forDocument, setForDocument] = useState(false);

  const onChangeDocs = (e) => {
    setisdocument(true);
  };

  const [insertDocuments] = useMutation(INSERT_DOCUMENT, {
    onCompleted: (data) => {
      console.log("last data updated euuuu");
    },
  });

  const handleContentManagement = async (coverImageId) => {
    try {
      const { data: insertedData } = await insertDocuments({
        variables: {
          associated_group_id: localStorage.getItem("group_id"),

          link: coverImageId,

          file_name: docName,
        },
      });

      // console.log("instered group Document", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const [docName, setDocName] = useState("");

  const handleDocUpload = (e) => {
    const input = document.createElement("input");

    input.type = "file";

    input.accept = "/";

    input.onchange = async (event) => {
      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)

      let file = event.target.files[0];

      setDocName(file.name);

      await upload({ file });

      setForDocument(true);

      // setImage(URL.createObjectURL(file));
      setCoverImage(URL.createObjectURL(file));

      // const publicUrl = await nhost.storage.getPresignedUrl({

      //   fileId: res1.fileMetadata.id,

      // })

      // handleContentManagement(res1.fileMetadata.id, file.name)
    };

    input.click();
  };

  if (isUploaded && (coverImageid || forDocument)) {
    // console.log("cover image id", coverImageId);

    if (coverImageid) {
      uploadImage(coverImageId);

      setCoverImageid(false);
    }

    if (forDocument) {
      handleContentManagement(coverImageId);

      setForDocument(false);
    }
  }

  const onChangeDocs2 = (e) => {
    setisdocument2(true);
  };
  // console.log("this is image propsesdzkfhskdzj",props)
  // console.log("this is image props",img)

  return (
    <Box
      sx={{
        display: "flex",

        maxWidth: "800px",

        flexWrap: "wrap",

        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontFamily: "MontserratRegular" }}>
        Upload Content
      </Typography>

      <Box sx={{ border: 1, borderRadius: 5, margin: 3 }}>
        <Box
          sx={{
            display: "flex",

            flexDirection: "row",

            alignItems: "center",

            my: 2,

            borderBottom: "black",

            borderRadius: 3,
          }}
        >
          <Typography sx={{ mx: 2, fontFamily: "MontserratRegular" }}>
            {" "}
            Cover Image
          </Typography>

          {image && (
            <Box>
              <img src={img?imgUrl:image} height={50} width={50} />
            </Box>
          )}

          <Button
            sx={{
              width: "360px",

              m: 1,

              height: 30,

              minWidth: "360px",

              border: 1,
            }}
            component="label"
            placeholder="Select Image"
            // onChange={(e) => handleImageClick(e)}

            onClick={handleImageClick}
            id="standard-required"
          >
            Choose Image
            <input
              type="file"
              accept="image/*"
              placeholder="Select Image"
              hidden
            />
          </Button>
        </Box>

        <div
          style={{
            flex: 1,
            backgroundColor: "grey",
            height: "1px",
            marginBottom: 2,
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderColor: "grey",
            borderRadius: 5,
          }}
        >
          <Box sx={{ mx: 2, my: 4 }}>
            <Typography sx={{ fontFamily: "MontserratRegular" }}>
              Contents
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",

                justifyContent: "center",

                alignItems: "center",

                mx: 4,
              }}
            >
              {isdocument && (
                <Box>
                  <img src={Doc1} height={50} width={50} />
                </Box>
              )}

              <Button
                sx={{
                  width: "360px",

                  m: 1,

                  minWidth: "360px",

                  fontFamily: "MontserratRegular",

                  color: "black",

                  border: 1,
                }}
                component="label"
                onChange={(e) => handleDocUpload(e)}
                id="standard-required"
              >
                {" "}
                Choose File{" "}
                <input
                  type="file"
                  accept=".docx , .pdf ,.doc , .csv"
                  placeholder="Select Image"
                  hidden
                />
              </Button>
            </Box>

            {fields}
          </Box>
        </Box>

        <Button
          sx={{ width: "100%", justifyContent: "flex-end" }}
          onClick={() => setNumFields(numFields + 1)}
        >
          <img src={plusicon} alt="" height={30} width={30} />
        </Button>
      </Box>

      <Box sx={{ display: "flex", width: "94%", justifyContent: "flex-end" }}>
        {/* <Button

          // onClick={addData}

          variant="contained"

          sx={{ color: "#999999", backgroundColor: "#FFFFFF", fontFamily:"MontserratRegular" }}

          // sx={[styles.btn, styles.textField]}

        >

          Previous

        </Button> */}

        <Button
          onClick={addData}
          variant="contained"
          sx={{
            ml: 2,

            width: "100px",

            color: "#FFFFFF",

            backgroundColor: "#FF0000",

            "&:hover": {
              backgroundColor: "#FF0000",
            },

            height: 30,
          }}

          // sx={[styles.btn, styles.textField]}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
