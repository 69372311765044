import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Radio from '@mui/material/Radio';
import { Box } from "@mui/system";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";



const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-label': checked && {
        
      },
    }),
  );

  const theme = createTheme({
    typography: {
      fontFamily: "MontserratRegular",
      marginLeft:"4px",
      color:"#999999",
      fontWeight:"bold",
    },
    
  });
  
  
  
function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }
  
  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };


export default function EventManagementSortByPopUp(props) {
  const { onClose, open } = props;

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <ThemeProvider theme={theme}>
    <Dialog
      sx={{
        display: "flex",
        justifyContent: "flex-end",
       marginBottom:20
      }}
      open={open}
      onClose={handleClose}

    >
    <RadioGroup sx={{padding:'7px',borderRadius:"10px",width:"210px",'& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked':{color:"#FF0000"}}}name="use-radio-group" defaultValue="first">
      <MyFormControlLabel value="first" label="All" control={<Radio />} />
      <MyFormControlLabel value="second" label="Concept" control={<Radio />} />
      <MyFormControlLabel value="third" label="Plannned" control={<Radio />} />
      <MyFormControlLabel value="fourth" label="Scheduled" control={<Radio/>}  />
      <MyFormControlLabel value="fifth" label="Published" control={<Radio />} />
      <MyFormControlLabel value="sixth" label="Postponed" control={<Radio />} />

    </RadioGroup>
    </Dialog>
    </ThemeProvider>
  );
}



