import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AddSchedule from "../Popup/AddSchedule";
// import "./Table.css";
import { gql, useQuery, useMutation } from "@apollo/client";
const GET_SCHEDULES_QUERY = gql`query MyQuery16($_eq: bigint = "") {
  schedules(where: {event_id: {_eq: $_eq}}) {
    description
    end_time
    event_id
    owner
    start_time
    id
  }
}
`

const GET_STAFF_QUERY=gql`
query getAllStaff {
  profile_details(where: {user: {defaultRole: {_eq: "staff"}}}) {
    bio
    birth_date
    category
    first_name
    last_name
    profile_pic_path
    second_name
    user_id
    user {
      defaultRole
      displayName
      email
      emailVerified
      id
      phoneNumber
    }
  }
}
`


function Schedule(props) {
  // console.log("user is", props.id);
  const event_id = props.id;
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [newtime, setNewtime] = useState("");
  const [newOwner, setNewOwner] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [openpop, setOpenpop] = useState(false);

  const openPopup = () => {

    // console.log("only for popup");

    setOpenpop(!openpop);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setNewtime(data[index].time);
    setNewOwner(data[index].owner);
    setNewDescription(data[index].description);
  };

  const handleSave = () => {
    const newData = [...data];
    newData[editIndex] = {
      ...newData[editIndex],
      time: newtime,
      owner: newOwner,
      description: newDescription,
    };
    setData(newData);
    setEditIndex(-1);
  };

  const handleDelete = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };
  const handleAddRow = () => {
    setData([
      ...data,
      { id: data.length + 1, time: "", owner: "", description: "" },
    ]);
  }; 

  const handleClick = () => {
    openPopup();
    handleAddRow();
    setisForUpdate(false );
    setrowsdata("");
    setstaff("");
  };

  const [isForUpdate, setisForUpdate] = useState(false);
  const [rowsdata, setrowsdata] = useState([]);
  const [staff, setstaff] = useState([]);
  const [scheduleId, setscheduleId] = useState([]);
  // console.log("staff users", staff);

  const editSchedule = (row, staff, schId) => {
    // console.log("this is staff in function",staff)
    openPopup();
    setisForUpdate(true);
    setrowsdata(row);
    setstaff(staff);
    setscheduleId(schId);
  };

  // fetch schedule api data
  const [schedule, setschedule] = useState([]);
  const { loading, error, data:sc,isError } = useQuery(GET_SCHEDULES_QUERY, {
    variables: {
            _eq: event_id,
          },
  })
  
  ;
 
  //   console.log("error is ", error);
 
  // console.log("set data scheduled", sc);

  useEffect(() => {
    setschedule(sc?.schedules);
    // console.log("data1",sc?.schedules)
  },[sc]);
  // console.log("data for schedule",schedule)

// fetch staff details

const [allstaff, setStaff] = useState([]);

const { loading:loading1, error:error1, data:sf } = useQuery(GET_STAFF_QUERY)
;
// console.log("error is ", error1);
 
// console.log("set data staff", sf);
useEffect(() => {
  if (sf?.profile_details) {
    setStaff(sf.profile_details);
  }
  // setStaff(sf?.profile_details);
  // console.log("staffdata",sf?.profile_details)

},[sf]);
// console.log("data for staff",allstaff)

  const getFirstName = (user_id) => {

    const user = allstaff.find((staff) => staff.user_id === user_id);

    return user ? user.first_name : '';

  };

 

  return (

    <>
      <Box>
        {props.user === "admin" && (
          <img
            style={Styles.addIcon}
            src={require("../../images/plus.png")}
            onClick={handleClick}
          />
        )}
        {openpop && (
          <AddSchedule
            scheduleId={scheduleId}
            staff={staff}
            isForUpdate={isForUpdate}
            event_id={event_id}
            open={openpop}
            setOpenpop={setOpenpop}
            oldData={rowsdata}
          />
        )}
      </Box>
      <table style={Styles.table}>
        <thead style={Styles.header}>
          <tr>
            <th></th>
            <th>Time</th>
            <th>Owner</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody style={Styles.body}>
          {schedule?.map((row, index) => (
            <tr style={Styles.row} key={row.id}>
              <td>
                {editIndex === index ? (
                  <>
                    <button onClick={handleSave}>Save</button>{" "}
                    {/* <button onClick={() => EditEvent(row.id)}>Cancel</button> */}
                  </>
                ) : (
                  <>
                    {props.user === "admin" && (
                      <img
                        src={require("../../images/EditNew.png")}
                        onClick={() => editSchedule(row, row.owner, row.id)}
                      />
                    )}
                  </>
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    value={newtime}
                    onChange={(e) => setNewtime(e.target.value)}
                  />
                ) : (

                  `${row.start_time} TO ${row.end_time}`

                )}

              </td>

              <td>

                {editIndex === index ? (

                  <input

                    value={newOwner}

                    onChange={(e) => setNewOwner(e.target.value)}

                  />

                ) : (
                 getFirstName(row.owner)  
                  // row.owner

                )}

              </td>

              <td style={{ textAlign: "center" }}>

                {editIndex === index ? (

                  <input

                    value={newDescription}

                    onChange={(e) => setNewDescription(e.target.value)}

                  />

                ) : (

                  row.description

                )}

                {/* {props.user === "admin" && (

                  <img

                    style={Styles.deleteIcon}

                    src={require("../../images/cross.png")}

                    onClick={() => handleDelete(index)}

                  />

                )} */}

              </td>

            </tr>

          ))}

        </tbody>

      </table>

    </>

  );

}

 

export default Schedule;

 

const Styles = {

  table: {
    position: "relative",
    width: "94%",
    overflow: "hidden",
    height: "120px",
    backgroundColor: "#FFFFFF",
    padding: "0px",
    left: " 37px",
    top: "20px",
    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    // border:"0px solid #FF0000",
    borderRadius: "10px",
  },
  header: {
    backgroundColor: "#FF0000",
    color: "white",
    border: "0px solid #FF0000",
    borderRadius: "10px",
    // padding: "10px",
  },
  body: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    border: "0px solid white",
    // overflow: "hidden",
  },
  row: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    borderWidth: 0,
    height: "10px",
  },
  deleteIcon: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    position: "absolute",
    right: "10px",
  },
  addIcon: {
    width: 25,
    height: 25,
    cursor: "pointer",
  },
}; 
const isFirefox = typeof InstallTrigger !== "undefined";

// Apply Firefox-specific styles
if (isFirefox) {
  Styles.table = {
    ...Styles.table,
    height: "20px",
    scrollSnapType: "y mandatory",
    overflowY: "scroll",
    scrollbarWidth: "thin",
  };
}
