import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import menu from "../../images/menu.png";
import NewTask from "../Popup/NewTask";

export default function TaskCard(props) {
    const {title,desc,assignee,date,item,id,status}=props
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [refreshTask, setRefreshTask] = useState(false);
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const formattedStartDate = `${formattedDate}`;

  const openNewTask = () => {
    setNewTaskOpen(!newTaskOpen);
  };

  return (
    <Box sx={styles.cardContainer}>
      <Box sx={styles.row}>
        <Typography
          sx={styles.title}
        >
          {title}
        </Typography>
       
        <img  onClick={openNewTask}  src={menu} alt="menu" width="16px" height="16px" style={{cursor:"pointer"}} />
      </Box>

      <Typography
        sx={styles.desc}
      >
        {desc}
      </Typography>

      <Box sx={styles.row}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={styles.normalTxt}>Assigned To : </Typography>
          <Typography sx={styles.boldTxt}>{assignee}</Typography>
        </Box>
        
        <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={styles.normalTxt}>Status : </Typography>
          <Typography sx={[styles.boldTxt,{marginRight:"10px"}]}>{status}</Typography>
        </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={styles.normalTxt}>Target Date : </Typography>
          <Typography sx={styles.boldTxt}>{formattedStartDate}</Typography>
          </Box>
        </Box>
      </Box>
      {newTaskOpen && (
          <NewTask open={newTaskOpen}  setRefreshTask={setRefreshTask} setNewTaskOpen={setNewTaskOpen} isForUpdate={true} item={item} date={date} id={id}/>
        )}    </Box>
  );
}
const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25);",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginInline: "10px",
    marginY: "2px",
  },
  title:{
    fontFamily: "MontserratRegular",
    fontWeight: "800",
    fontSize: "14px",
    color: "#252525",
  },
  desc:{
    fontFamily: "MontserratRegular",
    fontWeight: "400",
    fontSize: "14px",
    color: "#888888",
    marginInline: "10px",
    marginY: "2px",
  },
  normalTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "400",
    fontSize: "12px",
    color: "#888888",
  },
  boldTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "bold",
    fontSize: "12px",
    color: "#252525",
  },
};
