import React, { useEffect, useState } from 'react'
import { Box, Typography } from "@mui/material";
import searchbar from "../../images/searchbar.png";
import avatar from "../../images/avatar.png"
import { useNhostClient } from '@nhost/react';

export default function GroupManagementStepperCard2(props) {
  const{name,phoneNo,email,staffId,img} = props;

  const nhost = useNhostClient();
  const [imgUrl ,setImgUrl] = useState("");
  console.log("these are images",imgUrl);
  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: img,
        });
        // console.log("Image URL:", item);
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [props]);

  const isNullUrl = imgUrl.endsWith("null") || imgUrl.endsWith("/");    
  return (
    <Box>
          <Box sx={{width:"100%",display:'flex',flexDirection:"column"}}>
            {/* ..................name part */}
            <Box sx={{display:"flex",flexDirection:'row',alignItems:'center'}}>
              {/* <img src={avatar} alt="profile" width="22px" height="22px" /> */}
              {isNullUrl ? (
        <img src={avatar} alt="Static Image" />
      ) : (
        <img src={imgUrl} alt="profile" width="22px" height="22px" style={{borderRadius:50,marginRight:2}} />
      )}
            <Typography sx={styles.titleTxt}>Name : {name}</Typography>
            </Box>

            {/* ....................details part */}
            <Box sx={{display:"flex",flexDirection:'column',marginLeft:"26px"}}>
            <Typography sx={styles.normalTxt}>Phone Number : {phoneNo} </Typography>
            <Typography sx={styles.normalTxt}>Email ID : {email}</Typography>
            {/* <Typography sx={styles.normalTxt}>Staff ID: {staffId}</Typography> */}
            </Box>

          </Box>
        </Box>
  )
}

const styles = {
    titleTxt:{
        fontFamily:"MontserratRegular",
        color:'#414042',
        fontWeight:'bold',
        fontWeight:'14px',
        margin:'2px',
      },
      normalTxt:{
        fontFamily:"MontserratRegular",
        color:"#999999",
        fontWeight:'500',
        fontSize:'14px',
      }
}