import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import plusicon from "../EventManagementEventCreation/plusicon.png";
// import { useDispatch } from "react-redux";
// import { allGroupInfo, groupConfig } from "../../Redux/features/UserSlice";
// import { useSelector } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_FACILITATOR_ROLE = gql`
  query GetFacilitatorRole {
    facilitator_roles {
      created_at
      created_by
      description
      id
      name
    }
  }
`;

const GET_STAFF = gql`
  query getAllStaff {
    profile_details(where: { user: { defaultRole: { _eq: "staff" } } }) {
      bio
      birth_date
      category
      first_name
      last_name
      profile_pic_path
      second_name
      user_id
      user {
        defaultRole
        displayName
        email
        emailVerified
        id
        phoneNumber
      }
    }
  }
`;

const INSERT_GROUP_CONFIG_MUTATION = gql`
  mutation UpdatePublishTo(
    $id: bigint
    $publish_to: String
    $closure_date: date
    $attendance_capacity: String
  ) {
    update_group_master(
      where: { id: { _eq: $id } }
      _set: {
        publish_to: $publish_to
        closure_date: $closure_date
        attendance_capacity: $attendance_capacity
      }
    ) {
      returning {
        activation_date
        attendance_capacity
        category_id
        closure_date
        deactivation_date
        decription
        id
        level_up_group
        name
        price
        publish_to
        type
      }
    }
  }
`;


export default function GroupConfiguration(props) {
  // console.log("these are propssssss pratkk ", props?.data);
  const setNewData = props?.setData;
  const newData = props?.data;
  const setNewDate = props?.setDate;
  const newDate = props?.date;
  const matches = useMediaQuery("(max-width:1000px)");
  const [data, setData] = useState(newData);
  const [date, setDate] = useState(new Date(newDate));
  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const {
    loading: facilitatorLoading,
    error: facilitatorError,
    data: facilitatorData,
  } = useQuery(GET_FACILITATOR_ROLE);
  console.log("this is facilitaor role", facilitatorData?.facilitator_roles);

  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
  } = useQuery(GET_STAFF);
  console.log("this is facilitaor role", staffData?.profile_details);

  const [insertGroupConfig, { loading, error }] = useMutation(
    INSERT_GROUP_CONFIG_MUTATION,
    {
      onError: (error) => {
        console.error("Mutation Error:", error.message);
      },
      onCompleted: (data) => {
        console.log("pratik", data.update_group_master.returning[0]);
      },
    }
  );

  const [staff, setStaff] = useState([]);
  const [facilitatorRole, setfacilitatorRole] = useState([]);

  useEffect(() => {
    setStaff(staffData?.profile_details);
    setfacilitatorRole(facilitatorData?.facilitator_roles);
  }, [staffData]);

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setDate((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const handleNext = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setNewData(data);
    setNewDate(date);
  };

  const [numFields, setNumFields] = useState(0);
  const fields = [];
  for (let i = 0; i < numFields; i++) {
    fields.push(
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box>
          <FormControl
            sx={{
              width: "280px",
              mt: 1,
              marginLeft: 1,
              mb: 1,
              fontFamily: "MontserratRegular",
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              size="small"
              sx={{ fontFamily: "MontserratRegular", color: "#999999" }}
            >
              Select Roles
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              value={data.select_roles}
              size="small"
              label="Select Roles "
              // placeholder="select roles"
              sx={{ fontFamily: "MontserratRegular", color: "#999999" }}
              onChange={(e) => {
                handleChange("select_roles", e.target.value);
              }}
              //   onChange={handleChange}
            >
              {facilitatorRole?.map((item, index) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: "280px",
              // backgroundColor:"red",
              mt: 1,
              marginLeft: 1,
              mb: 1,
              fontFamily: "MontserratRegular",
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{ fontFamily: "MontserratRegular", color: "#999999" }}
              size="small"
            >
              Assign Facilitators
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              size="small"
              sx={{
                fontFamily: "MontserratRegular",
                color: "#999999",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                },
              }}
              label=" Assign Facilitators"
              value={data.facilitator}
              onChange={(e) => {
                handleChange("facilitator", e.target.value);
              }}
            >
              {staff?.map((item, index) => {
                return <MenuItem value={item.user.id}>{item.user?.displayName}</MenuItem>;
              })}
            </Select>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Checkbox
                id="check"
                sx={{
                  color: "#49454F",
                  "&.Mui-checked": {
                    color: "#FF0000",
                  },
                }}
              />
              <label
                for="check"
                style={{
                  fontFamily: "MontserratLite",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Make This Facilitator as an Owner
              </label>
            </Box>
          </FormControl>
        </Box>
      </Box>
    );
  }
  const [errors, setErrors] = useState({});
  const addData = async () => {
    const newErrors = {};
    if (!data.max_attendance) {
      newErrors.max_attendance = "Max attendence is required";
    }
    // if (!data.select_roles) {
    //   newErrors.select_roles = "select roles is required";
    // }
    if (!data.publishTo) {
      newErrors.publishTo = "publish To is required";
    }
    // if (!date.expDate) {
    //   newErrors.expDate = "publish To is required";
    // }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const { data: insertedData } = await insertGroupConfig({
          variables: {
            id: localStorage.getItem("group_id"),
            attendance_capacity: data["max_attendance"],
            closure_date: date["expDate"]?date["expDate"]:newDate?.expDate,
            publish_to: data["publishTo"].toString(),
          },
        });
        console.log("instered group Config info", insertedData);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
      handleNext();
    }
  };

  // const isDateValid = date.expDate !== null;

  return (
    <Box sx={{ display: "flex", width: "800px", flexWrap: "wrap" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <TextField
          sx={{
            width: "360px",
            m: 1,
            minWidth: "360px",
            borderRadius: "50%",
            "& .MuiInputBase-root": {
              fontFamily: "MontserratRegular",
            },
            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              fontFamily: "MontserratRegular",
              color: " #999999",
              fontSize: "14px",
              lineHeight: " 20px",
            },
          }}
          required
          size="small"
          // onChange={(e) => setAttendCapacity(e.target.value)}
          value={data.max_attendance}
          onChange={(e) => {
            handleChange("max_attendance", e.target.value);
          }}
          error={!!errors.max_attendance}
          helperText={errors.max_attendance}
          id="standard-required"
          label="Max Attendance Capacity"
        />
        {/* <TextField
          sx={{
            width: "360px",
            m: 1,
            minWidth: "360px",
            borderRadius: "50%",
            "& .MuiInputBase-root": {
              fontFamily: "MontserratRegular",
            },
            "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
              fontFamily: "MontserratRegular",
              color: " #999999",
              fontSize: "14px",
              lineHeight: " 20px",
            },
          }}
          required
          size="small"
          // onChange={(e) => setPageExpiration(e.target.value)}
          value={data.page_expiration_date}
          onChange={(e) => {
            handleChange("page_expiration_date", e.target.value);
          }}
          id="standard-required"
          label="Page Expiration Date"
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat="YYYY/MM/DD"
            format="YYYY/MM/DD"
            size="small"
            label={newDate?.expDate ? newDate?.expDate : "Page Expiration Date"}
            sx={{
              width: "360px",
              marginTop: "1%",
              "& .MuiInputBase-root": {
                fontFamily: "MontserratRegular",
                height: "40px",
              },
              "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                fontFamily: "MontserratRegular",
                color: "#999999",
                fontSize: "14px",
                lineHeight: "10px",
              },
            }}
            onChange={(e) => {
              handleDateChange("expDate", e);
            }}
            value={date.expDate}
            error={!!errors.expDate}
            helperText={errors.expDate}
          />
          {/* {isDateValid ? null : <p style={{ color: 'red' }}>Please select a date.</p>} */}
        </LocalizationProvider>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ fontFamily: "MontserratRegular", ml: 1 }}>
          Assign Facilitators
        </Typography>

        <Box sx={{}}>
          <Box
            sx={[
              { display: "flex", flexDirection: "row" },
              matches && { flexWrap: "wrap" },
            ]}
          ></Box>
          <Box
            sx={[
              { display: "flex", flexDirection: "row" },
              matches && { flexWrap: "wrap" },
            ]}
          >
            <FormControl
              sx={{
                width: "380px",
                mt: 1,
                marginLeft: 1,
                mb: 1,
                fontFamily: "MontserratRegular",
                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                },
              }}
            >
              <InputLabel id="demo-simple-select-label" size="small">
                Select Roles
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                size="small"
                label="Select Roles "
                // onChange={(e)=>setSelectRole(e.target.value)}
                value={data.select_roles}
                onChange={(e) => {
                  handleChange("select_roles", e.target.value);
                }}
                // error={!!errors.select_roles}
                // helperText={errors.select_roles}
              >
                {facilitatorRole?.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
                {/* <CheckBox label="Online" /> */}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: "380px",
                mt: 1,
                marginLeft: 1,
                mb: 1,
                fontFamily: "MontserratRegular",
                "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                },
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                size="small"
                sx={{
                  fontFamily: "MontserratRegular",
                  color: " #999999",
                  fontSize: "14px",
                  lineHeight: " 20px",
                }}
              >
                Assign Facilitators
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                size="small"
                label=" Assign Facilitators"
                value={data.assign_facillators}
                onChange={(e) => {
                  handleChange("assign_facillators", e.target.value);
                }}
                error={!!errors.assign_facillators}
                helperText={errors.assign_facillators}
              >
                {staff?.map((item, index) => {
                  return (
                    <MenuItem value={item.user?.id}>
                      {item.user?.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                id="check"
                sx={{
                  color: "#49454F",
                  "&.Mui-checked": {
                    color: "#FF0000",
                  },
                }}
              />
              <label
                for="check"
                style={{
                  fontFamily: "MontserratLite",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Make This Facilitator as an Owner
              </label>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={() => setNumFields(numFields + 1)}>
              <img
                style={{ marginLeft: "30px" }}
                src={plusicon}
                alt=""
                height={30}
                width={30}
              />
            </Button>
          </Box>

          {fields}

          <Box
            sx={{
              width: "45.5%",
              "@media (max-width:768px)": {
                ml: 0,
              },
            }}
          >
            <Typography sx={{ fontFamily: "MontserratRegular" }}>
              Group Visibility
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "flex-end",
                "@media (max-width : 768px)": {
                  justifyContent: "inherit",
                },
              }}
            >
              <FormControl
                sx={{
                  width: "50%",
                  minWidth: "320px",
                  mt: 1,
                  marginLeft: 0,
                  mb: 1,
                  fontFamily: "MontserratRegular",
                  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: " #999999",
                    fontSize: "14px",
                    lineHeight: " 20px",
                  },
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                  },
                }}
              >
                <InputLabel id="demo-simple-select-label" size="small">
                  Publish To
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="publish-to"
                  value={data.publishTo}
                  size="small"
                  label="Publish To "
                  //   onChange={handleChange}
                  onChange={(e) => {
                    handleChange("publishTo", e.target.value);
                  }}
                  error={!!errors.publishTo}
                  helperText={errors.publishTo}
                >
                  {["Lead", "Prospect", "Suspect", "Member"].map(
                    (item, index) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    }
                  )}

                  {/* <CheckBox label="Online" /> */}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            {/* <Button
              // onClick={addData}
              variant="contained"
              sx={{
                color: "#999999",
                backgroundColor: "#FFFFFF",
                fontFamily: "MontserratRegular",
              }}
              // sx={[styles.btn, styles.textField]}
            >
              Previous
            </Button> */}
            <Button
              onClick={addData}
              variant="contained"
              sx={{
                ml: 32,
                width: "100px",
                color: "#FFFFFF",
                backgroundColor: "#FF0000",
                "&:hover": {
                  backgroundColor: "#FF0000",
                },
                height: 30,
              }}
              // sx={[styles.btn, styles.textField]}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
