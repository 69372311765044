import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Styles from "../../styles/ForgotPassword";
import { Button, Input, TextField, Typography } from "@mui/material";
import "./Login.css";
import bgvideo from "./awakevideo.mp4.mp4";
import { Link, useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FullRedButton from "../AdminFeed/FullRedButton";
import { useSignInEmailPassword, useUserId } from "@nhost/react";
import SignPopUp from "../Popup/SignPopUp";
import TNCPop from '../Popup/TNCPop'
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { gql, useQuery, useMutation } from "@apollo/client";


const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
      defaultRole
    }
  }
`;
const INSERT_LOG_MASTER = gql`
mutation createdevecieinfolog($deleted_by: String = "", $device_info: String = "", $geolocation: String = "", $ip_address: String = "") {
  insert_logs_master(objects: {deleted_by: $deleted_by, device_info: $device_info, geolocation: $geolocation, ip_address: $ip_address}) {
    affected_rows
  }
}
`;

const logo = require("../../images/AWAKEORIGINS.png");

export default function Login() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [invalid, setInvalid] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [checkboxError, setCheckboxError] = useState("");
  const [logtask , setlogtask] = useState(true)
  const id = useUserId();
  const [geolocationData , setgeolocationData]=useState({})

  // Import React and any other necessary dependencies

const fetchGeolocation = async () => {
  try {
    // Fetch IP address
    const ipResponse = await fetch('https://geolocation-db.com/json/');
    const ipData = await ipResponse.json();
    const userIP = ipData.IPv4 || ipData.IPv6;

    // Fetch geolocation using IP address
    const geolocationResponse = await fetch(`https://geolocation-db.com/json/${userIP}&position=true`);
    const geolocationData1 = await geolocationResponse.json();
    setgeolocationData(geolocationData1)
    insertlog(geolocationData1)
    const { latitude, longitude, city, country_name } = geolocationData1;

    // Log or use the geolocation information as needed
    console.log('User IP:', userIP);
    console.log('Latitude:', latitude);
    console.log('Longitude:', longitude);
    console.log('City:', city);
    console.log('Country:', country_name);

    console.log(geolocationData1,'geo')
  } catch (error) {
    // Handle any errors that might occur during the fetch
    console.error('Error fetching geolocation:', error);
  }
};
console.log("geolocationData data is :",geolocationData)
const [
  insert_logs_master,
  { loading: insertEventLoading, error: insertEventError },
] = useMutation(INSERT_LOG_MASTER, {
  onError: (error) => {
    console.error("log err Error:", error);
    alert("error")

  },
  onCompleted:(data)=>{
    setlogtask(false)
  }
 
});


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setCheckboxError(""); // Clear any previous error message when the checkbox changes.
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignup(e);
    }
  };
  const navigate = useNavigate();
  const [data1, setData] = useState({});
  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data1,
      [key]: value,
    });
  };

  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
    user,
  } = useSignInEmailPassword();

  const handleSignup = (e) => {
    e.preventDefault();
    const newErrors = {};
    // Validate name
    if (!data1.email) {
      newErrors.email = "Email is required";
    }
    if (!data1.password) {
      newErrors.password = "Password is required";
    }
    if (!isChecked) {
      newErrors.checkbox = "Checkbox Require";
    } 
    // else {
    //   newErrors.checkbox = ""; // Clear the error message if the checkbox is checked
    // }
    
    // Navigate or perform login if all validations pass
    if (!newErrors.email && !newErrors.password && !newErrors.checkbox) {
      // No errors, proceed with login
      signInEmailPassword(data1.email, data1.password);
    }

    
  
  setErrors(newErrors);
  };

  console.log("this is user", id);
  const [roles, setRoles] = useState();

  const { loading, error1, data } = useQuery(GET_USER_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });
  console.log("this is role", user?.defaultRole);

  useEffect(() => {
    setRoles(user?.defaultRole);
  }, [data]);

  const [temp1, setTemp1] = useState(true);
  if (error && temp1) {
    setInvalid(true);
    setTemp1(false);
    console.log("error", error);
  }

  const [temp, setTemp] = React.useState(true);
  // useEffect(()=>{
  if (isSuccess && user && temp) {
    localStorage.setItem("id", user.id);
    localStorage.setItem("name", user.displayName);
    localStorage.setItem("email", user.email);
    // navigate('/admindash')
    if (
      user?.defaultRole === "admin" ||
      user?.defaultRole === "staff" ||
      user?.defaultRole === "Staff"
    ) {
    fetchGeolocation()

      navigate("/admindash", { replace: true, isAuthenticated: true });
      //  setTemp(false)
    } else {
    fetchGeolocation()

      navigate("userdashboard", { replace: true, isAuthenticated: true });
      setTemp(false);
    }
  }

  // console.log("Error",error)
  // },[])
  // if(isSuccess){
  //   navigate('')
  // }

  // const signupfunction = () => {
  //   console.log(data.email, data.password);

  // };
  const [openpop, setOpenpop] = useState(false);
  const openPopup = () => {
    console.log("only for popup");
    setOpenpop(!openpop);
  };
  const [opentncpop, setOpentncpop] = useState(false);
  const opentncPopup = () => {
    console.log("only for popup");
    setOpentncpop(!opentncpop);
  };
  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [ValidError, setValidError] = useState("");

  console.log("invalid credentials", ValidError);

  const insertlog =async(data) => {
    console.log(data,"comming from function")
    let deviceBrand = "Web";
    let countryname = data.country_name+',longitude:' + data.longitude +',latitude:' + data.latitude;
    let IPAddress = data.IPv4;
if(logtask){
  try {
    insert_logs_master({
      variables: {
        deleted_by: id,
        device_info: deviceBrand,
        geolocation: countryname,
        ip_address: IPAddress,
      },
    });
 
    console.log(" task created successfully");
  } catch (error) {
    console.error("GraphQL Mutation Error sdfsfvsf:", error);
  }
}
}

  return (
    <div className="login_bg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          //   background: { logobg },
        }}
      >
        <video autoPlay loop muted>
          <source src={bgvideo} type="video/mp4" />
        </video>

        <Box
          sx={{
            height: "530px",
            borderRadius: "10px",
            width: "400px",
            bgcolor: "white",
            minWidth: 370,
            mt: 9,
            mb: 9,
          }}
        >
          <Box>
            <img
              src={logo}
              alt="logo"
              style={{ width: "45%", height: "45%", marginTop: "25px" }}
            />
          </Box>

          <Typography variant="h4" sx={Styles.header}>
            Welcome
          </Typography>

          {/* ,emailaddress */}
          <Box sx={{ width: "100%", marginTop: "10px" }}>
            <TextField
              sx={{
                width: "80%",
                "& .MuiInputBase-root": {
                  fontFamily: "MontserratRegular",
                  height: "35px",
                  borderRadius: "10px",
                },
              }}
              onChange={(e) => {
                handleChange("email", e.target.value);
              }}
              error={!!errors.email}
              helperText={errors.email}
              id="outlined-basic"
              placeholder="Email"
              size="small"
              value={data1.email}
            />
          </Box>
          {emailError && (
            <p
              style={{
                fontFamily: "MontserratRegular",
                color: "#FF0000",
                margin: 0,
                marginTop: 2,
              }}
            >
              User Does not Exists
            </p>
          )}

          <Box
            sx={{
              mt: 1.5,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl
              sx={{ width: "80%", borderRadius: "10px" }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{ borderRadius: "10px", height: "35px" }}
                size="small"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => {
                  handleChange("password", e.target.value);
                }}
                onKeyPress={handleEnterKeyPress}
                value={data1.password}
                error={!!errors.password}
                helperText={errors.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }

                // { errorpass ? (
                //     <p style={{ color: "red" }}> Enter the valid Password</p>
                //   ) : (
                //     ""
                //   )
                // }
              />
            </FormControl>
          </Box>
          {passwordError && (
            <p
              style={{
                fontFamily: "MontserratRegular",
                color: "#FF0000",
                margin: 0,
                marginTop: 2,
              }}
            >
              Wrong Password
            </p>
          )}
          {invalid && (
            <p
              style={{
                fontFamily: "MontserratRegular",
                color: "#FF0000",
                margin: 0,
                marginTop: 2,
              }}
            >
              Invalid Email or Password
            </p>
          )}
          {/* {ValidError && <h4 style={{fontFamily:"MontserratRegular",color:"#FF0000"}}>Invalid Credentials</h4>} */}
          <Box
            sx={{
              width: "90%",
              height: 30,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p
              style={{ color: "#3457D5", cursor: "pointer" }}
              onClick={() => {
                navigate("/forgotpassword");
              }}
            >
              {" "}
              Forgot Password ?
            </p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
                style={{ display: "flex", marginLeft:'10px', justifyContent: "left","& .MuiFormControlLabel-label": {
                  fontFamily: "MontserratRegular",color: isChecked ? 'red' : 'red',
                },
                "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                  { color: "red" },}}
                  control={
                      <Checkbox
                      defaultChecked
                      required
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                        style={{
                          color: isChecked ? 'red' : 'red',
                        }}
                      />
                    }
                  
                />
                
            <p>EULA</p>
            <p
              style={{ color: "#3457D5", margin: 15, cursor: "pointer" }}
              onClick={opentncPopup}
            >
              
              Terms and Conditions
            </p>
          </Box>
          {opentncpop && <TNCPop open={opentncPopup} setOpentncpop={setOpentncpop} />}
          {errors.checkbox && (
        <p
          style={{
            color: "red",
            fontFamily: "MontserratRegular",
            margin: 0,
            marginTop: 2,
          }}
        >
          {errors.checkbox}
        </p>
      )}
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ width: "80%" }}>
              <FullRedButton
                title="Login"
                click={(e) => {
                  {!isChecked && <div style={{ color: 'red' }}></div>}
                  handleSignup(e);
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <p>Don't have an account ? </p>
            <p
              style={{ color: "#FF0000", margin: 15, cursor: "pointer" }}
              onClick={openPopup}
            >
              {" "}
              Sign Up
            </p>
          </Box>
          
        </Box>
      </Box>
      {openpop && <SignPopUp open={openpop} setOpenpop={setOpenpop} />}
      
    </div>
  );
}
