import { Box, Typography } from "@mui/material";
import { React, useState,useEffect } from "react";
import DetailsEdit from "../Popup/GroupManagementEditPopups/DetailsEdit";
import { Button } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { gql, useQuery } from "@apollo/client";


const GET_ALL_GROUP_QUERY = gql`query MyQuery15($_eq: bigint = "67") {
  group_master(where: { id: { _eq: $_eq } }) {
    activation_date
    attendance_capacity
    category_id
    closure_date
    deactivation_date
    decription
    duration
    group_category {
      created_by
      created_date
      description
      id
      name
    }
    group_events {
      event_id
      group_id
      id
      event_master {
        activation_date
        category_id
        closure_date
        cover_img_path
        created_at
        deactivation_date
        description
        end_time
        id
        is_recurring
        location
        metadata
        name
        parent_id

        recurrance_end_date
        recurrance_end_time
        recurrance_frequency
        recurrance_start_time
        recurrance_start_date
        start_time
        type_id
        updated_at
        event_category {
          name
          id
        }
        event_type {
          name
          id
        }
      }
    }
    group_type {
      created_by
      created_date
      description
      id
      name
    }
    id
    level_up_group
    name
    price
    task_masters {
      description
      event_id
      file_path
      group_id
      id
      target_date
      staff_id
      task_name
      user {
        id
        displayName
        defaultRole
        email
        profile_detail {
          first_name
        }
      }
    }
    type
    user_groups{
      group_id
      id
      user_id
    }
  }
}
`;

export default function DetailsBox(props) {
  // console.log("props group type name",props.grouptype?.name)

  // let namea = props.grouptype.name;
  let attendanceCapacity = props.group?.attendance_capacity;

  // console.log("id",props.id)
  const [group, setGroup] = useState([]);
  const { loading, error, data } = useQuery(GET_ALL_GROUP_QUERY, {
    variables: {
      _eq:props.id,
    },
    // skip: !id,
    onError: (error) => {
      // console.log("error for data", error);
    },
  });
  useEffect(() => {
    setGroup(data?.group_master[0]);
  }, [data]);

  // console.log("group data",group);


  const grpName = props.group?.name;
  const grpDescription = props.group?.decription;
  const grpCat = props.group?.group_category?.id;
  const startDate = props.group?.activation_date;
  const endDate = props.group?.deactivation_date;
  const grpImg =props.grpImg;
  const coverImgId = props.coverImgId;
  // console.log("this is grp name in edit",grpName,grpDescription);
  const [openpop, setOpenpop] = useState(false);
  const openPopup = () => {
    console.log("only for popup");
    setOpenpop(!openpop);
  };


  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: 30,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* {(props.user === "admin" || props.user === null) && (
            <Button onClick={openPopup}>
            <EditIcon style={{ height: 25, width: 25, color: "#FF0000" }} />
          </Button>
          )} */}
        
      </Box>
      <Box sx={Styles.detailsBox}>
        {/* Left Side Box */}

        <Box
          sx={{
            width: "95%",
            marginTop: "30px",
            // margin:"auto",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
            "@media (max-width:768px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img
                style={Styles.icon}
                src={require("../../images/Calender.png")}
              />
              <Typography sx={Styles.lowerHeader}>Group Category</Typography>
            </Box>
            <Typography sx={Styles.text}>{props.groupcat?.name}</Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img
                style={Styles.icon}
                src={require("../../images/Calender.png")}
              />
              <Typography sx={Styles.lowerHeader}>Group Type</Typography>
            </Box>
            <Typography sx={Styles.text}>{props.grouptype?.name}</Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img
                style={Styles.icon}
                src={require("../../images/Hours.png")}
              />
              <Typography sx={Styles.lowerHeader}>Duration</Typography>
            </Box>
            <Typography sx={Styles.text}>{props.group?.duration}</Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img
                style={Styles.icon}
                src={require("../../images/Notifications.png")}
              />
              <Typography sx={Styles.lowerHeader}>
                Attendance Capacity
              </Typography>
            </Box>
            <Typography sx={Styles.text}>
              {props.group?.attendance_capacity}
            </Typography>
          </Box>
          {(props.user !== "user" ) && (
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img
                style={Styles.icon}
                src={require("../../images/Price.png")}
              />
              <Typography sx={Styles.lowerHeader}>Price</Typography>
            </Box>
            <Typography sx={Styles.text}> $ {props.group?.price}</Typography>
          </Box>
          )}
        </Box>
        
        {openpop && (
          <DetailsEdit
            attendanceCapacity={props.group?.attendance_capacity}
            price={props.group?.price}
            duration={props.group?.duration}
            type={props.grouptype?.id}
            id={props.group.id}
            open={openpop}
            setOpenpop={setOpenpop}
            name={grpName}
            description={grpDescription}
            grpCat={grpCat}
            startDate={startDate}
            endDate={endDate}
            cover_img_path={grpImg}
            coverImgId={coverImgId}
          />
        )}
      </Box>
    </>
  );
}
const Styles = {
  detailsBox: {
    width: "100%",
    height: "265px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    // overflow:"scroll"
  },
  lowerHeader: {
    fontSize: "14px",
    marginLeft: "5px",
    color: "#252525",
    fontFamily: "MontserratBold",
  },
  text: {
    marginLeft: "0px",
    marginTop: "5px",
    fontSize: "12px",
    fontFamily: "MontserratRegular",
    lineHeight: "15px",
    color: "#252525",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },
};
