import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import datetime from "./Date & Time.png";
import location from "./Location.png";
import { useNhostClient } from "@nhost/react";
import img1 from "../../images/img3.jpg";

export default function GroupManagementStepperCard(props) {
  const { img, title, desc, date, time, address } = props;

  const nhost = useNhostClient();
  const [imgUrl ,setImgUrl] = useState("");
  console.log("these are images",imgUrl);
  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: img,
        });
        // console.log("Image URL:", item);
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [props]);

  const isNullUrl = imgUrl.endsWith("null") || imgUrl.endsWith("/");

  return (
    <>
      <Box style={{width:"250px",minWidth:'250px'}} >
        {/* <img src={img} style={{width:'100%',height:'120px',borderTopLeftRadius:'10px',borderTopRightRadius:"10px"}} alt="img" /> */}
        {isNullUrl ? (
        <img src={img1} alt="Static Image" style={{width:'100%',height:'120px',borderTopLeftRadius:'10px',borderTopRightRadius:"10px"}} />
      ) : (
        <img src={imgUrl} alt="profile" style={{width:'100%',height:'120px',borderTopLeftRadius:'10px',borderTopRightRadius:"10px"}} />
      )}
        <Box>
          <Typography
            sx={{
              "&::-webkit-scrollbar": {
                width: "0.2em",
                borderRadius: "10px",
                marginBottom: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#E1E5F8",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
              width: "100%",
              height: "24px",
              overflowY: "scroll",
              mx: 0.5,
              fontFamily: "MontserratMedium"
            }}
          >
            {title}
          </Typography>
          <p
            style={{
              // "&::-webkit-scrollbar": {
              //   width: "0.1em",
              //   borderRadius: "5px",
              //   marginBottom: "0px",
              // },
              // "&::-webkit-scrollbar-track": {
              //   background: "#E1E5F8",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   backgroundColor: "#888",
              // },
              // "&::-webkit-scrollbar-thumb:hover": {
              //   background: "#555",
              // },
              fontSize: "10px",
              width: "100%",
              height: "26px",
              overflowY: "hidden",
              marginLeft: 5,
              marginTop: -1,
              fontFamily: "MontserratRegular",
             
            }}
          >
            {desc}
          </p>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            my: 1,
          }}
        >
          <img src={datetime} alt="" height={20} />
          <Typography sx={{ fontSize: 12, fontFamily: "MontserratRegular" }}>
            {date}
          </Typography>
          <Typography
            sx={{ fontSize: 12, fontFamily: "MontserratRegular", mx: 1 }}
          >
            {time}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <img src={location} alt="" height={20} />
          <Typography
            sx={{
              width: "100%",
              height: "26px",
              overflowY: "scroll",
              fontSize: 10,
              fontFamily: "MontserratRegular",
              display: "flex",
              alignItems: "center",
              "&::-webkit-scrollbar": {
                width: "0.2em",
                borderRadius: "10px",
                marginBottom: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#E1E5F8",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            {address}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
