import React, { useEffect } from "react";
import { useState } from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import searchIcon from "../../images/searchIcon.png";
import Slide from "@mui/material/Slide";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useNhostClient } from "@nhost/react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import event from "../../images/All Experinces.png";
import group1 from "../../images/Group1.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMore1 = styled((props) => {
  const { expand1, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand1 }) => ({
  transform: !expand1 ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

  // const ExpandMore2 = styled((props) => {
  //   const { expand2, ...other } = props;
  //   return <IconButton {...other} />;
  // })(({ theme, expand2 }) => ({
  //   transform: !expand2 ? "rotate(0deg)" : "rotate(180deg)",
  //   marginLeft: "auto",
  //   transition: theme.transitions.create("transform", {
  //     duration: theme.transitions.duration.shortest,
  //   }),
  // }));

export default function DashSearchBar(props) {
  //   const [open, setOpen] = React.useState(false);
  // console.log("propedvalue", props.attendanceCapacity);
  const { onClose, open } = props;
  const [age, setAge] = React.useState("");

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [expanded1, setExpanded1] = React.useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const [expanded2, setExpanded2] = React.useState(false);

  // const handleExpandClick2 = () => {
  //   setExpanded2(!expanded2);
  // };

  // const handleClickOpen = () => {
  //   props.setOpenpop(true);
  // };

  const handleClose = () => {
    props.setOpenpop(false);
  };

  const nhost = useNhostClient();

  const [imgUrl, setImgUrl] = useState("");

  // useEffect(() => {
  //   async function getImage() {
  //     nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
  //     nhost.storage.setAccessToken(Events.cover_img_path);

  //     const publicUrl = await nhost.storage.getPresignedUrl({
  //       fileId: Events.cover_img_path,
  //     });

  //     setImgUrl(publicUrl.presignedUrl.url);
  //   }

  //   getImage();
  // }, []);




  const [data, setdata] = React.useState(props);
  const handleChange = (key, value) => {
    // console.log(key, value);
    setdata({
      ...data,
      [key]: value,
    });
  };
  const handleChange1 = (event) => {
    setAge(event.target.value);
  };

  const [Events, setEvents] = useState([]);


  const [group, setgroup] = useState([]);



  // const [members, setMembers] = React.useState([]);
  // // const [Events, setEvents] = useState([]);

  // const fetchmembers = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "x-hasura-admin-secret",
  //     process.env.REACT_APP_NHOST_SECREATEKEY
  //   );
  //   myHeaders.append("Access-Control-Allow-Credentials", true);
  //   myHeaders.append("Access-Control-Allow-Origin", "*");

  //   myHeaders.append(
  //     "Access-Control-Allow-Methods",
  //     "GET,OPTIONS,PATCH,DELETE,POST,PUT"
  //   );
  //   myHeaders.append(
  //     "Access-Control-Allow-Headers",
  //     "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
  //   );

  //   var graphql = JSON.stringify({
  //     query: `query getAllUsers {
  //             profile_details {
  //               bio
  //               birth_date
  //               category
  //               first_name
  //               last_name
  //               profile_pic_path
  //               second_name
  //               user_id
  //               users {
  //                 defaultRole
  //                 displayName
  //                 email
  //                 emailVerified
  //                 id
  //                 phoneNumber
  //               }
  //             }
  //           }`,
  //   });
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: graphql,
  //     redirect: "follow",
  //     // mode: "no-cors",
  //   };

  //   const res = await fetch(process.env.REACT_APP_URL, requestOptions);
  //   const data = await res.json();
  //   console.log("staff", data.data.profile_details[0].users);
  //   setMembers(data.data.profile_details);
  // };

  // React.useEffect(() => {
  //   // Calling fetchData Method
  //   // fetchData();
  //   fetchmembers();
  // }, []);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredMembers, setFilteredMembers] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value;
    // console.log("handleSearch",query);
    setSearchQuery(query);

    const filteredEvents = Events.filter(
      (item) =>
        (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
        (item.description &&
          item.description.toLowerCase().includes(query.toLowerCase()))
    );

    const filteredGroups = group.filter(
      (item) =>
        (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
        (item.description &&
          item.description.toLowerCase().includes(query.toLowerCase()))
    );

    // const filteredMembers = members.filter(
    //   (item) =>
    //     (item.first_name &&
    //       item.first_name.toLowerCase().includes(query.toLowerCase())) ||
    //     (item.bio && item.bio.toLowerCase().includes(query.toLowerCase()))
    // );

    const filteredData = [
      ...filteredEvents,
      ...filteredGroups,
      // ...filteredMembers,
    ];
    setFilteredData(filteredData);

    setFilteredEvents(filteredEvents);
    setFilteredGroups(filteredGroups);
    // setFilteredMembers(filteredMembers);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <Box
          sx={{
            height: 60,
            backgroundColor: "#EE4036",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginLeft: 4 }}>
            <img src={require("../../images/navLogo.png")} />
          </Box>
          <Button onClick={handleClose}>
            <Typography sx={{ color: "white" }}>Close</Typography>{" "}
          </Button>
        </Box>

        {/* Group Category */}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <input
            type="search"
            placeholder="Search here"
            // onChange={(e) => {

            //   const query = e.target.value;
            //   setSearchQuery(query);
            //   const filtered = feedData.filter((item) =>
            //     item.header_name.toLowerCase().includes(query.toLowerCase())
            //   );
            //   setFilteredData(filtered);

            // }}

            value={searchQuery}
            onChange={handleSearch}
            style={{
              width: "90%",
              marginTop: 10,
              paddingLeft: "15px",
              height: "50px",
              fontfamily: "MontserratLite",
              border: "1px solid #999999",
              borderRadius: "5px",
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxWidth: 600,
                width: 600,
                borderBottom: 1,
                borderBottomColor: "#999999",
                margin: 5,
              }}
            >
              <CardActions disableSpacing>
                <img sx={{ height: 25, width: 25 }} src={event} alt="event" />
                <Typography
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    marginLeft: 2,
                  }}
                >
                  Events
                </Typography>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <ImageList
                    sx={{ width: 550, height: 600 }}
                    cols={3}
                    rowHeight={200}
                  >
                    {searchQuery !== ""
                      ? filteredEvents.map((item, index) => (
                          <ImageListItem
                            key={Events.id}
                            onClick={() => alert("hrllo", index)}
                          >
                            <img
                              src={require("../../images/AWAKEORIGINS.png")}
                              srcSet={require("../../images/AWAKEORIGINS.png")}
                              alt={item.name}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item.description}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.name}`}
                                ></IconButton>
                              }
                            />
                          </ImageListItem>
                        ))
                      : filteredEvents.length > 0
                      ? filteredEvents.map((item, index) => (
                          <ImageListItem
                            key={Events.id}
                            onClick={() => alert("hrllo", index)}
                          >
                            <img
                              src={require("../../images/AWAKEORIGINS.png")}
                              srcSet={require("../../images/AWAKEORIGINS.png")}
                              alt={item.name}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item.description}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.name}`}
                                ></IconButton>
                              }
                            />
                          </ImageListItem>
                        ))
                      : Events.map((item, index) => (
                          <ImageListItem
                            key={Events.id}
                            onClick={() => alert("hrllo", index)}
                          >
                            <img
                              src={require("../../images/AWAKEORIGINS.png")}
                              srcSet={require("../../images/AWAKEORIGINS.png")}
                              alt={item.name}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item.description}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.name}`}
                                ></IconButton>
                              }
                            />
                          </ImageListItem>
                        ))}
                  </ImageList>
                </CardContent>
              </Collapse>
            </Card>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxWidth: 600,
                width: 600,
                borderBottom: 1,
                borderBottomColor: "#999999",
                margin: 5,
              }}
            >
              <CardActions disableSpacing>
                <img sx={{ height: 25, width: 25 }} src={group1} alt="group" />
                <Typography
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    marginLeft: 2,
                  }}
                >
                  Groups
                </Typography>
                <ExpandMore1
                  expand1={expanded1}
                  onClick={handleExpandClick1}
                  aria-expanded={expanded1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore1>
              </CardActions>
              <Collapse in={expanded1} timeout="auto" unmountOnExit>
                <CardContent>
                  <ImageList sx={{ width: 550, height: 600 }} cols={3}>
                    {searchQuery !== ""
                      ? filteredGroups.map((item, index) => (
                          <ImageListItem
                            key={group.id}
                            onClick={() => alert("hrllo", index)}
                          >
                            <img
                              src={require("../../images/AWAKEORIGINS.png")}
                              srcSet={require("../../images/AWAKEORIGINS.png")}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item.decription}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.name}`}
                                ></IconButton>
                              }
                            />
                          </ImageListItem>
                        ))
                      : filteredGroups.length > 0
                      ? filteredGroups.map((item, index) => (
                          <ImageListItem
                            key={group.id}
                            onClick={() => alert("hrllo", index)}
                          >
                            <img
                              src={require("../../images/AWAKEORIGINS.png")}
                              srcSet={require("../../images/AWAKEORIGINS.png")}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item.decription}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.name}`}
                                ></IconButton>
                              }
                            />
                          </ImageListItem>
                        ))
                      : group.map((item, index) => (
                          <ImageListItem
                            key={group.id}
                            onClick={() => alert("hrllo", index)}
                          >
                            <img
                              src={require("../../images/AWAKEORIGINS.png")}
                              srcSet={require("../../images/AWAKEORIGINS.png")}
                              loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.name}
                              subtitle={item.decription}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                  aria-label={`info about ${item.name}`}
                                ></IconButton>
                              }
                            />
                          </ImageListItem>
                        ))}
                  </ImageList>
                </CardContent>
              </Collapse>
            </Card>
          </Box>

          
        </Box>
      </Dialog>
    </div>
  );
}
