import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import navLogo from "../images/navLogo.png";
import profile1 from "../images/profile1.png";
import profile2 from "../images/profile2.png";
import searchIcon from "../images/searchIcon.png";
import "../styles/navbar.css";
import { useNhostClient, useSignOut, useUserId } from "@nhost/react";
import Profile from "../images/Profile.jpg";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import DashSearchBar from "./Popup/DashSearchBar";
import DashSearchBar2 from "./Popup/DashSearchBar2";
import ChatIcon from "@mui/icons-material/Chat";
import userchat from "../components/Chat/UserChat";

import { gql, useQuery } from "@apollo/client";

const GET_USER_QUERY = gql`
  query GetUser($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
      defaultRole
    }
  }
`;
const GET_USER_ROLE = gql`
  query getuserrole($_eq: uuid = "") {
    profile_details(where: { user_id: { _eq: $_eq } }) {
      roles
    }
  }
`;
const GET_PERMISSION_QUERY = gql`
  query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
    role_master(
      where: {
        access_name: { _eq: $_eq }
        _and: {
          roleprofile: {
            user_id: { _eq: $_eq1 }
            _and: { user: { defaultRole: { _eq: $_eq2 } } }
          }
        }
      }
    ) {
      access_name
      description
      id
      roles
      status
      roleprofile {
        user {
          defaultRole
        }
      }
    }
  }
`;

export default function Navbar(props) {
  const id = useUserId();
  const [roles, setRoles] = useState();

  const { loading, error, data } = useQuery(GET_USER_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });
  // console.log(data?.user.defaultRole);
  const userId = useUserId()

  useEffect(() => {
    setRoles(data?.user.defaultRole);
    localStorage.setItem("role", data?.user.defaultRole);
  }, [data]);
  const { data: roleprofile, } = useQuery(GET_USER_ROLE, {
    variables: {
      _eq: id,
    },
    onError: (error) => {
      // console.log("this is error for role" , error)
    },
    onCompleted: (data) => {
      // console.log("on complete data", data);
      localStorage.setItem("roleaccess", data?.profile_details[0]?.roles);
    },
    // skip: !id
  });
  const roll = localStorage.getItem("role");

  const { data: permsission } = useQuery(GET_PERMISSION_QUERY, {
    variables: {
      _eq: roleprofile?.profile_details[0]?.roles,
      _eq1: userId,
    },
    onError: (error) => {
      console.log(error, "this is error");
    },
    onCompleted: (data) => {
      // console.log("permission status :", data);
    },
  });

  const navigate = useNavigate();
  const { signOut } = useSignOut();
  const [Profilepic, setProfilepic] = useState("");
  const [openpop, setOpenpop] = useState(false);
  const [openpop1, setOpenpop1] = useState(false);

  const openPopup = () => {
    if (roles === "admin" || roles === "staff" || roles === "Staff") {
      setOpenpop(!openpop);
    } else if (roles === "user") {
      setOpenpop1(!openpop1);
    }
  };

  const nhost = useNhostClient();
  const [imgUrl, setImgUrl] = useState("");
  // console.log("image url in headwr", imgUrl);
  useEffect(() => {
    async function getImage() {
      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
      // nhost.storage.setAccessToken(Profilepic)

      // const publicUrl = await nhost.storage.getPresignedUrl({
      //   fileId: Profilepic,
      // })
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: data?.user?.avatarUrl,
        });
        // console.log("Image URL:", item);

        // setImgPreview(item)
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }

    getImage();
  }, [Profilepic]);

  // console.log("props is user or not in navbar", roles);

  const handleDashboardClick = () => {
    if (roles === "admin" || roles === "staff" || roles === "Staff") {
      navigate("/admindash");
    } else if (roles === "user") {
      navigate("/userdashboard");
    }
  };

  const handleProfileClick = () => {
    // if (roles === "admin") {
    navigate("/profile");
    // } else if (roles === "user") {
    //   navigate("/userprofile");
    // }
  };

  return (
    <div className="navbar">
      <div
        className="img"
        onClick={() => {
          handleDashboardClick();
        }}
      >
        <img src={navLogo} alt="logo" />
      </div>
      <div className="middle">
        {/* <img  className='profile1' src={profile1} alt='profile' onClick={handleLogout} /> */}
        <div className="searchbar">
          <img className="searchbar-icon" src={searchIcon} alt="searchIcon" />
          <input
            className="searchbar-input"
            type="text"
            placeholder=" Search here "
            readOnly
            onClick={openPopup}
          />
        </div>
      </div>

      <div className="icon">
        <Stack sx={{ display: "flex", flexDirection: "row" }}>
          {((permsission?.role_master[0]?.roles?.chatadmin &&
            roll === "staff") ||
            roll === "admin") && (
            <ChatIcon
              sx={{ height: 35, width: 35, margin: 3.5, color: "#FFFFFF" }}
              onClick={() => navigate("/userchat")}
            />
  )}
          <Avatar
            src={imgUrl}
            alt="profile"
            sx={{ margin: 2, marginTop: 3 }}
            onClick={handleProfileClick}
          />
        </Stack>
      </div>
      {/* {openpop &&<DashSearchBar open={openpop}  setOpenpop={setOpenpop} /> } */}
      {/* {openpop && props.user === 'user' && <DashSearchBar open={openpop} setOpenpop={setOpenpop} />} */}

      <DashSearchBar open={openpop} setOpenpop={setOpenpop} />

      <DashSearchBar2 open={openpop1} setOpenpop={setOpenpop1} />
    </div>
  );
}
