import * as React from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNhostClient } from "@nhost/react";
import dayjs from "dayjs";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";
// import {GET_ALL_GROUP_QUERY} from "../../../pages/GroupManagement2";

const GET_ALL_GROUP_QUERY = gql`
  query MyQuery15($_eq: bigint = "") {
    group_master(where: { id: { _eq: $_eq } }) {
      activation_date
      attendance_capacity
      category_id
      closure_date
      deactivation_date
      decription
      duration
      group_category {
        created_by
        created_date
        description
        id
        name
      }
      group_events {
        event_id
        group_id
        id
        event_master {
          activation_date
          category_id
          closure_date
          cover_img_path
          created_at
          deactivation_date
          description
          end_time
          id
          is_recurring
          location
          metadata
          name
          parent_id

          recurrance_end_date
          recurrance_end_time
          recurrance_frequency
          recurrance_start_time
          recurrance_start_date
          start_time
          type_id
          updated_at
          event_category {
            name
            id
          }
          event_type {
            name
            id
          }
        }
      }
      group_type {
        created_by
        created_date
        description
        id
        name
      }
      id
      level_up_group
      name
      price
      task_masters {
        description
        event_id
        file_path
        group_id
        id
        target_date
        staff_id
        task_name
        user {
          id
          displayName
          defaultRole
          email
          profile_detail {
            first_name
          }
        }
      }
      type
      user_groups {
        group_id
        id
        user_id
        users {
          displayName
          phoneNumber
          email
        }
      }
    }
  }
`;


const GET_GROUP_TYPE = gql`
  query GetGroupType {
    group_type {
      created_by
      created_date
      description
      id
      name
    }
  }
`;

const GET_GROUP_CATEGORY = gql`
query GetGroupCategory {
  group_category {
    created_by
    created_date
    description
    id
    name
  }
}
`;

const UPDATE_GROUP_DETAILS = gql`
mutation UpdateGroupDetails( $id: bigint = "", $duration: String = "", $attendance_capacity: String = "", $price: String = "", $type: bigint = "", $category_id1: bigint = "",$name: String = "", $decription: String = "", $activation_date: date = "", $deactivation_date: date = "", $cover_img_path: String = "") {
  update_group_master(where: {id: {_eq: $id}}, _set: {duration: $duration, attendance_capacity: $attendance_capacity, price: $price, type: $type, category_id: $category_id1,name: $name, decription: $decription,activation_date: $activation_date, deactivation_date: $deactivation_date, cover_img_path: $cover_img_path}) {
    returning {
      id
      name
      activation_date
      deactivation_date
      cover_img_path
      decription
      duration
      attendance_capacity
      price
      type
      category_id
    }
  }
}
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();
  //   const [open, setOpen] = React.useState(false);
  // console.log( "propedvalue", props.attendanceCapacity)
  const { onClose, open } = props;
  console.log("props",props)
  console.log("proped value grp cat ", props.grpCat);
  const coverImgId =props.coverImgId;
  const date = props.startDate;
  const eDate = props.endDate;
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false); 
  };

  const nhost = useNhostClient();
  const [image, setImage] = React.useState(null);
  const [coverImageId, setCoverImageId] = React.useState("");
  console.log("cover image id: ", coverImageId);
  console.log("this is images 1 and 2", image, coverImageId);

  const changeImg = () => {
    const confirmation = window.confirm(
      `Do you really want to change Cover Image?`
    );
    if (confirmation) {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      
      input.onchange = async (event) => {
        nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

        let image = event.target.files[0];
        setImage(URL.createObjectURL(image));

        let file = event.target.files[0];
        setCoverImageId(file.name);
        await upload({ file });

        // const fd = new FormData();

        // fd.append("file", image);

        // let res1 = await nhost.storage.upload({ formData: fd });

        // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
        // nhost.storage.setAccessToken(res1.fileMetadata.id);
        // const accessToken = nhost.auth.getAccessToken();

        // const publicUrl = await nhost.storage.getPresignedUrl({
        //   fileId: res1.fileMetadata.id,
        // });

        // // setImage(publicUrl.presignedUrl.url);
        // setCoverImageId(res1.fileMetadata.id);
        // console.log("image",res1.fileMetadata.id);
      };
      input.click();
    }
  };

  const {
    loading: grpTypeLoading,
    error: grpTypeError,
    data: grpTypeData,
  } = useQuery(GET_GROUP_TYPE);

  const {
    loading: grpCatLoading,
    error: grpCatError,
    data: grpCatData,
  } = useQuery(GET_GROUP_CATEGORY);

  const [updateGroupDetails, { loading, error, refetch }] = useMutation(UPDATE_GROUP_DETAILS,
    {
      onCompleted: (data) => {
        console.log("group infor updated : ", data)
        // props.setOpenpop(false);
        props.setOpen(false); 
        props.setOpenPop(false);
        refetch();
        alert("Group updated successfully");        
      },
      onError: (error) => {
        console.error("Mutation Error:", error);
      },
      refetchQueries:[
        {query:GET_ALL_GROUP_QUERY},
        
      ],
    })

  React.useEffect(() => {
    // setgroupType(grpTypeData?.group_type);
    setGroupType(grpTypeData?.group_type);
    setGroupCat(grpCatData?.group_category);

    // console.log("inside use effect", grpCatData?.group_category);
    // setgroupCategory(grpCatData?.group_category);
  }, [grpTypeData,grpCatData]);

  const handleSave = async () => {
    
    try {
      const { data:insertedData } = await updateGroupDetails({
        variables: {
          id: props.id,
          duration: data["duration"],
          attendance_capacity: data["attendanceCapacity"],
          price: data["price"],
          type: data["type"],
          category_id1: data["grpCat"],
          name: data["name"],
          decription: data["description"],
          activation_date:startDates ? startDates : data["start_date"],
          deactivation_date:endDates? endDates : data["end_date"],
          cover_img_path: cover_img_path ? cover_img_path :props.coverImgId,
        },
      });
      console.log("instered group header info",insertedData)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

  };
  // console.log("cover image path",cover_img_path)
  const [groupCat, setGroupCat] = React.useState([]);
  
  

  const [groupType, setGroupType] = React.useState([]);
 

  const [data, setdata] = React.useState(props);
  const handleChange = (key, value) => {
    console.log(key, value);
    setdata({
      ...data,
      [key]: value,
    });
  };

  const [startDates, setstartDates] = React.useState(dayjs());
  const [endDates, setendDates] = React.useState(dayjs());

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setdata((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
        
      };
      
    });
  };

  console.log("data ",data);
  return (
    <div>
     
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            height: 50,
            width: "100%",
            backgroundColor: "#FF0000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            style={{ marginLeft: 25 }}
            src={require("../../../images/navLogo.png")}
          />
          <Button onClick={handleClose}>
            <Typography sx={{ color: "white" }}>Close</Typography>{" "}
          </Button>
        </Box>

        {/* Group Category */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            <Typography>Group Name</Typography>
            <TextField
              placeholder="Group Name"
              value={data.name}
              onChange={(e) => handleChange("name", e.target.value)}
              size="small"
              sx={{ width: "360px" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            <Typography>Description</Typography>
            <TextField
              placeholder="Description"
              value={data.description}
              onChange={(e) => handleChange("description", e.target.value)}
              size="small"
              sx={{ width: "360px" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1, width: "360px" }}>
            <Typography>Start Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                // inputFormat="MM/DD/YYYY"
                format="YYYY/MM/DD"
                size="small"
                label="Enter Start Date"  
                // placeholder={date}
                // value={date}
                // value={date ? date : data?.startDate}
                // value={date ? date : (data?.startDate || null)}
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                    height: "40px",
                  },
                  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                    lineHeight: "15px",
                  },
                }}
                // onChange={(e) => {
                //   handleDateChange("start_date", e);
                // }}
                value={startDates}
                onChange={(date) => {
                  setstartDates(date);
                  handleDateChange("start_date", date);
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1, width: "360px" }}>
            <Typography>End Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                // inputFormat="MM/DD/YYYY"
                format="YYYY/MM/DD"
                size="small"
                label="Enter End Date"
                // placeholder={eDate}
                // value={data.end_date}
                value={endDates}
                onChange={(eDate) => {
                  setendDates(eDate);
                  handleDateChange("end_date", eDate);
                }}
                // value={date ? date : data?.startDate}
                // value={date ? date : (data?.startDate || null)}
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  "& .MuiInputBase-root": {
                    fontFamily: "MontserratRegular",
                    height: "40px",
                  },
                  "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    fontSize: "14px",
                    lineHeight: "15px",
                  },
                }}
                // onChange={(e) => {
                //   handleDateChange("end_date", e);
                // }}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1, width: "360px" }}>
            <Typography>Group Category</Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // label="Group Category"
                // sx={{ display: "flex", flexDirection: "column" }}
                value={data.grpCat}
                onChange={(e) => {
                  handleChange("grpCat", e.target.value);
                }}
                size="small"
              >
                {groupCat?.map((row) => (
                  <MenuItem
                    sx={{ display: "flex", flexDirection: "column" }}
                    value={row.id}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* Group Type */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1, width: "360px" }}>
            <Typography>Group Type</Typography>

            <FormControl fullWidth>
              {/* <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{ width: "95%" }}
                  >
                    Group
                  </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // label="Group Type     hello"
                // sx={{ display: "flex", flexDirection: "column" }}
                value={data?.type}
                // placeholder={data?.type}
                onChange={(e) => {
                  handleChange("type", e.target.value);
                }}
                size="small"
              >
                {groupType?.map((row) => (
                  <MenuItem
                    sx={{ display: "flex", flexDirection: "column" }}
                    value={row.id}
                    key={row.id}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* Duration */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            <Typography>Duration</Typography>
            <TextField
              placeholder="Duration"
              value={data.duration}
              onChange={(e) => handleChange("duration", e.target.value)}
              size="small"
              sx={{ width: "360px" }}
            />
          </Box>
        </Box>
        {/* Attendance Capacity */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            <Typography>Attendance Capacity</Typography>
            <TextField
              placeholder="Attendance Capacity"
              value={data.attendanceCapacity}
              onChange={(e) =>
                handleChange("attendanceCapacity", e.target.value)
              }
              size="small"
              sx={{ width: "360px" }}
            />
          </Box>
        </Box>
        {/* Price */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }}>
            <Typography>Price</Typography>
            <TextField
              placeholder="Price"
              value={data.price}
              onChange={(e) => handleChange("price", e.target.value)}
              size="small"
              sx={{ width: "360px" }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <Box sx={{ m: 1 }}> */}
          <Box>
            <Typography>Cover Image</Typography>
          </Box>
          <Box
            onClick={() => changeImg()}
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            {/* <img style={{ width: "80%" }} src={props.cover_img_path} /> */}
            {image ? (
              <img style={{ width: "80%",height:"320px" }} src={image} alt="Cover Image" />
            ) : (
              <img
                style={{ width: "80%", height:"320px" }}
                src={props.urlImg}
                alt="Cover Image"
              />
            )}
          </Box>
          {/* </Box> */}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            my: 1,
          }}
        >
          <Button
            // variant="contained"
            // sx={{ backgroundColor: "#FF0000", color: "#FFFFFF", width: 30 }}
            sx={{
              ml: 2,
              // width: "10px",
              color: "#FFFFFF",
              backgroundColor: "#FF0000",
              "&:hover": {
                backgroundColor: "#FF0000",
                color: "#FFFFFF",
                border: 1,
              },
              height: 30,
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}
