import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useState ,useEffect} from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import GroupManagementStepperCard from "../GroupManagement/GroupManagementStepperCard";
import { Box } from "@mui/material";
import GroupManagementStepperCard2 from "./GroupManagementStepperCard2";
import searchbar from "../../images/searchbar.png";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_ALL_MEMBERS = gql`
query getAllUdscsers {
  profile_details(where: {user: {defaultRole: {_eq: "user"}}}) {
    bio
    birth_date
    category
    first_name
    last_name
    profile_pic_path
    second_name
    user_id
    user {
      defaultRole
      displayName
      email
      emailVerified
      id
      phoneNumber
      avatarUrl
    }
  }
}
`;

const GET_MAPPED_USERS = gql`
query MyQuery10($_eq: bigint = "") {
  user_groups(where: {group_id: {_eq: $_eq}}) {
    user_id
    group_id
    users {
      displayName
      email
      id
    }
  }
}
`

const MAP_USER_GROUPS = gql`
mutation MapUserGrps($group_id: bigint, $user_id: uuid = "" ) {
  insert_user_groups(objects: {group_id: $group_id, user_id: $user_id}) {
    returning {
      group_id
      id
      user_id
    }
  }
}
`;

//  CHANGE THE USER_id BEFORE PRODUCTION

const REMOVE_USER_GROUPS = gql`
mutation RemoveMapUsrGrps($group_id: bigint = "", $user_id: uuid = "") {
  delete_user_groups(where: {_and: {group_id: {_eq: $group_id}, user_id: {_eq: $user_id}}}) {
    returning {
      group_id
      id
      user_id
    }
  }
}
`;

const GET_MEMBERS_DATA =gql`
query MyQuery($_eq: bigint = "") {
  user_groups(where: {group_id: {_eq: $_eq}}) {
    group_id
    id
    user_id
    users {
      displayName
      email
      phoneNumber
    }
  }
}
`
const GET_MAPPED_EVENTS = gql`
query MyQuery8($_eq: bigint = "") {
  group_event(where: {group_id: {_eq: $_eq}}) {
    event_id
    group_id
    event_master {
      id
      name
    }
  }
}
`
const INSERT_USER_TO_EVENT_MUTATION = gql`
mutation InsertUserToEvent($event_id: bigint, $userid: uuid,$booking_status: String ="booked") {
  insert_user_events(objects: { event_id: $event_id, userid: $userid , booking_status:$booking_status}) {
    returning {
      event_id
      id
      userid
    }
  }
}
`;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddMemberToGroup(props) {
  // const [open, setOpen] = React.useState(false);
  // const {open} = props
  const [selectedId, setSelectedId] = React.useState([]);
  const [memberId, setMemberId] = React.useState([]);
  const [card, setCard] = React.useState([]);

  const [insertUserToEvent,{error:userEventserr}] = useMutation(INSERT_USER_TO_EVENT_MUTATION)

  const [mappedEvents,setMappedEvents] = useState([]);
  const { data: allMapEvent } = useQuery(GET_MAPPED_EVENTS,{
    variables:{
      _eq:props.id
    },
    onCompleted:(data)=>{
      console.log("these are mapped events",data?.group_event);
      setMappedEvents(data?.group_event.map(event => event.event_id));
    }
  });

  


  React.useEffect(() => {
    let arr = selectedId;
    setSelectedId(arr);
  }, [selectedId]);
  const { onClose, open } = props;
  // console.log("props id",props.id)

  const handleClickOpen = () => {
    props.setOpenpop(true);
  };

  const handleClose = () => {
    props.setOpenpop(false);
    refetch();
    props.setCardRef(true);
  };
  const [members, setMembers] = React.useState([]);
  const [mappedMember, setMappedMembers] = React.useState([]);
  // const [Events, setEvents] = useState([]);
  const {
    loading: grpCatLoading,
    error: grpCatError,
    data: memberData,
    
  } = useQuery(GET_ALL_MEMBERS);

  React.useEffect(()=>{
    if (memberData) {
      setMembers(memberData?.profile_details);
    }
    
  },[memberData])

  const {  data:member,refetch } = useQuery(GET_MEMBERS_DATA, {
    variables: { 
      _eq: props.id,
     },
  })

  const {data:mappedMembers} = useQuery(GET_MAPPED_USERS,{
    variables:{
      _eq: props.id,
    }
  });

  useEffect(()=>{
  console.log("this is mapped members now",mappedMembers?.user_groups);
    setMappedMembers(mappedMembers?.user_groups);
  },[])
  useEffect(() => {
    // setCard(props.members);
    // setCard([...props.members, ...temp]);
      setCard(member?.user_groups);
   
  }, [member]);

  React.useEffect(() => {
    if (memberData) {
      const allMembers = memberData?.profile_details;

      if (mappedMembers) {
        // Extract user ids from the mapped users
        const mappedUserIds = mappedMembers.user_groups.map((userGroup) => userGroup.user_id);

        // Filter members that are not in the mapped users
        const filteredMembers = allMembers.filter((member) => !mappedUserIds.includes(member.user_id));

        // Set the state with the filtered members
        setMembers(filteredMembers);
      } else {
        // If mappedUserData is not available, set all members
        setMembers(allMembers);
      }
    }
  }, [mappedMembers]);

  const [mapUserGroups, { loading, error }] = useMutation(MAP_USER_GROUPS,
    {
      onCompleted: (data) => {
        console.log("group infor updated : ", data)
     
      
       },
       onError :(error) =>{
        console.log("group infor errorrr : ", error)

       }
       ,
       refetchQueries: [
        // Provide the name of the query you want to refetch after deletion
        // For example:
        { query: GET_MEMBERS_DATA },
      ],
    }
    
    )

    const [removeUserGroups,] = useMutation(REMOVE_USER_GROUPS,
      {
        onCompleted: (data) => {
          console.log("group infor updated : ", data)
          
        },
        onError:(error) =>{
          console.log("group infor errorr : ", error)

        }
      })
    const handleMembers = async (id, status) => {
    console.log('status is',status , id);
    setMemberId(memberId =>
      memberId.includes(id)
        ? memberId.filter(cardId => cardId !== id)
        : [...memberId, id]
    );
    if (!status) {
      try {
        const { data:insertedData } = await mapUserGroups({
          variables: {
            group_id:props.id,
            // change this for production
            user_id: id,
          },
          
        });
        console.log("instered group header info",insertedData)
        props.settemp(insertedData)
        await Promise.all(
          mappedEvents.map(async (event_id) => {
            try {
              const { data: insertedUserEvent } = await insertUserToEvent({
                variables: {
                  event_id: event_id,
                  userid: id,
                },
              });
              console.log("Inserted user event", insertedUserEvent);
            } catch (error) {
              console.error("GraphQL Mutation Error:", error.message);
            }
          })
        );
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }

    } else {
      try {
        const { data:insertedData } = await removeUserGroups({
          variables: {
            user_id: id,
          group_id:props.id,
          },
        });
        console.log("deleted data group header info",insertedData)
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    
    }
  };


  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = members.filter((item) =>
      item.user?.displayName.toLowerCase().includes(query)
    );
    setFilteredMembers(filtered);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* evnts */}
        <DialogContent>
          <Box sx={styles.container}>
            <Box sx={styles.searchbar}>
              <img sx={styles.searchbarIcon} src={searchbar} alt="searchIcon" />
              <input
                style={{ borderWidth: "0px", outline: "none", width: "350px" }}
                type="text"
                placeholder=" Search here "
                // onChange={(e) => {
                //   // const query = e.target.value;
                //   // setSearchQuery(query);
                //   // const filtered = Events.filter((item) =>
                //   //   item.name.toLowerCase().includes(query.toLowerCase())
                //   // );
                //   // setFilteredEvents(filtered);
                // }}
                value={searchQuery}
                onChange={handleSearch}
              />
            </Box>

            <Box sx={styles.container1}>
              {searchQuery !== ""
                ? filteredMembers?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        handleMembers(item.user?.id, memberId.includes(item.user?.id));
                      }}
                      sx={[
                        {
                          minWidth: "441px",
                          height: "100px",
                          margin: "12px",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                          cursor:"pointer"
                        },
                        memberId.includes(item.user.id) && {
                          border: "2px solid red",
                        },
                      ]}
                    >
                      <GroupManagementStepperCard2
                        name={item.user.displayName}
                        phoneNo={item.user.phoneNumber}
                        email={item.user.email}
                      />
                    </Box>
                  ))
                : members?.map((item, index) => (
                    <Box
                      key={index}
                      // onClick={() => {
                      //   handleMembers(item.users.id, console.log(item.users),memberId.includes(item.users.id),console.log("memberid",memberId.includes(item.users.id)),
                      //   );
                      // }}
                      onClick={() => {
                        handleMembers(item.user?.id, memberId.includes(item.user?.id));
                      }}
                      sx={[
                        {
                          minWidth: "441px",
                          height: "120px",
                          margin: "12px",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                          cursor:"pointer"

                        },
                        memberId.includes(item.user?.id) && {
                          border: "2px solid red",
                        },
                      ]}
                    >
                      <GroupManagementStepperCard2
                        name={item.user.displayName}
                        phoneNo={item.user.phoneNumber}
                        email={item.user.email}
                        img={item.user?.avatarUrl}
                        // ...
                      />
                    </Box>
                  ))}
            </Box>
            <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              ml: 28,
              marginTop: 5,
              width: "100px",
              color: "#FFFFFF",
              position: "absolute",
              fontWeight: "bold",
              bottom:'2%',
              right: '3%',
              backgroundColor: "#FF0000",
              "&:hover": {
                backgroundColor: "#FF0000",
              },
              height: 30,
            }}
          >
            SUBMIT
          </Button>
          </Box>
        </DialogContent>

        {/* ........................................members mapping.................................... */}
      </Dialog>
    </div>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // height:"40px",
  },
  container1: {
    width: "100%",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "2px",
    scrollbarRadius: "10px",
    "&::-webkit-scrollbar": {
      width: "0.7em",
      borderRadius: "10px",
      marginBottom: "0px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  searchbar: {
    height: "25px",
    width: "350px",
    display: "flex",
    margin: "4px",
    marginLeft: "14px",
    flexDirection: "row",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px",
    alignItems: "center",
    fontFamily: "MontserratRegular",
    padding: "2px",
    color: "#999999",
  },
  title: {
    fontFamily: "MontserratRegular",
    color: "#414042",
    fontWeight: "bold",
    fontWeight: "10px",
    margin: "2px",
  },
  searchbar: {
    height: "25px",
    width: "350px",
    display: "flex",
    margin: "4px",
    marginLeft: "14px",
    flexDirection: "row",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px",
    alignItems: "center",
    fontFamily: "MontserratRegular",
    padding: "2px",
    color: "#999999",
  },
  searchbarIcon: {
    width: "20px",
    height: "20px",
    margin: "auto 2% auto 3%",
  },
};
