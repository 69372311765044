import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { EnhancedEncryption } from "@mui/icons-material";

const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontSize: "10px",
    fontWeight: "bold",
  },
});

export default function CloseEventConfirmationPopUp(props) {
  const [open, setOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  const handleClickOpen = () => {
    props.setOpenPopUp(true);
  };

  const handleClose = () => {
    props.setOpenPopUp(false);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            ".MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#999999",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {"Close Event Confirmation"}
          </DialogTitle>
          <DialogContent
            sx={{
              "&.MuiDialogContent-root": {
                marginTop: "10px",
                paddingLeft: "20px",
              },
            }}
          >
            <DialogContentText sx={{ color: "#252525", fontSize: "14px" }}>
              Are you really going to close the event?
            </DialogContentText>

            <DialogContentText sx={{ color: "#252525", fontSize: "14px" }}>
              You cannot access the content onwards.
            </DialogContentText>

            <DialogContentText sx={{ color: "#FF0000", fontSize: "14px" }}>
              Make sure you are backed up all the content.
            </DialogContentText>

            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.MuiCheckbox-root.Mui-checked": { color: "#FF0000" },
                  }}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography
                  sx={{ color: "#252525", fontSize: "14px", color: "#999999" }}
                >
                  Click here to continue
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                border: "1px solid #999999",
                color: "#999999",
                borderRadius: "10px",
                backgroundColor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              sx={{
                border: "1px solid",
                color: "#FFFFFF",
                borderRadius: "10px",
                backgroundColor: isChecked ? "#FF0000" : "#999999",
                borderColor: isChecked ? "#FF0000" : "#999999",
                maxHeight: "40px",

                "&:hover": {
                  backgroundColor: isChecked ? "#FF0000" : "#999999",
                  borderColor: "#FF0000",
                },
              }}
              disabled={!isChecked}
              onClick={handleClose}
            >
              Close Event
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
