// import React, { StrictMode } from "react";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { HashRouter } from "react-router-dom";
// import { createRoot } from "react-dom/client";
// import { NhostClient, NhostProvider } from "@nhost/react";
// import { NhostApolloProvider } from "@nhost/react-apollo";
// import { useSelector } from "react-redux";
// import { Provider } from "react-redux";
// import store from "./Redux/App/Store";

// // const user = useSelector(selectUser);
// // const nhost = new NhostClient({
// //   subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
// //   region: process.env.REACT_APP_NHOST_REGION
// // })

// const nhost = new NhostClient({
//   authUrl: "http://192.168.29.130:1337/v1/auth",
//   graphqlUrl: "http://192.168.29.130:1337/v1/graphql",
//   storageUrl: "http://192.168.29.130:1337/v1/storage",
//   functionsUrl: "http://192.168.29.130:1337/v1/auth/functions",
// });

// const root = createRoot(document.getElementById("root"));
// root.render(
//   <StrictMode>
//     <NhostProvider nhost={nhost}>
//       <NhostApolloProvider nhost={nhost}>
//         <Provider store={store}>
//           <App />
//         </Provider>
//       </NhostApolloProvider>
//     </NhostProvider>
//   </StrictMode>
// );

import React, { StrictMode } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { HashRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { NhostClient, NhostProvider } from '@nhost/react'
import { NhostApolloProvider } from '@nhost/react-apollo'
import { useSelector } from "react-redux";
import { Provider } from "react-redux";
import { HashRouter } from 'react-router-dom';
import store from "./Redux/App/Store";

// const user = useSelector(selectUser);
// const nhost = new NhostClient({
//   subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
//   region: process.env.REACT_APP_NHOST_REGION
// })

// const nhost = new NhostClient({
//   authUrl: 'http://192.168.29.201:1337/v1/auth',
//   graphqlUrl: 'http://192.168.29.201:1337/v1/graphql',
//   storageUrl: 'http://192.168.29.201:1337/v1/storage',
//   functionsUrl: 'http://192.168.29.201:1337/v1/auth/functions',
// })
const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
  // storageUrl: 'https://imrgfwsebfwjpuktdnuv.storage.eu-central-1.nhost.run/v1',
  storageUrl: 'https://pppccjcfkxobnfibvhot.storage.ap-south-1.nhost.run/v1',

})



const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>

    {/* <NhostProvider nhost={nhost}> */}
      {/* <NhostApolloProvider nhost={nhost}> */}
        <Provider store={store}>
        {/* <HashRouter> */}

          <App />
    {/* </HashRouter> */}

        </Provider>
      {/* </NhostApolloProvider>
    </NhostProvider> */}
  </StrictMode>


);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";

// import { Provider } from "react-redux";
// import store from "./Redux/App/Store";
// import { ApolloProvider } from "@apollo/client";

// ReactDOM.render(
//   // <ApolloProvider client={client} >
//   <Provider store={store} >
//     <App />
//   </Provider>
//   // </ApolloProvider>
//   ,
//   document.getElementById("root")
// );
