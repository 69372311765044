import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import review from "../../images/review.png";

export default function BackUpCard(props) {
  const { img, filename, type, size, date, addedBy } = props;
  return (
    <Box sx={style.cardContainer}>
      <Box sx={{ display: "flex", flexDirection: "column",width:"100%" }}>
        <FormControlLabel
          sx={{ justifyContent: "flex-end" }}
          control={
            <Checkbox
              sx={{
                "&.MuiCheckbox-root.Mui-checked": { color: "#FF0000" },
              }}
              // checked={isChecked}
              // onChange={handleCheckboxChange}
            />
          }
          label={""}
        />

        <Box sx={{ display: "flex", flexDirection: "row", width: "100%",flexWrap:"wrap" }}>
          <img
            src={img}
            style={{ margin: "10px" }}
            alt="review"
            width="130px"
            height="100px"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "10px",
            }}
          >
            <Typography sx={style.blackText}>File Name : {filename}</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{display:"flex",minWidth:"300px",flexDirection:"row",flexWrap:"wrap"}}>
              <Typography sx={style.blackText}>File Type : {type}</Typography>
              <Typography sx={style.blackText}>File Size : {size}</Typography>
              </Box>

            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginRight: "8px",
                
              }}
            >
              <Typography sx={style.blackText}>Date Added : {date}</Typography>

              <Typography
                sx={[
                  style.blackText,
                  {
                    marginLeft: "16px",
                    "@media (max-width:768px)":{
                      margin:"0px",
                    },
                  },
                ]}
              >
                Added by : {addedBy}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const style = {
  cardContainer: {
    width: "600px",
    // minWidth:"320px",
    display: "flex",
    flexWrap: "wrap",
    height: "156px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "aquablue",
    margin: "10px",
    "@media (max-width:768px)":{
      width: "300px",
      height:"auto",
      margin:"auto",
      marginY:"4px",
    },
   
  },
  blackText: {
    fontFamily: "MontserratRegular",
    fontSize: "12px",
    color: "#252525",
    marginY: "5px",
    marginX:"5px",
  },
};
