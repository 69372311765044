import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import GroupManagementStepperCard from "../GroupManagement/GroupManagementStepperCard";
import { Box } from "@mui/material";
import img1 from "../../images/img3.jpg";
import searchbar from "../../images/searchbar.png";
import { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

// const GET_ALL_EVENTS = gql`
//   query getEvents {
//     event_master {
//       activation_date
//       category_id
//       closure_date
//       created_at
//       cover_img_path
//       deactivation_date
//       description
//       id
//       name
//       recurrance_end_date
//       recurrance_frequency
//       recurrance_start_date
//       type_id
//       updated_at
//       location
//     }
//   }
// `;

const GET_ALL_EVENTS = gql`
  query getEvents {
    event_master(
      where: { parent_id: { _is_null: true }, status: { _eq: "isactive" } }
    ) {
      activation_date
      category_id
      closure_date
      created_at
      cover_img_path
      deactivation_date
      description
      id
      name
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      type_id
      updated_at
      location
    }
  }
`;

const MAP_EVENT_TO_GROUPS = gql`
  mutation MapEventsGrps($event_id: bigint, $group_id: bigint) {
    insert_group_event(objects: { event_id: $event_id, group_id: $group_id }) {
      returning {
        id
        group_id
        event_id
      }
    }
  }
`;

const REMOVE_EVENTS_FROM_GROUPS = gql`
  mutation RemoveMapEventGrps($event_id: bigint = "", $group_id: bigint = "") {
    delete_group_event(
      where: {
        _and: { event_id: { _eq: $event_id }, group_id: { _eq: $group_id } }
      }
    ) {
      returning {
        event_id
        group_id
        id
      }
    }
  }
`;
const GET_EVENTS_QUERY = gql`
  query MyQuery3($_eq: bigint = "") {
    group_event(where: { group_id: { _eq: $_eq } }) {
      event_id
      event_master {
        cover_img_path
        description
        end_time
        event_category {
          description
          name
          id
        }
        id
        is_recurring
        location
        name
        parent_id
        publish_status
        publish_to
        recurrance_end_date
        recurrance_end_time
        recurrance_start_time
        start_time
        recurrance_start_date
        status
        type_id
      }
    }
  }
`;

const GET_MAPPED_USERS = gql`
  query MyQuery10($_eq: bigint = "") {
    user_groups(where: { group_id: { _eq: $_eq } }) {
      user_id
      id
    }
  }
`;
const INSERT_USER_TO_EVENT_MUTATION = gql`
  mutation InsertUserToEvent($event_id: bigint, $userid: uuid,$booking_status: String ="booked") {
    insert_user_events(objects: { event_id: $event_id, userid: $userid , booking_status:$booking_status}) {
      returning {
        event_id
        id
        userid
      }
    }
  }
`;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddEventToGroup(props) {
  const [selectedId, setSelectedId] = React.useState([]);
  console.log("ye hai group id ", props.gid);
  console.log(" groupid ", props.id);

  React.useEffect(() => {
    let arr = selectedId;
    setSelectedId(arr);
  }, [selectedId]);
  const { onClose, open } = props;

  const handleClickOpen = () => {
    props.setOpenpop(true);
  };

  const handleClose = () => {
    props.setOpenpop(false);
    props.seteventRefetch(true);
  };
  const [Events, setEvents] = React.useState([]);
  const [events, setevents] = React.useState([]);
  const {
    loading: grpCatLoading,
    error: grpCatError,
    data: eventData,
  } = useQuery(GET_ALL_EVENTS);

  React.useEffect(() => {
    setEvents(eventData?.event_master);
  }, [eventData]);

  const { data: eventsdata, refetch } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      _eq: props?.id,
    },
  });
  useEffect(() => {
    setevents(eventsdata?.group_event);
  }, [eventsdata]);
  const [mapEventGroups, { loading, error }] = useMutation(
    MAP_EVENT_TO_GROUPS,
    {
      onCompleted: (data) => {
        refetch();
        console.log("group infor updated : ", data);
      },
      refetchQueries: [
        // Provide the name of the query you want to refetch after deletion
        // For example:
        { query: GET_EVENTS_QUERY },
      ],
    }
  );
  const [removeEventGroups] = useMutation(REMOVE_EVENTS_FROM_GROUPS, {
    onCompleted: (data) => {
      console.log("group infor updated : ", data);
    },
    // refetchQueries: [
    //   // Provide the name of the query you want to refetch after deletion
    //   // For example:
    //   { query: GET_EVENTS_QUERY },
    // ],
  });

  const [insertUserToEvent, { error: userEventserr }] = useMutation(
    INSERT_USER_TO_EVENT_MUTATION
  ); // Add this line
  // console.log("error while inserting user events", userEventserr);
  const { data: allUser } = useQuery(GET_MAPPED_USERS, {
    variables: {
      _eq: props.id,
    },
  });

  const handleEvent = async (id, status) => {
    console.log("your event data", status, id);
    setSelectedId((selectedId) =>
      selectedId.includes(id)
        ? selectedId.filter((cardId) => cardId !== id)
        : [...selectedId, id]
    );

    if (!status) {
      try {
        const { data: insertedData } = await mapEventGroups({
          variables: {
            group_id: props.id,
            event_id: id,
          },
        });
        console.log("instered event to group", insertedData);
        mapUsersToEvents(
          allUser?.user_groups.map((user) => user.user_id),
          [id]
        );
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    } else {
      try {
        const { data: insertedData } = await removeEventGroups({
          variables: {
            group_id: props.id,
            event_id: id,
          },
        });
        console.log("delted event from  group", insertedData);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = Events.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    setFilteredEvents(filtered);
  };

  const mapUsersToEvents = async (userIds, eventIds) => {
    await Promise.all(
      userIds.map(async (user_id) => {
        await Promise.all(
          eventIds.map(async (event_id) => {
            try {
              const { data: insertedUserEvent } = await insertUserToEvent({
                variables: {
                  event_id: event_id,
                  userid: user_id,
                },
              });
              // console.log("Inserted user event", insertedUserEvent);
            } catch (error) {
              console.error("GraphQL Mutation Error:", error.message);
            }
          })
        );
      })
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* evnts */}
        <Box sx={styles.container}>
          <Box sx={styles.searchbar}>
            <img sx={styles.searchbarIcon} src={searchbar} alt="searchIcon" />
            <input
              style={{ borderWidth: "0px", outline: "none", width: "350px" }}
              type="text"
              placeholder=" Search here "
              // onChange={(e) => {
              //   // const query = e.target.value;
              //   // setSearchQuery(query);
              //   // const filtered = Events.filter((item) =>00
              //   //   item.name.toLowerCase().includes(query.toLowerCase())
              //   // );
              //   // setFilteredEvents(filtered);
              // }}
              value={searchQuery}
              onChange={handleSearch}
            />
          </Box>

          <Box sx={styles.container1}></Box>
          {searchQuery !== ""
            ? filteredEvents.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    handleEvent(item.id, selectedId.includes(item.id));
                  }}
                  sx={[
                    {
                      m: 2,
                      width: 250,
                      boxShadow: 1,
                      borderRadius: 2,
                      height: 240,
                      display: "flex",
                      flexDirection: "row",
                    },
                    selectedId.includes(item.id) && {
                      border: "2px solid red",
                    },
                  ]}
                >
                  <GroupManagementStepperCard
                    img={item?.cover_img_path}
                    title={item.name}
                    desc={item.description}
                    date={item.recurrance_start_date}
                    // time={item.time}
                    address={item.location}
                  />
                </Box>
              ))
            : Events.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    handleEvent(item.id, selectedId.includes(item.id));
                  }}
                  sx={[
                    {
                      m: 2,
                      width: 250,
                      boxShadow: 1,
                      borderRadius: 2,
                      height: 240,
                    },
                    selectedId.includes(item.id) && {
                      border: "2px solid red",
                    },
                  ]}
                >
                  <GroupManagementStepperCard
                    // img={img1}
                    img={item?.cover_img_path}
                    title={item.name}
                    desc={item.description}
                    date={item.recurrance_start_date}
                    address={item.location}
                  />
                </Box>
              ))}
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              ml: 28,
              marginTop: 5,
              width: "100px",
              color: "#FFFFFF",
              position: "fixed",
              fontWeight: "bold",
              bottom:'5%',
              backgroundColor: "#FF0000",
              "&:hover": {
                backgroundColor: "#FF0000",
              },
              height: 30,
            }}
          >
            SUBMIT
          </Button>
        </Box>
        {/* ........................................events mapping.................................... */}
      </Dialog>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container1: {
    width: "100%",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "2px",
    scrollbarRadius: "10px",
    "&::-webkit-scrollbar": {
      width: "0.2em",
      borderRadius: "10px",
      marginBottom: "0px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  title: {
    fontFamily: "MontserratRegular",
    color: "#414042",
    fontWeight: "bold",
    fontWeight: "10px",
    margin: "2px",
  },
  searchbar: {
    height: "25px",

    width: "350px",
    display: "flex",
    margin: "4px",
    marginLeft: "14px",
    flexDirection: "row",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px",
    alignItems: "center",
    fontFamily: "MontserratRegular",
    padding: "2px",
    color: "#999999",
    overflowX: "hidden",
  },
  "&::-webkit-scrollbar-track": {
    background: "#E1E5F8",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },

  title: {
    fontFamily: "MontserratRegular",
    color: "#414042",
    fontWeight: "bold",
    fontWeight: "10px",
    margin: "2px",
  },
  searchbarIcon: {
    width: "20px",
    height: "20px",
    margin: "auto 2% auto 3%",
  },
  searchbar: {
    height: "25px",
    width: "350px",
    display: "flex",
    margin: "4px",
    marginLeft: "14px",
    flexDirection: "row",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px",
    alignItems: "center",
    fontFamily: "MontserratRegular",
    padding: "2px",
    color: "#999999",
  },
  searchbarIcon: {
    width: "20px",
    height: "20px",
    margin: "auto 2% auto 3%",
  },
};
