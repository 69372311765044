import { Box, Button, Typography } from "@mui/material";
import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import jsPDF from 'jspdf';
import { useNhostClient } from "@nhost/react";
import html2canvas from 'html2canvas';
import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";


const GET_EVENT_USERS = gql`
  query eventUsers($_eq: bigint = "") {
    user_events(where: { event_id: { _eq: $_eq } }) {
      users {
        displayName
        id
      }
    }
  }
`;

const MARK_ATTENDANCE = gql`mutation MyMutation($_eq: bigint = "", $_eq1: uuid = "", $attendance: Boolean = false) {
  update_event_attendance(where: {event_id: {_eq: $_eq}, user_id: {_eq: $_eq1}}, _set: {attendance: $attendance}) {
    returning {
      attend_time
      event_id
      id
      user_id
      attendance
    }
  }
}
`;


const GET_ATTENDENCE = gql`query fetchAttendence($_eq: bigint = "") {
  event_attendance(where: {event_id: {_eq: $_eq}}) {
    attendance
    event_id
    user_id
  }
}`;

const INSERT_ATTENDANCE_PDF = gql`
mutation AddContent($associated_event_id: bigint, $associated_group_id: bigint, $file_name: String = "", $link: String = "", $published_by: String = "",  $type: String = "",  $uploaded_by: String = "") {
  insert_content_master(objects: {associated_event_id: $associated_event_id, associated_group_id: $associated_group_id,  file_name: $file_name, link: $link, published_by: $published_by, type: $type,  uploaded_by: $uploaded_by}) {
    returning {
      associated_event_id
      associated_group_id
      category_id
      expiry_date
      file_name
      id
      link
      published_by
      publishing_date
      type
      upload_date
      uploaded_by
    }
  }
}
`;

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    width: "100%",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    //   borderRadius:"10px"
  },
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: "0px 16px",
    height: 5,
    fontFamily: "MontserratRegular",
  },
  header: {
    height: 30,
  },
  headerCell: {
    padding: "10px 16px",
    height: 25,
    color: "#252525",
    fontFamily: "MontserratSemibold",
  },
});

const rows = [
  { id: 1, name: "Pratik", date: "23/03/2023" },
  { id: 2, name: "Prasanna", date: "23/03/2023" },
  { id: 3, name: "Omkar", date: "23/03/2023" },
  { id: 4, name: "Rushikesh", date: "23/03/2023" },
  { id: 5, name: "Pratik", date: "23/03/2023" },
  { id: 6, name: "Prasanna", date: "23/03/2023" },
  { id: 7, name: "Omkar", date: "23/03/2023" },
  { id: 8, name: "Rushikesh", date: "23/03/2023" },
  { id: 9, name: "Pratik", date: "23/03/2023" },
  { id: 10, name: "Prasanna", date: "23/03/2023" },
  { id: 11, name: "Omkar", date: "23/03/2023" },
  { id: 12, name: "Rushikesh", date: "23/03/2023" },
  { id: 13, name: "Pratik", date: "23/03/2023" },
  { id: 14, name: "Prasanna", date: "23/03/2023" },
  { id: 15, name: "Omkar", date: "23/03/2023" },
  { id: 16, name: "Rushikesh", date: "23/03/2023" },
];

export default function AttendanceTable(props) {

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();

  
  const nhost = useNhostClient();
  const navigate = useNavigate();
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState(rows);
  const [checkboxState, setCheckboxState] = useState({});
  const [checkboxDetails, setCheckboxDetails] = useState([]);

  // console.log("states",checkboxState)

  // console.log("attendance details", checkboxDetails);

const eventid=props.id;
  // console.log("props", props.id);
  // console.log("event id", eventid);
  // ...........................current time ...................................
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0"); // Pad single-digit days with leading zero

  const currentDateFormatted = `${currentYear}/${currentMonth}/${currentDay}`;
  // ...........................current time ...................................

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const [attendence, setAttendence] = useState([]);

  const { fetchAtt } = useQuery(GET_ATTENDENCE, {
    variables: { 
      __eq:props.id,
     },
  })
  // console.log("Attendence Data Fetched",attendence);

  useEffect(()=>{
    setAttendence(fetchAtt?.event_attendance);

  },[fetchAtt?.event_attendances])


  const [users, setUsers] = useState([]);
  const {
    loading,
    error,
    data: userData,
  } = useQuery(GET_EVENT_USERS, {
    variables: {
      _eq: props.id,
    },
  });
  useEffect(() => {
    setUsers(userData?.user_events);
    // console.log("users inside", userData?.user_events);
  }, [userData]);

  //   const filtereData = users?.filter((row) =>
  //   (row.name && row.name.toLowerCase().includes(filter.toLowerCase()))
  // );
  useEffect(() => {
    if (users) {
      const initialDetails = users.map((user) => ({
        user_id: user?.users?.id,
        event_id: props?.id,
        checkbox_value: false,
      }));
      setCheckboxDetails(initialDetails);
    }
  }, [users, props.id]);

  // ...
  const [insertRecEventHeaderInfo, { loading1, error1 }] = useMutation(
    MARK_ATTENDANCE,

    {
      onError: (error1) => {
        console.error("Mutation Error:", error1.message);
      },
      onCompleted: (data) => {
        // console.log("pratik", data.event_attendance?.returning[0]);
      },
    }
  );

  const update = async () => {
    for (let i = 0; i < checkboxDetails.length; i++) {
      // console.log("hi",checkboxDetails[i]);
      const { user_id, event_id, checkbox_value: attendance } = checkboxDetails[i];
    // console.log("User ID:", user_id);
    // console.log("Event ID:", event_id);
    // console.log("Attendance:", attendance);
      handleInsertQuery1(user_id, event_id, attendance);
      navigate("/admindash/eventclosure", {
        state: { eventid },
      }); 
    }
  }

  const handleInsertQuery1 = async (user_id, event_id,attendance) => {
    try {
      const { data: insertedRecData } = await insertRecEventHeaderInfo({
        variables: {
          _eq:event_id,
          _eq1:user_id,
          attendance:attendance,
        },
      });
      console.log("instered user attendence", insertedRecData);
      generateAndDownloadPDF();
      
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

    // }
  };

  const [docName, setDocName] = useState("");

  const generateAndDownloadPDF = async () => {
    const pdf = new jsPDF();
    const body = document.body;
    const canvas = await html2canvas(body);
    const imgData = canvas.toDataURL('image/png');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  
    // Save the PDF locally
    pdf.save(`${data?.event_master[0].name}_Attendance_Report.pdf`);
  
    // Upload the PDF to Nhost contents
    nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECRET_KEY);
    const file = new File([pdf.output('blob')], `${data?.event_master[0].name}_Attendance_Report.pdf`);
    await upload({ file });
  
    // Upload the content information to your GraphQL server
    uploadContent();
  };
  

  const uploadContent = async () => {
    try {
      const { data: uploadedAttendance } = await insertAttendance({
        variables: {
          associated_event_id: props.id,
          link: cover_img_path,
          file_name: docName,
        },
      });
      console.log("instered user attendence", uploadedAttendance);
      
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

    // }
  };

  const [insertAttendance] = useMutation(INSERT_ATTENDANCE_PDF,{
    onCompleted:(data)=>{
      console.log("last updated")
      // refetch()
    },
    onError:(error)=>{
      console.log('error is ' , error)
    }
  })



  return (
    <Box>
      <Box sx={Styles.tableBox}>
        <Box style={Styles.tableBox2}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="sticky table">
              <TableHead
                className={classes.header}
                style={{
                  backgroundColor: "#ffffff",
                  height: "20px",
                  color: "white",
                }}
              >
                <TableRow>
                  <TableCell className={classes.headerCell} align="center">
                    Name
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Date
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Attendance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((row) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell
                      className={classes.tableCell}
                      align="center"
                      component="th"
                      scope="row"
                    >
                      {row?.users?.displayName}

                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {currentDateFormatted}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {" "}
                      <Checkbox
                        sx={{
                          color: "#49454F",
                          "&.Mui-checked": {
                            color: "#FF0000",
                          },
                        }}
                        checked={checkboxState[row?.users?.id] || false}
                        onChange={(event) => {
                          setCheckboxState((prevState) => ({
                            ...prevState,
                            [row.users.id]: event.target.checked,
                          }));
                          // Update the checkboxDetails array
                          setCheckboxDetails((prevDetails) => {
                            const updatedDetails = prevDetails.filter(
                              (detail) => detail.user_id !== row.users.id
                            );
                            updatedDetails.push({
                              user_id: row.users.id,
                              event_id: props.id,
                              checkbox_value: event.target.checked,
                            });
                            return updatedDetails;
                          });
                        }}
                      />
                      
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box sx={{display:"flex",justifyContent:"flex-end"}}>
          <Button sx={Styles.btnRes} onClick = {update}  >Save</Button>
          {/* <Button sx={Styles.btnSave} onClick={() => navigate("/eventclosure")}>
            Submit
          </Button> */}
      {/* <Button sx={Styles.btnRes} onClick={generateAndDownloadPDF}>Print</Button> */}
        </Box>
    </Box>
  );
}
const Styles = {
  mainContainer: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btnRes: {
    ml: 2,
    width: "100px",
    color: "FF0000",
    backgroundColor: "#ffffff",
    border: "1px solid #999999",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#999999",
      color: "#ffffff",
    },
    height: 30,
    fontFamily: "MontserratRegular",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tableBox: {
    border: "1px solid #999999",
    width: "100%",
    height: "450px",
    borderRadius: " 10px",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.3em",
      // height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999999",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  tableBox2: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    marginBottom: "15px",
  },
};
