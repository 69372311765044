const Styles = {
    headerBox: {
      height: "8vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    header: {
      margin: "auto 2% auto 2%",
      fontSize: " 18px",
      color: " #414042",
      fontFamily: "MontserratMedium",
      "@media (max-width:768px)": {
        fontSize: "14px",
      },
    },
    card: {
      width: "93.9%",
      height:"280px",
      overflow:"hidden",
      display: "flex",
      flexDirection: "row",
      borderWidth: "1px 1px 0px 1px",
      borderStyle: "solid",
      borderColor: "#999999",
      borderRadius: "10px 10px 0px 0px",
      "@media (max-width:768px)": {
        height:"auto",
        flexDirection: "column",
        // overflow:"scroll",
      },
    },
    container: {
      width: "100%",
      display: "flex",
      marginTop:"15px",
      flexWrap:"wrap",
      justifyContent: "space-between",
      alignItems: "center",
      // overflow: "scroll",
      flexDirection: "column",
      "@media (max-width:768px)": {
        width: "100%",
        // overflow:"scroll"
      },
    },
    imgBox: {
      width: "500px",
      height:"100%",
      "@media (max-width:768px)": {
        width: "100%",
      },
      overflow: 'hidden' 
    },
    img: {
     
      width: "100%",
      height: "100%",
      // borderRadius: " 10px 0px 0px 0px",
       objectFit: "contain",
      // "@media (max-width:768px)": {
      //   width: "100%",
      // },
     

    },
    info: {
      width: "90%",
      margin: "25px",
      height:"auto",
      // border: " 1px solid #999999",
      // overflow:"scroll",
      "@media (max-width:768px)": {
        flexDirection: "column",
        width: "90%",
        overflow:"scroll",
      },
    },
    cardHeader: {
      fontFamily: "MontserratRegular",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: " 35px",
      color: "#252525",
      "@media (max-width:768px)": {
        fontSize: "21px",
        lineHeight: "25px",
        width:"100%"
      },
    },
    text: {
      marginLeft: "32px",
      marginTop: "5px",
      fontSize: "12px",
      fontFamily: "MontserratRegular",
      lineHeight: "15px",
      color: "#252525",
      height:"50px",
      overflowY: "auto",
      // textOverflow: "ellipsis",
      // display: "-webkit-box",
      // WebkitLineClamp: "2",
      // WebkitBoxOrient: "vertical",
    },
    textCard: {
      marginLeft: "10px",
      marginTop: "5px",
      fontSize: "12px",
      fontFamily: "MontserratMedium",
      lineHeight: "15px",
      color: "#252525",
      "@media (max-width:768px)": {
        fontSize: "10px",
        lineHeight: "10px",
      },
    },
    menu: {
      top: "10px",
      left: "0px",
      right: "15px",
      marginTop: "10px",
      marginRight: "10px",
      width: "25px",
      height: "25px",
    },
    threeDots:{
      top: "10px",
     
      right: 0,
      ":hover":{
        cursor:"pointer",
      },
      // border: " 1px solid #999999",
      // "@media (max-width:768px)": {
      //    display:"none"
      //   },
    },
    nav: {
      marginTop: "15px",
      width: "100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      cursor:"pointer"
    },
    navBox: {
      width: "94%",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      backgroundColor: "#FEFEFE",
      border: " 1px solid #999999",
      borderRadius: "0px 0px 10px 10px",
    },
    navName: {
      fontSize: "12px",
      color: "#252525",
      fontFamily: "MontserratRegular",
      cursor:"pointer"
    },
    lowerHeader: {
      fontSize: "14px",
      marginLeft: "5px",
      color: "#252525",
      fontFamily: "MontserratBold",
      
    },
    eventCards: {
      width: "100%",
      margin: "10px",
      display: "flex",
      flexDirection: "row",
    },
    detailsBox: {
      width: "100%",
      height:"280px",
      justifyContent: "center",
      // alignItems: "center",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      overflowY:"hidden",
    },
    firstLeftBox: {
      width: "100%",
      display: "flex",
      // justifyContent:"space-around",
      flexWrap: "wrap",
      flexDirection: "column",
      // backgroundColor: "red",
      "@media (max-width:768px)": {
        width: "100%",
      },
    },
    facilitators: {
      margin: "15px",
      marginLeft: "40px",
      marginRight: "0px",
      width: "90%",
    }, 
    descriptionBox: {
      // margin: "15px",
      // marginLeft: "40px",
      // minWidth: "400px",
      overflowY: "hidden",
      width: "100%",
      height: "100px",
      // overflow:"hidden",
      // textOverflow:"ellipsis",
      // height:'50px',
    },
    Attendance: {
      margin: "15px",
      // marginLeft: "40px",
      // width: "100%",
      "@media (max-width:768px)": {
        width: "100%",
        flexDirection: "column",
      },
    },
    firstRightBox: {
      width: "900px",
      display: "flex",
      // backgroundColor:"red",
      flexDirection: "column",
      flexWrap: "wrap",
      "@media (max-width:768px)": {
        width: "100%",
        // overflow:"scroll"
      },
    },
    horizontal: {
      width: "100%",
      //   minWidth:"320px",
      marginTop: "15px",
      marginLeft:"10px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      //   overflow:"scroll",
      "@media (max-width:768px)": {
      //   flexDirection: "column",
        justifyContent:"space-between",
        marginTop:"5%",
        marginLeft:"10%",
        width:"80%"
      },
    },
    cardBox: {
      width: "90%",
      display: "flex",
      overflowX: "scroll",
      margin:"auto",
      "@media (max-width:768px)": {
        width: "90%",
        marginLeft:"5%"
        // overflowX: "scroll",
      },
    },
    navMenu: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // justifyContent: "center", 
      borderBottom: "1px solid #999999",
      // cursor:"pointer"
      "@media (max-width:768px)": {
        overflow:"scroll",
      },
      paddingBottom: "15px",
    },
    contentBox:{
      height:"265px"
      
    },
    scheduleBox:{
      height:"265px",
      // overflow:"hidden",
    },
    activeTab: {
      paddingBottom: 12,
      borderBottomWidth: 5,
      borderBottomColor: "#FF0000",
    },
    checklist:{
      height:"265px",
      display:"flex",
      justifyContent:"space-between",
      overflow:"hidden",
    },
    leftArrow: {
      width: "25px",
      height: "25px",
      // marginTop: "4%",
      top:"27%",
      position:"relative",
      cursor:"pointer",
      "@media (max-width:768px)": {
        display:"none",
        backgroundColor:"red",
      },
    },
    rightArrow: {
      width: "25px",
      height: "25px",
      // marginTop: "4%",
      bottom:"30%",
      left: "98%",
      cursor:"pointer",
      position:"relative",
      "@media (max-width:768px)": {
        display:"none"
      },
    },
    icon:{
      width: "25px",
      height: "25px",
      // marginTop: "4%",
      position:"relative",
      cursor:"pointer",
    }
  };
  export default Styles;
  