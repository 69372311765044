import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Modal } from '@mui/material';

export default function ContentBoxPopup(props) {
//   const [open, setOpenpop] = React.useState(false);
  const { open,imgPreview ,name} = props;

  const isImage = name.endsWith(".png") || name.endsWith(".jpeg") || name.endsWith(".jpg");

  const handleClickOpen = () => {
    props.setOpenpop(true);
  };

  const handleClose = () => {
    props.setOpenpop(false);
  };

  return (
    <div>
      {/* <Box style={{width:"100%",display:"flex",justifyContent:"center"}}> */}

      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose} maxWidth='xl' style={{width:'100%'}}>
        {/* <DialogTitle style={{width:"700px"}} maxWidth='xl' >Subscribe</DialogTitle> */}
        {/* <a>{imgPreview}</a> */}
        {/* <Box style={{display:"flex", width:"auto",height:"auto", }}>

        <iframe style={{width:"auto",height:"auto",objectFit: "contain", }} src={imgPreview}/>

        
       </Box> */}

{isImage ? (
      <img src={imgPreview}  style={{width:"400px"}} alt="Image Preview" />
    ) : (
      <Box  style={{width:"700px",height:"700px",margin:'auto'}}>

        <iframe src={imgPreview}  style={{width:"100%",height:"100%",margin:'auto'}} title="Document Preview"></iframe>
      </Box>
      )
    }

       
      </Dialog>
          {/* </Box> */}
    </div>
  );
}