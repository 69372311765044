import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import styles from "../styles/global";
import { useLocation } from "react-router-dom";
import Styles from "../styles/EventManagement1";
import sortby from "../images/EventManagement1/sortby.png";
import EventManagementCard1 from "../components/EventManagement1/EventManagementCard1";
import { useNavigate } from "react-router";
import EventManagementSortByPopUp from "../components/EventManagement1/EventManagementSortByPopUp";
import { useDispatch } from "react-redux";
import { events } from "../Redux/features/UserSlice";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_EVENTS_QUERY = gql`
  query getEvents {
    event_master(
      where: { parent_id: { _is_null: true }, status: { _eq: "isactive" } }
    ) {
      activation_date

      category_id

      closure_date

      created_at

      deactivation_date

      description

      id

      name

      cover_img_path

      recurrance_end_date

      recurrance_frequency

      recurrance_start_date

      start_time

      end_time

      type_id

      updated_at

      location
    }
  }
`;
const GET_PERMISSION_QUERY = gql`
query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
  role_master(where: {access_name: {_eq: $_eq}, _and: {roleprofile: {user_id: {_eq: $_eq1}, _and: {user: {defaultRole: {_eq: $_eq2}}}}}}) {
    access_name
    description
    id
    roles
    status
    roleprofile {
      user {
        defaultRole
      }
    }
  }
}
`;
export { GET_EVENTS_QUERY };
export default function EventManagement1(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const {  data:permsission } = useQuery(GET_PERMISSION_QUERY, {
    variables: {
      _eq:localStorage.getItem("roleaccess"),
      _eq1:localStorage.getItem("id")
    },
    onError:(error) =>{
      console.log(error , "this is error")
    }
  });
  const roll = localStorage.getItem('role')
  // const { ispublished } = location?.state;

  const [openpop, setOpenpop] = useState(false);

  // const dispatch = useDispatch();

  const submit = (
    id,
    name,
    description,
    recurrance_start_date,
    recurrance_end_date,
    location,
    deac,
    imgUrl,
    edate,
    event_category,
    start_time,
    end_time,
    recurrance_frequency
  ) => {
    // dispatch(events(card1[index]))

    navigate("/eventmanagement2", {
      state: {
        id,
        name,
        description,
        recurrance_start_date,
        recurrance_end_date,
        location,
        deac,
        imgUrl,
        edate,
        event_category,
        start_time,
        end_time,
        recurrance_frequency
      },
    });

    // console.log(
    //   "this event is clicked",

    //   id,

    //   name,

    //   description,

    //   recurrance_start_date,

    //   location,

    //   imgUrl,

    //   edate,

    //   event_category
    // );

    // history.push('/eventmanagement2')
  };

  const [Events, setEvents] = useState([]);

  const { loading, error, isError, data, refetch } = useQuery(GET_EVENTS_QUERY);

  // console.log("data", data?.event_master);

  // console.log(location?.state?.ispublished, "it is the state");

  if (location?.state?.ispublished) {
    refetch();
  }

  if (location?.state?.isdeleted) {
    refetch();
  }

  if (isError) {
    // console.log("error is ", error);
  }

  useEffect(() => {
    setEvents(data?.event_master);

    // console.log("data", data?.event_master);

    // console.log("erroe", error);
  }, [data?.event_master]);

  const openPopUp = () => {
    setOpenpop(!openpop);
  };

  return (
    <Box sx={Styles.mainContainer}>
      {/*........................ navbar */}

      <Navbar />

      {/*........................ ends navbar */}

      <Box
        sx={[
          Styles.topContainer,

          { boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)" },
        ]}
      >
        <Typography sx={Styles.largeTxt}>Event Management</Typography>

        {/* ...........btn.................. */}
        {
  (    (permsission?.role_master[0]?.roles?.addevent && roll === "staff")   || roll==="admin") &&

     
        <Box
          sx={[
            styles.btnDashboard,

            {
              marginX: "5px",

              color: "#252525",

              border: "1px solid #252525",

              "&:hover": {
                cursor: "pointer",

                backgroundColor: "FF0000",

                color: "white",

                borderColor: "FF0000",
              },
            },
          ]}
          onClick={() => {
            navigate("/eventmanagementvs");
          }}
        >
          Add Event
        </Box>
}
        {/* ...................btn........... */}
      </Box>

      <Box sx={[Styles.topContainer, { height: "7vh", minWidth: "336px" }]}>
        <Typography
          sx={{
            color: "#252525",

            fontFamily: "MontserratRegular",

            marginLeft: "2%",

            marginY: "auto",
          }}
        >
          All Events
        </Typography>

        {/* <Box

          sx={{

            display: "flex",

 

            flexDirection: "row",

 

            "&:hover": { cursor: "pointer" },

          }}

          onClick={openPopUp}

        >

          <Typography

            sx={{

              fontFamily: "MontserratRegular",

 

              fontWeight: "400",

 

              color: "#252525",

            }}

          >

            Sort by Status

          </Typography>

 

          <img src={sortby} alt="sort by" />

        </Box> */}
      </Box>

      {/* ........................ upper cards */}

      <Box sx={Styles.upperCards}>
        <Box
          // key={card1.id}

          sx={Styles.card1Container}

          // onClick={submit}

          // {() => {

          //   navigate("/eventmanagement2",);

          // }}
        >
          {Events?.map((item, index) => (
            <Box
              styles={{ display: "flex", flexDirection: "row" }}
              onClick={() =>
                submit(
                  item.id,
                  item.name,
                  item.description,
                  item.recurrance_start_date,
                  item.recurrance_end_date,
                  item.location,
                  item.deac,
                  item.cover_img_path,
                  item.recurrance_end_date,
                  item.event_category,
                  item?.start_time,
                  item?.end_time,
                  item?.recurrance_frequency
                )
              }
            >
              <EventManagementCard1
                cover_img_path={item.cover_img_path}
                title={item.name}
                desc={item.description}
                date={item.recurrance_start_date}
                // time={item.time}
                recurrance_frequency={item?.recurrance_frequency}
                edate={item.recurrance_end_date}
                address={item.location}
              />
            </Box>
          ))}
        </Box>
      </Box>

      {/* ........................ end upper cards */}

      {openpop && (
        <EventManagementSortByPopUp open={openpop} setOpen={setOpenpop} />
      )}
    </Box>
  );
}
