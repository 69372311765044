import { hover } from "@testing-library/user-event/dist/hover";
import React from "react";

export default function BtnRed(props) {
  const { btn_title } = props;
  return (
    <>
      <button className="btnContainer" style={style.btnCotainer} onClick={props.click}>
        {btn_title}
      </button>
    </>
  );
}
const style = {
  btnCotainer: {
    backgroundColor: "#FF0000",
    width: "100px",
    height: "30px",
    borderRadius: "10px",
    border: "none",
    fontFamily:'MontserratRegular',
    fontWeight:500,
    color:'white',
    justifyContent:'center',
    alignItems:'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};
