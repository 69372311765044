import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import EditTaskPop from "../eventManagement/EditTaskPop";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Box } from "@mui/material";

const GET_ALL_TASKS = gql`
query MyQuery11($_eq: bigint = "") {
  task_master(where: {group_id: {_eq: $_eq}, _and: {status: {_nilike: "deleted"}}}) {
    description
    group_id
    id
    staff_id
    status
    target_date
    task_name
    user {
      displayName
      profile_detail {
        first_name
      }
    }
  }
}
`;

const DELETE_TASK_MUTATION = gql`
  mutation MyMutation($_eq: bigint = "") {
    update_task_master(
      where: { id: { _eq: $_eq } }
      _set: { status: "deleted" }
    ) {
      returning {
        description
        task_name
      }
    }
  }
`;

function Checklist(props) {
  const [data, setData] = useState([
    {
      taskid: "001",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc efficitur tem...",
      targetdate: "13/2/2023",
      assign: "Omkar",
      status: "Completed",
    },
    {
      taskid: "002",
      description: "Lecture delivery form guest",
      targetdate: "13/2/2023",
      assign: "Pratik",
      status: "InCompleted",
    },
    {
      taskid: "003",
      description: "Vote of thanks",
      targetdate: "13/2/2023",
      assign: "Prasanna",
      status: "In Progress",
    },
  ]);
  const [isforUpdate, setisforUpdate] = useState(false);
  const [Tasks, setTasks] = useState([]);
  console.log("taskaaaa", props.id);
  const { loading, error, data:task ,refetch} = useQuery(GET_ALL_TASKS, {
    variables: { 
      _eq: props.id,
    },
    onError:(error) => {
      console.log("error is" , error);
    }
  })

  useEffect(()=>{
    console.log("data is every thing " , task)
    setTasks(task?.task_master);
  },[task])

  const [deleteMutation] = useMutation(DELETE_TASK_MUTATION,{
    onCompleted: () =>{
      refetch();
    }
  });

  const deleteTask = async (id) => {
    // Ask the user for confirmation
    const confirmation = window.confirm("Are you sure you want to delete this task?");
  
    if (confirmation) {
      try {
        // Proceed with the deletion
        const deleteTaskRequest = await deleteMutation({
          variables: {
            _eq: id,
          },
        });
      } catch (error) {
        console.log("Error deleting task: ", error);
      }
    } else {
      console.log("Task deletion canceled.");
    }
  };

  const [open, setOpen] = useState(false);
  const [opn, setopn] = useState(false);
  const [rowsdata, setrowsdata] = useState([]);
  const AssignRolepage = (row) => {
    console.log(":rfskuhnsd skdjnKJSd", row);
    setopn(!opn);
    setisforUpdate(true);
    setrowsdata(row);
  };

  const [editIndex, setEditIndex] = useState(-1);
  const [newtaskid, setNewtaskid] = useState("");
  const [newdescription, setNewdescription] = useState("");
  const [newtargetdate, setNewtargetdate] = useState("");
  const [newassign, setNewAssigned] = useState("");
  const [newstatus, setNewstatus] = useState("");

 

  const handleEdit = (index) => {
    setEditIndex(index);
    setNewtaskid(data[index].taskid);
    setNewdescription(data[index].description);
    setNewtargetdate(data[index].targetdate);
    setNewAssigned(data[index].assign);
    setNewstatus(data[index].status);
  };

  const handleSave = async () => {
    const newData = [...data];
    newData[editIndex] = {
      ...newData[editIndex],
      taskid: newtaskid,
      description: newdescription,
      targetdate: newtargetdate,
      assign: newassign,
      status: newstatus,
    };
    setData(newData);
    setEditIndex(-1);
  };

  const handleDelete = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleAddRow = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        taskid: "",
        description: "",
        targetdate: "",
        assign: "",
        status: "",
      },
    ]);
  };
  const handleClick = () => {
    // Function 1
    setisforUpdate(false);
    // AssignRolepage();
    setopn(!opn);
    setrowsdata();
    // Function 2
    handleAddRow();
  };
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(value);
  };

  return (
    <>
      <img
        style={Styles.addIcon}
        src={require("../../images/plus.png")}
        onClick={handleClick}
      />
      <table style={Styles.table}>
        <thead style={Styles.header}>
          <tr>
            <th></th>
            <th>Task Name</th>
            <th>Description</th>
            <th>Target Date</th>
            <th>Assigned To</th>
            <th>Status</th>
            {/* <th style={{ width: 20 }}></th> */}
          </tr>
        </thead>  
        <tbody style={Styles.body}>
          {Tasks?.map((row, index) => (
            <tr style={Styles.row} key={row.id}>
            {/* <tr style={row.status === 'Completed' ? { color: 'green', } : {}} key={row.id}> */}
              <td>
                {editIndex === index ? (
                  <>
                    <button onClick={handleSave}>Save</button>
                    <button onClick={() => setEditIndex(-1)}>Cancel</button>
                  </>
                ) : (
                  <> 
                  <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                    <img
                      src={require("../../images/EditNew.png")}
                      // onClick={() => handleEdit(index)}
                      onClick={() => AssignRolepage(row)}
                    />
                    <img
                        src={require("../../images/delete.png")}
                        //onClick={() => handleEdit(index)}
                        onClick={() => deleteTask(row.id)}
                      />
                      </Box>
                  </>
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    value={newtaskid}
                    // idtask={newtaskid}
                    onChange={(e) => setNewtaskid(e.target.value)}
                  />
                ) : (
                  row.task_name
                )}
              </td>
              <td style={{ textAlign: "center" }}>
                {editIndex === index ? (
                  <input
                    value={newdescription}
                    onChange={(e) => setNewdescription(e.target.value)}
                  />
                ) : (
                  row.description
                )}
              </td>

              <td>
                {editIndex === index ? (
                  <input
                    value={newtargetdate}
                    onChange={(e) => setNewtargetdate(e.target.value)}
                  />
                ) : (
                  row.target_date
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <Select
                    id="demo-multiple-name"
                    // multiple
                    sx={{
                      width: "200px",
                      height: "30px",
                      fontSize: "10px",
                      fontFamily: "MontserratRegular",
                      "@media (max-width:768px)": {
                        width: "90px",
                      },
                    }}
                    value={newassign}
                    // onChange={handleChange}
                    onChange={(e) => setNewAssigned(e.target.value)}
                  >
                    <MenuItem value={row.staff_id} key={row.id}>
                      {row.first_name}
                    </MenuItem>
                  </Select>
                ) : (
                  row.user.profile_detail.first_name
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <Select
                    id="demo-customized-select"
                    sx={{
                      width: "120px",
                      height: "30px",
                      fontSize: "10px",
                      fontFamily: "MontserratRegular",
                      "@media (max-width:768px)": {
                        width: "40px",
                      },
                    }}
                    value={newstatus}
                    //   onChange={handleChange}
                    //   input={<BootstrapInput />}
                    onChange={(e) => setNewstatus(e.target.value)}
                  >
                    <MenuItem sx={Styles.dropMenu} value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem
                      sx={Styles.dropMenu}
                      value={"Completed"}
                      onChange={handleChange}
                    >
                      Completed
                    </MenuItem>
                    <MenuItem
                      sx={Styles.dropMenu}
                      value={"Not Completed"}
                      onChange={handleChange}
                    >
                      Not Completed
                    </MenuItem>
                    <MenuItem sx={Styles.dropMenu} value={"In Progress"}>
                      In Progress
                    </MenuItem>
                  </Select>
                ) : (
                  row.status
                )}
              </td>
              {/* <td>
              <img
                  style={Styles.deleteIcon}
                  src={require("../../images/cross.png")}
                  onClick={() => handleDelete(index)}
                />
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {opn && (
        <EditTaskPop
          open={opn}
          grpId={props.id}
          setOpen={setopn}
          isforUpdate={isforUpdate}
          Tasks={rowsdata}
        />
      )}
    </>
  );
}

export default Checklist;

const Styles = {
  table: {
    position: "relative",
    width: "94%",
    overflow: "hidden",
    height: "120px",
    backgroundColor: "#FFFFFF",
    padding: "0px",
    left: " 37px",
    top: "20px",
    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    // border:"0px solid #FF0000",
    borderRadius: "10px",
    "@media (max-width:768px)": {
      //   width: "100%",
      position: "relative",
      left: "0px",
      //   top: "0px",
    },
    marginTop: 20,
    marginRight: 60,
  },
  header: {
    backgroundColor: "#FF0000",
    color: "#FFFFFF",
    border: "0px solid #FF0000",
    borderRadius: "10px",
    fontFamily: "MontserratRegular",
    // padding: "10px",
  },
  body: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    border: "0px solid white",
    overflow: "scroll",
  },
  row: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    borderWidth: 0,
    height: "10px",
    overflow: "scroll",
  },
  deleteIcon: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    // position: "absolute",
    right: "0px",
  },
  addIcon: {
    width: "25px",
    height: "25px",
    cursor: "pointer",

    // position: "absolute",
    // right: "90px",
    // bottom: "40px",
    // "@media (max-width:768px)": {
    //   // position: "absolute",
    //   right: "10px",
    //   bottom: "10px",
    // },
  },
  dropMenu: {
    fontFamily: "MontserratRegular",
    fontSize: "12px",
  },
};

const isFirefox = typeof InstallTrigger !== "undefined";

// Apply Firefox-specific styles
if (isFirefox) {
  Styles.table = {
    ...Styles.table,
    height: "20px",
    scrollSnapType: "y mandatory",
    overflowY: "scroll",
    scrollbarWidth: "thin",
  };
}
