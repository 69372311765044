import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFileUpload, useNhostClient, useUserDefaultRole } from "@nhost/react";
import React, { useEffect, useState } from "react";

const theme = createTheme({
  components: {
    MuiPickersStaticWrapper: {
      styleOverrides: {
        root: {
          "& .MuiPickersBasePicker-pickerView": {
            padding: "0px",
            height: "10px",
          },
        },
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      activation_date
      closure_date
      created_at
      cover_img_path
      deactivation_date
      description
      id
      location
      metadata
      name
      parent_id
      is_recurring
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      start_time
      end_time
      type_id
      updated_at
      event_category {
        created_by
        created_date
        description
        id
        name
      }
      event_faculties {
        event_id
        faculty_type_id
        id
        user_id
      }
      event_type {
        created_by
        created_date
        description
        id
        name
      }
    }
  }
`;

const UPDATE_EVENT_MASTER = gql`
  mutation MyMutation4(  
    $cover_img_path: String = ""  
    $_eq: bigint = ""
    $start_time: timetz = ""
    $end_time: timetz = ""
  ) {
    update_event_master(
      where: { id: { _eq: $_eq } }
      _set: {
        cover_img_path: $cover_img_path
        start_time: $start_time
        end_time: $end_time
      }
    ) {
      returning {
        cover_img_path
        description
        id
        location
        name
        metadata
      }
    }
  }
`;

export default function EventCard(props) {
  // console.log("props for recurring edit ",props)
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: props.image,
        });
        // console.log("Image URL:", item);
        // setImgPreview(item)
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [props]);

  const d = new Date(props.day);
  d.setDate(d.getDate());
  const dt = d.getDate();
  const dayofweek = weekday[d.getDay()];
  const month = monthList[d.getMonth()];
  const year = new Date(props.day).getFullYear();
  const role = useUserDefaultRole();
  const timeString = props.startTime;
  const [hour, minute] = timeString
    ? timeString.split(":").slice(0, 2)
    : [null, null];
  
  // Convert hour to 24-hour format
  const formattedHour = (parseInt(hour, 10) % 24).toString().padStart(2, '0');
  
  const formattedTime = `${formattedHour}:${minute}`;

  const timeString1 = props.endTime;
  const [hour1, minute1] = timeString1
    ? timeString1.split(":").slice(0, 2)
    : [null, null];
  
  // Convert hour to 24-hour format
  const formattedHour1 = (parseInt(hour1, 10) % 24).toString().padStart(2, '0');
  
  const formattedEndTime = `${formattedHour1}:${minute1}`;
  
  // console.log(formattedEndTime);
  

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError: imgErr,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();

  //   const [open, setOpen] = React.useState(false);
  // console.log("propedvalue for faculty id", props.facultyid);
  const [openpop, setOpenpop] = useState(false);

  const { onClose, open } = props;
  // console.log("id is coming from props", props);
  const [Events, setEvents] = useState([]);

  const handleClickOpen = () => {
    setOpenpop(true);
  };

  const handleClose = () => {
    setOpenpop(false);
  };
  const { refetch, data: data3 } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      id: props.id,
    },
  });

  useEffect(() => {
    setEvents(data3?.event_master);
    // setEventType(data?.event_master[0]?.event_type.name)
  }, [data3]);

  const [UpdateEventMetadata, { loading, error, isError, isSuccess }] =
    useMutation(UPDATE_EVENT_MASTER, {
      onError: (error) => {
        console.error("Mutation Error:", error);
      },
      onCompleted: (data) => {
        setOpenpop(false);
        alert("Event updated successfully");
        refetch();
      },
      refetchQueries: [{ query: GET_EVENTS_QUERY }],
    });

  // console.log("this is saved cover image", props.image);

  const handleSave = async () => {
    // console.log("this is saved cover image", cover_img_path, props.image,updatedData.startTime);
    try {
      const { data } = await UpdateEventMetadata({
        variables: {
          _eq: props.id,
        
          start_time: updatedData["startTime"]
            ? updatedData["startTime"]
            : props.startTime,
          end_time: updatedData["endTime"]
            ? updatedData["endTime"]
            : props.endTime,
          cover_img_path: isUploaded ? cover_img_path : props.image,
         
        },
      });
      // console.log("instered group header info", data);

      // console.log("event configurations data", UpdateEventMetadataData);
    } catch (error) {
      // console.error("GraphQL Mutation Error:", error.message);
    }
  };


  const [data, setdata] = useState({});

  const [updatedData, setUpdatedData] = useState({
    name: props.name,
    description: props.description,
    startDate: props.sdate,
    endDate: props.edate,
    event_type: props.event_type,
    event_category: props.event_category,
    location: props.location,
    attendanceCapacity: props.attendanceCapacity,
    duration: props.duration,
    startTime: props.startTime,
    endTime: props.endTime,
    eventCatId: props.eventCatId,
    eventTypeId: props.eventTypeId,
  });

  // console.log("updated data", props);
  //   console.log("updatedData event_category:", updatedData.event_category);
  // console.log("props event_category:", props.event_category);

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    // console.log(formattedDate);
    setUpdatedData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const handleTimeChange = (key, value) => {
    let formattedTime = value.format("hh:mm a");
    // console.log(formattedTime);
    setUpdatedData((prevData) => {
      return {
        ...prevData,
        startTime: props.startTime,
        endTime: props.endTime,
        [key]: formattedTime,
      };
    });
  };
  // ................................ update cover image ..................................................................

  const nhost = useNhostClient();
  const [image, setImage] = useState(null);
  const [coverImageId, setCoverImageId] = useState("");
  const changeImg = () => {
    const confirmation = window.confirm(
      `Do you really want to change Cover Image?`
    );
    if (confirmation) {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = async (event) => {
        nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

        let image = event.target.files[0];
        setImage(URL.createObjectURL(image));

        let file = event.target.files[0];
        setCoverImageId(file.name);
        await upload({ file });
      };
      input.click();
    }
  };

  return (
    <Box sx={Styles.card}>
      <Typography sx={Styles.day}>{dayofweek}</Typography>
      <Typography sx={Styles.date}>{dt}</Typography>
      <Typography sx={Styles.month}>
        {month}, {year}
      </Typography>
      <Typography sx={Styles.time}>
        {formattedTime} to {formattedEndTime}
      </Typography>
      <Box sx={Styles.edit}>
      {role !=="user" &&
        <Typography  onClick={handleClickOpen} sx={Styles.editEvent}>Edit Event</Typography>
}
      </Box>

      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}></Button> */}
        <Dialog
          open={openpop}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="xl"
        >
          <Box
            sx={{
              height: 50,
              width: "100%",
              backgroundColor: "#FF0000",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              style={{ marginLeft: 25 }}
              src={require("../../images/navLogo.png")}
            />
            <Button onClick={handleClose}>
              <Typography sx={{ color: "white" }}>Close</Typography>{" "}
            </Button>
          </Box>

          {/* Group Category */}

          {/* .............................................................................................. */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                ml: "1%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* ......................event type......................... */}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ m: 1 }}>
                  <Typography>Start Time</Typography>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        size="Start Time"
                        label={
                          props.startTime
                            ? props.startTime
                            : "Enter new start time"
                        }
                        placeholder={props.startTime}
                        ap={true}
                        format="h:mm A "
                        sx={{
                          width: "360px",
                          mt: "15px",
                          "& .MuiInputBase-root": {
                            fontFamily: "MontserratRegular",
                            height: "40px",
                          },
                          "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              fontFamily: "MontserratRegular",
                              color: "#999999",
                              fontSize: "14px",
                              lineHeight: "10px",
                            },
                        }}
                        value={data.startTime}
                        onChange={(e) => {
                          handleTimeChange("startTime", e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            placeholder="Event Name"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </Box>
              </Box>

              {/* Attendance Capacity */}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Box sx={{ml:1}}> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ m: 1 }}>
                  <Typography>End Time</Typography>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        size="End Time"
                        // label="Enter new end time"
                        label={
                          props.endTime ? props.endTime : "Enter new end time"
                        }
                        ap={true}
                        format="h:mm A "
                        sx={{
                          width: "360px",
                          mt: "15px",
                          "& .MuiInputBase-root": {
                            fontFamily: "MontserratRegular",

                            height: "40px",
                          },

                          "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              fontFamily: "MontserratRegular",

                              color: "#999999",

                              fontSize: "14px",

                              lineHeight: "10px",
                            },
                        }}
                        value={data.startTime}
                        onChange={(e) => {
                          handleTimeChange("endTime", e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            placeholder="Event Name"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Box sx={{ m: 1 }}> */}
                <Box>
                  <Typography>Cover Image</Typography>
                </Box>
                <Box
                  onClick={() => changeImg()}
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {/* <img style={{ width: "80%" }} src={props.cover_img_path} /> */}
                  {image ? (
                    <img
                      style={{ maxWidth: "360px", height: "320px" }}
                      src={image}
                      alt="Cover Image"
                    />
                  ) : (
                    <img
                      style={{ maxWidth: "360px", height: "320px" }}
                      src={imgUrl}
                      alt="Cover Image"
                    />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "flex-end",
                  my: 1,
                }}
              >
                <Button
                  sx={{
                    width: "30%",
                    backgroundColor: "#EE4036",
                    color: "#FFFFFF",
                    height: 40,
                    margin: 3,
                  }}
                  variant="conatined"
                  onClick={handleSave}
                >
                  {" "}
                  SAVE
                </Button>
                <Button
                  sx={{
                    width: "30%",
                    backgroundColor: "#FFFFFF",
                    color: "#999999",
                    height: 40,
                    border: 1,
                    margin: 3,
                  }}
                  variant="conatined"
                  onClick={handleClose}
                >
                  {" "}
                  CANCEL
                </Button>
              </Box>
            </Box>
          </Box>
          {/* .............................................................................................. */}

          {/* Duration */}
          {/* Price */}
        </Dialog>
      </div>
    </Box>
  );
}

const Styles = {
  card: {
    width: "125px",
    minWidth: "125px",
    marginLeft: "20px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px",
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "borderbox",
  },
  day: {
    fontSize: "20px",
    fontWeight: "bold",

    color: "#252525",
    fontFamily: "MontserratRegular",
  },
  date: {
    marginTop: "5px",
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    borderRadius: "50px",
    backgroundColor: " #FF0000",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  month: {
    marginTop: "16px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#414042",
    lineHeight: "18px",
    fontFamily: "MontserratMedium",
  },
  time: {
    fontSize: "10px",
    color: "#414042",
    fontFamily: "MontserratRegular",
  },
  edit: {
    marginTop: "10px",
    width: "100%",
    height: "30px",
    justifyContent: "center",
    borderWidth: "1px",
    borderRadius: "0px 0px 9px 9px",
    // height:"30px",
    // borderRadius:"50px",
    backgroundColor: "#999999",
    color: "#FFFFFF",
  },
  editEvent: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#FFFFFF",
    lineHeight: "30px",
    fontFamily: "MontserratRegular",
  },
};
