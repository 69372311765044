import {React,useState, useEffect} from 'react'
import { Box, Typography } from "@mui/material";
import { useNhostClient } from "@nhost/react";



export default function SmallCard(props) {
    const {profile,name,header,desc,default_feed,img,onClick} = props;

    // setStatus(default_feed);

    // console.log("default feed",props);
    // .....................fetch image from id .....................
    const [imgUrl, setImgUrl] = useState('')
    const nhost = useNhostClient();
    useEffect(() => {

      async function setImage() {
  
      //   nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
      //   // nhost.storage.setAccessToken(res1.fileMetadata.id)
  
      //   console.log(cover_img_path)
  
      //   const publicUrl = await nhost.storage.getPresignedUrl({
      //     fileId: cover_img_path,
      //   })
  
      //   console.log(publicUrl)
      //   setImgUrl(publicUrl.presignedUrl.url)
      try {
  
        const item = await nhost.storage.getPublicUrl({
  
          fileId: props.img,
  
        });
  
        // console.log("Image URL:", item);
  // setImgPreview(item)
  
  setImgUrl(item)
  
      } catch (error) {
  
        console.error("Error retrieving image URL:", error);
  
      }
  
      }
  
  
      setImage()
  
    }, [])
    // .....................fetch image from id ends.....................



  

  return (
    <Box >
    <Box sx={[styles.upperpart,{border : props.default_feed ? "2px solid green" :" 1px solid #999999"                                            }]} onClick={onClick}>
        <Box sx={styles.upperStrip}>
          {/* <img
            src={profile}
            width="8px"
            height="8px"
            style={{ marginLeft: "4px" }}
          /> */}
          <Typography sx={styles.name}> {name}</Typography>
        </Box>

        <Box sx={styles.header}>
          <Typography sx={styles.headerTxt}>
          {header.split(' ').slice(0,5).join(' ')}{header.split('').length > 5 ? '...' : ' '}
          </Typography>
        </Box>

        <Box sx={styles.description}>
          <Typography sx={styles.descTxt}>{desc.split(' ').slice(0, 10).join(' ')}{desc.split(' ').length > 10 ? '...' : ''}
          </Typography>
        </Box>

        <Box sx={styles.imgContainer}>
          <img
            src={imgUrl ?? null}
            width="20%"
            alt="img"
            style={{margin:'3px'}}
          />
        </Box>
      </Box>
      </Box>
  )
}

const styles = {
    upperpart: {
        display:"flex", 
        flexDirection:'column',
        width: "160px",
        height:'90px',
        // border: "1px solid #999999",
        // border : props.default_feed ? "2px solid green" : "1px solid #999999",

        borderRadius: "8px",
        margin: "10px",
        minWidth: "160px",
        '&:hover':{
          cursor: "pointer",
          backgroundColor: "rgba(255, 255, 255)",
        },
      },
      upperStrip: {
        display: "flex",
        flexDirection: "row",
        height: "10px",
        backgroundColor: "#999999",
        borderRadius: "8px 8px 0px 0px",
        alignItems: "center",
        marginTop:'0px', 
      },
      name: {
        fontFamily: "MontserratMedium",
        fontSize: "7px",
        marginLeft: "4px",
      },
      header: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        textAlign: "center",
      },
      headerTxt: {
        fontFamily: "MontserratMedium",
        fontSize: "8px",
      },
      description: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      },
      descTxt: {
        fontFamily: "MontserratRegular",
        fontSize: "7px",
      },
      imgContainer: {
        width: "100%",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        marginBottom: "10px",
      },
}