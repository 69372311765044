import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { gql, useMutation } from '@apollo/client';

const UPDATE_EVENT_TYPE_MUTATION = gql`
  mutation UpdateEventType($id: bigint, $name: String, $description: String, $created_by: String) {
    update_event_type(where: {id: {_eq: $id}}, _set: {name: $name, description: $description, created_by: $created_by}) {
      returning {
        id
        name
        description
        created_date
        created_by
      }
    }
  }
`;

const INSERT_EVENT_TYPE_MUTATION = gql`
  mutation InsertEventType($created_by: String, $description: String, $name: String) {
    insert_event_type(objects: {created_by: $created_by, description: $description, name: $name}) {
      returning {
        created_by
        created_date
        description
        id
        name
      }
    }
  }
`;


export default function AddEventType(props) {
  const [formData, setFormData] = useState(props);
  const [error, setError] = useState("");

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const { event_type, description } = formData;

  const [updateEventTypeMutation] = useMutation(UPDATE_EVENT_TYPE_MUTATION);
  const [insertEventTypeMutation] = useMutation(INSERT_EVENT_TYPE_MUTATION);

  const handleClose = () => {
    props.setNewTaskOpen(false);
  };

  const handleSave = async () => {
    try {
      if (props.isforUpdate) {
        const { data } = await updateEventTypeMutation({
          variables: {
            id: props.id,
            description: description,
            name: event_type,
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(data); // Handle the returned data as needed
      } else {
        const { data } = await insertEventTypeMutation({
          variables: {
            description: description,
            name: event_type,
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(data); // Handle the returned data as needed
        props.setEventTypes((prevData) => [...prevData, data.insert_event_type.returning[0]]);
      }

      props.setNewTaskOpen(false);
    } catch (error) {
      console.error('Error saving event type:', error);
    }
  };

  const resetFields = () => {
    setFormData({
      event_type: "", // Reset event_type field
      description: "", // Reset description field
    });
    setError("");
  };

  React.useEffect(() => {
    if (!props.isforUpdate) {
      resetFields(); // Reset fields when opening the popup for adding a new event type
    }
  }, [props.isforUpdate]);

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <Box sx={{ backgroundColor: "#999999", height: "100%" }}>
          <Typography sx={{ m: 1, color: "#FFFFFF" }}>
            Add Event Type
          </Typography>
        </Box>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            size="small"
            id="outlined-textarea"
            placeholder="Event Type"
            multiline
            value={event_type}
            onChange={(e) => {
              handleChange("event_type", e.target.value);
            }}
            variant="outlined"
            sx={{ width: 360 }}
          />
          {error && (
            <Typography
              sx={{
                color: "FF0000",
                fontFamily: "MontserratRegular",
                fontSize: "16px",
              }}
            >
              {error}
            </Typography>
          )}
        </Box>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-multiline-static"
            placeholder="Description"
            sx={{ width: 360 }}
            value={description}
            onChange={(e) => {
              handleChange("description", e.target.value);
            }}
            multiline
            rows={4}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 1,
          }}
        >
          <Button
            sx={{
              border: 1,
              width: "100px",
              height: 30,
              mx: 1,
              borderColor: "#999999",
              color: "#999999",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              ml: 2,
              color: "#FFFFFF",
              backgroundColor: "#FF0000",
              "&:hover": {
                backgroundColor: "#FF0000",
                color: "#FFFFFF",
              },
              height: 30,
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}
