const Styles = {
    mainContainer: {
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    topContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      minWidth:'336px',
      margin: "auto",
      // height: "8vh",s
    },
    largeTxt: {
      fontFamily: "MontserratRegular",
      fontWeight: "500",
      fontSize: "20px",
      color: "#414042",
      margin: "auto 0px auto 4px",
      ml:'2%',
      lineHeight:2.5,

    },
    upperCards: {
      width: "95%",
      height: "75%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      flexDirection: "row",
      border: "1px solid #999999",
      borderRadius: "10px",
      margin: "auto",
      marginTop: "8px",
      marginBottom: "8px",
    },
    upperStrip: {
      width: "100%",
      height: "35px",
      backgroundColor: "#FF0000",
      borderRadius: "9px 9px 0px 0px",
      display: "flex",
      flexDirection: "row",
      marginTop: "0px",
    },
  
    textContainer: {
      height: "20%",
      fontFamily: "MontserratRegular",
      textAlign: "center",
      fontSize: "20px",
      margin: "10px",
      "@media (max-width: 600px)": {
        // apply these styles for screen sizes less than 600px
        fontSize: "5px",
        padding: "10px",
      },
    },
    card1Container: {
      // width: "98%",
      // height: "100%",
      // display: "flex",
      // alignItems: "center",
      // overflowY: "scroll",
      // scrollbarWidth:"1px",
      // margin: "10px auto 10px auto",
      // "&::-webkit-scrollbar": {
      //   width: "0.1em",
      //   height: "0.4em",
      // },
      // "&::-webkit-scrollbar-track": {
      //   background: "#E1E5F8",
      // },
      // "&::-webkit-scrollbar-thumb": {
      //   backgroundColor: "#888",
      // },
      // "&::-webkit-scrollbar-thumb:hover": {
      //   background: "#555",
      // },
     
      width: "95%",
      height: "72vh",
      display: "flex",
      flexWrap: "wrap",
      // justifyContent: "center",
      flexDirection: "row",
      // border: "1px solid #999999",
      borderRadius: "10px",
      margin: "auto",
      marginTop: "8px",
      marginBottom: "8px",
      overflowY:'scroll',
      scrollbarWidth: "auto",
      scrollbarRadius: "10px",
    "&::-webkit-scrollbar": {
      width: "0.7em",

    },
    
    
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    },
    card2Container: {
      width: "99%",
      display:"flex",
      flexDirection: "row",
      flexWrap:"wrap",
      justifyContent: "center",
      // overflowY: "scroll",
      // height: "90%",
      display: "flex",
      alignItems: "center",
      margin: "10px auto 10px auto",
    },
    bottomCards: {
      width: "95%",
      // height: "45vh",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      margin: "auto",
      justifyContent: "center",
  
      // backgroundColor: "pink",
    },
    tableContainer1: {
      width: "49%",
      minWidth: "320px",
      marginRight: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media (max-width:600px)": {
        justifyContent: "center",
        margin: "10px auto 10px auto",
        justifyContent: "center",
      },
    },
    tableContainer2: {
      width: "49%",
      minWidth: "320px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media (max-width:600px)": {
        justifyContent: "center",
        margin: "10px auto 10px auto",
        justifyContent: "center",
        marginLeft: "10px",
      },
    },
    tableTop: {
      width: "100%",
      height: "35px",
      backgroundColor: "#FF0000",
      borderRadius: "10px 10px 0px 0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width:600px)": {
        width: "320px",
        margin: "auto",
      },
    },
    tableBottom: {
      width: "99.8%",
      height: "230px",
      marginBottom: "20px",
      border: "1px solid #999999",
      borderRadius: "0px 0px 10px 10px",
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll ",
      overflowX: "hidden",
      "@media (max-width:600px)": {
        width: "320px",
        margin: "auto",
        overflowX: "scroll",
      },
      scrollbarWidth: "auto",
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        background: "#E1E5F8",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  }

export default Styles;