import { Button, Dialog, DialogActions } from "@mui/material";
import React from "react";

export default function ImagePopUp(props) {
    const handleClose = () => {
        props.setNewTaskOpen(false);
      };
  return (
    <div>
      <Dialog fullWidth="xl" onClose={handleClose} open={props.open}>
        <img src={props.img} />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
