import { React, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NewFeed from "../components/Popup/NewFeed";
import EditFeed from "./Popup/EditFeed";
import { useNhostClient, useUserEmail } from "@nhost/react";
import { gql, useQuery,useMutation } from '@apollo/client'


const GET_FEED_QUERY = gql`
query GetAllFeed {
  feed_management(
    order_by: [{ default_feed: desc }, { id: desc }], where: {status: {_eq: true}}
  ) {
    cover_img_path
    default_feed
    description
    end_date
    header_name
    start_date
    id
  }
}
`
const DELETE_FEED = gql`
mutation DeleteFeed( $id: bigint = "", $status: Boolean = false) {
  update_feed_management(where: {id: {_eq: $id}}, _set: {status: $status}) {
    returning {
      id
      status
    }
  }
}
`;
const INSERT_LOGS_MASTER = gql`
  mutation MyMutation5(
    $content_type: String = ""
    $deleted_by: String = ""
    $deleted_frmtable: String = ""
    $deleted_from: String = ""
    $deleted_ip: String = ""
    $deleted_region: String = ""
    $deleted_time: timestamptz = ""
  ) {
    insert_logs_master(
      objects: {
        content_type: $content_type
        deleted_by: $deleted_by
        deleted_frmtable: $deleted_frmtable
        deleted_from: $deleted_from
        deleted_ip: $deleted_ip
        deleted_region: $deleted_region
        deleted_time: $deleted_time
      }
    ) {
      returning {
        content_type
        deleted_by
        deleted_frmtable
        deleted_time
      }
    }
  }
`;
export default function FeedCardComponent(props) {
  // console.log("consilggggggggggg",props.img);
  const { id, name, sdate, edate, desc, img, default_feed } = props;
  const userName = useUserEmail();
  const currentTime = new Date();
  const [newFeedOpen, setNewFeedOpen] = useState(false);
  const [editFeedOpen, setEditFeedOpen] = useState(false);
  

  const [deleteFeed] = useMutation(DELETE_FEED,{
    onCompleted:(data)=>{
      // console.log('object',data )
    },
    onError:(error)=>{
      // console.log(error,"inseting error")
    }
,
    refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
       { query: GET_FEED_QUERY}
    ],

  })
  // const handleFeedUpdated = () => {
  //   // Refetch the data using Apollo Client's refetchQueries
  //   // Assuming you have a query named GET_FEED_QUERY
  //   client.refetchQueries({ query: GET_FEED_QUERY });
  // };
  const [selectedCard, setSelectedCard] = useState({});
  // ..........for refresh

  // .....................fetch image from id .....................
  const nhost = useNhostClient();
  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    async function setImage() {

      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: img,
        });
        // console.log("Image URL:", item);
  // setImage(item)
  setImgUrl(item);

  
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
   
    }

    setImage();
  }, [img]);
  // .....................fetch image from id ends.....................

  const openNewFeed = () => {
    setNewFeedOpen(!newFeedOpen);
  };

  const openEditFeed = () => {
    setEditFeedOpen(!editFeedOpen);
  };

  // ................................delete api starts................................
  const [feedData, setFeedData] = useState([]);
  const [feed, setFeed] = useState();
  const [insertLogs] = useMutation(INSERT_LOGS_MASTER);

  const onDelete = async ({ id }) => {
    try {
      const { data:newData } = await deleteFeed({
        variables: {
          id: id,
        status: false,
        },
         });
         const { data: insertedDataw } = await insertLogs({
          variables: {
            content_type: props.name,
            deleted_by: userName,
            deleted_frmtable:"feed_master",
            deleted_from:"feed management",
            deleted_ip:"-",
            deleted_region:'-',
            deleted_time:currentTime,
          },
        });
         setFeedData((prevFeedData) =>
         prevFeedData.filter((feed) => feed.id !== id)
       );
    } catch (error) {
      console.error('GraphQL Mutation Error:', error.message);
    }
  

    console.log(id);
    let arr = [];

    for (let i of feedData) {
      if (i.id != id) {
        arr.push(i);
      }
      // console.log(i)
    }

    setFeedData(arr);
    // window.location.reload();

  };

  // ................................delete api ends..................................

  return (
    <>
      <Box key={props.index} style={{ width: "100%", display: "flex" }}>
        <Box style={{ width: "100%", marginTop: "20px" }}>
          <Box
            style={{
              width: "99.4%",
              border: "1px solid grey",
              height: "15vh",
              backgroundImage: `url(${imgUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "10px 10px 0px 0px",
              border: props.default_feed ? "2px solid green" : "1px solid grey",
              boxShadow: props.default_feed
                ? "0px 0px 4px rgba(0, 128, 0, 0.5)"
                : "none",

              overflow: "hidden",
              position: "relative",
              filter: props.status === "Expired" ? "grayscale(100%)" : null,
            }}
            onClick={openNewFeed}
          >
            {/* <span
              style={{
                ...Styles.diag,
                
              }}
            >
              status
            </span> */}
          </Box>

          <Box
            style={{
              width: "100%",

              borderTopWidth: 0,
              borderRadius: "0px 0px 10px 10px",
              border: props.default_feed ? "2px solid green" : "1px solid grey",
              textAlign: "center",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            <h4 style={{ fontfamily: "MontserratLite" }}>{name}</h4>
            <Typography
              style={{
                width: "85%",
                fontSize: "12px",
                color: "#252525",
                fontfamily: "MontserratLite",
                textAlign: "justify",
                margin: "auto",
              }}
            >
              {props.desc}
            </Typography>
            <Box
              style={{
                width: "100%",
                border: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <EditIcon
                style={{
                  height: 25,
                  width: 25,
                  color: "#FF0000",
                  marginRight: 7,
                  cursor: "pointer",
                }}
                onClick={openEditFeed}
              />
              <DeleteIcon
                style={{
                  height: 25,
                  width: 25,
                  marginRight: 15,
                  color: "#999999",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onDelete({ id });
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* ........................................preview popup.................................................... */}

      {newFeedOpen && (
        <NewFeed
          temp="temp"
          open={newFeedOpen}
          setNewFeedOpen={setNewFeedOpen}
          id={id}
          name={name}
          sdate={sdate}
          edate={edate}
          desc={desc}
          img={img}
        />
      )}

      {/* ........................................edit popup.................................................... */}
      {editFeedOpen && (
        <EditFeed
          open={editFeedOpen}
          setEditFeedOpen={setEditFeedOpen}
          id={id}
          name={name}
          sdate={sdate}
          edate={edate}
          desc={desc}
          img={img}
          default_feed={default_feed}
          
        />
      )}
    </>
  );
}
const Styles = {
    diag: {
    WebkitTransform: 'rotate(-45deg)',
    MozTransform: 'rotate(-45deg)',
    transform: 'rotate(-45deg)',
    left: '-50px',
    top: '30px',
    fontFamily: 'MontserratLite',
    width: '173px',
    display: 'inline-block',
    height: '20px',
    lineHeight: '20px',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '14px',
    background: '#568203',
    position: 'absolute'
  },
  menuIcon: {
    position: "sticky",
    top: "0px",
    left: "252px",
    width: "24px",
    height: "24px",
    top: "7px",
    cursor: "pointer",
    border: "1px solid",
    marginLeft: 30,
  },
  menuitems: {
    listStyle: "none",
    width: "30%",
    height: "40%",
    left: "175px",
    top: "20px",
    border: "1px solid white",
    borderRadius: "10px",
    position: "sticky",
    backdropFilter: "blur(15px)",
    backgroundColor: "#FF0000",
    fontFamily: "MontserratLite",
    marginLeft: 20,
  },
  buttons: {
    width: "100%",
    color: "white",
    border: "0px",
    height: "20px",
    backgroundColor: "transparent",
    backdropFilter: "blur(15px)",
    fontFamily: "MontserratLite",
  },
  img: {
    width: "100%",
    height: "15vh",
    // backgroundImage: `url(${item.image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px 10px 0px 0px",
    overflow: "hidden",
    position: "relative",
  },
};