import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import PreviewCard from "./UserDashboard/Feed/PreviewCard";
import SmallCard from "./UserDashboard/Feed/SmallCard";
import profile2 from "../../../src/images/userProfile.png";
import { gql, useQuery, useMutation } from "@apollo/client";
const GET_FEEDDATA_USER_QUERY = gql`query GetAllFeed {
  feed_management(
    order_by: [{ default_feed: desc }, { id: desc }], where: {status: {_eq: true}}
  ) {
    cover_img_path
    default_feed
    description
    end_date
    header_name
    start_date
    id
  }
}
`
export default function FeedComponent(props) {
  const [selectedCard, setSelectedCard] = useState(null);
  const [Feed1, setFeed1] = useState([])
  // console.log("feed 1",Feed1)
  
  const handleCardSelection = (item) => {
    setSelectedCard(item);
  };
  const cardContainerRef = useRef(100);

  const scrollLeft = () => {
    const cardContainer = cardContainerRef.current;
    cardContainer.scrollLeft -= 100; // adjust the value as per your requirement
  };

  const scrollRight = () => {
    const cardContainer = cardContainerRef.current;
    cardContainer.scrollLeft += 100; // adjust the value as per your requirement
  };

// ................................fetch Api................................

  const [feedData, setFeedData] = useState([])
// fetch feed api data

const { loading, error, data:feedata,isError } = useQuery(GET_FEEDDATA_USER_QUERY, {

  variables: {
    status: {_eq: true}
        },
})

;
//   console.log("error is ", error);
// console.log("set data feed",feedata?.feed_management[0]);

useEffect(() => {

  setFeedData(feedata?.feed_management);
  setFeed1(feedata?.feed_management[0]);
  // console.log("data1",feedata)
},[feedata]);
// console.log("data for feed",feedData)



  const [feed, setFeed] = useState();
  // const fetchData = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("x-hasura-admin-secret", process.env.REACT_APP_NHOST_SECREATEKEY);
  //   myHeaders.append("Access-Control-Allow-Credentials", true);
  //   myHeaders.append("Access-Control-Allow-Origin", "*");

  //   myHeaders.append(
  //     "Access-Control-Allow-Methods",
  //     "GET,OPTIONS,PATCH,DELETE,POST,PUT"
  //   );
  //   myHeaders.append(
  //     "Access-Control-Allow-Headers",
  //     "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
  //   );

  //   var graphql = JSON.stringify({
  //     query: `query GetAllFeed {
  //       feed_management(
  //         order_by: [{ default_feed: desc }, { id: desc }], where: {status: {_eq: true}}
  //       ) {
  //         cover_img_path
  //         default_feed
  //         description
  //         end_date
  //         header_name
  //         start_date
  //         id
  //       }
  //     }`,
  //   });
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: graphql,
  //     redirect: "follow",
  //     // mode: "no-cors",
  //   };

  //   const res = await fetch(
  //     process.env.REACT_APP_URL,
  //     requestOptions
  //   );
  //   const data = await res.json();
  //   console.log("feed data updated", data.data.feed_management);
  //   if (data.data.feed_management[0].default_feed === true) {
  //     setFeed(true);
  //   }
  //   setFeedData(data.data.feed_management);
  // };

  // useEffect(() => {
  //   // Calling fetchData Method
  //   fetchData();
  // }, [])
// ................................fetch Api................................

  return (
    <Box sx={styles.container}>
      {/* ..............................preview start......................................................................  */}
      {selectedCard ? (
      <PreviewCard
        // pass selected card as props to PreviewCard component
        profile={selectedCard.profile}
        img={selectedCard.cover_img_path ?? null}
        name={selectedCard.name}
        header={selectedCard.header_name}
        desc={selectedCard.description}
      />
    ):(
       <PreviewCard
        profile={null}
        img={Feed1?.cover_img_path}
        name={null}
        header={Feed1?.header_name}
        desc={Feed1?.description}   
       />
    )}

      {/* ........................................preview end............................................................. */}

      {/* ......................................bottom cards..................... */}

      <Box sx={styles.bottomContainer}>
        <img
          alt=""
          onClick={scrollLeft}
          style={styles.leftArrow}
          src={require("../../images/left.png")}
        />
        <Box style={{width:"90%",}}>
        <Box sx={styles.cardBox} ref={cardContainerRef}>
        {feedData?.map((item) => (
            <SmallCard
              key={item.id}
              profile={profile2}
              img={item.cover_img_path ?? null}
              name={item.name}
              header={item.header_name}
              desc={item.description}
              default_feed={item.default_feed}
              onClick={() => handleCardSelection(item)}
            />
          ))}

        </Box>
        </Box>
        <img
          alt=""
          onClick={scrollRight}
          style={styles.rightArrow}
          src={require("../../images/right.png")}
        />
      </Box>
      {/* ..........................................end of bottom cards */}
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
 
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minWidth: "300px",
    justifyContent: "center",
    alignItems: "center",
    

  },
  cardBox: {
    width: "95%",
    minWidth: "300px",
    margin: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    justifySelf: "center",
    alignSelf: "center",
    alignItems: "center",
    overflowY: "hidden",
    overflowX: "scroll",
    scrollbarWidth: "auto",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  leftArrow: {
    width: "25px",
    height: "25px",
    // marginTop: "4%",
    top: "27%",
    position: "relative",
    cursor: "pointer",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  rightArrow: {
    width: "25px",
    height: "25px",
    // marginTop: "4%",
    bottom: "30%",
    left: "98%",
    cursor: "pointer",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
};
