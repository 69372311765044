// import styles from '../styles/components/ProtectedRoute.module.css'
import { useAuthenticationStatus } from '@nhost/react'
import { Navigate, useLocation } from 'react-router-dom'
// import Spinner from './Spinner'

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  const location = useLocation()

//   if (isLoading) {
//     return (
//       <div className={styles.container}>
//         <Spinner />
//       </div>
//     )
//   }
if(!isAuthenticated)
{
    console.log("not authenticated",isAuthenticated)
    return    <Navigate to="/" state={{ from: location }} replace />

}

  // if (!isAuthenticated) {
  //   return    <Navigate to="/admindash" state={{ from: location }} replace />
  // }

  return children
}

export default ProtectedRoute