import React from "react";
import '../../styles/FullRedbtn.css';
export default function FullRedButton(props) {
  return (
    <div>
      <button
        // onMouseOver={(e) => (e.target.style = Styles.buttonHover)}
        // onMouseOut={(e) => (e.target.style = Styles.button)}
        // style={Styles.button}
        className="button"
        onClick={props.click}
      >
        {props.title}
      </button>
      
    </div>
  );
}
// const Styles = {
  // button: {
  //   width: "100%",
  //   marginTop: "10px",
  //   height: "33px",
  //   border: "1px solid #FF0000",
  //   borderRadius: "5px",
  //   fontSize: "14px",
  //   fontFamily: "MontserratRegular",
  //   backgroundColor: "#FF0000",
  //   borderRadius: "10px",
  //   color: "#FFFFFF",
  //   boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.25)",
    // cursor: "pointer",
    // buttonHover: {
    //   backgroundColor: "blue",
    //   cursor: "pointer",
    //   boxShadow: 2,
    // },
    // :{
    //   backgroundColor: "black",
    //   cursor:"pointer",
    //   boxShadow:2
    // },
//     "&:hover": {
//       backgroundColor: "blue",
//       cursor: "pointer",
//       boxShadow: 2,
//     },
//   },
// };
