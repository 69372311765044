import { Routes, Route, BrowserRouter, HashRouter, Outlet } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword";
import EmailVerification from "./pages/EmailVerification";
import ResetPassword from "./pages/ResetPassword";
import UserManagement from "./pages/UserManagement";
import AddUser from "./components/UserMangement/AddUser";
import GroupStepper from "./components/GroupManagement/GroupManagementVS";
import EventManagement2 from "./pages/EventManagement2";
import GroupManagement2 from "./pages/GroupManagement2";
import EventManagement1 from "./pages/EventManagement1";
import GroupManagement from "./pages/GroupManagement";
import EventManagementVS from "./components/EventManagementEventCreation/EventManagementVS";
import AdminDashboard1 from "./pages/AdminDashboard1";
import Login from "./components/Login/Login";
import UserSignup from "./UserScreens/UserSignup";
import FeedManagement from "./pages/FeedManagement";
import ProfileAdmin from "./components/AdminDashboard1/ProfileAdmin";
import SystemConfig from "./pages/SystemConfig";
import BackupContent1 from "./pages/BackupContent1";
import AddRoles from "./components/UserMangement/AddRoles";

// import UserProfile from "../src/UserScreens/Components/User Profile/UserProfile";
import AttendanceReport from "./components/eventManagement/AttendanceReport";
import UserProfile from "../src/UserScreens/Components/User Profile/UserProfile";
import UserDashboard from "../src/UserScreens/UserDashboard";
import EventClosure from "./components/eventManagement/EventClosure";
import changepassword from "./components/AdminDashboard1/ChangePassword"
import UserChat from "./components/Chat/UserChat"

import { useSelector } from "react-redux";
import { selectUser } from "./Redux/features/UserSlice";
import { NhostClient, NhostProvider } from "@nhost/react";
import EventScreen from "./UserScreens/EventManagement2";
import { NhostApolloProvider } from "@nhost/react-apollo";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  const user = useSelector(selectUser);

  const nhost = new NhostClient({
    subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
    region: process.env.REACT_APP_NHOST_REGION,

  //   graphqlUrl: 'https://192.168.29.201:1337/graphql',
  // authUrl: 'https://192.168.29.201:1337/auth',
  // storageUrl: 'https://192.168.29.201:1337/storage',
  // functionsUrl:'https://192.168.29.201:1337/functions'



  });

  return (
    <NhostProvider nhost={nhost}>
      <NhostApolloProvider nhost={nhost}>
        <HashRouter>
          <Routes>
            {/* {user ? <Route path="/admindash" element={<AdminDashboard1 />} /> : <Route path="/" element={<Login />} />} */}
            {/* <Route path="/" element={user ? <AdminDashboard1 /> : <Login />} /> */}
            <Route path="/" element={<Login />} />
         
            {/*<Route path="/admindash" element={<AdminDashboard1 />} /> */}
            <Route path="/forgotpassword" element={<ForgotPassword />} />

            
            <Route path="/admindash" element={<ProtectedRoute ><AdminDashboard1 /></ProtectedRoute>}>
              {/* <Route index element={<AdminDashboard1 />} /> */}
              {/* <Route index element={/>} /> */}
              {/* <Route path="emailverify" element={<EmailVerification />} /> */}
              <Route path="resetpassword" element={<ResetPassword />} />
              {/* <Route path="admindash" element={<AdminDashboard1 />} /> */}
            {/* <Route path="eventmanagement1" element={<EventManagement1 />} /> */}
              {/* <Route path="eventmanagement2" element={<EventManagement2 />} /> */}
              {/* <Route path="eventscreen" element={<EventScreen />} /> */}
              {/* <Route path="eventmanagementvs" element={<EventManagementVS />} /> */}
              {/* <Route path="groupmanagement" element={<GroupManagement />} /> */}
              {/* <Route path="attendencereport" element={<AttendanceReport />} /> */}
              <Route path="eventclosure" element={<EventClosure />} />
              {/* <Route path="groupmanagement2" element={<GroupManagement2 />} /> */}
              {/* <Route path="groupManagementvs" element={<GroupStepper />} /> */}
              {/* <Route path="usermanagement" element={<UserManagement />} /> */}
              {/* <Route path="adduser" element={<AddUser />} /> */}
              {/* <Route path="feedmanagement" element={<FeedManagement />} /> */}
              {/* <Route path="profileadmin" element={<ProfileAdmin />} />
              <Route path="systemconfig" element={<SystemConfig />} /> */}
              <Route path="backupcontent1" element={<BackupContent1 />} />
              <Route path="User" element={<BackupContent1 />} />
              <Route path="userprofile" element={<UserProfile />} />

             
              <Route path="usersignup" element={<UserSignup />} />
             
            </Route>
            <Route path="attendance" element={<AttendanceReport />} />

            <Route path="/eventmanagement1" element={<ProtectedRoute ><EventManagement1 /></ProtectedRoute>}/>
            <Route path="/eventmanagement2" element={<ProtectedRoute ><EventManagement2 /></ProtectedRoute>}/>
            <Route path="/eventmanagementvs" element={<ProtectedRoute ><EventManagementVS /></ProtectedRoute>}/>
            <Route path="/groupmanagement" element={<ProtectedRoute ><GroupManagement /></ProtectedRoute>}/>
            <Route path="/groupmanagement2" element={<ProtectedRoute ><GroupManagement2 /></ProtectedRoute>}/>
            <Route path="/groupManagementvs" element={<ProtectedRoute ><GroupStepper /></ProtectedRoute>}/>
            <Route path="/usermanagement" element={<ProtectedRoute ><UserManagement /></ProtectedRoute>}/>
            <Route path="/feedmanagement" element={<ProtectedRoute ><FeedManagement /></ProtectedRoute>}/>
            <Route path="/adduser" element={<ProtectedRoute ><AddUser /></ProtectedRoute>}/>
            <Route path="/systemconfig" element={<ProtectedRoute ><SystemConfig /></ProtectedRoute>}/>
            <Route path="/profile" element={<ProtectedRoute ><ProfileAdmin /></ProtectedRoute>}/>
            <Route path="/userdashboard" element={<ProtectedRoute ><UserDashboard /></ProtectedRoute>}/>
            <Route path="/addroles" element={<ProtectedRoute ><AddRoles /></ProtectedRoute>}/>
            <Route path="/userchat" element={<ProtectedRoute ><UserChat /></ProtectedRoute>}/>

            {/* </ProtectedRoute> */}

          </Routes>
        </HashRouter>
      </NhostApolloProvider>
    </NhostProvider>
  );
}

export default App;