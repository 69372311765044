import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import UpcomingEventCard from "./AllExperiences/UpcomingEventCard";
import MoreEventCard from "./AllExperiences/MoreEventCard";
import { useNhostClient } from "@nhost/react";
import { useNavigate } from "react-router";
import { gql, useQuery, useMutation } from "@apollo/client";
import TempTest from "./AllExperiences/TempTest";
import Dashcard1 from "./AllExperiences/Dashcard1";

const GET_USER_CATEGORY = gql`
  query get_categorybyid($_eq: uuid = "") {
    profile_details(where: { user_id: { _eq: $_eq } }) {
      category
    }
  }
`;
const GET_RECUURANCE_EVENTS_ID = gql`
query MyQuery3weded($_eq: uuid = "") {
  user_events(where: {userid: {_eq: $_eq}, _and: {event_master: {status: {_eq: "isactive"}}}}) {    event_id
    event_master {
      parent_id
    }
  }
}

`;
const GET_USER_EVENTS = gql`

query newwvwnrquery( $_in: [String!] = [], $userid: uuid = "") {
  event_master(where: {publish_to: {_in: $_in}, _and: {status: {_eq: "isactive"}, _and: {is_recurring: {_eq: false}, _and: {user_events: {userid: {_neq: $userid}}}}}}) {
    activation_date
    category_id
    closure_date
    conevene_meeting_id
    cover_img_path
    created_at
    deactivation_date
    description
    end_time
    conven_meeting
    user_events {
      booking_status
    }
    status
    start_time
    id
    is_recurring
    location
    metadata
    name
    parent_id
    publish_status
    publish_to
    recording_status
    recurrance_end_date
    recurrance_end_time
    recurrance_frequency
    recurrance_start_date
    recurrance_start_time
  }
}

`;
// const GET_USER_EVENTS = gql`
// query MyQuery($_in: [String!] = [], $userid: uuid = "") {
//   event_master(where: {publish_to: {_in: $_in}, _and: {status: {_eq: "isactive"}, _and: {is_recurring: {_eq: false}}}}) {
//     description
//     id
//     location
//     name
//     activation_date
//     category_id
//     closure_date
//     cover_img_path
//     deactivation_date
//     end_time
//     is_recurring
//     metadata
//     parent_id
//     publish_to
//     recurrance_end_date
//     recurrance_frequency
//     recurrance_start_date
//     start_time
//     conven_meeting
//     user_events(where: {userid: {_eq: $userid}}) {
//       booking_status
//     }
//   }
// }
// `;

export default function AllExperiencesComponent() {
  const nhost = useNhostClient();
  const [Events, setEvents] = useState([]);
  const [isUser,setisUser] = useState(true);

  const EventClick = (id,imgUrl,name, description, recurrance_start_date,recurrance_end_date, location)=>{
    setisUser(true);
    // setmembership(true);
    let membership1 = true;
    navigate('/eventmanagement2', { state: { isUser: isUser, membership1, id, name,imgUrl, description, recurrance_start_date,recurrance_end_date, location } })
  }
  const ScheduledEventClick = (id,imgUrl,name, description, recurrance_start_date,recurrance_end_date, location,start_time,end_time,eveBooked)=>{
    setisUser(true);
    let membership1 = false;
    // setmembership(true);
    navigate('/eventmanagement2', { state: { isUser: isUser, membership1, id, name,imgUrl, description, recurrance_start_date,recurrance_end_date, location ,start_time,end_time,eveBooked} })
  }
  
// fetch category api
  const [Category, setCategory] = useState("");
  const navigate = useNavigate();
  const { loading, error, data:category,isError } = useQuery(GET_USER_CATEGORY, {
    variables: {
      _eq: localStorage.getItem("id"),
          },
  })
  ;
  // console.log("error is ", error);
  // console.log("set data category",category );

 
  // console.log("data for category",Category)

 
  
  const { data: userevents, error: eventserror } = useQuery(GET_USER_EVENTS, {
    // skip: Category === null,
    variables: {
      _in: [Category, "All"],
      userid:localStorage.getItem("id")
    },
  });
  const { data: userrecurrance, error: recurranceerror } = useQuery(
    GET_RECUURANCE_EVENTS_ID,
    {
      // skip: Category === null,
      variables: {
        _eq: localStorage.getItem("id"),
      },
    }
  );
  const [id, setId] = useState([]);

  if (userrecurrance) {
    userrecurrance.user_events.forEach((event) => {
      if (!id.includes(event.event_id)) {
        setId((prev) => [...prev, event.event_id]);
      }
    });
  }
  useEffect(() => {
    setCategory(category?.profile_details[0]?.category);
    setEvents(userevents?.event_master);
    console.log("data1 sdvkjsdlvjh",userevents?.event_master)
  },[category,userevents]);
  const cardContainerRef = useRef(null);
  const bottomContainerRef = useRef(null);

  return (
    <Box sx={styles.main}>
      <Box sx={styles.uppercards}>
        <Box sx={styles.txtContainer}>
          <Typography sx={styles.largeTxt}>Recurring Sessions</Typography>
        </Box>
        {/* ..............................................start upcoming events */}
        <Box sx={styles.arrowScroll}>
          <Box sx={styles.cardContainer}  ref={cardContainerRef}>
          {/* <TempTest id={id} /> */}
          <Dashcard1 id={id}/>
          </Box> 
        </Box>
        {/* ..............................................end upcoming events */}
      </Box>
      <Box sx={styles.lowercards}>
        <Box sx={styles.txtContainer}>
          <Typography sx={styles.largeTxt}>More Events</Typography>
        </Box>
        {/* ..............................................start more events */}
        <Box sx={styles.arrowScroll}>
          <Box sx={styles.bottomContainer} ref={bottomContainerRef}>
          {/* {Events.length === 0 ? (
              <p style={{fontFamily:"MontserratRegular"}}>No More Events </p>
            ) : ( */}
              {Events?.map((item, index) => (
                <Box style={{cursor:"pointer"}} onClick={()=>ScheduledEventClick(item.id,item.cover_img_path,item.name,item.description,item.recurrance_start_date,item.recurrance_end_date,item.location,item.start_time,item.end_time,item?.user_events[0]?.booking_status)}>
                <UpcomingEventCard
                  img={item.cover_img_path}
                  desc={item.description}
                  title={item.name}
                  date={item.recurrance_start_date}
                  address={item.location}
                  booked={item?.user_events[0]?.booking_status}
                />
                </Box>
              ))}
          </Box>
        </Box>
        {/* ..............................................end more events */}
      </Box>
    </Box>
  );
}

const styles = {
  main: {
    display: "flex",
    flexDirection: "row ",
    width:"100%"
  },
  uppercards: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
   
    height:"50%",
    width:"100%"
  },
  lowercards: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
   
    height:"50%",
    width:"100%"
  },
  txtContainer: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    height:"20%",
    
    marginBottom:10
  },
  largeTxt: {
    fontFamily: "MontserratMedium",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
    lineHeight: 1,
    marginTop: "8px",
  },
  cardContainer: {
    // cursor: "pointer",
   
    width: "100%",
    height: "520px",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    overflowY: "scroll",
   
    scrollbarWidth: "1px",
    overflowX: "hidden",
    // margin: "10px auto 10px auto",
    "&::-webkit-scrollbar": {
      width: "0.1em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  bottomContainer: {
    
    width: "100%",
    // height: "60%",
    height: "520px",
    display: "flex",
    flexDirection:"column",
    alignItems: "center",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "1px",
    margin: "10px",
    "&::-webkit-scrollbar": {
      width: "0.1em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },

  leftArrow: {
    // marginTop: "4%",
    width: "15px",
    height: "60px",
    position: "relative",
    cursor: "pointer",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  rightArrow: {
    width: "15px",
    height: "60px",
    // marginTop: "4%",

    cursor: "pointer",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  arrowScroll: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "60%",
    width: "102%",
    margin: "4px",
  },
};
