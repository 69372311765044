import { Box, Button, Typography } from "@mui/material";
import {React,useEffect,useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { gql, useQuery,useMutation } from '@apollo/client'
import { useUserDisplayName } from "@nhost/react";
const GET_USERS_INFO=gql`
query MyQuery {
  profile_details {
    user_id
  }
}
`
const GET_PROFILE_INFO=gql`

query MyQuery2 {
  users {
    email
    phoneNumber
    displayName
    defaultRole
    id
  }
}



`
const GET_EXISTING_USER_QUERY = gql`
query getAllUserRequests {
  profile_details(where: {category: {_neq: "unapproved"}}) {
    bio
    birth_date
    category
    first_name
    last_name
    profile_pic_path
    second_name
    city
    province
    user_id
    address1
    address2
    gender
    country
    postalcode
    postcode
    roles
    user {
      defaultRole
      displayName
      email
      emailVerified
      id
      phoneNumber
      avatarUrl
    }
  }
}
`
const DELETE_EXISTING_USER_MUTATION = gql`
mutation DeleteUserRequests($id: uuid = "") {
  deleteUsers(where: {id: {_eq: $id}}) {
    returning {
      id
      email
      displayName
    }
  }
}
`;

const DELETE_USER = gql`
mutation MyMutation15($_eq: uuid = "") {
  deleteUsers(where: {id: {_eq: $_eq}}) {
    returning {
      id
    }
  }
}
`;

const INSERT_USER_INACTIVEUSER = gql`
mutation insertinactiveuser($userid: uuid = "", $firstname: String = "", $deactivationdate: timestamptz = "", $deactivatedtype: String = "", $deactivatedby: String = "") {
  insert_inactiveusers(objects: {userid: $userid, firstname: $firstname, deactivationdate: $deactivationdate, deactivatedtype: $deactivatedtype, deactivatedby: $deactivatedby}) {
    returning {
      id
      userid
    }
  }
}
`;

const useStyles = makeStyles({
    table: {
      minWidth: 600,
      width: '100%',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '10px',
    //   borderRadius:"10px"
    },
    tableRow: {
        height: 20
      },
      tableCell: {
        padding: "0px 16px",
        height:5,
        fontFamily:"MontserratRegular"
      },
      header: {
        height: 30
      },
      headerCell:{
        padding: "5px 16px",
        height:15,
        color: "white",
        fontFamily:"MontserratRegular"
      }
  });

export default function ExistingUser() {
  const navigate = useNavigate();
  function handleNav() {
    navigate("/adduser");
  }
  const currentTimestamp = new Date().toISOString();
  const userDisplayName = useUserDisplayName();
  const userDetails = useSelector((state)=>state.user.users);
  const {userName, userEmail,memberCategory,userMobile,userCity} = userDetails;

  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [data, setData] = useState();
  console.log(userDisplayName,"this is admin name ")



  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const [existUser , setExistingUser] = useState([])
  const { data:existing_user } = useQuery(GET_EXISTING_USER_QUERY);
  //  console.log("the data we want",existing_user?.profile_details)
  useEffect(() => {
  setExistingUser(existing_user?.profile_details)

  },[existing_user])


  const [profile , setProfile] = useState([])
  const { data:user } = useQuery(GET_USERS_INFO,{
    onCompleted:(data)=>{
       setProfile(data?.profile_details)
    }
  });

  const [usersInfo , setusersInfo] = useState([])
  const { data:info } = useQuery(GET_PROFILE_INFO,{
    onCompleted:(data)=>{
      setusersInfo(data?.users)
    }
  });
  // useEffect(() => {
  // setinfo(info?.users)

  // },[info])

  const filteredUsers = profile.filter(user => {
    return !usersInfo.some(userInfo => userInfo.id === user.user_id);
  });

  console.log("Filtered Users:", filteredUsers); 
  console.log("ishika this is data we want sdgfvsfvs??",profile,usersInfo)


  const [deleteExistingUser] = useMutation(DELETE_EXISTING_USER_MUTATION,{
    onCompleted:(data)=>{
      
    },
    refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
       { query: GET_EXISTING_USER_QUERY}
    ],
  });
  const [deleteUserId,setdeletedUserId] = useState();
  
  const handleDeleteRow = async (id,row) => {
    const shouldDelete = window.confirm("Are you sure you want to delete this user?");
    if (!shouldDelete) {
      return; // User canceled the deletion
    }
  
    setdeletedUserId(id);
    try {
      await deleteExistingUser({ variables: { id } });
      setExistingUser((prevExistingUser) =>
      prevExistingUser.filter((row) => row.id !== id)

      
    );
    } catch (error) {
      console.error('Error deleting event type:', error);
    }
    try {
      // Run your API to close the event here
      const { data: deletedata } = await insertincactiveuser({
        variables: {      
          userid: id,
          firstname:row.first_name,
          deactivationdate:currentTimestamp,
          deactivatedtype:"byadmin",
          deactivatedby:userDisplayName
        },
      });
     
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }

  };

  const [insertincactiveuser] = useMutation(INSERT_USER_INACTIVEUSER, {
    onerror: (error) => {
      console.log("errror romvr `", error);
    },
    onCompleted:(data)=>{
      console.log("insert completed")
      deleteuserquery()
    }
  });

  const [deleteuser,{error,data:deletedata}] = useMutation(DELETE_USER, {
    onerror: (error) => {
      console.log("errror romvr `", error);
    },
    onCompleted:(data)=>{
      alert("User deleted");

    }
  });

  const deleteuserquery = async()=>{
    try {
      // Run your API to close the event here
      const { data: deletedata } = await deleteuser({
        variables: {
          _eq: deleteUserId,
        },
      });
     
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  }

  const [isForUpdate , setIsForUpdate] = useState(true)
  const handleApprove = async (id , rows,)=>{
    navigate("/adduser",{state: {id, rows,isForUpdate}})
    

  }

  const filtereData = existUser?.filter((row) =>
  (row.first_name && row.first_name.toLowerCase().includes(filter.toLowerCase())) ||
  (row.category && row.category.toLowerCase().includes(filter.toLowerCase())) ||
  (row.city && row.city.toLowerCase().includes(filter.toLowerCase())) ||
  (row.users?.email && row.users?.email.toString().includes(filter.toLowerCase())) ||
  (row.users?.phoneNumber && row.users?.phoneNumber.toLowerCase().includes(filter.toLowerCase()))
);
  return (
    <Box>
      <Box sx={Styles.tableBox}>
      <div className={classes.searchContainer}>
        <TextField
          label="Search Here"
          value={filter}
          style={{ "& .MuiInputBase-root": {
            fontFamily: "MontserratRegular",
          },}}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <IconButton size="small">
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        <Box
          sx={[
            Styles.btnDashboard,
            {
              marginX: "5px",
              color: "#252525",
              border: "1px solid #252525",
              "&:hover": {
                cursor: "pointer",
                borderColor: "FF0000",
              },
            },
          ]}
          onClick={handleNav}
        >
          {" "}
          Add User
        </Box>
      </div>
      
      <Box style={Styles.tableBox2}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header} style={{backgroundColor:"#999999",height:"20px",color:"white"}} >
            <TableRow  >
              <TableCell className={classes.headerCell}  align="center">Name</TableCell>
              <TableCell className={classes.headerCell} align="center">Email ID</TableCell>
              <TableCell className={classes.headerCell} align="center">Member Category</TableCell>
              <TableCell className={classes.headerCell} align="center">Mobile Number</TableCell>
              <TableCell className={classes.headerCell} align="center">City</TableCell>
              <TableCell className={classes.headerCell} align="center"></TableCell>
              <TableCell className={classes.headerCell} align="center"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {filtereData?.map((row) => (
              <TableRow className={classes.tableRow} key={row.id}>
                <TableCell className={classes.tableCell}  align="center" component="th" scope="row">
                  {row.first_name}
                </TableCell>
                
                <TableCell className={classes.tableCell}  align="center">{row.user.email}</TableCell>
                <TableCell className={classes.tableCell} align="center" >{row.category}</TableCell>
                <TableCell className={classes.tableCell} align="center">{row.user.phoneNumber}</TableCell>
                <TableCell className={classes.tableCell} align="center">{row.city}</TableCell>

                <TableCell className={classes.tableCell} align="center">
                <IconButton onClick={() => handleApprove(row.user.id , row)}>
                    <img src={require('../../images/view.png')}/>
                  </IconButton>
                </TableCell>

                <TableCell className={classes.tableCell} align="center">
                  <IconButton onClick={() => handleDeleteRow(row.user.id,row)}>
                    <img src={require('../../images/delete.png')}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  mainContainer: {
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "8vh",
  },
  btnDashboard:{
    // backgroundColor: "#FF0000",
    width: "85px",
    borderWidth: "1px",
    height: "30px",
    borderRadius: "10px",
    fontFamily:'MontserratRegular',
    fontWeight:500,
    fontStyle:'normal',
    fontSize:'12px',
    display:'flex',
    lineHeight:'20px',
    letterSpacing:'0.5px',
    color:'white',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    padding:'auto',
    margin:'auto',
 },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  tableBox:{
    width: "100%",
    height: "540px",
    borderRadius: " 10px",
    overflowX:"hidden",
    // display:'flex',
    // // flexDirection:'row',
    "&::-webkit-scrollbar": {
        width: "0.5em",
        height: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        background: "#E1E5F8",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
  },
  tableBox2: {
    width:"98%",
    justifyContent:"center",
    alignItems: "center",
    textAlign: "center",
    margin:"auto",
    marginBottom: "15px",
  }
};