import { React, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditTaskPop from "../eventManagement/EditTaskPop";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Box } from "@mui/material";

const GET_CHECKLIST_DETAILS = gql`
query GetEventTask($_eq: bigint = "") {
  task_master(where: {event_id: {_eq: $_eq}, _and: {status: {_nilike: "deleted"}}}) {
    description
    event_id
    file_path
    group_id
    id
    staff_id
    target_date
    status
    task_name
    user {
      displayName
      profile_detail {
        first_name
      }
    }
  }
}

`;
const DELETE_TASK_MUTATION = gql`
  mutation MyMutation($_eq: bigint = "") {
    update_task_master(
      where: { id: { _eq: $_eq } }
      _set: { status: "deleted" }
    ) {
      returning {
        description
        task_name
      }
    }
  }
`;

function Checklist(props) {
  //fetch checklist data
  const [task, setTask] = useState([]);
  // console.log("id",props.id);
  const {
    loading,
    error,
    data: checklistdata,
    isError,
    refetch
  } = useQuery(GET_CHECKLIST_DETAILS, {
    variables: {
      _eq: props.id,
    },
  });
  if (isError) {
    console.log("error is ", error);
  }

  const [deleteMutation] = useMutation(DELETE_TASK_MUTATION,{
    onCompleted: () =>{
      refetch();
    }
  });

  // console.log("set data checklist", checklistdata);
  useEffect(() => {
    setTask(checklistdata?.task_master);
    // console.log("checklistdata",checklistdata?.task_master)
  }, [checklistdata]);
  // console.log("data for checklist",task)
  const [data, setData] = useState([
    {
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc efficitur tem...",
      targetdate: "13/2/2023",
      assign: "Omkar",
      status: "Completed",
    },
    {
      description: "Lecture delivery form guest",
      targetdate: "13/2/2023",
      assign: "Pratik",
      status: "InCompleted",
    },
    {
      description: "Vote of thanks",
      targetdate: "13/2/2023",
      assign: "Prasanna",
      status: "In Progress",
    },
  ]);
  const [open, setOpen] = useState(false);
  const [opn, setopn] = useState(false);
  const eventId = props.id;

  const [editIndex, setEditIndex] = useState(-1);
  const [newtaskid, setNewtaskid] = useState("");
  const [newdescription, setNewdescription] = useState("");
  const [newtargetdate, setNewtargetdate] = useState("");
  const [newassign, setNewAssigned] = useState("");
  const [newstatus, setNewstatus] = useState("");

  const handleEdit = (index) => {
    setEditIndex(index);
    setNewtaskid(data[index].taskid);
    setNewdescription(data[index].description);
    setNewtargetdate(data[index].targetdate);
    setNewAssigned(data[index].assign);
    setNewstatus(data[index].status);
  };

  const handleSave = () => {
    const newData = [...data];
    newData[editIndex] = {
      ...newData[editIndex],
      taskid: newtaskid,
      description: newdescription,
      targetdate: newtargetdate,
      assign: newassign,
      status: newstatus,
    };
    setData(newData);
    setEditIndex(-1);
  };

  const handleDelete = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleAddRow = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        taskid: "",
        description: "",
        targetdate: "",
        assign: "",
        status: "",
      },
      // console.log(data),
    ]);
  };

  const [isforUpdate, setisforUpdate] = useState(false);
  const [rowsdata, setrowsdata] = useState([]);

  const AssignRolepage = (row) => {
    setopn(!opn);
    setisforUpdate(true);
    setisEvent(true);
    setrowsdata(row);
  };

  const [isEvent, setisEvent] = useState(true);
  const handleClick = () => {
    // Function 1
    AssignRolepage();
    setisforUpdate(false);
    setisEvent(true);
    setrowsdata();
    // Function 2
    handleAddRow();
  };
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // console.log(value);
  };

  const deleteTask = async (id) => {
    // Ask the user for confirmation
    const confirmation = window.confirm("Are you sure you want to delete this task?");
  
    if (confirmation) {
      try {
        // Proceed with the deletion
        const deleteTaskRequest = await deleteMutation({
          variables: {
            _eq: id,
          },
        });
      } catch (error) {
        console.log("Error deleting task: ", error);
      }
    } else {
      console.log("Task deletion canceled.");
    }
  };
  

  return (
    <>
      {props.user !== "user" && (
        <img
          style={Styles.addIcon}
          src={require("../../images/plus.png")}
          onClick={handleClick}
        />
      )}

      <table style={Styles.table}>
        <thead style={Styles.header}>
          <tr>
            <th></th>
            <th>Description</th>
            <th>Target Date</th>
            <th>Assign To</th>
            <th>Status</th>
            {/* <th style={{ width: 30, height: 30 }}></th> */}
          </tr>
        </thead>
        <tbody style={Styles.body}>
          {task?.map((row, index) => (
            <tr style={Styles.row} key={row.id}>
              <td>
                {editIndex === index ? (
                  <>
                    <button onClick={handleSave}>Save</button>
                    <button onClick={() => setEditIndex(-1)}>Cancel</button>
                  </>
                ) : (
                  <>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <img
                        src={require("../../images/EditNew.png")}
                        //onClick={() => handleEdit(index)}
                        onClick={() => AssignRolepage(row)}
                      />
                      <img
                        src={require("../../images/delete.png")}
                        //onClick={() => handleEdit(index)}
                        onClick={() => deleteTask(row.id)}
                      />
                    </Box>
                  </>
                )}
              </td>

              <td style={{ textAlign: "justify" }}>
                {editIndex === index ? (
                  <input
                    value={newdescription}
                    onChange={(e) => setNewdescription(e.target.value)}
                  />
                ) : (
                  row.task_name
                )}
              </td>

              <td>
                {editIndex === index ? (
                  <input
                    value={newtargetdate}
                    onChange={(e) => setNewtargetdate(e.target.value)}
                  />
                ) : (
                  row.target_date
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <Select
                    id="demo-multiple-name"
                    // multiple
                    sx={{
                      width: "200px",
                      height: "30px",
                      fontSize: "10px",
                      fontFamily: "MontserratRegular",
                      "@media (max-width:768px)": {
                        width: "90px",
                      },
                    }}
                    value={newassign}
                    // onChange={handleChange}
                    onChange={(e) => setNewAssigned(e.target.value)}
                  >
                    {/* {task.map((row) => ( */}
                    <MenuItem value={row.staff_id} key={row.id}>
                      {row.first_name}
                    </MenuItem>
                    {/* ))} */}
                  </Select>
                ) : (
                  row.user.profile_detail?.first_name
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <Select
                    id="demo-customized-select"
                    sx={{
                      width: "120px",
                      height: "30px",
                      fontSize: "10px",
                      fontFamily: "MontserratRegular",
                      "@media (max9width:768px)": {
                        width: "47px",
                      },
                    }}
                    value={newstatus}
                    //   onChange={handleChange}
                    //   input={<BootstrapInput />}
                    onChange={(e) => setNewstatus(e.target.value)}
                  >
                    <MenuItem sx={Styles.dropMenu} value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem
                      sx={Styles.dropMenu}
                      value={"Completed"}
                      onChange={handleChange}
                    >
                      Completed
                    </MenuItem>
                    <MenuItem
                      sx={Styles.dropMenu}
                      value={"Not Completed"}
                      onChange={handleChange}
                    >
                      Not Completed
                    </MenuItem>
                    <MenuItem sx={Styles.dropMenu} value={"In Progress"}>
                      In Progress
                    </MenuItem>
                  </Select>
                ) : (
                  row.status
                )}
              </td>
              {/* <td>
              <img
                style={Styles.deleteIcon}
                src={require("../../images/cross.png")}
                onClick={() => handleDelete(index)}
              />
            </td> */}
            </tr>
          ))}
        </tbody>
        {opn && (
          <EditTaskPop
            Tasks={rowsdata}
            eventId={eventId}
            isEvent={isEvent}
            isforUpdate={isforUpdate}
            open={opn}
            setOpen={setopn}
          />
        )}
      </table>
    </>
  );
}

export default Checklist;

const Styles = {
  table: {
    position: "relative",
    width: "94%",
    overflow: "hidden",
    height: "120px",
    backgroundColor: "#FFFFFF",
    padding: "0px",
    left: " 9px",
    top: "47px",
    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    WebkitOverflowScrolling: "touch", // Example of a WebKit-specific property
    MozBoxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  header: {
    backgroundColor: "#FF0000",
    color: "#FFFFFF",
    border: "0px solid #FF0000",
    borderRadius: "10px",
    fontFamily: "MontserratRegular",
    // padding: "10px",
  },
  body: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    border: "0px solid white",
    overflow: "scroll",
  },
  row: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    borderWidth: 0,
    height: "10px",
    overflow: "scroll",
  },
  deleteIcon: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    position: "absolute",
    right: "0px",
  },
  addIcon: {
    width: 25,
    height: 25,
    // margin: 15,
    cursor: "pointer",
  },
  dropMenu: {
    fontFamily: "MontserratRegular",
    fontSize: "12px",
  },
};
const isFirefox = typeof InstallTrigger !== "undefined";

// Apply Firefox-specific styles
if (isFirefox) {
  Styles.table = {
    ...Styles.table,
    height: "20px",
    scrollSnapType: "y mandatory",
    overflowY: "scroll",
    scrollbarWidth: "thin",
  };
}
