import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import calender from "../../../.././images/EventManagement1/Calender.png";
import location from "../../../.././images/EventManagement1/Location.png";
import { useNhostClient } from "@nhost/react";
import { useNavigate } from "react-router-dom";
import nullimage from '../../../../images/nullImage.jpeg'

export default function MoreEventCard(props) {
  const { img, title, desc, date, time, address } = props;
  const nhost = useNhostClient();
  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: props.data.cover_img_path,
        });
        console.log("Image URL:", item);
        // setImgPreview(item)
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [props]);

  const dateObject = new Date(props.data.recurrance_start_date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const utcString = dateObject.toISOString();
  const formattedStartDate = `${formattedDate}`;

  const [isUser, setisUser] = useState(true);
  const navigate = useNavigate();

  const EventClick = (
    id,
    imgUrl,
    name,
    description,
    recurrance_start_date,
    recurrance_end_date,
    location,
    start_time,
    end_time,
    event_type,
    conven_meeting
  ) => {
    setisUser(true);
    // setmembership(true);
    let membership1 = true;
    console.log(
      "startdate enddate",
      recurrance_end_date,
      recurrance_start_date
    );
    navigate("/eventmanagement2", {
      state: {
        isUser: isUser,
        membership1,
        id,
        name,
        imgUrl,
        description,
        recurrance_start_date,
        recurrance_end_date,
        location,
        start_time,
        end_time,
        event_type,
        conven_meeting
      },
    });
  };

  return (
    <Box
      sx={styles.container}
      onClick={() =>
        EventClick(
          props.data.id,
          props.data.cover_img_path,
          props.data.name,
          props?.data.description,
          props.data.recurrance_start_date,
          props.data.recurrance_end_date,
          props.data.location,
          props.data.start_time,
          props.data.end_time,
          props?.data.event_type?.name,
          props?.data?.conven_meeting
        )
      }
    >
      {/* .................img container */}
      <Box sx={styles.imgContainer}>
        <img
          // src={imgUrl }
          src={ imgUrl && (!imgUrl.endsWith("null") || !imgUrl.endsWith(""))
            ? imgUrl
            : nullimage}
          alt="img"
          style={{
            width: "100%",
            height: "130px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        />
      </Box>
      {/* .................img container ends */}
      {/*.................text container */}
      <Box sx={styles.textContainer}>
        <Typography sx={styles.titleTxt}>{props.data.name}</Typography>
        <Typography sx={styles.desc}>{props?.data.description}</Typography>
        <Box sx={styles.dateTime}>
          <img
            style={{ width: "15px", height: "15px" }}
            src={calender}
            alt="calender"
          />
          <Typography
            sx={[styles.baseTxt, { marginRight: "20px", marginLeft: "2px" }]}
          >
            {formattedStartDate} CET
          </Typography>
          {/* <Typography sx={styles.baseTxt}>{props.data.start_time}</Typography> */}
        </Box>
        <Box sx={styles.dateTime}>
          <img
            style={{ width: "17px", height: "17px" }}
            src={location}
            alt="location"
          />
          <Typography sx={styles.baseTxt}>{props.data.location}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    width: "250px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "220px",
    margin: "10px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  imgContainer: {
    width: "250px",
    height: "120px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginLeft: "2px",
    width: "100%",
  },
  titleTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#252525",
    lineHeight: "20px",
    width: "90%",
    height: "15px",
    overflow: "hidden",
    marginBottom: "5px",
  },
  desc: {
    fontFamily: "MontserratRegular",
    fontSize: "9px",
    textAlign: "left",
    fontWeight: "400",
    color: "#414042",
    lineHeight: "14.63px",
    overflow: "hidden",
    width: "90%",
    height: "15px",
    marginBottom: "5px",
  },
  dateTime: {
    display: "flex",
    flexDirection: "row",
    // justifyContent:'space-between',
    alignItems: "center",
    width: "100%",
    marginBottom: "5px",

    // margin:'2px auto 2px auto',
  },
  baseTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "8px",
    fontWeight: "500",
    color: "#414042",
    lineHeight: "12.19px",
    width: "90%",
    overflow: "hidden",
  },
};
