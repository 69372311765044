import back from "../images/Space.png";
const Styles = {
  container: {
    height: "100vh",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "@media (max-width:768px)": {
      height: "100vh",
    },
  },
  box: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // verticalAlign: "middle",
  },
  form: {
    width: "400px",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "530px",
    borderRadius: "10px",
    "@media (max-width:768px)": {
      width: "280px",
      height: "470px",
    },
  },
  logoBox: {
    width: "100%",
    paddingTop: "25px",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: "45%",
    height: "45%",
  },
  headerBox: {
    width: "200px",
    margin: "auto",
  },
  errorMsg:{
    fontFamily:"MontserratRegular",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#ee3046",
  },
  header: {
    marginTop: "10px",
    fontFamily: "MontserratBold",
    fontSize: "20px",
    lineHeight: "29px",
    color: "#414042",
  },
  inputBox: {
    width: "70%",
    justifyContent: "center",
    margin: "auto",
  },
  input: {
    marginTop: "20px",
    width: "94%",
    height: "30px",
    background: "#FFFFFF",
    border: " 1px solid #999999",
    borderRadius: "10px",
    color: "#999999",
    fontFamily: "MontserratRegular",
    paddingLeft: "15px",
    fontSize: "12px",
  },
  btnBox: {
    width: "100%",
    marginTop: "20px",
  },
  footerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: 80,
    position: "relative",
    "@media (max-width:768px)": {
      // flexDirection:"column"
      left: 0,
    },
  },

  backButton: {
    top: 0,
    left: 100,
    width: "15px",
    height: "15px",
    cursor: "pointer",
  },
  footerText: {
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    marginLeft: "5px",
    color: "#252525",
    textAlign: "center",
    cursor: "pointer",
    "@media (max-width:768px)": {
      fontSize: "8px",
    },
  },
  otpinputBox:{ 
    width:"70%",
    // marginTop:"20px",
    margin:"auto"
  },
  otpBox: {
    fontFamily: "MontserratRegular",
    width: "27px",
    height: "35px",
    marginTop:"10px",
    marginBottom:"20px",
    background: "#FFFFFF",
    margin: "5px",
    textAlign: "center",
    border: " 0.5px solid #999999",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
  helperText: {
    marginTop:"10px",
    marginBottom:"10px",
    fontSize: "12px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#999999",
  },
};
export default Styles;
