import {React, useEffect, useState} from "react";
import { Box, Typography } from "@mui/material";
import userProfile from "../../../../../src/images/userProfile.png";
import { useNhostClient } from "@nhost/react";


export default function PreviewCard(props) {
  const { profile, name, header, desc, img } = props;

  // console.log("preview card data",props);


    // .....................fetch image from id .....................
    const [imgUrl, setImgUrl] = useState(null); // Initialize imgUrl as null
    const [isImgLoading, setIsImgLoading] = useState(true); // Initialize loading state
  
    const nhost = useNhostClient();

  useEffect(() => {
    async function setImage() {
      setIsImgLoading(true); // Set loading state to true

      if (img) {
        try {
          nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

          const publicUrl = await nhost.storage.getPresignedUrl({
            fileId: img,
          });

          // console.log("image url", publicUrl.presignedUrl.url);
          setImgUrl(publicUrl.presignedUrl.url);
        } catch (error) {
          console.error("Error fetching image:", error);
          setImgUrl(null); // Set imgUrl to null in case of an error
        }
      } else {
        setImgUrl(null); // Set imgUrl to null when img is null
      }

      setIsImgLoading(false); // Set loading state to false
    }

    setImage();
  }, [img]);
    // .....................fetch image from id ends.....................







  return (
    <Box sx={styles.upperpart}>
      <Box sx={styles.upperStrip}>
        {/* <img
          src={profile}
          width="35px"
          height="35px"
          style={{ marginLeft: "10px" }}
        /> */}
        <Typography sx={styles.name}>{name}</Typography>
      </Box>
      
      <Box sx={styles.scrollContainer}>
      <Box sx={styles.header}>
        <Typography sx={styles.headerTxt}>{header}</Typography>
      </Box>

      <Box sx={styles.description}>
        <Typography sx={styles.descTxt}>{desc}</Typography>
      </Box>

      <Box sx={styles.imgContainer}>
      {isImgLoading ? (
            <span>Loading...</span>
          ) : (
            <img src={imgUrl} alt="img" />
          )}
      </Box>
      </Box>
    </Box>
  );
}

const styles = {
  upperpart: {
    display: "flex",
    flexDirection: "column",
    width: "92%",
    borderRadius: "10px",
    justifyContent: "center",
    margin: "10px auto 10px auto",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  upperStrip: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    backgroundColor: "#999999",
    borderRadius: "10px 10px 0px 0px",
    alignItems: "center",
    marginTop: "0px",
  },
  name: {
    fontFamily: "MontserratMedium",
    fontSize: "14px",
    marginLeft: "10px",
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  headerTxt: {
    fontFamily: "MontserratMedium",
    fontSize: "16px",
    lineHeight: 2,
  },
  description: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "10px",
  },
  descTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "14px",
  },
  imgContainer: {
    width: "100%",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  scrollContainer:{
    height:'350px',overflowY:'scroll',overflowX:'hidden',
    scrollbarWidth:'0.3rem',
    scrollbarWidth: "auto",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }
};
