import { Box, IconButton, Typography } from "@mui/material";

import { React, useState, useRef, useEffect } from "react";

import Styles from "../../styles/eventManagement/EventManagement";

import EventCard from "../../components/eventManagement/EventCard";

import { useSelector } from "react-redux";

import EditEvent from "../Popup/EventManagement/EditEvent";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      activation_date

      closure_date

      created_at

      deactivation_date

      description

      id

      location

      metadata

      name

      recurrance_end_date

      recurrance_frequency

      recurrance_start_date

      type_id

      updated_at

      event_category {
        created_by

        created_date

        description

        id

        name
      }

      event_faculties {
        event_id

        faculty_type_id

        id

        user_id
      }

      event_type {
        created_by

        created_date

        description

        id

        name
      }
    }
  }
`;

const GET_RECURRING_EVENTS_QUERY = gql`
  query GetRecurringEvent($parent_id: bigint) {
    event_master(where: { parent_id: { _eq: $parent_id }},order_by: { recurrance_start_date: asc }) {
      category_id

      closure_date

      created_at

      deactivation_date

      description

      end_time

      id

      is_recurring

      location

      metadata

      name

      parent_id

      publish_to

      recurrance_end_date

      recurrance_frequency

      recurrance_start_date

      start_time

      event_category {
        created_by

        created_date

        description

        id

        name
      }

      event_faculties {
        event_id

        faculty_type_id

        id

        user_id
      }

      event_type {
        created_by

        created_date

        description

        id

        name
      }
    }
  }
`;

// const GET_FACILITATORROLEID_QUERY = gql`
//   query get_roles($_eq: bigint = "") {
//     facilitator_roles(where: { id: { _eq: $_eq } }) {
//       name
//     }
//   }
// `;

const GET_FACILITATOR_NAME = gql`
  query MyQuery3($_eq: bigint = "") {
    event_faculty(where: { event_id: { _eq: $_eq }, facilitator_role: {} }) {
      facilitator_role {
        name
      }

      profile_details {
        first_name
      }
    }
  }
`;

export default function DetailsBox(props) {
  const [role, setRole] = useState("");

  // .................parent event................................................................

  const [Events, setEvents] = useState([]);

  // console.log("api ............. by api", Events);

  const { loading, error, data } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      id: props.id,
    },

    skip: !props.id,
  });

  useEffect(() => {
    setEvents(data?.event_master[0]);

    // setRole(data?.event_master[0].event_faculties[0]?.faculty_type_id);
  }, [data?.event_master[0]]);

  // .................parent event................................................................

  const [FacilitatorsRole, setFacilitatorsRole] = useState([]);

  // console.log("api ............. by facilitator id", FacilitatorsRole);

  // const { data: facilitatorid } = useQuery(GET_FACILITATORROLEID_QUERY, {
  //   variables: {
  //     _eq: role,
  //   },
  // });

  // useEffect(() => {
  //   setFacilitatorsRole(facilitatorid?.facilitator_roles);

  //   // console.log("roles",facilitatorid?.facilitator_roles)
  // }, []);

  let faculties = Events?.event_faculties;

  const [roles, setRoles] = useState([]);

  const {
    loading: facilitatorsLoading,
    error: facilitatorsErr,
    data: facilitatorsData,
    isError: facilitatorsIsErr,
  } = useQuery(GET_FACILITATOR_NAME, {
    variables: {
      _eq: props.id,
    },
  });

  useEffect(() => {
    setRoles(facilitatorsData?.event_faculty);

    // console.log("facultiesssssssssss",facilitatorsData?.event_faculty)
  }, [facilitatorsData?.event_faculty]);

  // .................recurring  event api starts................................................................

  const [RecurringEvents, setRecurringEvents] = useState([]);

  const {
    loading: recLoading,
    error: recErr,
    data: recData,
    isError: recDataIsErr,
  } = useQuery(GET_RECURRING_EVENTS_QUERY, {
    variables: {
      parent_id: props.id,
    },
  });

  if (recDataIsErr) {
    // console.log("error of rec",recErr);
  }

  // console.log("event_id",props.id);

  useEffect(() => {
    setRecurringEvents(recData?.event_master);

    // console.log("rec",recData);
  }, [recData?.event_master]);
  const [openpop, setOpenpop] = useState(false);

  const editEvents = () => {
    setOpenpop(!openpop);
  };

  const cardContainerRef = useRef(null);

  const scrollLeft = () => {
    const cardContainer = cardContainerRef.current;

    cardContainer.scrollLeft -= 100; // adjust the value as per your requirement
  };

  const scrollRight = () => {
    const cardContainer = cardContainerRef.current;

    cardContainer.scrollLeft += 100; // adjust the value as per your requirement
  };

  return (
    <Box sx={Styles.detailsBox}>
      {/* Left Side Box */}

      <Box sx={Styles.firstLeftBox}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Box sx={Styles.descriptionBox}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img alt="" src={require("../../images/Description.png")} />

              <Typography sx={Styles.lowerHeader}>Description</Typography>
            </Box>

            <Typography numberOfLines={2} sx={Styles.text}>
              {Events?.description ? Events?.description : props.description}
            </Typography>
          </Box>
        </Box>

        <Box sx={Styles.facilitators}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img alt="" src={require("../../images/User.png")} />

            <Typography sx={Styles.lowerHeader}>
              Facilitators Details
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ margin: "10px 30px", marginRight: "0px" }}>
              {/* <Typography sx={Styles.text}>Trainer</Typography>

              <Typography sx={Styles.text}>Facilitator</Typography>

              <Typography sx={Styles.text}>Concsious Creator </Typography> */}

              {roles?.map((role) => (
                <Typography key={role.name} sx={Styles.text}>
                  {role?.facilitator_role?.name}
                </Typography>
              ))}
            </Box>

            <Box sx={{ margin: "10px 30px", marginRight: "0px" }}>
              {/* <Typography sx={Styles.text}>ESTHER DE KRUIJK</Typography>

              <Typography sx={Styles.text}>MICHAEL DAWKINS</Typography>

              <Typography sx={Styles.text}>MARTIJN VAN EIJK</Typography> */}

              {roles?.map((role) => (
                <Typography key={role.displayName} sx={Styles.text}>
                  {role?.profile_details?.first_name}
                </Typography>
              ))}

              {/* {UserName.map((user) => (

          <Typography key={user.displayName} sx={Styles.text}>

            {user.displayName}

          </Typography>

        ))} */}
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <Box sx={Styles.Attendance}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img alt="" src={require("../../images/Notifications.png")} />

              <Typography sx={Styles.lowerHeader}>
                Attendance Capacity?
              </Typography>
            </Box>

            <Box sx={{ marginLeft: "30px" }}>
              <Typography sx={Styles.text}>
                Max Seats- {Events?.metadata?.max_attendance}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Right Side Box */}

      <Box sx={Styles.firstRightBox}>
        {/* Box For Horizontal 3 Boxes */}

        <Box sx={Styles.horizontal}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img alt="" src={require("../../images/Calender.png")} />

              <Typography sx={Styles.lowerHeader}>Event Type</Typography>
            </Box>

            <Typography sx={Styles.text}>{Events?.event_type?.name}</Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img alt="" src={require("../../images/Calender.png")} />

              <Typography sx={Styles.lowerHeader}>Event Category</Typography>
            </Box>

            <Typography sx={Styles.text}>
              {Events?.event_category?.name}
            </Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img alt="" src={require("../../images/Hours.png")} />

              <Typography sx={Styles.lowerHeader}>Duration</Typography>
            </Box>

            <Typography sx={Styles.text}>
              {Events?.metadata?.duration}
            </Typography>
          </Box>

          {/* {(props.isUser === "admin" || props.isUser === null) && (

            <IconButton aria-label="edit" onClick={editEvents}>

              <img src={require("../../images/edit.png")} alt="Edit" />

            </IconButton>

          )} */}
        </Box>
        {RecurringEvents && RecurringEvents.length > 0 && (

<Box>
  
        <Box
          sx={{
            marginTop: "25px",
            "@media (max-width:768px)": {
              marginLeft: "15px",
            },
          }}
        >
          <Box
            sx={{ marginLeft: "10px", display: "flex", flexDirection: "row" }}
          >
            <img alt="" src={require("../../images/Calender.png")} />

            <Typography sx={Styles.lowerHeader}>Recurring Events</Typography>
          </Box>
        </Box>

        <img
          alt=""
          onClick={scrollLeft}
          style={Styles.leftArrow}
          src={require("../../images/left.png")}
        />
    <img
          alt=""
          onClick={scrollRight}
          style={Styles.rightArrow}
          src={require("../../images/right.png")}
        />
        <Box sx={Styles.cardBox} ref={cardContainerRef}>
          <Box sx={Styles.eventCards}>
            {RecurringEvents?.map((item, index) => (
              <EventCard
                key={item.id}
                id={item.id}
                image={props.image}

                day={item.recurrance_start_date}
                startTime={item?.start_time}
                endTime={item?.end_time}
              />
            ))}
          </Box>
        </Box>

      
      </Box>
      )}
      </Box>
        

      {openpop && (
        <EditEvent
          id={props.id}
          facultyid={props.faculties.id}
          open={openpop}
          attendanceCapacity={Events.metadata?.max_attendance}
          duration={Events.metadata?.duration}
          description={Events?.description}
          event_type={Events.event_type?.name}
          event_category={Events.event_category?.name}
          setOpenpop={setOpenpop}
          name={props.name}
        />
      )}
    </Box>
  );
}
