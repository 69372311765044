//import * as React from "react";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BtnRed from "../../components/AdminFeed/BtnRed";
import { useFileUpload, useNhostClient } from "@nhost/react";

import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addTask } from "../../Redux/features/UserSlice";
import profile from "../../images/Profile.jpg";
import { gql, useQuery,useMutation } from '@apollo/client'

const UPDATE_FEED = gql`
mutation update_feed($id: bigint = "", $cover_img_path: String = "", $default_feed: Boolean = false, $description: String = "", $end_date: date = "", $header_name: String = "",  $start_date: date = "", ) {
  update_feed_management(where: {id: {_eq: $id}}, _set: {cover_img_path: $cover_img_path, default_feed: $default_feed, description: $description, end_date: $end_date, header_name: $header_name, start_date: $start_date,}) {
    returning {
      cover_img_path
      default_feed
      description
      end_date
      header_name
      start_date
      status
    }
  }
}
`;

const GET_FEED_QUERY = gql`
query GetAllFeed {
  feed_management(
    order_by: [{ default_feed: desc }, { id: desc }], where: {status: {_eq: true}}
  ) {
    cover_img_path
    default_feed
    description
    end_date
    header_name
    start_date
    id
  }
}
`

export default function EditFeed(props) {
  // console.log(props);
  const {
    add,

    upload,

    cancel,

    isUploaded,

    isUploading,

    isError,

    progress,

    id: cover_img_path,

    bucketId,

   
  } = useFileUpload();
  const { name } = props;
  const [updateFeed] = useMutation(UPDATE_FEED,{
    onCompleted:(data)=>{
      console.log('object',data )
      props.onFeedUpdated(); // Call the callback to trigger data refresh
      // ... Other actions after update ...
    },
    onError:(error)=>{
      console.log(error,"inseting error")
    },
    refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
       { query: GET_FEED_QUERY}
    ],


  })
  const [open, setOpen] = React.useState(false);
  const [drop, setDrop] = React.useState();
  const [drop2, setDrop2] = React.useState();

  const openpop = () => {
    setDrop(true);
    setDrop2(false);
  };
  const openpop2 = () => {
    setDrop2(true);
    setDrop(false);
  };

  const handleClickOpen = () => {
    props.setEditFeedOpen(true);
  };
  const handleClickclose = () => {
    props.setEditFeedOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };

  const [errors, setErrors] = useState({});
  const handleClose = async () => {
    console.log(data);
    // const datas = {
    //   taskName:data.taskName,
    //   taskCategory:data.category,
    //   taskEventName:data.event,
    //   taskGroupName:data.group,
    //   taskDesc:data.desc,
    //   targetDate:date.targetDate,
    //   assignStaff:personName
    // }
    // dispatch(addTask(datas))

    const newErrors = {};
    // Validate displayName
    if (!data.taskName) {
      newErrors.taskName = "Taskname is required";
    }
    //  console.log("displayName");

    if (!data.category) {
      newErrors.category = "Category is required";
    }
    if (personName.length === 0) {
      newErrors.personName = "staff  is required";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
    }
  };

  const handleCheckboxChange = (e) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      default_feed: e.target.checked,
    }));
  };


      // .....................fetch image from id .....................
      const [imgUrl, setImgUrl] = useState('')
      useEffect(() => {
  
        async function setImage() {
    
          // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
          // nhost.storage.setAccessToken(res1.fileMetadata.id)
    
          // console.log(cover_img_path)
          try {
            const item = await nhost.storage.getPublicUrl({
              fileId: props.img,
            });
            console.log("Image URL:", item);
      // setImage(item)
      // setImgUrl(item);
      setImgUrl(item)

    
      
          } catch (error) {
            console.error("Error retrieving image URL:", error);
          }
    
          // const publicUrl = await nhost.storage.getPresignedUrl({
          //   fileId: props.img,
          // })
    
          // console.log(publicUrl)
    
        }
    
        setImage()
    
      }, [])
      // .....................fetch image from id ends.....................
  


  const [image, setImage] = React.useState(null);
  const [coverImageId, setCoverImageId] = useState("");
  const nhost = useNhostClient();

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let file = event.target.files[0];
      console.log("feed image upload link",file)
      setImage(URL.createObjectURL(file))
      await upload({ file });
      // const fd = new FormData();

      // fd.append("file", image);

      // let res1 = await nhost.storage.upload({ formData: fd });

      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
      // nhost.storage.setAccessToken(res1.fileMetadata.id);
      // const accessToken = nhost.auth.getAccessToken();

      // const publicUrl = await nhost.storage.getPresignedUrl({
      //   fileId: res1.fileMetadata.id,
      // });

      // // setImage(publicUrl.presignedUrl.url);

      // setCoverImageId(res1.fileMetadata.id);


      // console.log("image",publicUrl.presignedUrl.url);
    };
    input.click();
  };

  const [docUpload, setDocUpload] = useState(true);

  if (isUploaded && docUpload) {
    console.log("done doen", cover_img_path, name);

    // handleContentManagement(cover_img_path);

    setDocUpload(false);
  }

  const [groupType, setgroupType] = React.useState([]);
  const [Events, setEvents] = React.useState([]);
  const [staff, setStaff] = React.useState([]);

  const post = async () => {
    console.log("object for updatae" , updatedData,cover_img_path);
    // console.log("img", coverImageId);
    const newErrors = {};
    try {
      const { data:newData } = await updateFeed({
        variables: {
          id: props.id,
          start_date: updatedData.startDate,
          header_name: updatedData.name,
          description: updatedData.description,
          end_date: updatedData.endDate,
          default_feed: updatedData.default_feed,
          cover_img_path: cover_img_path ? cover_img_path : props.img,
        },
         });
        //  console.log("last",updatenew)
          //  props.setEditFeedOpen(false);
          props.setEditFeedOpen(false);

          props.onFeedUpdated(); // Call the callback to trigger data refresh
          alert('Post Saved!');
    } catch (error) {
      console.error('GraphQL Mutation Error:', error.message);
    }
    // Validate name
    //   if (!data.name) {
    //     newErrors.name = "Name is required";

    //   }
    // //  console.log("name");

    //   if (!data.startDate) {
    //     newErrors.startDate= "startDate is required";
    //   }
    //  // console.log("startDate");
    //   if (!data.endDate) {
    //     newErrors.endDate = "endDate is required";

    //   }
    //  console.log("endDate");
    // dispatch(
    //   feed({
    //     // feedName,
    //     // feedDescription,
    //     // feedCover,
    //     // feedStartDate,
    //     // feedEndDate
    //   })
    // );
    // setErrors(newErrors)
    // if (Object.keys(newErrors).length === 0) {
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "x-hasura-admin-secret",
    //   process.env.REACT_APP_NHOST_SECREATEKEY
    // );
    // myHeaders.append("Access-Control-Allow-Credentials", true);
    // myHeaders.append("Access-Control-Allow-Origin", "*");

    // myHeaders.append(
    //   "Access-Control-Allow-Methods",
    //   "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    // );
    // myHeaders.append(
    //   "Access-Control-Allow-Headers",
    //   "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    // );

    // // console.log(data['description'])

    // console.log("Hi : ", data["default_feed"]);

    // var graphql = JSON.stringify({
    //   query: `mutation update_feed($id: bigint = "", $cover_img_path: String = "", $default_feed: Boolean = false, $description: String = "", $end_date: date = "", $header_name: String = "",  $start_date: date = "", ) {
    //     update_feed_management(where: {id: {_eq: $id}}, _set: {cover_img_path: $cover_img_path, default_feed: $default_feed, description: $description, end_date: $end_date, header_name: $header_name, start_date: $start_date,}) {
    //       returning {
    //         cover_img_path
    //         default_feed
    //         description
    //         end_date
    //         header_name
    //         start_date
    //         status
    //       }
    //     }
    //   }`,
    //   variables: {
    //     id: props.id,
    //     start_date: updatedData.startDate,
    //     header_name: updatedData.name,
    //     description: updatedData.description,
    //     end_date: updatedData.endDate,
    //     default_feed: updatedData.default_feed,
    //     cover_img_path: image ? coverImageId : props.img,
    //   },
    // });
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: graphql,
    //   redirect: "follow",
    //   // mode: "no-cors",
    // };

    // const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    // const data1 = await res.json();
    // console.log("sds", data1);

    // if (data["default_feed"] === true) {
    //   var myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append(
    //     "x-hasura-admin-secret",
    //     process.env.REACT_APP_NHOST_SECREATEKEY
    //   );
    //   myHeaders.append("Access-Control-Allow-Credentials", true);
    //   myHeaders.append("Access-Control-Allow-Origin", "*");

    //   myHeaders.append(
    //     "Access-Control-Allow-Methods",
    //     "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    //   );
    //   myHeaders.append(
    //     "Access-Control-Allow-Headers",
    //     "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    //   );

    //   // console.log(data['description'])

    //   console.log("Hi : ", data["default_feed"]);

    //   var graphql = JSON.stringify({
    //     query: `mutation MakeDefaultStatus($id: bigint = "") {
    //     update_feed_management(where: {id: {_neq: $id}}, _set: {default_feed: false}) {
    //       returning {
    //         default_feed
    //       }
    //     }
    //   }`,
    //     variables: {
    //       // default_feed: data["default_feed"],
    //       id: data1.data.update_feed_management.returning[0].id,
    //     },
    //   });
    //   var requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: graphql,
    //     redirect: "follow",
    //     // mode: "no-cors",
    //   };

    //   const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    //   const data2 = await res.json();
    //   console.log("sds", data2);
    // }
    // props.setEditFeedOpen(false);
    // alert("Post Saved!");

    // window.location.reload();


    // }
  };

  const [personName, setPersonName] = React.useState([]);

  const handleChangeMember = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(personName);
  };

  const dispatch = useDispatch();

  const [data, setData] = React.useState({});
  
  const [updatedData, setUpdatedData] = useState({
    name: props.name,
    description: props.desc,
    startDate: props.sdate,
    endDate: props.edate,
    img:cover_img_path?cover_img_path: props.img,
    default_feed: props.default_feed,
  });

  // console.log("up",updatedData);

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const [date, setDate] = React.useState(new Date());
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setUpdatedData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  return (
    <div>
      <Dialog
        maxWidth="xl"
        sx={Styles.popup}
        open={props.open}
        onClose={handleClickclose}
      >
        <Box sx={Styles.header}>
          <img
            style={{ width: "79px", height: "25",marginLeft:25 }}
            src={require("../../images/navLogo.png")}
          />
          <DialogActions>
            <Button
              onClick={handleClickclose}
              sx={{ font: 30, color: "#FFFFFF" ,}}
            >
              Close
            </Button>
          </DialogActions>
        </Box>

        <DialogContent>
          <Box sx={Styles.mainBox}>
            <Box sx={Styles.firstBox}>
              {/* ...................................................name and description........................ */}
              <p>Header Name</p>

              <input
                style={Styles.headerInput}
                type="text"
                placeholder={name}
                value={updatedData.name}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                }}
                error={!!errors.name}
                helperText={errors.name}
              />

              <p>Description</p>

              <textarea
                style={Styles.descriptionInput}
                type="text"
                placeholder={props.desc}
                value={updatedData.description}
                onChange={(e) => {
                  setUpdatedData((prevData) => ({
                    ...prevData,
                    description: e.target.value,
                  }));
                }}
              />

              {/* ...................................................name and description........................ */}
            </Box>
            {/* .................................................dates........................................... */}
            <Box sx={Styles.secondBox}>
              <Typography style={Styles.header1}>Start Date :</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // inputFormat="MM/DD/YYYY"
                  format="YYYY/MM/DD"
                  size="small"
                  label={props.sdate}
                  sx={{
                    width: "275px",
                    marginBottom: "30px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",
                      height: "40px",
                    },
                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",
                      color: "#999999",
                      fontSize: "14px",
                      lineHeight: "10px",
                    },
                  }}
                  value={data.startDate}
                  onChange={(e) => {
                    handleDateChange("startDate", e);
                  }}
                  error={!!errors.startDate}
                  helperText={errors.startDate}
                />
              </LocalizationProvider>

              <Typography style={Styles.header1}>End Date :</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // inputFormat="MM/DD/YYYY"
                  format="YYYY/MM/DD"
                  size="small"
                  label={props.edate}
                  sx={{
                    width: "275px",
                    marginBottom: "30px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",
                      height: "40px",
                    },
                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",
                      color: "#999999",
                      fontSize: "14px",
                      lineHeight: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleDateChange("endDate", e);
                  }}
                  value={data.endDate}
                  error={!!errors.endDate}
                  helperText={errors.endDate}
                />
              </LocalizationProvider>

              <div>
                <Checkbox
                  id="check"
                  checked={data.default_feed}
                  value={updatedData.default_feed}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#49454F",
                    "&.Mui-checked": {
                      color: "#FF0000",
                    },
                  }}
                />
                <label
                  for="check"
                  style={{
                    fontFamily: "MontserratLite",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  Make this feed as default
                </label>
              </div>

              {/* .................................................dates........................................... */}
              {/* .................................................image........................................... */}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {image ? (
                  <img style={Styles.img} src={image} alt="new" />
                ) : (
                  <img style={Styles.img} src={imgUrl} alt="previous" />
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button style={Styles.uploadInput} onClick={handleImageClick}>
                    Upload Image
                  </button>

                  {/* <BtnRed btn_title="Post" click={post} /> */}
                  <button style={Styles.uploadInput} onClick={post}>
                    Post
                  </button>
                </Box>
              </Box>
              {/* .................................................image........................................... */}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const Styles = {
  header: {
    // width:"100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FF0000",
  },
  header1: {
    fontFamily: "MontserratSemibold",
    marginBottom: "10px",
  },
  img: {
    height: "200px",
    width: "70%",
    marginTop: 10,
  },
  popup: {
    borderRadius: "15px",
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems:"center",
    width: "800px",
    minWidth: "400px",
  },
  input: {
    width: "100%",
    marginTop: "18px",

    height: "40px",
    // backgroundColor:"#FF0000",
    borderRadius: "5px",
    fontFamily: "MontserratRegular",
  },
  firstBox: {
    width: "49%",
  },
  secondBox: {
    width: "49%",
  },
  description: {
    width: "100%",
    height: "60%",
    marginTop: "15px",
    marginBottom: "15px",
    fontFamily: "MontserratRegular",
    border: "1px solid grey",
  },
  fileUpload: {
    marginLeft: "10px",
    backgroundColor: "#D9D9D9",
    border: "1px solid #888888",
    borderRadius: "5px",
    fontFamily: "MontserratRegular",
  },
  date: {
    textAlign: "start",
    width: "100%",
    height: "40px",
    marginTop: "15px",
    color: "#999999",
    background: "#FFFFFF",
    border: " 1px solid #999999",
    borderRadius: "5px",
  },
  assignBtn: {
    // width: "100%",
    height: "40px",
    marginTop: "15px",
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    fontFamily: "MontserratRegular",
  },
  headerInput: {
    fontFamily: "MontserratLite",
    height: " 35px",
    paddingLeft: " 15px",
    width: "340px",
    border: "1px solid #999999",
    borderRadius: "10px",
  },
  inputDiv: {
    width: "50%",
    height: " 100%",
    paddingLeft: "25px",
  },
  descriptionInput: {
    resize: "none",
    fontFamily: "MontserratLite",
    height: "308px",
    paddingLeft: "15px",
    paddingTop: "15px",
    width: " 340px",
    border: "1px solid #999999",
    borderRadius: "10px",
  },
  uploadInput: {
    border: "1px solid #FF0000",
    fontFamily: "MontserratRegular",
    height: " 35px",
    width: "170px",
    backgroundColor: "#FF0000",
    color: "#ffffff",
    borderRadius: "10px",
    margin: "10px",
    marginLeft: "0px",
    top: 20,
  },
};