import { Box, Button, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";
// import styles from "../../styles/global";
import Doc1 from "./Doc1.png";
import Doc2 from "../../images/blankContent.jpeg";
import plusicon from "./plusicon.png";
import { useSelector } from "react-redux";
import { allEventsData } from "../../Redux/features/UserSlice";
import { useDispatch } from "react-redux";
import { useNhostClient } from "@nhost/react";
import { useRef } from "react";
import {  useAccessToken, useFileUpload, useFileUploadItem, useMultipleFilesUpload,id, useSignOut, useAuthenticationStatus } from '@nhost/react'
import { gql, useQuery, useMutation } from "@apollo/client";


const INSERT_COVER_IMAGE = gql`
mutation UpdateEventCoverImg($id: bigint = "", $cover_img_path: String = "", $publish_status: String ="published") {
  update_event_master(where: {id: {_eq: $id}}, _set: {cover_img_path: $cover_img_path,publish_status: $publish_status}) {
    returning {
      cover_img_path
      id
    }
  }
}
`;
const INSERT_DOCUMENT = gql`
mutation AddContent($associated_event_id: bigint, $associated_group_id: bigint, $file_name: String = "", $link: String = "", $published_by: String = "",  $type: String = "",  $uploaded_by: String = "") {
  insert_content_master(objects: {associated_event_id: $associated_event_id, associated_group_id: $associated_group_id,  file_name: $file_name, link: $link, published_by: $published_by, type: $type,  uploaded_by: $uploaded_by}) {
    returning {
      associated_event_id
      associated_group_id
      category_id
      expiry_date
      file_name
      id
      link
      published_by
      publishing_date
      type
      upload_date
      uploaded_by
    }
  }
}
`;

const UPDATE_COVER_IMAGE = gql`
mutation MyMutaduosdhnsstion13($cover_img_path: String = "", $_eq: bigint = "") {
  update_event_master(where: {parent_id: {_eq: $_eq}}, _set: {cover_img_path: $cover_img_path}) {
    returning {
      cover_img_path
    }
  }
}`
export default function SpocForm(props) {
const inputRef = useRef(null);
const {
  add,
  upload,
  cancel,
  isUploaded,
  isUploading,
  isError,
  progress,
  id:coverImage,
  bucketId,
  name,
} = useFileUpload();
  const [formData, setFormData] = useState({});
  const [numFields, setNumFields] = useState(0);
  const [obj, setObj] = useState({});
  const dispatch = useDispatch();
  const nhost = useNhostClient();
  const [insertCoverImage] = useMutation(INSERT_COVER_IMAGE,{
    onCompleted:(data)=>{
      console.log("last data updated euuuu")
    }
  })
  const [updateimage] = useMutation(UPDATE_COVER_IMAGE, {
    onCompleted: (data) => {
      // console.log("document and image data updated");
    },
  });
  // const [numFields, setNumFields] = useState(1);

  const fields = [];
  for (let i = 0; i < numFields; i++) {
    fields.push(
      <>
      {/* <Button
        sx={{
          width: "360px",
          m: 1,
          mx: 5,
          minWidth: "360px",
          fontFamily: "MontserratRegular",
          color: "black",
          border: 1,
        }}
        component="label"
        onChange={(e) => onChangeDocs2(e)}
        id="standard-required"
      >
        {" "}
        Choose File{" "}
        <input
          type="file"
          accept=".docx , .pdf ,.doc , .csv"
          placeholder="Select Image"
          hidden
        />
      </Button> */}
      </>
    );
  }
  console.log("fields docs :", fields);

  const handleNext = () => {
    // console.log(props.data);
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [coverimage, setCoverImage] = useState();
  const [iscoverimage, setiscoverimage] = useState(false);
  const [isdocument, setisdocument] = useState(false);
  const [isdocument2, setisdocument2] = useState(false);

  const eventData = useSelector((state) => state.user.user);

  useEffect(() => {
    setObj(eventData);
    console.log("GRP:  ", eventData);
  }, [eventData]);

  const addData = (e) => {
    console.log(formData);
    // props.setData([...props.data, formData]);
    // console.log(props.data);
    var isValid = /\.jpe?g$/i.test(setCoverImage);
    if (!isValid) {
      handleNext();
    } else {
      alert("enter cover image");
    }
    const newObj = {
      ...obj,
      cover: coverimage,
    };
    console.log("Check State : " + JSON.stringify(newObj));

    dispatch(allEventsData(newObj));
  };

  const uploadImage = async (coverImage) => {

    try {
      const { data:insertedData } = await insertCoverImage({
        variables: {
          id: localStorage.getItem("event_id"),
          cover_img_path: coverImage,
        },
      });
      console.log("instered group header info",insertedData)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }  
    try {
      const { data: insertedData } = await updateimage({
        variables: {
          _eq: localStorage.getItem("event_id"),
          cover_img_path: coverImage,
        },
      });
      // console.log("instered event cover img ",insertedData);
    } catch (error) {
      // console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const [image, setImage] = useState(null);
  // console.log("wharf image is thias",image)
  const [coverImageId, setCoverImageId] = useState("");
  const [forCoverimg , setForCoverimg] = useState(false)
  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let file = event.target.files[0];
      await upload({ file })
      setForCoverimg(true)

      setImage(file);
      setCoverImageId(URL.createObjectURL(file));
 };
    input.click();
  };


  const [insertDocuments] = useMutation(INSERT_DOCUMENT,{
    onCompleted:(data)=>{
      console.log("last documents updated euuuu")
    }
  })


  const handleContentManagement = async (coverImage) => {
    console.log(coverImage , 'for Document')
    try {
      const { data:insertedData } = await insertDocuments({
        variables: {
          associated_event_id: localStorage.getItem("event_id"),
        link: coverImage,
        file_name: docName,
        },
      });
      console.log("instered group header info",insertedData)
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }  
 };

  const [selectedPdf, setSelectedPdf] = useState([]);
  const [forDocument , setForDocument]= useState(false)
const [docName , setDocName] = useState('')
// console.log("this is doc name: " + docName);

  const handleDocUpload = (e) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*/*";
    input.onchange = async (event) => {
      nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let file = event.target.files[0];
      // console.log('document name is ',file.name)
      setDocName(file.name)
      await upload({ file })
      setForDocument(true)
      setImage(URL.createObjectURL(file));
      // setSelectedPdf(file.name); 
      alert(file.name+ " uploading");

    };
    input.click();
  };
  
  if(isUploaded && (forCoverimg || forDocument)){
    console.log('cover image path is' , coverImage)
    if(forCoverimg){
      console.log("uploading image" ,forCoverimg)
      uploadImage(coverImage);
      setForCoverimg(false)
    }
    if(forDocument){
      handleContentManagement(coverImage)
      setForDocument(false)
      setisdocument(true)
    }
       }

  const onChangeDocs2 = (e) => {
    setisdocument2(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "800px",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontFamily: "MontserratRegular" }}>
        Upload Content
      </Typography>
     
      <Box sx={{ border: 1, borderRadius: 5, margin: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            my: 2,
            borderBottom: "black",
            borderRadius: 3,
          }}
        >
          <Typography sx={{ mx: 2, fontFamily: "MontserratRegular" }}>
            {" "}
            Cover Image
          </Typography>
          {image && (
            <Box>
              <img src={coverImageId} height={50} width={50} />
            </Box>
          )}
          <Button
            sx={{
              width: "360px",
              m: 1,
              height: 30,
              minWidth: "360px",
              border: 1,
            }}
            component="label"
            placeholder="Select Image"
            // onChange={(e) => handleImageClick(e)}
            onClick={handleImageClick}
            id="standard-required"
          >
            Choose Image
            {/* <input type="file" accept="image/*" placeholder="Select Image" hidden /> */}
          </Button>
          <Typography sx={{ fontFamily: "MontserratRegular" }}>
        Image size 358x135px
      </Typography>
        </Box>

        <div
          style={{
            flex: 1,
            backgroundColor: "grey",
            height: "1px",
            marginBottom: 2,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderColor: "grey",
            borderRadius: 5,
          }}
        >
          <Box sx={{ mx: 2, my: 4 }}>
            <Typography sx={{ fontFamily: "MontserratRegular" }}>
              Contents
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mx: 4,
              }}
            >
              {isdocument && (
                <Box>
                  <img src={image} height={50} width={50} />
                </Box>
              )}
              <Button
                sx={{
                  width: "360px",
                  m: 1,
                  minWidth: "360px",
                  fontFamily: "MontserratRegular",
                  color: "black",
                  border: 1,
                }}
                component="label"
                // onChange={(e) => handleDocUpload(e)}
                onClick={() => handleDocUpload(inputRef.current)}
                id="standard-required"
              >
                {" "}
                Choose File{" "}
                {/* <input
                  ref={inputRef}
                  type="file"
                  accept=".docx , .pdf ,.doc , .csv"
                  placeholder="Select Image"
                  hidden
                /> */}
              </Button>
              {/* <p>Selected PDF: {selectedPdf}</p> */}

            </Box>

            {fields}
          </Box>
        </Box>
        <Box>
       
      </Box>
        {/* <Typography>{selectedPdf}</Typography> */}
        <Button
          sx={{ width: "100%", justifyContent: "flex-end" }}
          onClick={() => {
            setNumFields(numFields + 1);
            handleDocUpload(inputRef.current); // Upload a new file when plusicon button is clicked
          }}
        >
          <img src={plusicon} alt="" height={30} width={30} />
        </Button>

        
      </Box>

      <Box sx={{ display: "flex", width: "94%", justifyContent: "flex-end" }}>
        <Button
          onClick={addData}
          variant="contained"
          sx={{
            ml: 2,
            width: "100px",
            color: "#FFFFFF",
            backgroundColor: "#FF0000",
            fontFamily: "MontserratRegular",
          }}
          // sx={[styles.btn, styles.textField]}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}