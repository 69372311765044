import { Box, Typography } from "@mui/material";
import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FullRedButton from "../components/AdminFeed/FullRedButton";
import Styles from "../styles/ForgotPassword";
// import { useResetPassword } from "@nhost/react";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleNewPasswordChange = (event) => {
    const { value } = event.target;
    setNewPassword(value);
    setIsValid(value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPassword(value);
    setIsValid(value === newPassword);
  };

  return (
    <Box sx={Styles.container}>
      <Box sx={Styles.box}>
        <Box sx={Styles.form}>
          <Box sx={Styles.logoBox}>
            <img
              style={Styles.logo}
              src={require("../images/AWAKEORIGINS.png")}
            />
          </Box>
          <Box sx={Styles.headerBox}>
            <Typography sx={Styles.header}>Reset Password</Typography>
          </Box>
          <Box sx={Styles.inputBox}>
            <input
              style={Styles.input}
              placeholder="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <input
              style={Styles.input}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {isValid ? (
              <p style={{ color: "green",fontFamily:"MontserratRegular",fontSize:"14px" }}>Passwords match</p>
            ) : (
              <p style={{ color: "red",fontFamily:"MontserratRegular",fontSize:"14px" }}>* Passwords do not match</p>
            )}
            <Box sx={Styles.btnBox}>
              <FullRedButton
                title="Set Password"
                click={() => {
                  navigate('/');
                }}
              />
            </Box>
          </Box>
          <Box sx={Styles.footerBox}onClick={() => {
                  navigate("/");
                }}>
            <img
              style={Styles.backButton}
              src={require("../images/BackButton.png")}
            />
            <Typography style={Styles.footerText}>Go To Login Page</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
