import { Box, Checkbox, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { React, useEffect, useMemo, useRef, useState } from "react";
import search from "../../images/search.png";
import menu from "../../images/menu.png";
import share from "../../images/Share.png";
import { useLocation, useNavigation } from "react-router-dom";
import { useNhostClient } from "@nhost/react";
import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";
import { useSubscription, gql, useMutation, useQuery } from "@apollo/client";
import ChatImage from "./ChatImage";
import ImagePopUp from "./ImagePopUp";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon from your library
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  redCheckbox: {
    "&.Mui-checked": {
      color: "red",
    },
  },
});

const SUBSCRIPTION_QUERY = gql`
  subscription MyQuery2($_eq: uuid = "", $_eq1: uuid = "") {
    group_chatn(
      where: {
        sender_id: { _eq: $_eq }
        _and: {
          participent: { _eq: $_eq1 }
          _and: { status: { _eq: "active" } }
        }
      }
    ) {
      chat_content
      contents
      id
      participent
      sender_id
      group_id
      timestamp
    }
  }
`;
const DELETE_MESSAGE_QUERY = gql`
  mutation MyMutation6($_eq: bigint, $status: String = "inActive") {
    update_group_chatn(
      where: { id: { _eq: $_eq } }
      _set: { status: $status }
    ) {
      returning {
        id
        chat_content
        status
      }
    }
  }
`;
const SUBSCRIPTION_QUERY1 = gql`
  subscription personal_chat($_eq: uuid = "", $_eq1: uuid = "") {
    group_chatn(
      where: {
        sender_id: { _eq: $_eq }
        _and: {
          participent: { _eq: $_eq1 }
          _and: {
            chat_content: { _is_null: false }
            _and: { status: { _eq: "active" } }
          }
        }
      }
    ) {
      participent
      sender_id
      id
      chat_content
      timestamp
    }
  }
`;

const SUBSCRIPTION_GROUP_QUERY = gql`
  subscription MySubscription2($_eq: bigint) {
    group_chatn(
      where: { group_id: { _eq: $_eq }, _and: { status: { _eq: "active" } } }
    ) {
      group_id
      id
      chat_content
      sender_id
      contents
      timestamp
      group_master {
        name
      }
    }
  }
`;

const EVENT_GROUP_QUERY = gql`
  subscription MySubscription3($_eq: bigint) {
    group_chatn(
      where: { event_id: { _eq: $_eq }, _and: { status: { _eq: "active" } } }
    ) {
      event_id
      id
      chat_content
      sender_id
      contents
      timestamp
      event_master {
        name
      }
    }
  }
`;

const INSERT_MESSAGE_MUTATION = gql`
  mutation MyMutation(
    $chat_content: String = ""
    $contents: String = ""
    $participent: uuid = ""
    $sender_id: uuid = ""
    $timestamp: timestamptz = ""
    $replying_to: uuid = ""
    $status: String = "active"
  ) {
    insert_group_chatn(
      objects: {
        chat_content: $chat_content
        contents: $contents
        participent: $participent
        sender_id: $sender_id
        timestamp: $timestamp
        replying_to: $replying_to
        type: "text"
        status: $status
      }
    ) {
      returning {
        chat_content
        participent
        sender_id
        id
        timestamp
        status
      }
    }
  }
`;

const INSERT_GROUP_MUTATION1 = gql`
  mutation MyMutation4(
    $chat_content: String = ""
    $group_id: bigint
    $sender_id: uuid = ""
    $type: String = "text"
    $contents: String = ""
    $status: String = "active"
  ) {
    insert_group_chatn(
      objects: {
        chat_content: $chat_content
        group_id: $group_id
        sender_id: $sender_id
        type: $type
        contents: $contents
        status: $status
      }
    ) {
      returning {
        chat_content
        group_id
        sender_id
        timestamp
        status
        contents
      }
    }
  }
`;

const INSERT_EVENT_MUTATION2 = gql`
  mutation MyMutation5(
    $chat_content: String = ""
    $event_id: bigint
    $sender_id: uuid = ""
    $type: String = "text"
    $contents: String = ""
    $status: String = "active"
  ) {
    insert_group_chatn(
      objects: {
        chat_content: $chat_content
        event_id: $event_id
        sender_id: $sender_id
        type: $type
        contents: $contents
        status: $status
      }
    ) {
      returning {
        chat_content
        event_id
        sender_id
        timestamp
        contents
        status
      }
    }
  }
`;

const USER_QUERY = gql`
  query MyQuery {
    users {
      displayName
      email
      id
      avatarUrl
    }
  }
`;

const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation MyMutation5($_eq: uuid = "", $_eq1: bigint) {
    update_group_chatn(
      where: { users: { id: { _eq: $_eq } }, group_id: { _eq: $_eq1 } }
      _set: { notification: "0" }
    ) {
      returning {
        notification
        group_id
        id
      }
    }
  }
`;

const GET_GROUP_USERS = gql`
 query MyQuery1cs4csdcdsc($_eq: bigint = "") {
  user_groups(where: {group_id: {_eq: $_eq}, _and: {status: {_is_null: true}, _and: {users: {defaultRole: {_neq: "admin"}}}}}) {
    user_id
    group_id
    users {
      displayName
      email
      id
    }
  }
}
`;
const GET_EVENT_USERS = gql`
query Msdsdcdvy18($_eq: bigint = "") {
  user_events(where: {event_id: {_eq: $_eq}, _and: {status: {_is_null: true}, _and: {users: {defaultRole: {_neq: "admin"}}}}}, distinct_on: userid) {
    event_id
    userid
    users {
      displayName
      id
    }
    event_master {
      name
    }
  }
}
`;

const CHAT_ADMIN_STAFF = gql`
  query MyQuery5($_eq: uuid = "", $_eq1: bigint = "") {
    event_faculty(
      where: {
        user_id: { _eq: $_eq }
        _and: {
          facilitator_role: { name: { _eq: "Chat Admin" } }
          _and: { event_id: { _eq: $_eq1 } }
        }
      }
    ) {
      id
      user_id
      faculty_type_id
      event_id
    }
  }
`;

const REMOVE_USER_MUTATION = gql`
  mutation MyMutation2($_eq: bigint = "", $_eq1: uuid = "") {
    update_user_groups(
      where: { group_id: { _eq: $_eq }, user_id: { _eq: $_eq1 } }
      _set: { status: "removed" }
    ) {
      returning {
        group_id
        user_id
      }
    }
  }
`;
const REMOVE_EVT_USER_MUTATION = gql`
  mutation MyMutation2as(
    $_eq: bigint = ""
    $_eq1: uuid = ""
    $status: String = ""
  ) {
    update_user_events(
      where: { event_id: { _eq: $_eq }, userid: { _eq: $_eq1 } }
      _set: { status: $status }
    ) {
      returning {
        event_id
        userid
      }
    }
  }
`;

export default function Innerchat(props) {
  // const navigation = useNavigation();
  const { selectedCard } = props;
  const classes = useStyles();
  // console.log("Props checking groups", selectedCard.image);
  // console.log("this is image in chat", selectedCard?.image);
  const sId = localStorage.getItem("id");
  const [imageTemp, setImageTemp] = useState(true);
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [image, setImage] = useState("");
  const rId = props.id;
  // console.log("ids all", props);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default "Enter" key behavior (usually form submission)
      handleSendMessage();
    }
    else if(e.key === "Escape")
    {
      // navigation.navigate('/userdashboard')
      // selectedCard?.setSelectedCard(null);
      // alert("Please select")
    }
  }; 
  const [RealChat, setRealChat] = useState([]);
  const [senderChat, setSenderChat] = useState([]);
  const { data: sendChat } = useSubscription(SUBSCRIPTION_QUERY1, {
    variables: {
      _eq: rId,
      _eq1: sId,
    },
  });
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION_MUTATION);
  const [insertMessage] = useMutation(INSERT_MESSAGE_MUTATION);
  const [insertGroupMessage, { error }] = useMutation(INSERT_GROUP_MUTATION1);
  // console.log("error fount insertMessage", error);
  const [openGrpMembers, setopenGrpMembers] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [grpMembers, setGrpMembers] = useState([]);
  const [evntMembers, setEvntMember] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [removeUser] = useMutation(REMOVE_USER_MUTATION);
  const [removeEvtUser] = useMutation(REMOVE_EVT_USER_MUTATION);
  const {
    data: grpMembData,
    loading: grpMembLoading,
    error: grpMembError,
    refetch,
  } = useQuery(
    GET_GROUP_USERS,
    {
      variables: selectedCard?.thisIsGrp
        ? {
            _eq: rId,
          }
        : null,
    },
    {
      onCompleted: (data) => {
        setGrpMembers(data?.user_groups);
        // console.log("data received members", data);
      },
    },
    {
      onerror: (err) => {
        console.log("error in onCompleted callback ", err);
      },
    }
  );

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: coverImageId,
    bucketId,
    name,
  } = useFileUpload();

  const { data: adminStaff } = useQuery(
    CHAT_ADMIN_STAFF,
    {
      variables: selectedCard?.thisIsEvent
        ? {
            _eq: sId,
            _eq1: rId,
          }
        : null,
    }
    // (onCompleted = (data) => {})
  );

  const { data: eventMembData, refetch: evtRefetch } = useQuery(
    GET_EVENT_USERS,
    {
      variables: selectedCard?.thisIsEvent
        ? {
            _eq: rId,
          }
        : null,
    },
    {
      onCompleted: (data) => {
        setEvntMember(data?.user_events);
        // console.log("data received members", data);
      },
    },
    {
      onerror: (err) => {
        console.log("error in onCompleted callback ", err);
      },
    }
  );

  useEffect(() => {
    setGrpMembers(grpMembData?.user_groups);
    setEvntMember(eventMembData?.user_events);
    refetch();
  }, [grpMembData, eventMembData]);

  const [messageToDeleteId, setMessageToDeleteId] = useState(null);
  // console.log("deleted id", messageToDeleteId);
  const [deletechat] = useMutation(DELETE_MESSAGE_QUERY, {
    onCompleted: (data) => {
      // console.log('object',data )
    },
    onError: (error) => {
      console.log(error, "inseting error");
    },
    // refetchQueries: [
    //   // Provide the name of the query you want to refetch after deletion
    //   // For example:
    //    { query: GET_FEED_QUERY}
    // ],
  });

  const [insertEventMessage] = useMutation(INSERT_EVENT_MUTATION2);
  const {
    data: userChatData,
    isError: userChatIsError,
    error: userChatError,
  } = useSubscription(
    // selectedCard?.thisIsGrp ? SUBSCRIPTION_GROUP_QUERY : SUBSCRIPTION_QUERY,
    selectedCard?.thisIsEvent
      ? EVENT_GROUP_QUERY
      : selectedCard?.thisIsGrp
      ? SUBSCRIPTION_GROUP_QUERY
      : SUBSCRIPTION_QUERY,
    {
      variables: selectedCard?.thisIsEvent
        ? {
            _eq: rId,
          }
        : selectedCard?.thisIsGrp
        ? {
            _eq: rId,
          }
        : {
            _eq: sId,
            _eq1: rId,
          },
    }
  );
  const messageContainerRef = useRef(null);

  useEffect(() => {
    // Function to scroll to the bottom
    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    };

    // Call scrollToBottom whenever new messages are added
    scrollToBottom();
  }, [RealChat]);
  useEffect(() => {
    // setRid(props.id);

    if (selectedCard?.thisIsGrp) {
      setRealChat(userChatData?.group_chatn || []);
    } else if (selectedCard?.thisIsEvent) {
      setRealChat(userChatData?.group_chatn || []);
    } else if (props.thisIsNotification) {
      updateNotification({
        variables: {
          _eq: sId,
          _eq1: rId,
        },
      });
    } else {
      setRealChat([
        ...(userChatData?.group_chatn || []),
        ...(sendChat?.group_chatn || []),
      ]);
    }
  }, [userChatData, selectedCard?.thisIsGrp, sendChat]);
  const sortedMessages = useMemo(() => {
    return RealChat.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  }, [RealChat]);

  useEffect(() => {
    if (isUploaded && imageTemp) {
      // console.log("image uploaded successfully");

      // console.log("Entering image upload block");

      // Assuming coverImageId is correctly set before this point
      // console.log("coverImageId:", coverImageId);
      setImage(coverImageId);
      if (selectedCard?.thisIsGrp && imageTemp) {
        // console.log("Uploading group message image");

        insertGroupMessage({
          variables: {
            group_id: rId,
            // chat_content: message.trim(),
            contents: coverImageId,
            sender_id: sId, //get sender id
          },
          onCompleted: () => {
            // console.log("uploaded to database successfully", image);
            // console.log(
            //   "Uploaded to group message database successfully",
            //   image
            // );
            setImageTemp(false);
          },
          onerror: (error) => {
            console.log("error while group msg", error);
          },
        });
      } else if (selectedCard?.thisIsEvent && imageTemp) {
        // console.log("Uploading event message image");
        insertEventMessage({
          variables: {
            event_id: rId,
            // chat_content: message.trim(),
            contents: coverImageId,
            sender_id: sId, //get sender id
          },
          onCompleted: () => {
            // console.log("uploaded to database successfully");
            setImageTemp(false);
          },
        });
      } else {
        // console.log("Uploading regular message image");

        const sender_id = rId;
        const participent = selectedCard?.thisIsGrp ? null : sId; // Set receiver_id as null for group chat
        const replying_to = sId;
        const currentDate = new Date();
        const formattedDateTime = currentDate.toISOString();
        insertMessage({
          variables: {
            sender_id: participent,
            participent: sender_id,
            replying_to,
            contents: coverImageId,
            timestamp: formattedDateTime, // Include the formatted date time in the mutation
          },
          onCompleted: () => {
            // console.log("uploaded to database successfully", image);
            setImageTemp(false);
          },
          onerror: (error) => {
            console.log("uError", error);
          },
        });
      }
    }
  }, [imageTemp, coverImageId, isUploaded]);

  // console.log("this is contents", RealChat[3]?.contents);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const data = location.state;

  const nhost = useNhostClient();

  if (!selectedCard) {
    return null; // You can handle the case when no card is selected, or render a default state
  }
  // console.log("this is real time char", userChatData);

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      const sender_id = rId;
      const participent = selectedCard?.thisIsGrp ? null : sId; // Set receiver_id as null for group chat
      const receiver_id = selectedCard?.thisIsGrp ? null : sId; // Set receiver_id as null for group chat
      const replying_to = sId;
      if (!sender_id || (!selectedCard?.thisIsGrp && !receiver_id)) {
        // console.error("Invalid sender_id or receiver_id");
        return;
      }

      const currentDate = new Date();
      const formattedDateTime = currentDate.toISOString();

      if (selectedCard?.thisIsGrp) {
        insertGroupMessage({
          variables: {
            group_id: rId,
            chat_content: message.trim(),
            sender_id: sId, //get sender id
          },
        });
      } else if (selectedCard?.thisIsEvent) {
        insertEventMessage({
          variables: {
            event_id: rId,
            chat_content: message.trim(),
            sender_id: sId, //get sender id
          },
        });
      } else {
        insertMessage({
          variables: {
            sender_id: participent,
            participent: sender_id,
            replying_to,
            chat_content: message.trim(),
            timestamp: formattedDateTime, // Include the formatted date time in the mutation
          },
        });
      }
      setMessage("");
    }
  };
  // const [imageTemp, setImageTemp] = useState(true);

  const handleImageClick = () => {
    setImageTemp(true);

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*/*";
    input.onchange = async (event) => {
      let file = event.target.files[0];
      await upload({ file });
    };
    input.click();
  };

  const onDelete = async ({ id }) => {
    try {
      const { data: newData } = await deletechat({
        variables: {
          _eq: id,
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
    handleClose(); // Close the dialog after delete action
  };
  const handleClickOpen = (messageId) => {
    // console.log("message id", messageId);
    setMessageToDeleteId(messageId); // Store the message ID to delete
    setOpen(true);
  };

  // Helper function to handle member selection
  const handleMemberSelect = (memberId) => {
    const isSelected = selectedMembers.includes(memberId);
    if (isSelected) {
      setSelectedMembers((prevSelected) =>
        prevSelected.filter((id) => id !== memberId)
      );
    } else {
      setSelectedMembers((prevSelected) => [...prevSelected, memberId]);
    }
  };

  const handleRemoveUsers = () => {
    selectedMembers.forEach((memberId) => {
      if (selectedCard?.thisIsGrp) {
        removeUser({
          variables: selectedCard?.thisIsGrp
            ? {
                _eq: rId,
                _eq1: memberId,
              }
            : null,
        })
          .then((response) => {
            // Handle success if needed
            // setModalVisible2(false);
            // refetch();
          })
          .catch((error) => {
            // Handle errors if needed
            console.error(error);
          });
      } else {
        removeEvtUser({
          variables: selectedCard?.thisIsEvent
            ? {
                _eq: rId,
                _eq1: memberId,
                status: "removed",
              }
            : null,
        })
          .then((response) => {
            // Handle success if needed
            // setModalVisible2(false);
            // refetch();
            // evntMemRefetch();
          })
          .catch((error) => {
            // Handle errors if needed
            console.error("sedgfvsedfvsfsd", error);
          });
      }
    });
    setopenGrpMembers(false);
    setOpen1(false);
    setSelectedMembers([]);
    refetch();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  return (
    <Box style={{ backgroundColor: "#FFFFFF", height: "100%", width: "100%" }}>
      <Box style={styles.card}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box style={styles.cardcircle}>
            <img style={styles.cardimage} src={ selectedCard.image } />
          </Box>
          <Typography style={styles.text}>{selectedCard.title}</Typography>
        </Box>

        <Box style={{ display: "flex", flexDirection: "row" }}>
          <img src={search} style={styles.searchicon} />
          <img
            src={menu}
            onClick={() => handleClickOpen1()}
            style={styles.menuicon}
          />
        </Box>
        <Dialog open={open1} onClose={handleClose1}>
          {/* {openGrpMembers && ( */}
          <DialogContent>
            {openGrpMembers && (
              <div>
                {selectedCard.thisIsGrp ? (
                  grpMembers?.map((item) => (
                    <div key={item.users?.id}>
                      <label>
                        <Checkbox
                          classes={{ root: classes.redCheckbox }}
                          checked={selectedMembers.includes(item.users?.id)}
                          onChange={() => handleMemberSelect(item.users?.id)}
                        />
                        {item.users?.displayName}
                      </label>
                    </div>
                  ))
                ) : selectedCard.thisIsEvent ? (
                  evntMembers?.map((item) => (
                    <div key={item.users?.id}>
                      <label>
                        <Checkbox
                          classes={{ root: classes.redCheckbox }}
                          checked={selectedMembers.includes(item.users?.id)}
                          onChange={() => handleMemberSelect(item.users?.id)}
                        />
                        {item.users?.displayName}
                      </label>
                    </div>
                  ))
                ) : (
                  <p>No members available.</p>
                )}
              </div>
            )}
            {openGrpMembers && (
              <Box
                style={{
                  width: "250px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                <Button
                  onClick={() => {
                    setopenGrpMembers(false);
                  }}
                  style={{ color: "red" }}
                >
                  Close
                </Button>
                <Button
                  onClick={handleRemoveUsers}
                  style={{
                    color: "white",
                    backgroundColor: "red",
                    marginLeft: "10%",
                  }}
                >
                  Remove
                </Button>
              </Box>
            )}
          </DialogContent>
          {/* )} */}
          <DialogActions>
            {selectedCard?.thisIsGrp || selectedCard?.thisIsEvent ? (
              <>
                {!openGrpMembers && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: 270,
                      height: 80,
                    }}
                  >
                    {/* <Button
                      style={{
                        color: "black",
                        fontFamily: "MontserratRegular",
                        whiteSpace: "normal",
                        fontSize: 14,
                        // margin: 10,
                      }}
                      // onClick={leaveGroup}
                    >
                      Leave Group
                    </Button> */}
                    <Button
                      style={{
                        color: "black",
                        fontFamily: "MontserratRegular",
                        // whiteSpace: "normal",
                        fontSize: 14,
                        // margin: 20,
                      }}
                      onClick={() => {
                        setopenGrpMembers(true);
                      }}
                    >
                      Show Members
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 300,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      color: "black",
                      fontFamily: "MontserratRegular",
                      whiteSpace: "normal",
                      fontSize: 14,
                    }}
                    // onClick={BlockUser}
                  >
                    Block User
                  </Button>
                  {/* <Button
                    style={{
                      color: "black",
                      whiteSpace: "normal",
                      fontSize: 18,
                      margin: 10,
                    }}
                    onClick={handleClose1}
                    autoFocus
                  >
                    Close
                  </Button> */}
                </Box>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Box>
      <Box
        style={{
          width: "100%",
          // height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Typography>{selectedCard.time}, 23/03/2023</Typography> */}
      </Box>
      <Box
        ref={messageContainerRef}
        style={{
          // width: "100%",
          display: "flex",
          height: "99%",
          flexDirection: "column",
          overflowY: "scroll",
          scrollbarWidth: "auto",
          scrollbarRadius: "10px",
          "&::-webkit-scrollbar": {
            width: "0.7em",
          },

          "&::-webkit-scrollbar-track": {
            background: "#E1E5F8",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {sortedMessages?.map((item) => (
          <Box>
            {item.sender_id ===
            (selectedCard?.thisIsGrp ?? selectedCard?.thisIsEvent ?? true
              ? sId
              : rId) ? (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  height: "auto",
                }}
              >
                {item.contents ? (
                  <Box
                    style={{
                      marginLeft: "0px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <ChatImage img={item.contents} />
                    <Box style={{ marginTop: "11%" }}>
                      <img
                        src={menu}
                        onClick={() => handleClickOpen(item.id)}
                        style={styles.menuicon1}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    style={{
                      ...styles.senderchatbox,
                      flexWrap: "wrap", // Apply flexWrap to the message container
                      maxWidth: "60%", // Adjust this value as needed
                    }}
                  >
                    <Typography
                      style={{
                        ...styles.receiverchatboxtext,
                        color: "black",
                        whiteSpace: "normal",
                      }}
                    >
                      {item.chat_content}
                    </Typography>
                    <Box onClick={() => handleClickOpen(item.id)}>
                      <img src={menu} style={styles.menuicon1} />
                    </Box>
                    <Typography
                      style={{ ...styles.timestamp, color: "#252525" }}
                    >
                          {
  new Date(item.datetime ? item.datetime : item.timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
}
                    </Typography>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to delete this message ?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => onDelete({ id: messageToDeleteId })}
                        >
                          Yes
                        </Button>
                        <Button onClick={handleClose} autoFocus>
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {item.contents ? (
                  <ChatImage img={item.contents} />
                ) : (
                  <Box style={styles.receiverchatbox}>
                    <Typography style={styles.receiverchatboxtext}>
                      {item.chat_content}
                    </Typography>
                    <Box onClick={() => handleClickOpen(item.id)}>
                      <img src={menu} style={styles.menuicon1} />
                    </Box>
                    <Typography style={styles.timestamp}>
                      
                           {
  new Date(item.datetime ? item.datetime : item.timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Box style={styles.chatbox}>
        {/* <img style={styles.emoji} src={emoji} /> */}
        <img style={styles.emoji} src={share} onClick={handleImageClick} />
        <TextField
          style={{
            // height: "60%",
            width: "75%",
            backgroundColor: "#FFFFFF",
            borderRadius: 5,
            margin: 4,
          }}
          onKeyDown={handleKeyPress}
          placeholder="Type a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          style={{
            background: "#FF0000",
            border: "none",
            color: "white",
            borderRadius: 5,
            cursor: "pointer",
            padding: "8px 16px", // Adjust padding according to your design
            outline: "none",
            width: "10%",
            height: 40,
            marginLeft: 10,
          }}
          onClick={handleSendMessage}
        >
          Send
        </button>
      </Box>
      {/* {newTaskOpen && (
        <ImagePopUp img={} open={newTaskOpen} setNewTaskOpen={setNewTaskOpen} />
      )} */}
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    height: "100vh",
    // height: 'auto',
    width: "10%",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    // elevation: 1,
    justifyContent: "space-between",
    // height: "10vh",
    width: "100%",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  cardcircle: {
    width: 50,
    height: 50,
    marginLeft: 10,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  cardimage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  text: {
    color: "#252525",
    fontFamily: "MontserratBold",
    marginLeft: 10,
    fontSize: 20,
  },
  searchicon: {
    width: 20,
    height: 20,
    // margin: 15,
    marginRight: "25%",
  },
  menuicon: {
    width: 20,
    height: 20,
    marginLeft: 10,
    marginRight: 50,
  },
  menuicon1: {
    width: 20,
    height: 20,
    marginRight: 10,
    marginTop: 5,
  },
  scrollicon: {
    // width: 20,
    // height: 20,
    // margin: 5,
  },
  receiverchatbox: {
    display: "flex",
    flexWrap: "wrap", // Apply flexWrap to the message container
    maxWidth: "60%", // Adjust this value as needed
    width: "560px",
    height: "auto",
    backgroundColor: "#f7f7f7",
    minWidth: 100,
    margin: 10,
    justifyContent: "flex-end",
    elevation: 2,
    borderRadius: 17,
    boxShadow: "1px 2px 1px #9E9E9E",
    maxWidth: "80%", // Adjust this value as needed
  },
  receiverchatboxtext: {
    fontSize: 16,
    color: "#252525",
    fontFamily: "MontserratRegular",
    lineHeight: "18px",
    // backgroundColor: '#FFFFFF',
    padding: 5,
    marginLeft: 10,
    display: "flex",
    // flexWrap:"wrap",
    // flex: "1", // Allow the text content to expand within the message box
    // wordWrap: "break-word", // Allow long words to break and wrap
    // width: "100%",
    flex: "1", // Allow the text content to expand within the message box
    // wordWrap: "break-word", // Allow long words to break and wrap
    wordBreak: "break-all", // Break words aggressively
    width: "100%",
    maxWidth: "80%", // Set maximum width to 60%
    // overflowWrap: "break-word", // Break continuous words to the next line
  },
  senderchatbox: {
    flexWrap: "wrap",
    display: "flex",
    width: "560px",
    backgroundColor: "white",
    margin: 10,
    elevation: 3,
    borderRadius: 17,
    minWidth: 100,
    justifyContent: "flex-end",
    boxShadow: "1px 1px 1px #FF0000",
    overflow: "hidden",
    // height: "50px",
    border: "1px solid #FF0000",
    // borderColor:"#F2F2F2"
  },
  chatbox: {
    height: "auto",
    width: "100%",
    backgroundColor: "#F2F2F2",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    // position: "absoulte",
    // bottom:0
  },
  emoji: {
    height: 30,
    width: 30,
    margin: 3,
  },
  timestamp: {
    // width:"100%",
    position: "relative",
    marginLeft: "80%",
    // alignItems:"flex-start",
    // justifyContent:"flex-start",
    // bottom: 0,
    fontSize: "10px",
    // color: "#999999",
    marginRight: 10,
  },
};
