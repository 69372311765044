import React, { useState, useEffect, useRef } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useFileUpload } from "@nhost/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";

const INSERT_CONTENT = gql`
  mutation AddContent(
    $associated_event_id: bigint
    $associated_group_id: bigint
    $file_name: String = ""
    $link: String = ""
    $published_by: String = ""
    $type: String = ""
    $uploaded_by: String = ""
  ) {
    insert_content_master(
      objects: {
        associated_event_id: $associated_event_id
        associated_group_id: $associated_group_id
        file_name: $file_name
        link: $link
        published_by: $published_by
        type: $type
        uploaded_by: $uploaded_by
      }
    ) {
      returning {
        associated_event_id
        associated_group_id
        category_id
        expiry_date
        file_name
        id
        link
        published_by
        publishing_date
        type
        upload_date
        uploaded_by
      }
    }
  }
`;

const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      id
      publish_status
      conevene_meeting_id
    }
  }
`;

const JitsiComponent = ({
  isUser,
  eventData,
  interfaceConfigOverwrite,
  recordstatus,
}) => {
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: coverImage,
    bucketId,
    name: testname,
  } = useFileUpload();
  const eventId = eventData.eventId;

  const {data:statusData,refetch:statusRef} = useQuery(GET_EVENTS_QUERY,{
    variables:{
      id:eventId
    },
    onCompleted:(data)=>{
      setcustomerMeetId(data.event_master[0]?.conevene_meeting_id)
    }
  })

  const userName = localStorage.getItem("name");
  const userEmail = localStorage.getItem("email");
  
  const userId = localStorage.getItem("id");
  const [isJitsiVisible, setIsJitsiVisible] = useState(true);
  const [recordedId, setrecordedId] = useState("");

  const domain = "advance.convenevc.com"; // Replace with your actual Jitsi Meet server domain
  const roomName = eventData.eventName; // Replace with your unique room name

  const [jwtToken, setJwtToken] = useState("");
  const [isMeetingActive, setIsMeetingActive] = useState(true);
  const jitsiMeetRef = useRef(null);

  const user_name = userName;
  const user_email = userEmail;
    const [customerMeetId,setcustomerMeetId]= useState("")
  const organizaionID = 121225;

  const [insertrecording] = useMutation(INSERT_CONTENT, {
    onCompleted: (data) => {
      // console.log("last data updated");
    },
    onError: (error) => {
      console.log("error is ", error);
    },
  });

  useEffect(() => {
    // Fetch the JWT token from the server
    fetch(
      `https://token.convenevc.com/generate-jwt?room_name=${roomName}&user_name=${user_name}&user_email=${user_email}`
    )
      .then((response) => response.json())
      .then((data) => {
        setJwtToken(data.token);
        // console.log(data.token, "tokennnnn");
      })
      .catch((error) => {
        console.error("Error fetching JWT token:", error);
      });
  }, []);
  var today = new Date();
  var formattedDate = today.toISOString().split("T")[0];

  // Function to handle the leave action when the button is clicked
  const handleLeaveMeeting = async () => {
    // Execute the leave action using the Jitsi Meet External API
    if (jitsiMeetRef.current) {
      jitsiMeetRef.current.executeCommand("hangup");
    }
    setIsMeetingActive(false);
    setIsJitsiVisible(false);
    if (recordstatus === true && isUser === "admin") {
      await handleFetchVideo();
    }
    handleExitScreen();
    window.history.back();
  };

// Handle leave meeting code
const handleExitScreen = async () => {
  try {
    // Your API endpoint
    const apiUrl = `https://portalapi.convenevc.com/api/leave-meeting/${customerMeetId}`;

    // Request parameters
    const requestData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify({
        organizationID: organizaionID,
        CustomerMeetingID: customerMeetId,
        displayName: user_name,
        email: user_email,
      }),
    };

    const response = await fetch(apiUrl, requestData);
    console.log(response,'response exit screen')
    // Check if the request was successful (status code 2xx)
    if (response.ok) {
      console.log('Exit screen or end meeting');
    } else {
      // Handle errors
      console.error('Failed to make the POST request:', response.status, response.statusText);
    }
  } catch (error) {
    console.error('An error occurred during the POST request:', error);
  } finally {
    // Always navigate back, whether the request was successful or not
  }
};
  const uploadRecording = async (video) => {
    console.log("this is data for database upload", coverImage, testname);
    try {
      const { data: insertedData } = await insertrecording({
        variables: {
          associated_event_id: eventId,
          file_name: roomName + formattedDate,
          link: video,
        },
      });
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const [hasUploaded, setHasUploaded] = useState(false);
  const handleFetchVideo = async () => {
    console.log("U R O T E ");
    try {
      const response = await fetch(
        `https://advrecordings.convenevc.com:8443/download?filename=${
          roomName.toLocaleLowerCase() + "_" + formattedDate
        }`
      );

      // Check if the request was successful (status code in the range 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      for (let i = 0; i < data.download_urls.length; i++) {
        console.log(data.download_urls[i], "required urls ", i);

        const responsevideo = await fetch(data.download_urls[i]);
        const file = await responsevideo.blob();
        console.log("downloaded content:", i, file);
        await upload({ file });
      }
      setHasUploaded(true);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    if (isUploaded && !hasUploaded) {
      console.log("it is downloaded", coverImage);
      uploadRecording(coverImage);
    }
  }, [isUploaded, hasUploaded, coverImage]);

  let testData = "";
  if (!jwtToken) {
    return <div>Loading...</div>;
  }

  const configOverwrite = {
    // Your config overwrites here
    startWithAudioMuted: false,
    hideModeratorSettingsTab: true,
    CHAT_ENABLED: false,
    INVITE_ENABLED: false,
    disableInviteFunctions: true,
    hideMuteAllButton: true,
    PREJOIN_PAGE_ENABLED: false,
    prejoinPageEnabled: false,
    ENABLE_PREJOIN_PAGE: false,
    ENABLE_WELCOME_PAGE: false,
    enableClosePage: true,
    "prejoinConfig.enabled": false,
    enableClosePage: true,
    ENABLE_CLOSE_PAGE: true,
    ENABLE_CLOSE_PAGE: true,
    enableWelcomePage: false,
    default_background: "#ffffff",
    backgroundColor: "#ffffff",
  };
  const config = {
    enableClosePage: true,
    "prejoinConfig.enabled": false,
    enableClosePage: true,
    enableClosePage: true,
    ENABLE_CLOSE_PAGE: true,
    ENABLE_CLOSE_PAGE: true,
    enableWelcomePage: false,
  };
  const prejoinConfigOverwrite = {
    PREJOIN_PAGE_ENABLED: false,
    enableClosePage: true,
    "prejoinConfig.enabled": false,
    enableClosePage: true,
    enableClosePage: true,
    ENABLE_CLOSE_PAGE: true,
    ENABLE_CLOSE_PAGE: true,
    enableWelcomePage: false,
 
  };

  const userInfo = {
    displayName: "DummyUser",
    useEmail: "email@email.com",
  };

  const handleApi = (JitsiMeetAPI) => {
  };

  return (
    <>
     {isMeetingActive ? (
        <>
          <JitsiMeeting
            domain={domain}
            roomName={roomName}
            jwt={isUser === "admin" ? jwtToken : ""}
            eventId={eventId}
            configOverwrite={configOverwrite}
            interfaceConfigOverwrite={interfaceConfigOverwrite}
            prejoinConfigOverwrite={prejoinConfigOverwrite}
            userInfo={userInfo}
            onAPILoad={handleApi}
            onApiReady={(externalApi) => {}}
            onIframeLoad={(iframe) => {
              // Store the iframe reference in the ref for later use
              jitsiMeetRef.current = iframe;

              // Add custom event listeners or interactions with the iframe here
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = "100%";
              iframeRef.style.width = "100%";
            }}
          />
          {/* Render the Leave Meeting button */}
          <button
            onClick={handleLeaveMeeting}
            style={{
              position: "relative",
              bottom: "100%",
              left: "81%",
              background: "red",
              color: "white",
              fontSize: "16px",
              padding: "10px 20px",
              borderRadius: "5px",
              margin: "10px",
              fontFamily: "MontserratRegular",
            }}
          >
            Leave Meeting
          </button>
        </>
      ) : (
        <Box
        style={{
          width: "100%",
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        {recordstatus === true && isUser === "admin" ? (
          <Box>
            <img
              style={{ margin: "auto" }}
              src={require("../images/icons8-spinner.gif")}
            />
          </Box>
        ) : null}
        <div>
          <Typography
            style={{
              fontFamily: "MontserratRegular",
              textAlign: "center",
            }}
          >
            Meeting has ended Please wait, Recording is being uploaded...
          </Typography>
        </div>
        {isUploading && (
          <div
            style={{
              marginTop: "10px",
              height: "50vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "MontserratRegular",
                textAlign: "center",
                marginTop: "0px",
                color: "#FF0000",
              }}
            >
              {progress}%
            </Typography>
          </div>
        )}
      </Box>       )}
    </>
  );
};

export default JitsiComponent;
