import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import MyGroupCard from "./Groups/MyGroupCard";
import MoreGroupCard from "./Groups/MoreGroupCard";
import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { gql, useQuery, useMutation } from "@apollo/client";
const FETCH_CATEGORY =gql`
query get_categorybyid($_eq: uuid = "") {
  profile_details(where: {user_id: {_eq: $_eq}}) {
    category
  }
}
`
// change userId before production
const GET_ASSGROUP=gql`
query MyQuerydqw9($_eq1: uuid = "") {
  group_master(where: {user_groups: {user_id: {_eq: $_eq1}}, status: {_eq: "isactive"}}) {
    activation_date
    attendance_capacity
    closure_date
    cover_img_path
    deactivation_date
    decription
    duration
    name
    id
    price
    publish_to
  }
}
`
export default function GroupComponent() {
const navigate = useNavigate();
const [isUser, setisUser] = useState(true);
const Submit = (id, name, description, activation_date, deactivation_date, cover_img_path) => {
  setisUser(true);
  let membership = true;
  navigate("/groupmanagement2", {
    state: {
      isUser: isUser,
      name,
      membership,
      id,
      description,
      activation_date,
      deactivation_date,
      cover_img_path,
    },
  });
};

  const cardContainerRef = useRef(null);
  const bottomContainerRef=useRef(null);

  
  const [Category, setCategory] = useState("");
  const { loading, error, data:category,isError } = useQuery(FETCH_CATEGORY, {
    variables: {
      _eq: localStorage.getItem("id"),
          },
  })
  ;
  // console.log("error is ", error);
  // console.log("set data category",category );

  useEffect(() => {
    setCategory(category?.profile_details);
    // console.log("data1",category?.profile_details)
  },[category]);

  const [group, setgroup] = useState([]);

  useEffect(() => {
    // const storedGroups = localStorage.getItem('groups');
    // if (storedGroups) {
    //   setgroup(JSON.parse(storedGroups));
    // } else {
      fetchData();
    // }
  }, [group]);

  const fetchData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-hasura-admin-secret': process.env.REACT_APP_NHOST_SECREATEKEY,
        },
        body: JSON.stringify({
          query: `query get_groupmaster_bycategory($_eq: String = "") {
            group_master(where: {publish_to: {_eq: $_eq}}) {
              attendance_capacity
              cover_img_path
              deactivation_date
              decription
              duration
              category_id
              activation_date
              closure_date
              level_up_group
              name
              price
              publish_to
              type
            }
          }`,
          variables: {
            _eq: Category,
          },
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const fetchedGroups = data.data.group_master;
        setgroup(fetchedGroups);
        localStorage.setItem('groups', JSON.stringify(fetchedGroups));
      } else {
        console.error('Failed to fetch groups:', data.errors);
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };
  
  const [assGroup, setassGroup] = useState([]);
  const {  error:error1, data:assgroup } = useQuery(GET_ASSGROUP, {
    variables: {
      _eq1:localStorage.getItem('id'),
          },
  });
    useEffect(() => {
    setassGroup(assgroup?.group_master);
    // console.log("data1",assgroup?.group_master)
  },[assgroup]);

  return (
    <Box sx={styles.main}>
      <Box sx={styles.uppercards}>
        <Box sx={styles.txtContainer}>
          <Typography sx={styles.largeTxt}>My Groups</Typography>
        </Box>
        {/* ..............................................start my group */}
        <Box sx={styles.arrowScroll}>
          <Box sx={styles.cardContainer} ref={cardContainerRef}>
            {assGroup?.map((item, index) => (
              <Box onClick={() =>
                Submit(item.id, item.name, item.decription, item.activation_date, item.deactivation_date, item.cover_img_path)
              }>
              <MyGroupCard
                img={item.cover_img_path}
                title={item.name}
                desc={item.decription}
                startDate={item.activation_date}
                endDate={item.deactivation_date}
              />
              </Box>
            ))}
          </Box>        
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  main: {
    display: "flex",
    flexDirection: "row",
    width:"100%"
  },
  uppercards: {
    display: "flex",
    flexDirection: "row",
    height:"50%",
    flexWrap: "wrap",
    width:"100%"
  },
  lowercards: {
    display: "flex",
    flexDirection: "row",
    height:"50%",
    flexWrap: "wrap",
    width:"100%"
  },
  txtContainer: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    height:"20%",
    
    marginBottom:10
  },
  largeTxt: {
    fontFamily: "MontserratMedium",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
    lineHeight: 1,
    marginTop:'8px',
  },
  cardContainer: {
    width: "100%",
    // height: "75%",
    // height: "520px",
    // backgroundColor:"red",
    display: "flex",
    flexWrap:"wrap",
    flexDirection:"row",
    alignItems: "center",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "1px",
    margin: "10px",
    "&::-webkit-scrollbar": {
      width: "0.1em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  bottomContainer: {
    width: "100%",
    // height: "75%",
    height: "520px",
    display: "flex",
    alignItems: "center",
    flexDirection:"column",
    overflowY: "scroll",
    scrollbarWidth: "1px",
    overflowX: "hidden",
    margin: "10px",
    "&::-webkit-scrollbar": {
      width: "0.1em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },

  leftArrow: {
    // marginTop: "4%",
    width: "15px",
    height: "60px",
    position: "relative",
    cursor: "pointer",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  rightArrow: {
    width: "15px",
    height: "60px",
    // marginTop: "4%",

    cursor: "pointer",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  arrowScroll: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "60%",
    width: "102%",
    margin: "4px",
  },
};
