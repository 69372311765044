import React from "react";
import { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Styles from "../styles/BackupContent1";
import Navbar from "../components/Navbar";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "../styles/global";
import { useNavigate } from "react-router";
import BackUpCard from "../components/BackupContent/BackUpCard";
import ChatBackupCard from "../components/BackupContent/ChatBackupCard";

import review from "../../src/images/review.png";
import upload from "../../src/images/Upload.png";
import prof from "../../src/images/prof.png";

export default function BackupContent1(props) {
  const navigate = useNavigate();
  const data = [
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
    {
      img: require("../../src/images/review.png"),
      filename: "Go Digital Stay Human.Pdf",
      type: "Pdf",
      size: "11.28 MB",
      date: "14 Mar 2023",
      addedBy: "Omkar Shirote",
    },
  ];

  return (
    <Box sx={Styles.mainContainer}>
      {/*........................ navbar */}
      <Navbar />
      {/*........................ ends navbar */}

      <Box
        sx={[
          Styles.topContainer,
          { boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)" },
        ]}
      >
        <Typography sx={Styles.largeTxt}>
          Go Digital Stay Human {">"} Complete Event {">"} Backup Events{" "}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {/* .................left part start */}
        <Box sx={Styles.container1}>
          <Box sx={{ margin: "4px", marginLeft: "10px" }}>
            <Typography
              sx={{
                fontFamily: "MontserratRegular",
                fontSize: "16px",
                color: "#252525",
              }}
            >
              Content Backup
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "4px",
              marginLeft: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratRegular",
                fontSize: "12px",
                color: "#252525",
              }}
            >
              Check the boxes to download content
            </Typography>
            <Typography
              sx={{
                fontFamily: "MontserratRegular",
                fontSize: "12px",
                color: "#3457D5",
              }}
            >
              Select All
            </Typography>
          </Box>

          {/* card */}
          <Box sx={Styles.cardContainer}>
            {data.map((item, index) => (
              <BackUpCard
                img={item.img}
                filename={item.filename}
                type={item.type}
                size={item.size}
                date={item.date}
                addedBy={item.addedBy}
              />
            ))}
          </Box>
        </Box>
        {/* ....................left part end */}

        {/* .........................right part start */}
        <Box sx={Styles.container2}>
          <Box sx={{ margin: "4px", marginLeft: "10px" }}>
            <Typography
              sx={{
                fontFamily: "MontserratRegular",
                fontSize: "16px",
                color: "#252525",
              }}
            >
              Chat Backup
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "4px",
              marginLeft: "10px",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      "&.MuiCheckbox-root.Mui-checked": { color: "#FF0000" },
                    }}
                    // checked={isChecked}
                    // onChange={handleCheckboxChange}
                  />
                }
                label={""}
              />
            </Box>
            <Typography
              sx={{
                fontFamily: "MontserratRegular",
                fontSize: "12px",
                color: "#252525",
                marginLeft: "0px",
              }}
            >
              Include that backup
            </Typography>
          </Box>

          {/* ..................small box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "4px",
              marginLeft: "10px",
              height: "45px",
              backgroundColor: "#F4F4F4",
              borderRadius: "10px",
            }}
          >
            <img
              src={upload}
              alt="backup"
              width="17px"
              height="17px"
              style={{ margin: "5px" }}
            />
            <Typography
              sx={{
                fontFamily: "MontserratSemiBold",
                fontSize: "12px",
                color: "#252525",
                marginLeft: "0px",
                marginY: "5px",
              }}
            >
              Backup your chats. You cannot backup it afterwards.
            </Typography>
          </Box>

          <Box sx={{ margin: "4px", marginLeft: "10px" }}>
            <Typography
              sx={{
                fontFamily: "MontserratRegular",
                fontSize: "16px",
                color: "#252525",
              }}
            >
              Preview
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "4px",
              marginLeft: "10px",
              backgroundColor: "#F4F4F4",
              borderRadius: "10px",
              height:"250px",
              overflowY:"scroll",
              overflowX: "hidden",
              "@media (max-width:768px)": {
                width: "320px",
                margin: "auto",
                overflowX: "hidden",
              },
              scrollbarWidth: "auto",
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#E1E5F8",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
           
            <ChatBackupCard/>
            <ChatBackupCard/>
            <ChatBackupCard/>
            <ChatBackupCard/>
            <ChatBackupCard/>



          </Box>

          <Box
            sx={{
              width:"100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop:"40px",
              height:"40px",
              justifyContent:"flex-end",
            }}
          >
        <Box sx={[styles.btnDashboard,{margin:"6px",backgroundColor:"#ffffff",color:"#999999",borderColor:"#999999"}]}>Cancel</Box>
        <Box sx={[styles.btnDashboard, {margin:"6px",width:"150px"}]}>Backup Content</Box>


          </Box>


        </Box>

        {/* ...................right part end */}
      </Box>
    </Box>
  );
}
