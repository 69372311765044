import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import calender from "../../../.././images/EventManagement1/Calender.png";
import location from "../../../.././images/EventManagement1/Location.png";
import { useNhostClient } from "@nhost/react";
import nullimage from '../../../../images/nullImage.jpeg'

export default function UpcomingEventCard(props) {
  const { img, title, desc, date, time, address,booked } = props;
  const nhost = useNhostClient();
  const [imgUrl, setImgUrl] = useState("");
  // const recurrenceStartDate = '2023-10-25'; // Replace this with your dynamic variable
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const utcString = dateObject.toISOString();
  const formattedStartDate = `${formattedDate}`;
// console.log(formattedStartDate,"formatted date??  ???-?????-");

// console.log("Bookeds,m vcfdsv",props.booked)
  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: img,
        });
        // console.log("Image URL:", item);
        // setImgPreview(item)
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [img]);

  return (
    <Box sx={styles.container}>
      {/* .................img container */}
     
      <Box sx={styles.imgContainer}>
        {/* <img  src={img}  alt="img" width="250px" /> */}
       
        {img ? (
          <Box>
          
          <img
            src={ imgUrl && (!imgUrl.endsWith("null") || !imgUrl.endsWith("/"))
            ? imgUrl
            : nullimage}
            alt="Event Cover"
            style={{
              width: "100%",
              height: "130px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
            />
            {booked==="booked" &&(
              <span
              style={styles.diag}
              >
                Booked
              </span>
            )}
          </Box>
        ) : (
          <div style={{ width: "100%", height: "130px" }} />
        )}
        
      </Box>
      {/* .................img container ends */}

      {/*.................text container */}
      <Box sx={styles.textContainer}>
        <Typography sx={styles.titleTxt}>{title}</Typography>
        <Typography sx={styles.desc}>{desc}</Typography>

        <Box sx={styles.dateTime}>
          <img
            style={{ width: "15px", height: "15px" }}
            src={calender}
            alt="calender"
          />
          <Typography
            sx={[styles.baseTxt, { marginRight: "20px", marginLeft: "2px" }]}
          >
            {formattedStartDate} CET
          </Typography>
          {/* <Typography sx={styles.baseTxt}>{time}</Typography> */}
        </Box>

        <Box sx={styles.dateTime}>
          <img
            style={{ width: "17px", height: "17px" }}
            src={location}
            alt="location"
          />
          <Typography sx={styles.baseTxt}>{address}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  diag: {
    WebkitTransform: 'rotate(-45deg)',
    MozTransform: 'rotate(-45deg)',
    transform: 'rotate(-45deg)',
    left: '-50px',
    top: '-112px',
    fontFamily: 'MontserratMedium',
    width: '159px',
    display: 'inline-block',
    // height: '20px',
    lineHeight: '20px',
    color: 'white',
    textAlign: 'center',
    fontSize: '14px',
    background: '#80b918',
    position: 'relative',
  },
  container: {
    width: "250px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "90%",
    margin: "10px",
    overflow: "hidden",
  },
  imgContainer: {
    width: "250px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "2px",
    width: "100%",
  },
  titleTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#252525",
    lineHeight: "20px",
    height: "15px",
    width: "90%",
    overflow: "hidden",
  },
  desc: {
    fontFamily: "MontserratRegular",
    fontSize: "9px",
    textAlign: "left",
    fontWeight: "400",
    color: "#414042",
    lineHeight: "14.63px",
    overflow: "hidden",
    height: "15px",
    marginTop:'5px',
    marginBottom: "5px",
  },
  dateTime: {
    display: "flex",
    flexDirection: "row",
    // justifyContent:'space-between',
    alignItems: "center",
    width: "100%",
    // margin:'2px auto 2px auto',
    marginBottom: "5px",

  },
  baseTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "8px",
    fontWeight: "500",
    color: "#414042",
    lineHeight: "12.19px",
  },
};
