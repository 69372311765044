import { useNhostClient } from '@nhost/react';
import React, { useEffect, useState } from 'react'
import ImagePopUp from './ImagePopUp';

export default function ChatImage(props) {
    const nhost = useNhostClient();
    const [fetchedContent,setFetchedContent] = useState("");
    const [newTaskOpen, setNewTaskOpen] = useState(false);

    // console.log("fetchedContent is",fetchedContent);
  
    useEffect(() => {
    async function getContents(){
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: props.img,
        });
      //   console.log("Image URL:", item);
        setFetchedContent(item)
      } catch (error) {
        // console.error("Error retrieving image URL:", error);
      }
    }
    getContents();
  },[props.img])
  
  const openNewTask = () => {
    setNewTaskOpen(!newTaskOpen);
  };

  return (
    <div  onClick={openNewTask}>
      <img style={{width:"100px",height:"100px",marginTop:"20px"}} src={fetchedContent} />
       {newTaskOpen && (
        <ImagePopUp img={fetchedContent} open={newTaskOpen} setNewTaskOpen={setNewTaskOpen} />
      )}
    </div>
  )
}
