import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import styles from "../../styles/global";
import Navbar from '../Navbar'
import HeaderInformationGM from "./HeaderInformationGM";
import GroupConfiguration from "./GroupConfiguration";
import GroupContent from "./GroupContent";
import EventMemberConfiguration from "./EventMemberConfiguration";
import { useNavigate } from "react-router";
import { useState } from "react";


export default function GroupManagement() {
  const matches = useMediaQuery("(max-width:1000px)");
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    console.log(data);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [data, setData] = React.useState([]);
  const [date,setDate] = React.useState(new Date());
  const [img,setImg] = React.useState("");
  const [eId,setEid] = React.useState([]);
  // const []
  const steps = [
    {
      label: "Header Information",
      form: (
        <HeaderInformationGM
          date={date}
          setDate={setDate}
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Group Configuration",
      form: (
        <GroupConfiguration
          date={date}
          setDate={setDate}
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Content",
      form: (
        <GroupContent
          date={date}
          setDate={setDate}
          img={img}
          setImg={setImg}
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: "Event & Member Configuration",
      form: (
        <EventMemberConfiguration
        date={date}
        setDate={setDate}
    eId={eId}
    setEid={setEid}
          data={data}
          setData={setData}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <Box sx={[style.topContainer, { boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)", }]}>

        <Typography
          sx={[
            style.largeTxt,
          ]}
        >
          Group Management
        </Typography>
      </Box>
      {/* <Box sx={{width:'100%' , border:0.1 ,mt:-4 , borderColor:'grey' }} ></Box> */}
      {/* <div style={{width:'100%' , borderWidth:1 , borderColor:'grey' , marginTop:-30 }}> <hr/></div> */}
     
      <Box sx={[style.topContainer, { height: "7vh", minWidth: "336px" }]}>

      <Typography sx={{
        color: "#252525",
        fontFamily: "MontserratRegular",
        marginLeft: "2%",
        cursor: "pointer",
      }}
      onClick ={ () => {
        navigate('/groupmanagement');
      }}
      >
        Group Management {'>'}
      </Typography>

      <Typography sx={{
        color: "#252525",
        fontFamily: "MontserratRegular",
        marginLeft: "4px",
      }}>
       Create Group
      </Typography>
      </Box>

      <Box sx={{ width: "100%", mx: 5, my: 5, mt: -1 }}>
        <Stepper activeStep={activeStep} orientation="vertical" sx={[
          { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {color: '#FF0000'}} ,
          { ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed ": {color: '#FF0000'}}
          ]}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 3 ? (
                    <Typography variant="caption" sx={{ fontFamily: "MontserratRegular", color: "#252525" }}>Last step</Typography>
                  ) : null
                }
              >
                <Typography sx={[styles.subTitle]}>{step.label}</Typography>
              </StepLabel>
              <StepContent>
                {step.form}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={[{ mt: 1, mr: 1, fontFamily: "MontserratRegular" }]}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={[styles.btn, { mt: 1, mr: 1, width: "200px" }]}
            >
              Publish
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
}
const style = {
  topContainer: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // minWidth:'336px',
    margin: "auto",
    height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: '2%',
  },

}