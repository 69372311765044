import React, { useEffect } from "react";
import { useState } from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import searchIcon from "../../images/searchIcon.png";
import Slide from "@mui/material/Slide";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useNhostClient } from "@nhost/react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import event from "../../images/All Experinces.png";
import group1 from "../../images/Group1.png";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import awakeOriginsImage from "../../images/AWAKEORIGINS.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMore1 = styled((props) => {
  const { expand1, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand1 }) => ({
  transform: !expand1 ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMore2 = styled((props) => {
  const { expand2, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand2 }) => ({
  transform: !expand2 ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DashSearchBar(props) {
  //   const [open, setOpen] = React.useState(false);
  // console.log("propedvalue", props.attendanceCapacity);
  const { onClose, open } = props;
  const [age, setAge] = React.useState("");

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const navigate = useNavigate();
  const [expanded1, setExpanded1] = React.useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const [expanded2, setExpanded2] = React.useState(false);

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };

  const handleClickOpen = () => {
    props.setOpenpop(true);
  };

  const handleClose = () => {
    props.setOpenpop(false);
  };

  const nhost = useNhostClient();

  const [imgUrl, setImgUrl] = useState("");
  const [searchQueryExists, setSearchQueryExists] = useState(false);

  const GET_EVENTS_QUERY = gql`
    query getEvents {
      event_master(
        where: {
          parent_id: { _is_null: true }
          _and: { status: { _eq: "isactive" } }
        }
      ) {
        activation_date
        category_id
        closure_date
        created_at
        deactivation_date
        description
        id
        name
        cover_img_path
        recurrance_end_date
        recurrance_frequency
        recurrance_start_date
        type_id
        updated_at
        location
      }
    }
  `;
  const [Events, setEvents] = useState([]);
  const { loading, error, isError, data } = useQuery(GET_EVENTS_QUERY);
  // console.log("data of all events: ", data);
  // console.log("data", data?.event_master);
  if (isError) {
    // console.log("error is ", error);
  }
  useEffect(() => {
    setEvents(data?.event_master);
    // console.log("data", data?.event_master);
    // console.log("error", error);
  }, [Events]);
  // console.log("events", Events);

  const [eventImageUrls, setEventImageUrls] = useState([]);

  useEffect(() => {
    async function getEventImages() {
      try {
        if (data?.event_master) {
          const imagePromises = data.event_master.map((event) =>
            nhost.storage.getPublicUrl({ fileId: event?.cover_img_path })
          );

          const imageUrls = await Promise.all(imagePromises);
          // console.log("Event Image URLs:", imageUrls);

          setEventImageUrls(imageUrls);
        }
      } catch (error) {
        // console.error("Error retrieving Event image URLs:", error);
      }
    }
    if (data?.event_master) {
      // console.log(
      //   "Event Cover Image Paths:",
      //   data.event_master.map((event) => event?.cover_img_path)
      // );
    }
    getEventImages();
  }, [data]);

  // console.log("Event Image URLs:", eventImageUrls);

  const GET_GROUP_QUERY = gql`
    query getAllGroups {
      group_master(where: { status: { _eq: "isactive" } }) {
        activation_date
        attendance_capacity
        category_id
        closure_date
        deactivation_date
        decription
        id
        cover_img_path
        level_up_group
        name
        price
        publish_to
        type
      }
    }
  `;

  const [group, setGroup] = useState([]);
  const {
    loading: loadgroup,
    error: errorgroup,
    data: groupdata,
  } = useQuery(GET_GROUP_QUERY);
  // console.log("data of all groups", groupdata);
  useEffect(() => {
    setGroup(groupdata?.group_master);

    // console.log("error", error);
  }, [group]);
  // console.log("groups", group);
  // console.log("imagedata", groupdata?.group_master[5].cover_img_path);
  if (groupdata?.group_master) {
    groupdata.group_master.forEach((group) => {
      // console.log("imagedata", group.cover_img_path);
    });
  }

  const GET_MEMBERS_QUERY = gql`
    query getAllUsers {
      profile_details {
        bio
        birth_date
        category
        first_name
        last_name
        profile_pic_path
        second_name
        user_id
        users {
          defaultRole
          displayName
          email
          emailVerified
          id
          phoneNumber
        }
      }
    }
  `;

  const [members, setMembers] = useState([]);
  const {
    loading: loadmembers,
    error: errormembers,
    data: membersdata,
  } = useQuery(GET_MEMBERS_QUERY);
  // console.log("data of all memebrs", membersdata);
  useEffect(() => {
    setMembers(membersdata?.profile_details);
    // console.log("data", membersdata?.profile_details);
    // console.log("error", error);
  }, [members]);
  // console.log("members", members);

  const [memberImageUrls, setMemberImageUrls] = useState([]);

  useEffect(() => {
    async function getMemberImages() {
      try {
        if (membersdata?.profile_details) {
          const imagePromises = membersdata.profile_details.map((member) =>
            nhost.storage.getPublicUrl({ fileId: member?.profile_pic_path })
          );

          const imageUrls = await Promise.all(imagePromises);
          // console.log("Member Profile Image URLs:", imageUrls);

          setMemberImageUrls(imageUrls);
        }
      } catch (error) {
        console.error("Error retrieving Member profile image URLs:", error);
      }
    }

    if (membersdata?.profile_details) {
      // console.log(
      //   "Member Profile Image Paths:",
      //   membersdata.profile_details.map((member) => member?.profile_pic_path)
      // );
    }

    getMemberImages();
  }, [membersdata]);

  // console.log("Member Profile Image URLs:", memberImageUrls);

  const [image, setImage] = useState([]);

  // useEffect(() => {
  //   async function getImage() {

  //     try {
  //       const item = await nhost.storage.getPublicUrl({
  //         fileId: props.img,
  //       });
  //       console.log("Image URL:", item);

  //       setImage(item);
  // // setImgUrl(item)
  //     } catch (error) {
  //       console.error("Error retrieving image URL:", error);
  //     }
  //   }
  //   getImage();
  // }, []);

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    async function getImages() {
      try {
        if (groupdata?.group_master) {
          const imagePromises = groupdata.group_master.map((group) =>
            nhost.storage.getPublicUrl({ fileId: group?.cover_img_path })
          );

          const imageUrls = await Promise.all(imagePromises);
          // console.log("Image URLs:", imageUrls);

          setImageUrls(imageUrls);
        }
      } catch (error) {
        console.error("Error retrieving image URLs:", error);
      }
    }

    getImages();
  }, [groupdata]);

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQueryExists(query.trim() !== ""); // Update the state based on query presence
    setSearchQuery(query);

    let filteredEvents = Events?.filter(
      (item) =>
        (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
        (item.description &&
          item.description.toLowerCase().includes(query.toLowerCase()))
    );
    // console.log("events", filteredEvents);
    let filteredGroups = group?.filter(
      (item) =>
        (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
        (item.description &&
          item.description.toLowerCase().includes(query.toLowerCase()))
    );

    let filteredMembers = members?.filter(
      (item) =>
        (item.first_name &&
          item.first_name.toLowerCase().includes(query.toLowerCase())) ||
        (item.bio && item.bio.toLowerCase().includes(query.toLowerCase()))
    );

    // const filteredData = [
    //   ...filteredEvents,
    //   ...filteredGroups,
    //   ...filteredMembers,
    // ];
    // setFilteredData(filteredData);
    // filteredMembers = filteredMembers || [];
    // filteredEvents = filteredEvents || [];
    // filteredGroups = filteredGroups || [];
    setFilteredEvents(filteredEvents);
    setFilteredGroups(filteredGroups);
    setFilteredMembers(filteredMembers);
  };

  const handleEventNavigate = (
    id,
    name,
    description,
    recurrance_start_date,
    recurrance_end_date,
    location,
    deac,
    imgUrl,
    edate,
    event_category,
    start_time,
    end_time,
    recurrance_frequency
  ) => {
    navigate("/eventmanagement2", {
      state: {
        id,
        name,
        description,
        recurrance_start_date,
        recurrance_end_date,
        location,
        deac,
        imgUrl,
        edate,
        event_category,
        start_time,
        end_time,
        recurrance_frequency,
      },
    });
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <Box
          sx={{
            height: 60,
            backgroundColor: "#FF0000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ marginLeft: 4 }}>
            <img src={require("../../images/navLogo.png")} />
          </Box>
          <Button onClick={handleClose}>
            <Typography sx={{ color: "white" }}>Close</Typography>{" "}
          </Button>
        </Box>

        {/* Group Category */}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <input
            type="search"
            placeholder="Search here"
            // onChange={(e) => {

            //   const query = e.target.value;
            //   setSearchQuery(query);
            //   const filtered = feedData.filter((item) =>
            //     item.header_name.toLowerCase().includes(query.toLowerCase())
            //   );
            //   setFilteredData(filtered);

            // }}

            value={searchQuery}
            onChange={handleSearch}
            style={{
              width: "90%",
              marginTop: 10,
              paddingLeft: "15px",
              height: "50px",
              fontfamily: "MontserratLite",
              border: "1px solid #999999",
              borderRadius: "5px",
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxWidth: 600,
                width: 600,
                borderBottom: 1,
                borderBottomColor: "#999999",
                margin: 5,
              }}
            >
              <CardActions disableSpacing>
                <img sx={{ height: 25, width: 25 }} src={event} alt="event" />
                <Typography
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    marginLeft: 2,
                  }}
                >
                  Events
                </Typography>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  {searchQuery !== "" && (
                    <ImageList
                      sx={{ width: 550, height: 600 }}
                      cols={3}
                      rowHeight={200}
                    >
                      {searchQuery !== ""
                        ? filteredEvents?.map((item, index) => (
                            <ImageListItem
                              key={Events.id}
                              onClick={() =>handleEventNavigate(item.id,item.name,item.description,item.recurrance_start_date,item.recurrance_end_date,item.location,item.description,item.cover_img_path,item.recurrance_end_date,item.event_category,item.start_time,item.end_time)}
                            >
                              <img
                                src={eventImageUrls[index]}
                                srcSet={eventImageUrls[index]}
                                alt={item.name}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.name}
                                subtitle={item.description}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))
                        : filteredEvents?.length > 0
                        ? filteredEvents?.map((item, index) => (
                            <ImageListItem
                              key={Events.id}
                              onClick={() =>handleEventNavigate(item.id,item.name,item.description,item.recurrance_start_date,item.recurrance_end_date,item.location,item.description,item.cover_img_path,item.recurrance_end_date,item.event_category,item.start_time,item.end_time)}

                            >
                              <img
                                src={eventImageUrls[index]}
                                srcSet={eventImageUrls[index]}
                                alt={item.name}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.name}
                                subtitle={item.description}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))
                        : Events?.map((item, index) => (
                            <ImageListItem
                              key={Events.id}
                              onClick={() =>handleEventNavigate(item.id,item.name,item.description,item.recurrance_start_date,item.recurrance_end_date,item.location,item.description,item.cover_img_path,item.recurrance_end_date,item.event_category,item.start_time,item.end_time)}

                            >
                              <img
                                src={eventImageUrls[index]}
                                srcSet={eventImageUrls[index]}
                                alt={item.name}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.name}
                                subtitle={item.description}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))}
                    </ImageList>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxWidth: 600,
                width: 600,
                borderBottom: 1,
                borderBottomColor: "#999999",
                margin: 5,
              }}
            >
              <CardActions disableSpacing>
                <img sx={{ height: 25, width: 25 }} src={group1} alt="group" />
                <Typography
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    marginLeft: 2,
                  }}
                >
                  Groups
                </Typography>
                <ExpandMore1
                  expand1={expanded1}
                  onClick={handleExpandClick1}
                  aria-expanded={expanded1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore1>
              </CardActions>
              <Collapse in={expanded1} timeout="auto" unmountOnExit>
                <CardContent>
                  {searchQuery !== "" && (
                    <ImageList
                      sx={{ width: 550, height: 600 }}
                      cols={3}
                      rowHeight={200}
                    >
                      {searchQuery !== ""
                        ? filteredGroups?.map((item, index) => (
                            <ImageListItem
                              key={group?.id}
                              onClick={() =>
                                navigate("/groupmanagement2", {
                                  state: {
                                    id: item?.id,
                                    name: item?.name,
                                    decription: item?.decription,
                                    activation_date: item.activation_date,
                                    deactivation_date: item.deactivation_date,
                                    cover_img_path: item.cover_img_path,
                                  },
                                })
                              }
                            >
                              <img
                                src={imageUrls[index]}
                                // srcSet={imageUrls}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item?.name}
                                subtitle={item?.decription}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item?.name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))
                        : filteredGroups?.length > 0
                        ? filteredGroups?.map((item, index) => (
                            <ImageListItem
                              key={group.id}
                              onClick={() =>
                                navigate("/groupmanagement2", {
                                  state: {
                                    id: item.id,
                                    name: item.name,
                                    decription: item.decription,
                                    activation_date: item.activation_date,
                                    deactivation_date: item.deactivation_date,
                                  },
                                })
                              }
                            >
                              <img
                                src={imageUrls[index]}
                                // srcSet={imageUrls}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.name}
                                subtitle={item.decription}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))
                        : group?.map((item, index) => (
                            <ImageListItem
                              key={group.id}
                              onClick={() =>
                                navigate("/groupmanagement2", {
                                  state: {
                                    id: item.id,
                                    name: item.name,
                                    decription: item.decription,
                                    activation_date: item.activation_date,
                                    deactivation_date: item.deactivation_date,
                                  },
                                })
                              }
                            >
                              <img
                                src={imageUrls[index]}
                                // srcSet={imageUrls}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.name}
                                subtitle={item.decription}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))}
                    </ImageList>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                maxWidth: 600,
                width: 600,
                borderBottom: 1,
                borderBottomColor: "#999999",
                margin: 5,
              }}
            >
              <CardActions disableSpacing>
                <img
                  sx={{ height: 25, width: 25 }}
                  src={group1}
                  alt="members"
                />
                <Typography
                  sx={{
                    fontFamily: "MontserratRegular",
                    color: "#999999",
                    marginLeft: 2,
                  }}
                >
                  Members
                </Typography>
                <ExpandMore2
                  expand2={expanded2}
                  onClick={handleExpandClick2}
                  aria-expanded={expanded2}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore2>
              </CardActions>
              <Collapse in={expanded2} timeout="auto" unmountOnExit>
                <CardContent>
                  {searchQuery !== "" && (
                    <ImageList
                      sx={{ width: 550, height: 600 }}
                      cols={3}
                      rowHeight={200}
                    >
                      {searchQuery !== ""
                        ? filteredMembers?.map((item, index) => (
                            <ImageListItem
                              key={members.user_id}
                              onClick={() => alert("hrllo", index)}
                            >
                              <img
                                src={memberImageUrls[index]}
                                srcSet={memberImageUrls[index]}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.first_name}
                                subtitle={item.bio}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.first_name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))
                        : filteredMembers?.length > 0
                        ? filteredMembers?.map((item, index) => (
                            <ImageListItem
                              key={members.user_id}
                              onClick={() => alert("hrllo", index)}
                            >
                              <img
                                src={memberImageUrls[index]}
                                srcSet={memberImageUrls[index]}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.first_name}
                                subtitle={item.bio}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.first_name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))
                        : members?.map((item, index) => (
                            <ImageListItem
                              key={members.user_id}
                              onClick={() => alert("hrllo", index)}
                            >
                              <img
                                src={memberImageUrls[index]}
                                srcSet={memberImageUrls[index]}
                                alt={item.first_name}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.first_name}
                                subtitle={item.bio}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                    aria-label={`info about ${item.first_name}`}
                                  ></IconButton>
                                }
                              />
                            </ImageListItem>
                          ))}
                    </ImageList>
                  )}
                </CardContent>
              </Collapse>
            </Card>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
