import { gql,useQuery } from '@apollo/client';
import { Typography } from '@mui/material'
import React, { useEffect } from 'react'

const GET_USER_QUERY = gql`
query MyQuery12($_eq: uuid = "") {
  users(where: {id: {_eq: $_eq}}) {
    displayName
    email
    id
    avatarUrl
  }
}
`;

export default function Temp2(props) {
    // console.log("props in Temp", props.userIds);
    const uid = props.userIds;
    const { loading, error, data: userChat } = useQuery(GET_USER_QUERY,{
        variables: {
          _eq:uid,
        },
        onCompleted:(data) => {
        //   setusers(data?.users)
        console.log("props users", data)
        },
        onerror:(error) => {
            console.log("props error", error)
        }
      });
      console.log("props users", userChat)

      useEffect(() => {
        if (userChat?.users) {
          props.setusers((prevUsers) => [...prevUsers, ...userChat.users]);
      
          return () => {
            // Clear accumulated data when the component unmounts
            props.setusers([]);
          };
        }
      }, [userChat]);

  return (
    <div>
      {/* <Typography>{uid}</Typography> */}
    </div>
  )
}
