import { Box, Typography } from "@mui/material";
import {React,useEffect,useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import FullRedButton from "../components/AdminFeed/FullRedButton";
import Styles from "../styles/ForgotPassword";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [validEmail, setValidEmail] = useState(false);



  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (!isValidEmail(value)) {
      setEmailError("Please enter a valid email address");
      setValidEmail(false);
    } else {
      setEmailError("");
      setValidEmail(true);
    }
  };

  const isValidEmail = (email) => {
    console.log(email)
    // Regular expression for email validation
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const handleButtonClick = async() => {
    if (validEmail) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "x-hasura-admin-secret",
        process.env.REACT_APP_NHOST_SECREATEKEY
      );
  
      var graphql = JSON.stringify({
        query: `query CheckEmail($email: citext = "") {
          users(where: {email: {_eq: $email}}) {
            id
            email
          }
        }`,
        variables: {
          email: email,
        },
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: graphql,
        redirect: "follow",
      };
  
      const res = await fetch(process.env.REACT_APP_URL, requestOptions);
      const data1 = await res.json();
      console.log(data1);
  
      if (data1.data.users.length != 0) {
        navigate("/emailverify");
      } else {
        setEmailError("User does not exist");
      }
    } else {
      setEmailError("Please enter your email address");
    }
  
  };

    // useEffect(() => {
  //   // Calling fetchData Method
  //   fetchData();
  // }, []);

  return (
    <Box
      sx={Styles.container}
    >
      <Box sx={Styles.box}>
        <Box sx={Styles.form}>
          <Box sx={Styles.logoBox}>
            <img
              style={Styles.logo}
              src={require("../images/AWAKEORIGINS.png")}
            />
          </Box>
          <Box sx={Styles.headerBox}>
            <Typography sx={Styles.header}>Forgot Password</Typography>
          </Box>
          <Box sx={Styles.inputBox}>
            <input
              style={Styles.input}
              placeholder="Enter Your Registered Email"
              value={email}
              onChange={handleEmailChange}
            />
             {emailError && <Box><Typography sx={Styles.errorMsg}>{emailError}</Typography></Box>}
            <Box sx={Styles.btnBox}>
              <FullRedButton title="Send Link" click={handleButtonClick} />
            </Box>
          </Box>
          <Box sx={Styles.footerBox} onClick={() => {
                  navigate("/");
                }}>
            <img style={Styles.backButton} src={require('../images/BackButton.png')}/>
          <Typography style={Styles.footerText}>Go To Login Page</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
