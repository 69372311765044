import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import logolight from "../../../images/logolight.png"
import styles from "../../../../src/styles/global"


const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontSize: "10px",
    fontWeight: "bold",
  },
});


export default function ConfirmPasswordPopUp(props) {
  const { onClose, selectedValue, open } = props;

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  

  return (
    <ThemeProvider theme={theme}>
      <Dialog  open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "543px",
            overflow: "hidden",
            
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              backgroundColor: "#FF0000",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img style={{ marginLeft: "10px" }} src={logolight} alt="img" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "543px",
              justifyContent: "center",
              "@media (max-width:768px)":{
                justifyContent: "center",
                width:"480px",
              }
            }}
          >
            <TextField
              variant="outlined"
              sx={Styles.textField}
              label="Old Password"
              id="oldpassword"
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
            />
            <TextField
              sx={Styles.textField}
              label="New Password"
              id="newpassword"
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
            />
            <TextField
              sx={Styles.textField}
              label="Confirm Password"
              id="confirmpassword"
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
            />
          </Box>

          <Box sx={Styles.buttonBox}>
            <Box
              sx={[
                styles.btnDashboard,
                { width: "160px", fontSize: "14px", margin: "4px",height:"40px","&:hover":{
                  cursor: "pointer",
                  backgroundColor:"#999999",
                }},
              ]}
            >
              Change Password
            </Box>
            <Box
              sx={[
                styles.btnDashboard,
                {
                  backgroundColor: "#ffffff",
                  color: "#999999",
                  border: "1px solid #999999",
                  fontSize: "14px",
                  margin: "4px",
                  height:"40px",
                  "&:hover":{
                    cursor: "pointer",
                  }
                },
              ]}
            >
              Cancel
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}





const Styles = {
  textField: {
    margin: "8px",
    width: "400px",
    justifyContent: "center",
    "& fieldset": { border: 'none' },
    ".MuiInputBase-root":{
      border: "1px solid #999999",
      borderRadius: "10px",
    },
    ".MuiOutlinedInput-root":{
      height:"50px",
      
    },
    "@media (max-width:768px)": {
      width: "320px",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    width:"",
    width: "465px",

    justifyContent: "flex-end",
    "@media (max-width:768px)": {
      width: "400px",
    },
  },
};
