import { Box, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import Icon from "@mui/material/Icon";
import { useNavigate } from 'react-router';
import { useSignOut } from "@nhost/react";



export default function Sidebar({ active, setActive, handleClick }) {
  const navigate=useNavigate();

  const { signOut } = useSignOut()

  const handleLogout = () => {
    localStorage.clear();
    signOut();
    navigate("/");
  }

  // const [showSidebar, setShowSidebar] = useState(true);
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(max-width: 768px)");
  //   const handleMatches = () => {
  //     setShowSidebar(!mediaQuery.matches);
  //   };
  //   handleMatches();
  //   mediaQuery.addEventListener("change", handleMatches);
  //   return () => {
  //     mediaQuery.removeEventListener("change", handleMatches);
  //   };
  // }, []);


 
  return (
    <Box sx={styles.main}>
      <>
      <Box sx={styles.top}>
        <Box
          sx={{
            ...styles.item,
            backgroundColor: active === 1 ? "#FF0000" : "#F2F2F2",
            color: active === 1 ? "white" : "inherit",
          }}
          onClick={() => {
            setActive(1);
            handleClick(1);
          }}
        >
        {active === 1 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/ActiveFeed.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/feed.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Home</Typography>
        </Box>

        <Box  sx={{
            ...styles.item,
            backgroundColor: active === 2 ? "#FF0000" : "#F2F2F2",
            color: active === 2 ? "white" : "inherit",
          }}
          onClick={() => {
            setActive(2);
            handleClick(2);
          }}>
          {active === 2 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/ActiveExperiences.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/experiences.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>All Experiences</Typography>
        </Box>

        <Box  sx={{
            ...styles.item,
            backgroundColor: (active === 3) ? "#FF0000" : "#F2F2F2",
            color: active === 3 ? "white" : "inherit",
          }}
          onClick={() => {
            setActive(3);
            handleClick(3);
          }}>
           {active === 3 ? (
        <img
          style={{ marginLeft: "10px",height:'20px',width:'20px' }}
          src={require("../../src/images/ActiveGroup.png")}
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/groups.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Groups</Typography>
        </Box>

        <Box  sx={{
            ...styles.item,
            backgroundColor: active === 4 ? "#FF0000" : "#F2F2F2",
            color: active === 4 ? "white" : "inherit",
          }}
          onClick={() => {
            setActive(4);
            handleClick(4);
          }}>
          {active === 4 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/ActiveSchedule.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/Schedule.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>My Schedule</Typography>
        </Box>

        <Box  sx={{
            ...styles.item,
            backgroundColor: active === 5 ? "#FF0000" : "#F2F2F2",
            color: active === 5 ? "white" : "inherit",
          }}
          onClick={() => {
            setActive(5);
            handleClick(5);
          }}>
         {active === 5 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/ActiveChat.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../src/images/Chat.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Chats</Typography>
        </Box>
      </Box>

      <Box sx={styles.bottom}>
        <Box sx={styles.item}>
          <img
            style={{ marginLeft: "10px" }}
            src={require("../../src/images/Logout.png")}
            width="20px"
            height="20px"
          />
          <Typography sx={styles.txt} onClick={handleLogout}>Log Out</Typography>
        </Box>
      </Box>
      </>
   
    </Box>
  );
}

const styles = {
  main: {
    backgroundColor: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: " ",
    width: "100%",
    '@media (max-width: 768px)':{
      width:"38px",
    }
  },
  top: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    '@media (max-width: 768px)':{
      marginLeft:"0px",
    }
  },
  item: {
    display: "flex",
    flexDirection: "row",
    margin: "auto auto auto 8px",
    alignItems: "center",
    marginY: "10px",
    width: "90%",
    height: "30px",
    borderRadius: "10px",
    cursor: "pointer",
    padding:'2px',
    overflow: "hidden",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "FF0000",
      color: "white",
    },
    '@media (max-width: 768px)':{
      margin:'0px',
      padding:'2px',
      marginY:'4px',
    },
  },
  txt: {
    fontFamily: "MontserratRegular",
    fontSize: "16px",
    marginLeft: "8px",
    '@media (max-width: 998px)':{
      fontSize:"14px"
    },
    '@media (max-width: 768px)':{
      color: 'rgba(0, 0, 0, 0)',
    }
    
  },
  [`@media (max-width: 600px)`]: {
    main: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "10px",
      height: "auto",
      minWidth: "initial",
      

    },
    top: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "10px",
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "10px",
    },
    item: {
      width: "30%",
      height: "40px",
      margin: "10px",
    },
    txt: {
      marginLeft: "10px",
      fontSize: "14px",
    },
  },
};
