import { Box, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";
import Styles from "../styles/eventManagement/EventManagement";
import EventCard from "../components/eventManagement/EventCard";
import Navbar from "../components/Navbar";
import BookedMembers from "../components/eventManagement/BookedMembers";
import Schedule from "../components/eventManagement/Schedule";
import Checklist from "../components/eventManagement/Checklist";
import DetailsBox from "../components/eventManagement/DetailsBox";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import ContentBox from "../components/eventManagement/ContentBox";
import styles from "../../src/styles/global";
import PublishEventPopUp from "../components/eventManagement/PublishEventPopUp";
import { useNhostClient, useUserId } from "@nhost/react";
import { gql, useQuery, useMutation } from "@apollo/client";
import JitsiMeetWrapper from "../components/JitsiMeetWrapper";
import nullimage from "../images/nullImage.jpeg";
import dayjs from "dayjs";

const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      activation_date
      closure_date
      created_at
      cover_img_path
      deactivation_date
      description
      id
      location
      metadata
      name
      parent_id
      publish_status
      is_recurring
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      start_time
      end_time
      type_id
      updated_at
      recording_status
      conven_meeting
      conevene_meeting_id
      event_category {
        created_by
        created_date
        description
        id
        name
      }
      event_faculties {
        event_id
        faculty_type_id
        id
        user_id
      }
      event_type {
        created_by
        created_date
        description
        id
        name
      }
    }
  }
`;

const BOOK_EVENT_MUTATION = gql`
  mutation bookEvent(
    $event_id: bigint = ""
    $is_recurring: Boolean = true
    $recurrance_end_date: date = ""
    $recurrance_start_date: date = ""
    $userid: uuid = ""
    $booking_status: String ="booked"
  ) {
    insert_user_events(
      objects: {
        event_id: $event_id
        is_recurring: $is_recurring
        recurrance_end_date: $recurrance_end_date
        recurrance_start_date: $recurrance_start_date
        userid: $userid
        booking_status:$booking_status
      }
    ) {
      returning {
        event_id
        event_master {
          name
        }
      }
    }
  }
`;

const INSERT_BOOKEVENT_ATTENDENCE = gql`
  mutation BookAttendence(
    $event_id: bigint = ""
    $user_id: uuid = ""
    $end_time: timestamptz = "2023-08-22T12:27:15.318426+00:00"
  ) {
    insert_event_attendance(
      objects: { event_id: $event_id, user_id: $user_id, end_time: $end_time }
    ) {
      returning {
        id
        user_id
        attendance
      }
    }
  }
`;
const GET_PERMISSION_QUERY = gql`
  query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
    role_master(
      where: {
        access_name: { _eq: $_eq }
        _and: {
          roleprofile: {
            user_id: { _eq: $_eq1 }
            _and: { user: { defaultRole: { _eq: $_eq2 } } }
          }
        }
      }
    ) {
      access_name
      description
      id
      roles
      status
      roleprofile {
        user {
          defaultRole
        }
      }
    }
  }
`;

const UNBOOK_EVENT = gql`
mutation deleteunbookevent($_eq: uuid = "", $_eq1: bigint = "") {
  delete_user_events(where: {userid: {_eq: $_eq}, event_id: {_eq: $_eq1}}) {
    returning {
      booking_status
    }
  }
}
`;

const INSERT_UNBOOK_EVENT = gql`
mutation MyMutation2($userid: uuid = "", $event_id: bigint = "", $dereg_date: timestamptz = "") {
  insert_unbookevnt_master(objects: {userid: $userid, event_id: $event_id, dereg_date: $dereg_date}) {
    returning {
      id
    }
  }
}
`;


export default function EventManagement2(props) {
  const [remainingTime, setRemainingTime] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [isJitsiVisible, setIsJitsiVisible] = useState(false);
  const navigate = useNavigate();
  const location1 = useLocation();
  const userid = useUserId();
  // console.log("these are all revents details pp :",currentTime)
  const [faculties, setFaculties] = useState([]);
  const [Events, setEvents] = useState([]);
  const [eventType, setEventType] = useState("");
  const [eventctgry, setEventctgry] = useState("");
  const [eventCatId,setEventCatId] =useState("");
  const [eventTypeId,seteventTypeId] = useState("");
  const [recording , setRecording] = useState(false);
  const [vcMode, setVcMode] = useState(true);
  const [customerMeetId,setcustomerMeetId]= useState("")

  const {
    id,
    isUser,
    membership1,
    name,
    description,
    location,
    imgUrl,
    recurrance_end_date,
    recurrance_start_date,
    event_category,
    event_type,
    metadata,
    start_time,
    end_time,
    event,
    eveBooked,
    conven_meeting
  } = location1.state;
  let tempid=''
  const [fetchId,setFetchId] = useState("")
  
  const { data: permsission } = useQuery(GET_PERMISSION_QUERY, {
    variables: {
      _eq: localStorage.getItem("roleaccess"),
      _eq1: localStorage.getItem("id"),
    },
    onError: (error) => {
      console.log(error, "this is error");
    },
    onCompleted: (data) => {
      // console.log("permission status :", data)
    },
  });
  const [user, setUser] = useState(
    location1.state && location1.state.isUser ? "user" : "admin"
  );
  const [activeTab, setActiveTab] = useState(
    user === "admin" || user === "user" ? 1 : ""
  );
  useEffect(() => {
    if (permsission?.role_master[0]?.roles?.eventdetailbox) {
      setActiveTab(1);
    }
    if (permsission?.role_master[0]?.roles?.eventcontents) {
      setActiveTab(2);
    }
    if (permsission?.role_master[0]?.roles?.eventschedule) {
      setActiveTab(3);
    }
    if (permsission?.role_master[0]?.roles?.eventchecklist) {
      setActiveTab(4);
    }
  }, [
    permsission?.role_master[0]?.roles?.eventchecklist ||
      permsission?.role_master[0]?.roles?.eventdetailbox ||
      permsission?.role_master[0]?.roles?.eventdetailbox ||
      permsission?.role_master[0]?.roles?.eventschedule,
  ]);
  const [activeOption, setActiveOption] = useState("null");
  const [openpop, setOpenpop] = useState(false);
  const [membership, setMembership] = useState(
    location1.state && location1.state.membership1
      ? "groupmember"
      : "nogroupmember"
  );
  // console.log("groupmembership is", membership,"scsc",membership1);
  const openPopUp = () => {
    setOpenpop(!openpop);
  };
  const handleButtonClick = (tabIndex, option) => {
    setActiveTab(tabIndex);
    setActiveOption(option);
  };
  const [bookingEvent] = useMutation(BOOK_EVENT_MUTATION, {
    onCompleted: (data) => {
      // console.log('book zal event')
    },
  });
  const [bookedEventAttendence] = useMutation(INSERT_BOOKEVENT_ATTENDENCE);

  const roll = localStorage.getItem("role");

  const [img, setImg] = useState("");
  const nhost = useNhostClient();
  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: Events.cover_img_path ? Events.cover_img_path : imgUrl,
        });
        // console.log("Image URL:", item);
        setImg(item);
      } catch (error) {
        // console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [Events]);

  const dateObject = new Date(Events?.recurrance_start_date
    ? Events?.recurrance_start_date
    : recurrance_start_date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const formattedStartDate = `${formattedDate}`;

  const dateObjecte = new Date(Events?.recurrance_end_date
    ? Events?.recurrance_end_date
    : recurrance_end_date);
  const daye = dateObjecte.getDate();
  const monthe = dateObjecte.getMonth() + 1; // Month is zero-based, so add 1
  const yeare = dateObjecte.getFullYear();
  const formattedDatee = `${daye < 10 ? '0' : ''}${daye}/${monthe < 10 ? '0' : ''}${monthe}/${yeare}`;
  const formattedEndDate = `${formattedDatee}`;

  // console.log("times coming",recurrance_start_date,start_time,end_time);

  const combinedStartDateTime = `${Events?.recurrance_start_date
    ? Events?.recurrance_start_date
    : recurrance_start_date} ${
    start_time?.split("+")[0]
  }`;
  // console.log('combinedStartDateTime:', combinedStartDateTime);
  const combinedStartDateObject = new Date(combinedStartDateTime);
  // console.log('combinedStartDateObject :', combinedStartDateObject);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  const formattedStartDateTime = combinedStartDateObject.toLocaleString(
    "en-US",
    options
  );
  // console.log('formattedStartDateTime :', formattedStartDateTime);
  const combinedEndDateTime = `${Events?.recurrance_end_date
    ? Events?.recurrance_end_date
    : recurrance_end_date} ${
    end_time?.split("+")[0]
  }`;
  const combinedEndDateObject = new Date(combinedEndDateTime);
  const formattedEndDateTime = combinedEndDateObject.toLocaleString(
    "en-US",
    options
  );
  useEffect(()=>{
    if(event){
      tempid=event.id
      setFetchId(event.id)
      // console.log("inside event",tempid)
    }
    else{
      tempid=id
      console.log("outside event",id)
     
      setFetchId(id)
    }
  refetch()

  },[location1?.state])

  // console.log("which id is this",fetchId)
  // console.log(" event ion",Events)

  const { loading, error, data, refetch } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      id:id?id:event.id,
    },
    onCompleted: (data) => {
      setEvents(data?.event_master);
      setStatus(data?.event_master[0]?.publish_status);
      setEventType(data?.event_master[0]?.event_type.name);
      seteventTypeId(data?.event_master[0]?.event_type.id);
      setEventctgry(data?.event_master[0]?.event_category.name);
      setEventCatId(data?.event_master[0]?.event_category.id);
      setRecording(data?.event_master[0]?.recording_status);
      setcustomerMeetId(data?.event_master[0]?.conevene_meeting_id)
    },
  });

  useEffect(() => {
    setVcMode(data?data?.event_master[0]?.conven_meeting :conven_meeting)
  }, [data]);

  const eventData = {
    eventName: Events?.name
      ? Events?.name
      : name
      ? name.replace(/\s+/g, "")
      : "",
    eventId: Events?.id ? Events?.id : id,
    startDate: Events?.recurrance_start_date
      ? Events?.recurrance_start_date
      : recurrance_start_date,
    endDate: Events?.recurrance_end_date
      ? Events?.recurrance_end_date
      : recurrance_end_date,
    startTime: formattedStartDateTime, // Replace this with the actual field for start time from the API response
    endTime: formattedEndDateTime, // Replace this with the actual field for end time from the API response
    eventType: eventType ? eventType : event_type,
    eventDetails: description,
        // recording: Events.recording,
  };
  // console.log("id is coming from calendar", eventType);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const date = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");
      const customDateString = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
      setCurrentTime(customDateString);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleJoinClick = () => {
    if (isButtonEnabled) {
      joinNowFunction()
      setIsJitsiVisible(true);
    } else {
      console.log("");
    }
  };
 

  useEffect(() => {
    try {
      if (
        eventData.eventType &&
        (eventData.eventType.includes("Live Online") ||
          eventData.eventType.includes("Hybrid Event") ||
          eventData.eventType.includes("Live Virtual"))
      ) {
        if (eventData.startTime && eventData.endTime) {
          const customDateString = new Date(currentTime).getTime();
          const startTime = new Date(eventData.startTime).getTime();
          const endTime = new Date(eventData.endTime).getTime();
          const fiveMinutesInMillis = 5 * 60 * 1000;
          if (
            customDateString >= startTime - fiveMinutesInMillis &&
            customDateString < endTime
          ) {
            setIsButtonEnabled(true);
          } else {
            setIsButtonEnabled(false);
          }
        }
      } else {
        setIsButtonEnabled(false);
      }
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
  }, [
    currentTime,
    eventData.eventType,
    eventData.startTime,
    eventData.endTime,
  ]);

  const buttonStyle = {
    background: isButtonEnabled ? "red" : "grey",
    color: "white",
    width: "100px",
    fontSize: "16px",
    height: "35px",
    // padding: "10px 20px",
    alignItems: "center",
    // padding:"auto",
    textAlign: "center",
    borderRadius: "10px",
    cursor: isButtonEnabled ? "pointer" : "not-allowed",
    margin: "10px",
  };
  const buttonText = isUser ? "Join" : "Join";
  // const status = ["Draft"];
  const [status, setStatus] = useState(null);

  const bookEvents = async (name) => {
    // console.log(id,'for event booking')
    const startDateTime = new Date(data.event_master[0]?.start_time);
    const endDateTime = new Date(data.event_master[0]?.end_time);

    const options = { hour: "numeric", minute: "numeric", hour12: true };

    const formattedStartTime = startDateTime.toLocaleString("en-US", options);
    const formattedEndTime = endDateTime.toLocaleString("en-US", options);
    try {
      // const id = /* Extract the event ID from data */;
      // console.log("start_timr", data.event_master[0]?.start_time);
      // console.log("beforer query");

      if (
        data.event_master[0]?.event_type.description !== "Live Online" &&
        data.event_master[0]?.event_type.description !== "Live Virtual" &&
        data.event_master[0]?.event_type.description !== "Hybrid Event"
      ) {
        // console.log("running");
        // console.log(data.event_master[0].event_type.description);
        await bookedEventAttendence({
          variables: {
            event_id: id,
            user_id: localStorage.getItem("id"),
            // start_time: formattedStartTime,
            // end_time: formattedEndTime,
          },
        });
      }
      await bookingEvent({
        variables: {
          event_id: id,
          userid: localStorage.getItem("id"),
          is_recurring:
            data.event_master[0]?.is_recurring === true ? true : false,
          recurrance_start_date: data.event_master[0]?.recurrance_start_date,
          recurrance_end_date: data.event_master[0]?.recurrance_end_date,
        },
      });

      alert(`${name} Event Booked !`);
    } catch (error) {
      // Handle error here
      console.error("Error while booking event", error);
    }
  };

  const parseAndFormatTime = (time) => {
    if (time) {
      const timeParts = time.split("+")[0].split(":"); // Split and extract time parts
      const formattedTime = dayjs(
        `${timeParts[0]}:${timeParts[1]}`,
        "HH:mm"
      ).format("HH:mm ");
      return formattedTime;
    } else {
      // Handle the case where 'time' is undefined or null
      return "N/A"; // You can change this to a suitable default value
    }
  };

  const formattedStartTime = Events?.start_time
    ? parseAndFormatTime(Events.start_time)
    : parseAndFormatTime(start_time);

  const formattedEndTime = Events?.end_time
    ? parseAndFormatTime(Events.end_time)
    : parseAndFormatTime(end_time);

  // console.log(formattedStartDateTime, "start");
  // console.log(start_time, "start_time");

  // console.log("this is end timwee",Events?.recurrance_end_date)

  const interfaceConfigOverwrite = {
    // Your interface config overwrites here
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    INVITE_ENABLED: false,
    PREJOIN_PAGE_ENABLED: false,
    prejoinPageEnabled: false,
    ENABLE_CLOSE_PAGE: true,
    ENABLE_PREJOIN_PAGE: false,
    ENABLE_WELCOME_PAGE: false,
    enableClosePage: true,
    "prejoinConfig.enabled": false,
    'enableClosePage': true,
    ENABLE_CLOSE_PAGE: true,
    'ENABLE_CLOSE_PAGE': true,
    enableWelcomePage: false,
    DEFAULT_BACKGROUND: '#F5F5F5',

    TOOLBAR_BUTTONS: [
      // Customize the toolbar buttons here
      'camera',
      'closedcaptions',
      'desktop',
      'download',
      'etherpad',
      'filmstrip',
      'fullscreen',
      // 'hangup',
      'help',
      'highlight',
      'microphone',
      'raisehand',
      //  'recording',
      'settings',
      'tileview',
      'toggle-camera',
      'whiteboard',
    ],
    MAIN_TOOLBAR_BUTTONS: ['microphone', 'camera'],

  };
  // const recording = true;

  if (recording) {
    interfaceConfigOverwrite.TOOLBAR_BUTTONS.push('recording');
    // Add the property if recording is true
    interfaceConfigOverwrite.recording = true;
  }
  const [unbookingEvent] = useMutation(UNBOOK_EVENT,{
    onCompleted:(data)=>{
      // console.log("event unbooked")
    },
    onError:(error)=>{
      console.log("error")
    }
  })
  const [insertubookevent] = useMutation(INSERT_UNBOOK_EVENT,{
    onCompleted:(data)=>{
      // console.log("event unbooked")
      deleteevent()
    },
    onError:(error)=>{
      console.log("error",error)
    }
  })

  const deleteevent = async ()=>{
    try {
    await unbookingEvent({
      variables: {
        _eq: userid,
        _eq1: id
      },
    });
    window.location.reload();
  } catch (error) {
    // Handle error if unbooking fails
    console.error("Error while unbooking event", error);
  }
  }

  const unBookevent = async () => {
    // Display a confirmation dialog
    const confirmed = window.confirm("Are you sure you want to unbook this event?");
  
    if (confirmed) {
      try {
        await insertubookevent({
          variables: {
            userid: userid,
            dereg_date: currentTime,
            event_id: id,
          },
        });
        // Alert.alert("Event Unbooked");
      } catch (error) {
        // Handle error if unbooking fails
        console.error("Error while unbooking event", error);
      }
    }
  };
  
  const VideoCall =()=>{
    const meetingLink = location;
    window.open(meetingLink, '_blank');  
  }

   
  // join button api
  const OrganizationID = 121225
  const displayName = localStorage.getItem("name");
  const email = localStorage.getItem("email");

  const joinNowFunction = async () => {
    try {
      // Your API endpoint for joining the meeting
      const apiUrl = `https://portalapi.convenevc.com/api/join-meeting/${customerMeetId}`;
  
      // Request parameters
      const requestData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers if needed
        },
        body: JSON.stringify({
          organizationID: OrganizationID,
          CustomerMeetingID: customerMeetId,
          displayName: displayName,
          email: email,
        }),
      };
  
      // Make the POST request
      const response = await fetch(apiUrl, requestData);
      console.log(response,'ressss api')
      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        console.log('Join now function called successfully');
        // Add any other logic you need after a successful join
      } else {
        // Handle errors
        console.error('Failed to make the POST request:', response.status, response.statusText);
        // Add any error handling logic here
      }
    } catch (error) {
      console.error('An error occurred during the POST request:', error);
    } finally {
      // finally code hrer 
    }
  };
  

  return (
    <Box>
      {/* Header */}
      <Navbar />
      {/* Nav Box */}
      <Box
        sx={Styles.headerBox}
        // onClick={() => {
        //   navigate("/admindash");
        // }}
      >
        {/* <Typography sx={Styles.header}>
          {user == "user" ? "Upcoming Event" : "Event Management"}
        </Typography> */}
        {user === "user" ? (
          <Typography sx={Styles.header}>Upcoming Event</Typography>
        ) : (
          <Typography
            sx={[Styles.header, { cursor: "pointer" }]}
            onClick={() => {
              navigate("/eventmanagement1");
            }}
          >
            Event Management - {Events?.name ? Events?.name : name}
          </Typography>
        )}
        {/* <Typography sx={Styles.header}>
          {user==='user' ? 'Upcoming Event' : 'Event Management'}
        </Typography> */}
      </Box>
      {/* Container */}
      <Box sx={Styles.container}>
        {/* Card Image Box */}
        <Box sx={Styles.card}>
          <Box sx={Styles.imgBox}>
            <img
              src={img && !img.endsWith("null") ? img : nullimage}
              alt="cover image"
              style={Styles.img}
            />
            {/* <img alt="" style={Styles.img} src={img} /> */}
          </Box>
          {/* Card info Box */}
          <Box sx={Styles.info}>
            <Typography sx={Styles.cardHeader}>
              {/* Go Digital Stay Human */}
              {Events?.name ? Events.name : name}
              {/* {name} */}
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                {/* Date */}
                <img alt="" src={require("../images/Calender.png")} />
                <Typography sx={Styles.textCard}>
                  {formattedStartDate} CET{" "}
                  {""}
                  To {""}
                  {formattedEndDate} CET
                </Typography>
              </Box>
              {/* ..............time................................................................... */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <img alt="" src={require("../images/Clock.png")} />
                <Typography sx={Styles.textCard}>
                  {/* 10:30 AM to 11:30 AM */}
                  {formattedStartTime} To {formattedEndTime}
                </Typography>
              </Box>
              {/* ..............time................................................................... */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                {/* Address */}
                <img alt="" src={require("../images/Location.png")} />
                {!vcMode &&
                <a style={Styles.textCard} href={Events?.location ? Events?.location : location}>
                  
                  {/* {location} */}
                  {Events?.location ? Events?.location : location}
                  {/* ABC Road, XYZ Town, PQR Country, 123456 */}
                </a>
}  {vcMode &&
                <Typography style={Styles.textCard}>
                  
                  {/* {location} */}
                  {Events?.location ? Events?.location : location}
                  {/* ABC Road, XYZ Town, PQR Country, 123456 */}
                </Typography>
}
              </Box>
            </Box>
          </Box>
          { eveBooked==="booked" && (

<Box sx={[Styles.threeDots]}>
<Box
  sx={[
    styles.btnDashboard,
    {
      margin: "10px",
      // marginTop: "20px",
      // height:42,
      height: "35px",

      width: "150px",
      fontSize: "12px",
      backgroundColor: "grey",
      // color:"red"
    },
  ]}
>
  <Typography
   onClick={unBookevent}
    style={{ color: "#ffffff", fontFamily: "montserratRegular" }}
  >
    Unbook Event
  </Typography>
</Box>
</Box>


            // <Box sx={[Styles.threeDots]}>
            //   <Box
            //     sx={[
            //       styles.btnDashboard,
            //       {
            //         margin: "4px",
            //         fontSize: "12px",
            //         // backgroundColor: "FF0000",
            //       },
            //     ]}
            //   >
            //     Unbook Event
            //   </Box>
            // </Box>
          )}
          {vcMode &&
          <div> 
            {remainingTime !== null && isJitsiVisible ? (
              <div>
                <Typography>
                  Event starts in: {remainingTime} seconds
                </Typography>
                <button style={buttonStyle} disabled>
                  {buttonText}
                </button>
              </div>
            ) : (
              // <Box style={{height:"35px",display:"flex",alignItems:"center"}}>

              <button
                style={buttonStyle}
                onClick={handleJoinClick}
                disabled={!isButtonEnabled}
              >
                {buttonText}
              </button>
              // </Box>
            )}
          </div>
}{!vcMode &&

            <button
                style={{background:"red", color: "white",
                width: "100px",
                fontSize: "16px",
                height: "35px",
                // padding: "10px 20px",
                alignItems: "center",
                // padding:"auto",
                textAlign: "center",
                borderRadius: "10px",
                cursor: "pointer",
                margin: "10px",}}
                onClick={()=>{VideoCall()}}
                // disabled={!isButtonEnabled}
              >
                {buttonText}
              </button>
}
          {/* .........................................if user is not in grouTypography */}
          {user === "user" && membership === "nogroupmember"&& eveBooked!=="booked" && (
            <Box sx={[Styles.threeDots]}>
              <Box
                sx={[
                  styles.btnDashboard,
                  {
                    margin: "10px",
                    // marginTop: "20px",
                    // height:42,
                    height: "35px",

                    width: "100px",
                    fontSize: "12px",
                    backgroundColor: "#FF0000",
                    // color:"red"
                  },
                ]}
              >
                <Typography
                  onClick={() => {
                    bookEvents(Events?.name ? Events?.name : name);
                  }}
                  style={{ color: "#ffffff", fontFamily: "montserratRegular" }}
                >
                  Book Now
                </Typography>
              </Box>
            </Box>
          )}
          {user === "admin" && (
            <Box sx={Styles.eventStatus}>
              <Typography
                sx={[
                  Styles.textCard,
                  { "& .css-is2u56-MuiTypography-root": { fontSize: "10px" } },
                ]}
              >
                Event Status : {status ? status : "Draft"}
              </Typography>
            </Box>
          )}
          {/* 3 dot Menu */}

          {((permsission?.role_master[0]?.roles?.eventeddit &&
            roll === "staff") ||
            roll === "admin") && (
            <Box sx={Styles.threeDots} onClick={openPopUp}>
              <img
                alt=""
                style={Styles.menu}
                src={require("../images/menu.png")}
              />
            </Box>
          )}

          {/* {openpop && <StartEventPopUp open={openpop} setOpen={setOpenpop} />} */}
          {/* {openpop && <CompleteEventPopUp open={openpop} setOpen={setOpenpop} />} */}
          {/* {openpop && <DownloadReportPopUp open={openpop} setOpen={setOpenpop} />} */}
          {/* {openpop && <MarkAttendencePopUp open={openpop} setOpen={setOpenpop} />} */}
        </Box>
        <Box
          style={{ display: isJitsiVisible ? "block" : "none" }}
          sx={Styles.jitsi}
        >
          <div style={{ width: "100%", height: "100%" }}>
            {isJitsiVisible && (
             <JitsiMeetWrapper recordstatus={data?.event_master[0]?.recording_status} isUser={user} eventData={eventData} interfaceConfigOverwrite={interfaceConfigOverwrite} />
            )}
          </div>
        </Box>
        {/* Navigation Menu */}
        <Box sx={Styles.navBox}>
          <Box sx={Styles.navMenu}>
            {((permsission?.role_master[0]?.roles?.eventdetailbox &&
              roll === "staff") ||
              roll === "admin" ||
              roll === "user") && (
              <Box
                sx={[Styles.nav, activeTab === 1 && styles.activeTab]}
                onClick={() => handleButtonClick(1, "DetailsBox")}
              >
                <img
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                  src={
                    activeTab === 1
                      ? require("../images/edit.png")
                      : require("../images/editGray.png")
                  }
                />
                <Typography sx={Styles.navName}>Details</Typography>
              </Box>
            )}
            {((permsission?.role_master[0]?.roles?.eventcontents &&
              roll === "staff") ||
              roll === "admin" ||
              roll === "user") && (
              <Box
                sx={[Styles.nav, activeTab === 2 && styles.activeTab]}
                onClick={() => handleButtonClick(2, "ContentBox")}
              >
                <img
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                  src={
                    activeTab === 2
                      ? require("../images/ShareRed.png")
                      : require("../images/Share.png")
                  }
                />
                <Typography sx={Styles.navName}>Contents</Typography>
              </Box>
            )}
            {((permsission?.role_master[0]?.roles?.eventschedule &&
              roll === "staff") ||
              roll === "admin" ||
              roll === "user") && (
              <Box
                sx={[Styles.nav, activeTab === 3 && styles.activeTab]}
                onClick={() => handleButtonClick(3, "Schedule")}
              >
                <img
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                  src={
                    activeTab === 3
                      ? require("../images/ScheduleRed.png")
                      : require("../images/Schedule.png")
                  }
                />
                <Typography sx={Styles.navName}>Schedule</Typography>
              </Box>
            )}
            {((permsission?.role_master[0]?.roles?.eventchecklist &&
              roll === "staff") ||
              roll === "admin" ||
              roll === "user") && (
              <Box
                sx={[Styles.nav, activeTab === 4 && styles.activeTab]}
                onClick={() => handleButtonClick(4, "Checklist")}
              >
                <img
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                  src={
                    activeTab === 4
                      ? require("../images/checklistRed.png")
                      : require("../images/Checklist.png")
                  }
                />
                <Typography sx={Styles.navName}>Checklist</Typography>
              </Box>
            )}

            {((permsission?.role_master[0]?.roles?.eventschedule &&
              roll === "staff") ||
              roll === "admin") && (
              <Box
                sx={[Styles.nav, activeTab === 5 && styles.activeTab]}
                onClick={() => handleButtonClick(5, "BookedMembers")}
              >
                <img
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                  src={
                    activeTab === 5
                      ? require("../images/MembersRed1.png")
                      : require("../images/NotificationsGray.png")
                  }
                />
                <Typography sx={Styles.navName}>Booked Members </Typography>
              </Box>
            )}
          </Box>
          {/* Lower main Box */}
          {activeTab === 1 && (
            <DetailsBox
              id={id}
              name={name}
              isUser={user}
              image={Events?.cover_img_path?Events?.cover_img_path :imgUrl}
              recurrance_start_date={
                Events?.recurrance_start_date
                  ? Events?.recurrance_start_date
                  : recurrance_start_date
              }
              recurrance_end_date={
                Events?.recurrance_end_date
                  ? Events?.recurrance_end_date
                  : recurrance_end_date
              }
              description={description}
              eventName={event_category?.name}
              eventTypename={event_type?.name}
              duration={metadata?.duration}
              maxattendance={metadata?.max_attendance}
              faculties={faculties}
            />
          )}
          {activeTab === 2 && (
            <Box sx={Styles.contentBox}>
              <ContentBox id={id} isUser={isUser} />
            </Box>
          )}
          {activeTab === 3 && (
            <Box sx={Styles.scheduleBox}>
              <Schedule id={id} user={user} />
            </Box>
          )}
          {activeTab === 4 && (
            <Box sx={Styles.checklist}>
              <Checklist id={id} user={user} />
            </Box>
          )}
          {activeTab === 5 && (
            <Box sx={Styles.scheduleBox}>
              <BookedMembers id={id} />
            </Box>
          )}
          {openpop && (
            <PublishEventPopUp
              open={openpop}
              setOpen={setOpenpop}
              id={id}
              urlImg={img}
              sdate={
                Events[0]?.recurrance_start_date
                  ? Events[0]?.recurrance_start_date
                  : recurrance_start_date
              }
              edate={
                Events[0]?.recurrance_end_date
                  ? Events[0]?.recurrance_end_date
                  : recurrance_end_date
              }
              name={name}
              location={location}
              description={description}
              event_category={eventctgry}
              eventCatId={eventCatId}
              eventTypeId={eventTypeId}
              vcMode={vcMode}
              event_type={eventType}
              duration={Events[0]?.metadata?.duration}
              attendanceCapacity={metadata?.max_attendance}
              faculties={faculties}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
