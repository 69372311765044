import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { React, useEffect } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSignUpEmailPassword, useUserData } from "@nhost/react";
import { useLocation, useRoutes } from "react-router-dom";
import { useNhostClient } from "@nhost/react";
import { AirplanemodeActive } from "@material-ui/icons";
import { useMutation, gql, useQuery } from "@apollo/client";
import List from "@mui/material/List";

const INSERT_ROLE_MASTER = gql`
  mutation insertroles(
    $access_name: String = ""
    $description: String = ""
    $roles: jsonb = ""
    $status: String = ""
  ) {
    insert_role_master(
      objects: {
        access_name: $access_name
        description: $description
        roles: $roles
        status: $status
      }
    ) {
      returning {
        access_name
        description
        roles
      }
    }
  }
`;
const UPDATE_ROLE_MASTER = gql`
  mutation updatroles(
    $_eq: bigint = ""
    $access_name: String = ""
    $description: String = ""
    $roles: jsonb = ""
  ) {
    update_role_master(
      where: { id: { _eq: $_eq } }
      _set: {
        access_name: $access_name
        description: $description
        roles: $roles
      }
    ) {
      returning {
        roles
        access_name
        description
      }
    }
  }
`;

const GET_ALL_ROLES = gql`
  query getallroles {
    role_master {
      access_name
      description
      roles
      id
      status
    }
  }
`;
export default function AddUser() {
  // const [image, setImage] = useState(null);
  // const router = us
  const location = useLocation();

  //  const datesda =location.state;
  const accessrole_names = [
    {
      rollname: "Event Management",
      keyname: "evntmngmnt",
    },
    {
      rollname: "Group Management",
      keyname: "grpmngmnt",
    },
    {
      rollname: "User Management",
      keyname: "usrmngmnt",
    },
    {
      rollname: "System Configuration",
      keyname: "systmconfig",
    },
    {
      rollname: "Task Management",
      keyname: "taskmngmnt",
    },
    {
      rollname: "Edit Event",
      keyname: "eventeddit",
    },
    {
      rollname: "Event Details",
      keyname: "eventdetailbox",
    },
    {
      rollname: "Event Content",
      keyname: "eventcontents",
    },
    {
      rollname: "Event Schedule",
      keyname: "eventschedule",
    },
    {
      rollname: "Event Checklist",
      keyname: "eventchecklist",
    },
    {
      rollname: "Edit Group",
      keyname: "editgroup",
    },
    {
      rollname: "Edit Details",
      keyname: "grpdetailbox",
    },
    {
      rollname: "Edit Group Contents",
      keyname: "grpcontents",
    },
    {
      rollname: "Edit Group Events",
      keyname: "grpevent",
    },
    {
      rollname: "Edit Group Members",
      keyname: "grpmember",
    },
    {
      rollname: "Edit Group Checklist",
      keyname: "grpchecklist",
    },
    {
      rollname: "Add Event",
      keyname: "addevent",
    },
    {
      rollname: "Add Group",
      keyname: "addgroup",
    },
    {
      rollname: "Add Feed",
      keyname: "addfeed",
    },
    {
      rollname: "Add User",
      keyname: "adduser",
    },
    {
      rollname: "System Configuration Event Type",
      keyname: "addeventtype",
    },
    {
      rollname: "System Configuration Event Category",
      keyname: "addeventcategory",
    },
    {
      rollname: "System Configuration Group Type",
      keyname: "addgrouptype",
    },
    {
      rollname: "System Configuration Group Category",
      keyname: "addgroupcategory",
    },
    {
      rollname: "System Configuration  Facillator Role",
      keyname: "addfaciltor",
    },
    {
      rollname: "Add Task(Dashboard)",
      keyname: "addtask",
    },
    {
      rollname: "Chat Functionality",
      keyname: "chatadmin",
    },
    {
      rollname: "Feed Management",
      keyname: "fdmngmnt",
    },
  ];

  const [checkboxState, setCheckboxState] = useState( location?.state?.roledata || {});

  useEffect(() => {
    if (location?.state?.update) {
      const updatedCheckboxState = { ...checkboxState };

      for (const item of accessrole_names) {
        updatedCheckboxState[item.keyname] =
          location?.state?.row?.roles?.[item.keyname] || false;
      }

      setCheckboxState(updatedCheckboxState);
    }
  }, [location?.state?.update, location?.state?.row]);

  console.log("palalaa", location?.state?.row?.access_name);
  const [initialCheckboxState, setinitialCheckboxState] = useState(
    location?.state?.roledata
  );
  
  const [data, setData] = useState({});
  let mergedCheckboxState = {};

  const handleChange = (key, value) => {
    // console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    const { name, checked } = event.target;
    console.log(name, checked, "selecteredd");
    const updatedCheckboxState = { ...initialCheckboxState };
    updatedCheckboxState[name] = checked;
    setData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    setCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [name]: checked,
    }));
    handleChange(name, checked);
    console.log(updatedCheckboxState);
    setinitialCheckboxState(updatedCheckboxState);
  };
  console.log("pslslsl", mergedCheckboxState);
  console.log("hdhdhdhdh", initialCheckboxState);

  if (location?.state?.update) {
    for (const item of accessrole_names) {
      initialCheckboxState[item.keyname] =
        location?.state?.row?.roles?.[item.keyname] || false;
    }
  }

  const [datat, setDatat] = useState({
    access_name: location?.state?.row?.access_name,
    description: location?.state?.row?.description,
    ...initialCheckboxState, // Add initial checkbox state
  });

  const handleChanget = (key, value) => {
    console.log(key, value);
    setDatat({
      ...datat,
      [key]: value,
    });
  };

  const [insertrolemaster] = useMutation(INSERT_ROLE_MASTER, {
    onCompleted: (data) => {
      console.log("some data updated", data);
    },
  });

  const [updaterolemaster] = useMutation(UPDATE_ROLE_MASTER, {
    onCompleted: (data) => {
      console.log("some data updated", data);
      const confirmation = window.confirm(`Role updated successfully!!`);
      if (confirmation) {
        // console.log("confirmed")
        navigate("/usermanagement", { newUserAdded: true });
      }
    },
    onError: (error) => {
      console.log("error for the update", error);
    },
  });

  const {
    loading: errr,
    error: err,
    data: profileDetails,
    refetch,
  } = useQuery(GET_ALL_ROLES, {
    onCompleted: (data) => {
      // console.log("fet")
    },
  });
  useEffect(() => {
    refetch();
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handlesave = async () => {
    console.log("aksosksj", initialCheckboxState);
    const newErrors = {};
    // Validate name
    if (!datat.access_name) {
      newErrors.access_name = "Please Enter Role Name";
    }
    if (!datat.description) {
      newErrors.description = "Please Enter Description";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      console.log("all the data i required", data, "and", datat);
      if (location?.state?.updaterole) {
        try {
          const { data: insertedData } = await updaterolemaster({
            variables: {
              _eq: location?.state?.id,
              access_name: datat["access_name"]
                ? datat["access_name"]
                : location?.state?.row?.access_name,
              description: datat["description"]
                ? datat["description"]
                : location?.state?.row?.description,
              roles: location?.state?.updaterole
                ? initialCheckboxState
                : location.state.row.roles,
            },
          });
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }
      } else {
        try {
          const { data: insertedData } = await insertrolemaster({
            variables: {
              access_name: datat["access_name"],
              description: datat["description"],
              roles: data,
              status: "true",
            },
          });
          console.log("instered role master", insertedData);
          const confirmation = window.confirm(`Role created successfully!!`);

          if (confirmation) {
            // console.log("confirmed")
            navigate("/usermanagement", { newUserAdded: true });
          }
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }
      }
    }
  };

  // useEffect(() => {
  //   // Check if the location.state data is available
  //   if (location?.state?.row) {
  //     const updatedData = { ...datat };
  //     for (const key in location.state.row) {
  //       if (location.state.row[key] === true) {
  //         updatedData[key] = true;
  //       }
  //     }
  //     setDatat(updatedData);
  //   }
  // }, [location?.state?.row, datat]);

  return (
    <Box sx={{ height: "100%", overflowX: "hidden" }}>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography sx={[Styles.largeTxt, { cursor: "pointer" }]}>
          User Management - Add Roles
        </Typography>
      </Box>
      <Box sx={Styles.mainBox}>
        <Box sx={Styles.firstBox}>
          <Box sx={Styles.headerBox}>
            <Typography sx={Styles.headerText}>Add Roles</Typography>
          </Box>
          <Box sx={Styles.secondBox}>
            <Box sx={Styles.inner2}>
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Role Name"
                required
                variant="outlined"
                onChange={(e) => {
                  handleChanget("access_name", e.target.value);
                }}
                value={datat.access_name}
                error={!!errors.access_name}
                helperText={errors.access_name}
              />
              <TextField
                size="small"
                sx={Styles.textField}
                id="outlined-basic"
                placeholder="Description"
                required
                variant="outlined"
                onChange={(e) => {
                  handleChanget("description", e.target.value);
                }}
                value={datat.description}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>

            <Box sx={Styles.inner2}>
              <List sx={{ pt: 0 }}>
                {accessrole_names.map((item) => (
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "80%",
                        height: "40px",
                        border: "1px solid #999999",
                        borderRadius: "10px",
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                      }}
                    >
                      <FormControlLabel
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiFormControlLabel-label": {
                            fontFamily: "MontserratRegular",
                          },
                          "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                            { color: "red" },
                        }}
                        control={
                          <Checkbox
                            // checked={datat[item.keyname]}
                            checked={checkboxState[item.keyname] || false}

                            onChange={handleCheckboxChange}
                            name={item.keyname}
                            style={{
                              "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                                {
                                  color: "red",
                                },
                            }}
                          />
                        }
                        label={item.rollname}
                      />
                    </Box>
                  </Box>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                width: 200,
                height: "40px",
                border: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 6,
                borderRadius: 2,
                backgroundColor: "#FF0000",
                borderColor: "#FF0000",
              }}
              onClick={handlesave}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "MontserratRegular",
                  fontSize: "bold",
                }}
              >
                SAVE
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
const Styles = {
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
  },
  btn: {
    border: "1px solid #252525",
    filter: " drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: " 10px",
    color: "#252525",
    marginRight: "25px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    // minWidth: "336px",
    margin: "auto",
    height: "8vh",
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  mainBox: {
    width: "100%",
    height: "620px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
    "@media (max-width:768px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  firstBox: {
    marginTop: "25px",
    width: "95%",
    // height: "570px",
    border: "1px solid #999999",
    borderRadius: "10px",
    "@media (max-width:768px)": {
      width: "90%",
      height: "100%",
      margin: "auto",
    },
  },
  headerBox: {
    width: "100%",
    height: "25px",
    borderBottom: "1px solid #999999",
    alignItems: "center",
    // padding:"auto"
  },
  headerText: {
    marginTop: "5px",
    margin: "15px",
    // marginLeft:"15px",
    padding: "auto",
    alignItems: "center",
    fontSize: "14px",
    color: "#252525",
    fontWeight: "bold",
    fontFamily: "MontserratRegular",
  },
  secondBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  inner1: {
    width: "33.33%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  inner2: {
    marginTop: "51px",
    width: "33.33%",
    // height:"50px"
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  textField: {
    width: "90%",
    marginLeft: "15px",
    marginBottom: "15px",
    "& .MuiInputBase-root": {
      fontFamily: "MontserratRegular",
    },
  },
  gender: {
    fontSize: "16px",
    fontFamily: "MontserratMedium",
    // fontWeight: "bold",
    margin: "15px",
    marginLeft: "25px",
    marginRight: "55px",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  uploadPhoto: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'center',
    width: "95%",
    height: "150px",
    border: "1px solid #999999",
    borderRadius: "10px",
    margin: "auto",
    // display:"flex",
    // justifyContent:"center",
    "@media (max-width:768px)": {
      margin: "auto",
    },
  },
  uploadText: {
    margin: "10px",
    fontFamily: "MontserratRegular",
    fontSize: "14px",
    color: "#999999",
  },
  uploadLogo: {
    width: "80px",
    marginLeft: "40%",
    cursor: "pointer",
  },
  uploaded: {
    width: "100%",
    bottom: "0px",
  },
  img: {
    height: "90px",
    width: "90px",
    marginTop: 10,
  },
  uploadInput: {
    border: "1px solid #FF0000",
    fontFamily: "MontserratRegular",
    height: " 35px",
    width: "170px",
    backgroundColor: "#FF0000",
    color: "#ffffff",
    borderRadius: "10px",
    margin: "10px",
    marginLeft: "0px",
    top: 20,
  },
};
