import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreEventCard from "./MoreEventCard";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_RECURRANCE_EVENT = gql`
query MyQueryforrecurrurur12( $_gt: date = "", $_lte: date = "", $_in: [bigint!] = "") {
  event_master(where: {parent_id: {_in: $_in}, _and: {recurrance_start_date: {_gt: $_gt}, _and: {recurrance_start_date: {_lte: $_lte}}}}, order_by: {recurrance_start_date: asc}) {
    activation_date
    closure_date
    category_id
    cover_img_path
    created_at
    deactivation_date
    description
    end_time
    id
    is_recurring
    location
    metadata
    name
    parent_id
    publish_to
    recurrance_end_date
    recurrance_frequency
    recurrance_start_date
    start_time
    updated_at
    type_id
    conven_meeting
  }
}
`;

export default function Dashcard1(props) {
  var previousDate = new Date(new Date().setDate(new Date().getDate() - 5))
    .toISOString()
    .split("T")[0];
  // console.log("previousDate",previousDate);
  var nextDate = new Date(new Date().setDate(new Date().getDate() + 30))
    .toISOString()
    .split("T")[0];
  // console.log("newDate",nextDate);

  // console.log(props.id  ,"date we are expecting")
  const { data: allrecurrance, error: recurranceall } = useQuery(
    GET_RECURRANCE_EVENT,
    {
      // skip: Category === null,
      variables: {
        _in: props.id,
        _gt: previousDate,
        _lte: nextDate,
      },
    }
  );
  const [datarecurring, setdatarecurring] = useState([]);
  console.log("tjis is in dashcard1", datarecurring);
  useEffect(() => {
    if (allrecurrance?.event_master.length > 0) {
      setdatarecurring((prevData) => [
        ...prevData,
        ...allrecurrance.event_master,
      ]);
    }
  }, [allrecurrance]);
  return (
    <div>
      {datarecurring?.map((item, index) => (
        <MoreEventCard
          data={item}
          key={props.key}
          //   key={item.event_id}
          //   img={item.event_master.cover_img_path}
          //   title={item.event_master.name}
          //   desc={item.event_master.description}
          //   date={item.event_master.recurrance_start_date}
          //   endDate={item.event_master.recurrance_end_date}
          //   time={item.event_master.time}
          //   address={item.event_master.location}
        />
      ))}
      {/* <Box style={{cursor:"pointer"}} onClick={()=>EventClick(item.event_master.id,item.event_master.cover_img_path,item.event_master.name,item.event_master.description,item.event_master.recurrance_start_date,item.event_master.recurrance_end_date,item.event_master.location)}>
              <MoreEventCard
                key={item.event_id}
                img={item.event_master.cover_img_path}
                title={item.event_master.name}
                desc={item.event_master.description}
                date={item.event_master.recurrance_start_date}
                endDate={item.event_master.recurrance_end_date}
                time={item.event_master.time}
                address={item.event_master.location}
              />
              </Box> */}
    </div>
  );
}
