import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import styles from "../../styles/global";
import logolight from "../../images/logolight.png";
import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useFileUpload, useUserId } from "@nhost/react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const INSERT_NEW_TASK = gql`
mutation InsertNewTask($description: String = "", $file_path: String, $staff_id: uuid, $target_date: date = "", $task_name: String = "", $rpt_uid: uuid = "", $status: String = "inCompleted") {
  insert_task_master(objects: {description: $description, file_path: $file_path, staff_id: $staff_id, target_date: $target_date, task_name: $task_name, rpt_uid: $rpt_uid, status: $status}) {
    returning {
      description
      file_path
      id
      staff_id
      target_date
      task_name
      status
    }
  }
}
`;

const GROUP_FIND_QUERY = gql`
query MyQuery($_eq: uuid = "") {
  user_groups(where: { user_id: { _eq: $_eq } }) {
    group_id
    user_id
  }
}
`;
const GET_All_EVENT_ID = gql`
query MyQuery29($_eq: uuid = "") {
  user_events(where: {userid: {_eq: $_eq}}, distinct_on: event_id) {
    event_id
    userid
    users {
      displayName
    }
  }
}
`;
const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontSize: "10px",
    fontWeight: "bold",
    borderRadius:"10px"
  },
});

const GROUP_MEMBERS_QUERY = gql`
query MyQuery9($_eq: bigint, $_neq: uuid = "") {
  user_groups(
    where: { group_id: { _eq: $_eq }, _and: { user_id: { _neq: $_neq } } }
  ) {
    user_id
    group_id
    id
    users {
      email
      displayName
      id
    }
  }
}
`;
const EVENT_MEMBERS_QUERY = gql`
query MyQuery4($_eq: bigint) {
  user_events(where: { event_id: { _eq: $_eq } }, distinct_on: userid) {
    userid
    users {
      displayName
      id
      email
    }
  }
}
`;
const GET_All_USER_ID = gql`
query MyQuery30($_eq: bigint = "") {
  user_events(where: {event_id: {_eq: $_eq}}, distinct_on: userid) {
    userid
    users {
      displayName
    }
  }
}
`;

const GET_All_MODERATOR = gql`
query MyQuery27 {
  profile_details(where: {roles: {_eq: "Moderator"}}) {
    first_name
    user_id
  }
}
`;
const BLOCKED_USER = gql`
  query MyQuery23($_eq: uuid = "") {
    group_userchat(
      where: { sender_id: { _eq: $_eq }, _and: { status: { _eq: "blocked" }}}, distinct_on: receiver_id ) {
      group_id
      status
      sender_id
      receiver_id
      receiveruser {
        displayName
        id
      }
    }
  }
`;
const BLOCK_USER_MUTATION = gql`
  mutation MyMutation5($_eq: uuid = "", $_eq1: uuid = "") {
    update_group_userchat(
      where: { receiver_id: { _eq: $_eq }, _and: { sender_id: { _eq: $_eq1 } } }
      _set: { status: "unblocked" }
    ) {
      returning {
        group_id
        receiver_id
        sender_id
        status
      }
    }
  }
`;
export default function ReportIssue(props) {
  //   const { onClose, selectedValue, open } = props;
const [evntId, setevntId] = useState([]);
const [grpId, setGrpId] = useState([]);
const [usersId, setUsersId] = useState([]);
const [searchText, setSearchText] = useState(""); // Track the search input
const [update, setUpdate] = useState(false);
const [imgUrl, setImgUrl] = useState(null);
const [staff, setStaff] = useState([]);
const [eventId , setEventId] = useState([])
  const [open, setOpen1] = useState(true);
 
  const [error, setError] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id:cover_img_path,
    bucketId,
    name,
  } = useFileUpload();
//   console.log("email", localStorage.getItem("email"));
  const { data: moderator,refetch:moderat } = useQuery(GET_All_MODERATOR);
  useEffect(() => {
    setStaff(moderator?.profile_details);
    // seteventsAll(eventsData?.event_master);
  //   setgroupsAll(grpsData?.group_master);
    // console.log("data",moderator);
  }, [moderator?.profile_details]);

  const userId = useUserId()
  const [blocked, setBlocked] = useState([]);
  const { data:blockeduser , refetch } = useQuery(BLOCKED_USER, {
    variables: {
      _eq: userId,
    },
    onCompleted:(data)=>{
        setBlocked(data?.group_userchat)
        // console.log("all data",blockeduser)
    }
  });


  // ..............................fetch old pasword..........................................
  const [oldPassword, setOldPassword] = useState("");
  const [show, setShow] = useState(false);
  const { data: eventsid, refetch: eveid } = useQuery(GET_All_USER_ID, {
    variables: {
      _eq: evntId,
    //   _neq: userId,
    },
    onCompleted: (data) => {
    //   setUserName(data.user_events);
    // console.log("user ids  data" , data)
    },
    onError:(error)=>{
        console.log("your error is" , error)
    }
  });
  const {data: grp1Chat} = useQuery(GROUP_FIND_QUERY, {
    variables: {
      _eq: userId,
    },
    onCompleted: (data) => {
    //   console.log("this is grp find query", data?.user_groups);
      setGrpId(data?.user_groups[0]?.group_id);
    },
  });
  // ...................................fetch password ..................................................

  const { data: eventsDataid,refetch:eveReid } = useQuery(GET_All_EVENT_ID,{
    variables:{
        _eq: userId
    },
    onCompleted:(data)=>{
        // setEventId(data.user_events)
        const newEventIds = data.user_events.filter((newEvent) => {
            return !eventId.some((existingEvent) => existingEvent.userid === newEvent.userid);
          });
      
          // Add the new event IDs to the eventId array
          setEventId((prevEventIds) => [...prevEventIds, ...newEventIds]);
          setevntId(data?.user_events[0]?.id)
    }
});
const {
    loading: memberGrpLoad,
    error: memberGrpErr,
    data: memberGrpChat,
    refetch: memberGrpRefetch,
  } = useQuery(GROUP_MEMBERS_QUERY, {
    variables: {
      _eq: grpId,
      _neq: userId,
    },
    onCompleted: (data) => {
      // console.log("this is grp member query", data?.user_groups);
      setUsersId(data?.user_groups);
      // setGrpId(data?.user_groups[0]?.group_id)
      
    },
  });
  const {
    loading: memberevntLoad,
    error: memberevntErr,
    data: memberevntChat,
    refetch: memberevntRefetch,
  } = useQuery(EVENT_MEMBERS_QUERY, {
    variables: {
      _eq: eventId,
    },
    onCompleted: (data) => {
        // console.log("event users ",data.user_events)
      const filteredUserEvents = data?.user_events.filter((eventUser) => {
        const isLoggedInUser = userId === eventUser.userid;
        const isExistingUser = usersId.some(
          (groupUser) => groupUser.user_id === eventUser.userid
        );
        return !isLoggedInUser && !isExistingUser;
      });
    //   console.log(filteredUserEvents,"user from events")
      setUsersId((prevUsers) => prevUsers.concat(filteredUserEvents));
      // setGrpId(data?.user_groups[0]?.group_id)
    },
  });

// console.log("all the users :" , filteredUsers)
// const [data, setData] = useState({});


  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // width: "643px",
            overflow: "hidden",
            borderRadius:"10px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              backgroundColor: "#FF0000",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img style={{ marginLeft: "10px" }} src={logolight} alt="img" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "543px",
              justifyContent: "center",
              "@media (max-width:768px)": {
                justifyContent: "center",
                width: "480px",
              },
            }}
          >
            


<Box sx={{width:'100%' , display:'flex' , justifyContent:'center' , alignItems:'center' , flexDirection:'column'}}>
<Typography sx={{color:'black' , fontSize:25}} >Blocked Users</Typography>
{blocked?.map((item) => (
<Typography sx={{color:'black' , fontSize:15}} >{item.receiveruser?.displayName}</Typography>
    ))}
</Box>
               
            

            {error && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "FF0000" }}>{error}</Typography>
              </Box>
            )}
          </Box>

          <Box sx={Styles.buttonBox}>
           
            <Box
              sx={[
                styles.btnDashboard,
                {
                  backgroundColor: "#ffffff",
                  color: "#999999",
                  border: "1px solid #999999",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              onClick={handleClose}
            >
              Cancel
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

const Styles = {
  textField: {
    margin: "8px",
    width: "400px",
    justifyContent: "center",
    "& fieldset": { border: "none" },
    ".MuiInputBase-root": {
      border: "1px solid #999999",
      borderRadius: "10px",
    },
    ".MuiOutlinedInput-root": {
      height: "50px",
    },
    "@media (max-width:768px)": {
      width: "320px",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    width: "",
    width: "465px",
    justifyContent: "center",
    "@media (max-width:768px)": {
      width: "400px",
    },
  },
  errorBox: {
    width: "72%",
    display: "flex",
    justifyContent: "flex-start",
  },
};
