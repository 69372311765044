import { React, useState, forwardRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import navLogo from "../../images/navLogo.png";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { useLocation } from "react-router";
import { gql, useQuery, useMutation } from "@apollo/client";
const GET_CHECKLIST_DETAILS=gql`
query GetEventTask($_eq: bigint ="") {
  task_master(where: {event_id: {_eq: $_eq}}) {
    description
    event_id
    file_path
    group_id
    id
    staff_id
    target_date
    status
    task_name
    user {
      displayName
      profile_detail {
        first_name
      }
    }
  }
}
`
const GET_ALL_TASKS = gql`
query MyQuery11($_eq: bigint = "") {
  task_master(where: {group_id: {_eq: $_eq}}) {
    description
    group_id
    id
    staff_id
    status
    target_date
    task_name
    user {
      displayName
      profile_detail {
        first_name
      }
    }
  }
}
`;
const GET_ALL_STAFF = gql`
query getAllStaff {
  profile_details(where: {user: {defaultRole: {_eq: "staff"}}}) {
    bio
    birth_date
    category
    first_name
    last_name
    profile_pic_path
    second_name
    user_id
    user {
      defaultRole
      displayName
      email
      emailVerified
      id
      phoneNumber
    }
  }
}
`;

const UPDATE_TASK_MASTER = gql`
mutation MyMutation6($_eq: bigint = "", $description: String = "", $staff_id: uuid = "", $status: String = "", $target_date: date = "", $task_name: String = "") {
  update_task_master(where: {id: {_eq: $_eq}}, _set: {description: $description, staff_id: $staff_id, status: $status, target_date: $target_date, task_name: $task_name}) {
    returning {
      id
      task_name
      target_date
      description
      status
      staff_id
    }
  }
}
`;

const INSERT_TASK_MASTER_EVENT = gql`
mutation InsertNewTaskEvents($description: String = "", $event_id: bigint = "", $staff_id: uuid = "", $status: String = "", $target_date: date = "", $task_name: String = "") {
  insert_task_master(objects: {description: $description, event_id: $event_id, staff_id: $staff_id, status: $status, target_date: $target_date, task_name: $task_name}) {
    returning {
      description
      event_id
      id
      staff_id
      status
      target_date
      task_name
    }
  }
}
`;

const INSERT_TASK_MASTER_REGULAR = gql`
mutation InsertNewTaskEvents($description: String = "", $group_id: bigint = "", $staff_id: uuid = "", $status: String = "", $target_date: date = "", $task_name: String = "") {
  insert_task_master(objects: {description: $description, group_id: $group_id, staff_id: $staff_id, status: $status, target_date: $target_date, task_name: $task_name}) {
    returning {
      description
      group_id
      id
      staff_id
      status
      target_date
      task_name
    }
  }
}
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
}));
export default function EditTaskPop(props) {
  const [open, setOpen] = useState({});
  console.log("is this for Event?", props.isEvent);
  // console.log("is for update??", props.isforUpdate);
  const [update, setUpdate] = useState(props?.isforUpdate);
  console.log("is for update??", update);
  // console.log("value", update);
  const tasks = props?.Tasks;
  // console.log("props data in task", props.Tasks?.target_date);
  const [task, setTask] = useState([]);
  console.log("id",props.id);
  const { data:checklistdata,isError,refetch } = useQuery(GET_CHECKLIST_DETAILS ,{

    variables: {
            _eq: props.eventId,
          },
        })
  ;
  if(isError){
  console.log("error is ", error);
  }
  
  console.log("set data checklist", checklistdata);
  useEffect(() => {
    setTask(checklistdata?.task_master);
    console.log("checklistdata",checklistdata?.task_master)
  },[checklistdata]);

  const [Tasks, setTasks] = useState([]);
  console.log("taskaaaa", props.id);
  const {  data:taskss ,refetch:refetch1} = useQuery(GET_ALL_TASKS, {
    variables: { 
      _eq: props.grpId,
    },
    onError:(error) => {
      console.log("error is" , error);
    }
  })

  useEffect(()=>{
    console.log("data is every thing " , taskss)
    setTasks(taskss?.task_master);
  },[taskss])




  const date = props.Tasks?.target_date;
  const statusUpdate = props.Tasks?.status;
  const staffId = props.Tasks?.staff_id;
  const [newId, setNewId] = useState("");
  const {
    loading: grpCatLoading,
    error: grpCatError,
    data: staffData,
  } = useQuery(GET_ALL_STAFF);


  useEffect(() => {
    setStaff(staffData?.profile_details);
    
  }, [staffData]);

useEffect(() => {
  if (props.eventId) {
    setNewId(props.eventId);
  } else {
    setNewId(props.grpId);
  }
}, []); 
console.log("this is new id ....",newId)
  const handleClose = () => {
    props.setOpen(false);
  };
// writing code from here
const [updateTaskMaster, { loading, error }] = useMutation(UPDATE_TASK_MASTER,
  {
    onCompleted: (data) => {
      console.log("update task Master : ", data)
      refetch();
     },
     refetchQueries: [
      // Provide the name of the query you want to refetch after deletion
      // For example:
       { query: GET_CHECKLIST_DETAILS}
    ],
  })

  const [insertTaskMasterEvent] = useMutation(INSERT_TASK_MASTER_EVENT,
    {
      onCompleted: (data) => {
        console.log("update task Master : ", data)
        refetch();
       },
       refetchQueries: [
        // Provide the name of the query you want to refetch after deletion
        // For example:
         { query: GET_CHECKLIST_DETAILS}
      ],
    })

    const [insertTaskMasterRegular] = useMutation(INSERT_TASK_MASTER_REGULAR,
      {
        onCompleted: (data) => {
          console.log("update task Master : ", data)
          refetch1();
         },
         refetchQueries: [
          // Provide the name of the query you want to refetch after deletion
          // For example:
           { query:GET_ALL_TASKS}
        ],
      })

  const handleSave = async (id, taskID) => {
    if (update) {
      try {
        const { data:insertedData } = await updateTaskMaster({
          variables: {
            _eq: taskID,
            // group_id:id,
            description: data["description"] || "",
            task_name: data["task_name"] || "",
            target_date: data["startDate"] || "",
            staff_id: dropdown || "",
            status: selectedValue || "",
          },
        });
        console.log("instered group header info",insertedData)
        setOpen(false);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
            
    } else {

      if (props.isEvent) {
        // Insert mutation query for events
        try {
          const { data:insertedData } = await insertTaskMasterEvent({
            variables: {
              description: data["description"],
              event_id: id,
              staff_id: dropdown,
              status: selectedValue,
              target_date: data["startDate"],
              task_name: data["task_name"],
            },
          });
          console.log("instered task for this event",insertedData)
          setOpen(false);
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }
       
      } else {
        // Insert mutation query for regular tasks
        try {
          const { data:insertedData } = await insertTaskMasterRegular({
            variables: {
              group_id: id,
            description: data["description"],
            task_name: data["task_name"],
            target_date: data["startDate"],
            staff_id: dropdown,
            status: selectedValue,
            },
          });
          console.log("instered task for this event",insertedData)
          setOpen(false);
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }
      } 

    }
  };

  const [data, setData] = useState(tasks);
  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };
  const [staff, setStaff] = useState([]);
  // const [date, setDate] = useState();
  const [dropdown, setDropdown] = useState(staffId);
  const [selectedValue, setSelectedValue] = useState(statusUpdate);
  console.log("selected value",selectedValue);
  console.log("dropdown values", dropdown);

  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setData((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <Box>
          {/* <Typography>hello</Typography> */}
          <Box
            sx={{
              height: "40%",
              width: 600,
              backgroundColor: "red",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={navLogo}
              alt="temp"
              style={{ padding: 10 }}
              height={35}
              width={100}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Box sx={{ width: "100%", mt: 1 }}>
              <Box sx={{ m: 1 }}>
                <TextField
                  id="filled-multiline-flexible"
                  // label="Multiline"
                  sx={{ width: "100%", marginBottom: "10px" }}
                  placeholder="Task Name"
                  value={data?.task_name}
                  onChange={(e) => {
                    handleChange("task_name", e.target.value);
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{ width: "100%" }}
                    // lable="Status"
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    lable="Status"
                    value={selectedValue}
                    onChange={(event) => setSelectedValue(event.target.value)}
                  >
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                    <MenuItem value={"Not Yet Completed"}>Not Yet Completed</MenuItem>
                    <MenuItem value={"In progress"}>In progress</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ my: 1, mx: 0.5 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    // inputFormat="MM/DD/YYYY"
                 
                    format="YYYY/MM/DD"
                    size="small"
                    label={date}
                    placeholder={date}
                    value={data?.startDate}
                          sx={{
                      width: "97.5%",
                      marginLeft: "4px",
                      "& .MuiInputBase-root": {
                        fontFamily: "MontserratRegular",
                        height: "40px",
                      },
                      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                        fontFamily: "MontserratRegular",
                        color: "#999999",
                        fontSize: "14px",
                        lineHeight: "15px",
                      },
                    }}
                    onChange={(e) => {
                      handleDateChange("startDate", e);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box sx={{ width: "100%", my: 1 }}>
              <Box shandleSavex={{ m: 1 }}>
                <TextField
                  id="filled-multiline-flexible"
                  // label="Multiline"
                  sx={{ width: "95%", marginTop: "8px", marginBottom: "0px" }}
                  placeholder="Description"
                  multiline
                  rows={3.8}
                  // variant="filled"
                  value={data?.description}
                  onChange={(e) => {
                    handleChange("description", e.target.value);
                  }}
                />
              </Box>

              <Box
                sx={{
                  my: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{ width: "95%" }}
                  >
                    Assign to
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"                   
                    size="small"
                    label="Assign to"
                    sx={{ width: "95%" }}
                    value={dropdown}
                    onChange={(e) => setDropdown(e.target.value)}
                  >
                    {staff?.map((row) => (
                      <MenuItem value={row.user?.id}>{row.first_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{ mx: 1, backgroundColor: "#FF0000", color: "white" }}
                  onClick={() => handleSave(newId, props.Tasks?.id)}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
