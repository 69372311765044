import { Box, Typography } from "@mui/material";

import { useNhostClient } from "@nhost/react";

import { React, useEffect, useState } from "react";

import ContentBoxPopup from "../Popup/GroupManagementEditPopups/ContentPop";

import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";

import { gql, useQuery, useMutation } from "@apollo/client";

const INSERT_DOCUMENT_IMAGE = gql`
  mutation AddContent(
    $associated_event_id: bigint
    $associated_group_id: bigint
    $file_name: String = ""
    $link: String = ""
    $published_by: String = ""
    $type: String = ""
    $uploaded_by: String = ""
  ) {
    insert_content_master(
      objects: {
        associated_event_id: $associated_event_id
        associated_group_id: $associated_group_id
        file_name: $file_name
        link: $link
        published_by: $published_by
        type: $type
        uploaded_by: $uploaded_by
      }
    ) {
      returning {
        associated_event_id

        associated_group_id

        category_id

        expiry_date

        file_name

        id

        link

        published_by

        publishing_date

        type

        upload_date

        uploaded_by
      }
    }
  }
`;

const DELETE_DOCUMENT_IMAGE = gql`
  mutation MyMutation5($_eq: bigint) {
    delete_content_master(where: { id: { _eq: $_eq } }) {
      returning {
        id

        file_name
      }
    }
  }
`;

const GET_GROUP_CONTENT = gql`
  query GetGroupContent($id: bigint = "") {
    content_master(where: { associated_group_id: { _eq: $id } }) {
      associated_event_id

      associated_group_id

      category_id

      expiry_date

      file_name

      id

      link

      published_by

      publishing_date

      type

      upload_date

      uploaded_by
    }
  }
`;

export default function ContentBox(props) {
  const [imageUrls, setImageUrls] = useState([]);
  const [images, setImages] = useState([]); 
  const [urls, setUrls] = useState([]);
  const [images1, setImages1] = useState([]);
  const {
    add,

    upload,

    cancel,

    isUploaded,

    isUploading,

    isError,

    progress,

    id: cover_img_path,

    bucketId,

    name,
  } = useFileUpload();

  const {
    loading,
    error,
    data: contentdata,
    refetch,
  } = useQuery(GET_GROUP_CONTENT, {
    variables: {
      id: props.id,
    },

    // skip: !id
  });

  useEffect(() => {
    setContent(contentdata?.content_master);

    // console.log(contentdata)
  }, [contentdata]);

  const filealldata = {};

  const [insertDocumentImage] = useMutation(INSERT_DOCUMENT_IMAGE, {
    onCompleted: (data) => {
      // console.log("document and image data updated");

      refetch();
    },
  });

  const [deleteDocumentImage] = useMutation(DELETE_DOCUMENT_IMAGE, {
    onCompleted: (data) => {
      // console.log("document and image data updated");

      refetch();
    },
  });

  const [docName, setDocName] = useState("");

  const [content, setContent] = useState([]);

  const handleContentManagement = async (fileId) => {
    // const handleContentManagement = async (fileId, fileName) => {

    try {
      const { data: insertedData } = await insertDocumentImage({
        variables: {
          associated_group_id: props.id,

          link: fileId,

          file_name: docName,
        },
      });

      // console.log("instered group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  const handleImageClick = () => {
    const input = document.createElement("input");

    input.type = "file";

    input.accept = "*/*";

    input.onchange = async (event) => {
      nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let file = event.target.files[0];

      // setImage(URL.createObjectURL(file));

      // filealldata=file

      setDocName(file.name);

      await upload({ file });
    };

    input.click();
  };

  const [docUpload, setDocUpload] = useState(true);

  if (isUploaded && docUpload) {
    // console.log("done doen", cover_img_path, filealldata, name);

    handleContentManagement(cover_img_path);

    setDocUpload(false);
  }

  const [roles, setRoles] = useState();
  const nhost = useNhostClient();
  const [openpop, setOpenpop] = useState(false);
  const [imgPreview, setImgPreview] = useState();
  const [fileName, setFileName] = useState("");

  const onContentClick = async (id,name) => {
    setOpenpop(!openpop);
    setFileName(name);

    // console.log('id', id)

    // const publicUrl = await nhost.storage.getPresignedUrl({

    //   fileId: id,

    // })

    try {
      const item = await nhost.storage.getPublicUrl({
        fileId: id,
      });
      setImgPreview(item);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  };
  const deleteContent = async (id, name) => {
    const confirmation = window.confirm(
      `Are you sure you want to delete ${name}?`
    );

    // console.log(id, "deleting id");

    if (confirmation) {
      // Perform the deletion logic

      try {
        const { data: insertedData } = await deleteDocumentImage({
          variables: {
            _eq: id,
          },
        });

        // console.log("instered group header info", insertedData);

        alert(`Content ${name} Deleted successfully`);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }

      // ...

      // alert(`Content ${name} deleted successfully`);
    }
  };

  useEffect(()=>{
    // setRoles(roledata?.users[0]?.defaultRole)
    setContent(contentdata?.content_master);
        // setIsLoading(false);
        const images = contentdata?.content_master?.map((item) => ({
          link: item.link,
          fileName: item.file_name,
        }));
        setImages(images);

        // console.log("images",images);

        const imageUrlsData = images?.map((item) => {
          if (
            item.fileName.endsWith(".jpeg") ||
            item.fileName.endsWith(".jpg") ||
            item.fileName.endsWith(".png")
          ) {
            return item.link;
          }
          return null;
        });

        // console.log("images urls ",imageUrlsData);
        setImageUrls(imageUrlsData);

  },[contentdata])

  useEffect(() => {
    const imageIds = imageUrls;
    const convertedUrls = [];
    const fetchImageUrls = async () => {
      for (let i = 0; i < imageIds?.length; i++) {
        const imageId = imageIds[i];
        if (imageId) {
          const publicUrl = await nhost.storage.getPresignedUrl({
            fileId: imageId,
          });
          convertedUrls.push(publicUrl.presignedUrl.url);
        } else {
          convertedUrls.push(null);
        }
      }
      setUrls(convertedUrls);
    };
    fetchImageUrls();
  }, [imageUrls]);

  useEffect(() => {
    mapUrlsToImages();
  }, [urls]);

  const mapUrlsToImages = () => {
    const mappedImages = urls.map((url, index) => {
      if (url) {
        const fileExtension = url.split('.').pop().toLowerCase();
        const imageSource = getFileImage(fileExtension, index);
        return {
          link: url,
          fileName: imageSource,
        };
      } else {
        return {
          link: null,
          fileName: null,
        };
      }
    });
    setImages1(mappedImages);
    // setIsLoading(false); 
  };

  const getFileImage = (fileExtension, index) => {
    if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
      return images1[index]?.link; // Return the URL directly
    } else if (fileExtension === "pdf") {
      return require("../../images/PDF.png"); // Use require for local images
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return require("../EventManagementEventCreation/Doc1.png"); // Use require for local images
    } else {
      return require("../../images/blankContent.jpeg"); // Use require for local images
    }
  };

  return (
    <Box sx={Styles.box}>
      {(props.user === "admin" || roles === "staff") && (
        <Box sx={Styles.addbox}>
          <img
            style={Styles.add}
            src={require("../../images/plus.png")}
            onClick={handleImageClick}
          />
        </Box>
      )}

      {content?.map((item, index) => (
        <Box
          sx={Styles.uploadedImgDiv}
          key={item.id}
          onClick={() => {
            onContentClick(item.link,item.file_name);
          }}
        >
          <Box sx={Styles.filebox}>
            {(props.user === "admin" || roles === "staff") && (
              <Box sx={Styles.filebox1}>
                <img
                  style={{ height: 20, width: 20, cursor: "pointer" ,backgroundColor:"white"}}
                  onClick={(event) => {
                    event.stopPropagation(); // Stop event propagation

                    deleteContent(item.id, item.file_name);
                  }}
                  src={require("../../images/cross.png")}
                ></img>
              </Box>
            )}

            <img
              style={Styles.uploadedImage}
              src={getFileImage(item.file_name.split(".").pop(), index)}

              // src={require("../../images/blankContent.jpeg")}
              alt="uploaded content preview"
            />

            {/* <video  style={Styles.uploadedImage} src={url}/> */}
          </Box>

          <Box style={Styles.filename}>
            <Typography
              sx={Styles.fileText}
            >{`${item.file_name}`}</Typography>
          </Box>
        </Box>
      ))}

      {openpop && (
        <ContentBoxPopup
        name={fileName}

          open={openpop}
          setOpenpop={setOpenpop}
          imgPreview={imgPreview}
        />
      )}
    </Box>
  );
}

const Styles = {
  box: {
    width: "100%",

    display: "flex",

    flexDirection: "row",

    // overflowX:"scroll",

    minWidth: "200px ",

    "@media (max-width:768px)": {
      // flexDirection:"column",

      overflowX: "scroll",
    },
  },

  addbox: {
    display: "flex",

    position: "relative",

    // backgroundColor:"red",

    alignItems: "center",

    justifyContent: "flex-end",
  },

  add: {
    // margin: "20px",

    cursor: "pointer",

    width: 25,

    height: 25,
  },

  uploadedImgDiv: {
    margin: "10px",

    marginLeft: "30px",

    marginRight: "0px",

    width: "10%",

    height: "50%",

    // backgroundColor:"red",

    display: "flex",

    flexDirection: "column",

    "@media (max-width:768px)": {
      width: "100%",
    },
  },

  filebox: {
    width: "100%",

    height: "100%",
  },

  filebox1: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    top: 20,
  },

  uploadedImage: {
    width: "100%",

    height: "100px",

    // resizeMode:"content",
  },

  filename: {
    display: "flex",

    // justifyContent:"end",

    alignItems: "flex-end",

    textAlign: "center",

    width: "100%",

    backgroundColor: "#F1F1F1",

    color: "#252525",

    overflow: "hidden",
  },

  fileText: {
    fontSize: "12px",

    fontFamily: "MontserratRegular",

    color: "#252525",
  },
};
