import React from 'react'
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import prof from "../../images/prof.png";

export default function ChatBackupCard() {
  return (
    <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: "8px",
                width: "100%",
                "@media (max-width:768px)":{
                    width:"300px",
                },
              }}
            >
              <img src={prof} alt="" width="30px" height="30px" style={{margin:"4px"}} />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: "12px",
                    color: "#252525",
                    marginLeft: "0px",
                    marginX: "5px",
                  }}
                >
                  Sinead Branagan
                </Typography>

                <Box
                  sx={{
                    // height: "32px",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    margin: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "MontserratRegular",
                      fontSize: "12px",
                      color: "#252525",
                      margin: "8px",
                    }}
                  >
                    Include that backup kkkkkkkkkkkkkkkkkkkk kkkkkkkkkkkkkkkkkkkkkkjjjjjjjjj jjjjjjjjjjjjj jjjjjjjjjjjjjjjjjjjjjjjj jjjjjjjjjjjjjj
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontFamily: "MontserratRegular",
                    fontSize: "12px",
                    color: "#999999",
                    margin: "8px",
                    marginY: "2px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  12:20 PM, 23/03/2023
                </Typography>
              </Box>
            </Box>
  )
}
