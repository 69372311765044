import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Innerchat from "./Innerchat";
import blankProfile from "../../../images/blankProfile.png";
import { useNhostClient } from "@nhost/react";

export default function ChatCard(props) {
  // console.log("this is id in chat card", props.isGroup);
  const [selectedCard, setSelectedCard] = useState(null);
  const [input, setInput] = useState("");
  const handleCardPress = (
    id,
    image,
    title,
    content,
    date,
    time,
    messagecount,
    thisIsGrp,
    thisIsEvent,
    setRefetchChat,
    setSelectedCard
  ) => {
    props.setSelectedCard({
      id,
      image,
      title,
      content,
      date,
      time,
      messagecount,
      thisIsGrp,
      thisIsEvent,
      setRefetchChat,
      setSelectedCard
    });
  };
  const nhost = useNhostClient();
  const [imgUrl, setImgUrl] = useState("");
  // console.log("images in chat", imgUrl);

  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: props.image,
        });
        // console.log("Image URL:", item);
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [props.image]);

  return (
    <Box style={{ width: "100%", borderBottom: "1px solid #999999" }}>
      <Box style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <Box
          style={{ width: "40%" }}
          key={props.id}
          onClick={() =>
            handleCardPress(
              props.id,
              imgUrl,
              props.title,
              props.content,
              props.date,
              props.time,
              props.messagecount,
              props.isGroup,
              props.isEvent,
              props.setRefetchChat,
              props.setSelectedCard
            )
          }
        >
          {props?.title
            ?.toLowerCase()
            .includes(props?.input?.toLowerCase()) && (
            <Box style={styles.card}>
              <Box style={styles.cardcircle}>
                <img
                  style={styles.cardimage}
                  src={!imgUrl.endsWith("null" || " " || "undefined") ? imgUrl: blankProfile}
                />
              </Box>

              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: 400,
                    height: 35,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={styles.title}>{props.title}</Typography>
                  <Typography style={styles.time}>{props.time}</Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 400,
                    height: 35,
                  }}
                >
                  <Typography style={styles.content}>
                    {props.content}
                  </Typography>
                  {/* <Box style={styles.messagecountcircle2}>
                          <Typography style={styles.notificationcount}>
                            {props.messagecount}
                          </Typography>
                        </Box> */}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {/* <Box style={{ width: "60%", backgroundColor: "red" }}> */}
        {/* <Innerchat selectedCard={selectedCard} id={props.id} /> */}
        {/* </Box> */}
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    height: "100%",
    //  backgroundColor:"red"
    // width: '100%',
  },
  searchbar: {
    // marginTop: '15%',
    marginLeft: "5%",

    width: "90%",

    borderColor: "#999999",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  searchicon: {
    width: 15,
    height: 15,
    margin: 15,
  },
  searchTypography: {
    color: "#999999",
    fontFamily: "MontserratRegular",
    backgroundColor: "#FFFFFF",

    width: "80%",
  },

  sorticon: {
    width: 35,
    height: 35,
    marginLeft: "10%",
  },
  notifiycontainer: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    elevation: 0.5,
    height: 50,
    width: "auto",
    alignItems: "center",

    backgroundColor: "rgba(238, 64, 54, 0.1)",
  },
  notificationicon: {
    width: 30,
    height: 30,
    marginLeft: 10,
  },
  notificationTypography: {
    fontSize: 18,
    fontFamily: "MontserratRegular",
    color: "#414042",
    marginLeft: "5%",
  },
  messagecountcircle1: {
    width: 25,
    height: 25,
    marginLeft: "45%",
    borderRadius: 50,
    backgroundColor: "#EE4036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationcount: {
    fontFamily: "MontserratBold",
    color: "#FFFFFF",
  },
  card: {
    display: "flex",
    flexDirection: "row",

    height: 70,
    width: "auto",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  cardcircle: {
    width: 50,
    height: 50,
    marginLeft: 10,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  cardimage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  title: {
    fontFamily: "MontserratBold",
    fontSize: 15,
    color: "#414042",
    marginLeft: "6%",
    marginTop: "5%",
  },
  time: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: "#414042",
    marginBottom: "6%",
    marginRight: "3%",
  },
  content: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: "#888888",
    marginLeft: "6%",
    marginBottom: "2%",
  },
  messagecountcircle2: {
    width: 22,
    height: 22,
    borderRadius: 22,
    backgroundColor: "#EE4036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "2%",
    marginBottom: "2%",
  },
};
