import { Box, Typography } from "@mui/material";
import { useNhostClient, useUserDefaultRole } from "@nhost/react";
import { React, useEffect, useState } from "react";
import ContentBoxPopup from "../Popup/GroupManagementEditPopups/ContentPop";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  useAccessToken,
  useFileUpload,
  useFileUploadItem,
  useMultipleFilesUpload,
  id,
  useSignOut,
  useAuthenticationStatus,
} from "@nhost/react";
import { WindowSharp } from "@mui/icons-material";


const GET_USER_QUERY = gql`
  query GetUserRoleById($id: uuid = "") {
    users(where: { id: { _eq: $id } }) {
      defaultRole
    }
  }
`;
const GET_EVENT_CONTENT = gql`
  query GetGroupContent($id: bigint = "") {
    content_master(where: { associated_event_id: { _eq: $id } }) {
      associated_event_id
      associated_group_id
      category_id
      expiry_date
      file_name
      id
      link
      published_by
      publishing_date
      type
      upload_date
      uploaded_by
    }
  }
`;
const INSERT_CONTENT = gql`
  mutation AddContent(
    $associated_event_id: bigint
    $associated_group_id: bigint
    $file_name: String = ""
    $link: String = ""
    $published_by: String = ""
    $type: String = ""
    $uploaded_by: String = ""
  ) {
    insert_content_master(
      objects: {
        associated_event_id: $associated_event_id
        associated_group_id: $associated_group_id
        file_name: $file_name
        link: $link
        published_by: $published_by
        type: $type
        uploaded_by: $uploaded_by
      }
    ) {
      returning {
        associated_event_id
        associated_group_id
        category_id
        expiry_date
        file_name
        id
        link
        published_by
        publishing_date
        type
        upload_date
        uploaded_by
      }
    }
  }
`;

const DELETE_DOCUMENT_IMAGE = gql`
  mutation MyMutation5($_eq: bigint) {
    delete_content_master(where: { id: { _eq: $_eq } }) {
      returning {
        id
        file_name
      }
    }
  }
`;

export default function ContentBox(props) {
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();
  //  console.log(props.id, "id for content fetching")
  const [content, setContent] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [images, setImages] = useState([]); 
  const [urls, setUrls] = useState([]);
  const [images1, setImages1] = useState([]);
  const defaultRole= useUserDefaultRole();
// console.log("these are images", urls)

  const {
    loading,
    error,
    data: roledata,
  } = useQuery(GET_USER_QUERY, {
    variables: {
      id: localStorage.getItem("id"),
    },
  });
  const { data: contentdata, refetch } = useQuery(GET_EVENT_CONTENT, {
    variables: {
      id: props.id,
    },
  });

  // console.log('all contents we got ',contentdata);

  useEffect(() => {
    setRoles(roledata?.users[0]?.defaultRole);
    setContent(contentdata?.content_master);
    // console.log("all content data", contentdata);
  }, [contentdata]);

  const [insertuser] = useMutation(INSERT_CONTENT, {
    onCompleted: (data) => {
      // console.log("last data updated");
      refetch();
    },
    onError: (error) => {
      console.log("error is ", error);
    },
  });

  const [docName, setDocName] = useState("");
  
  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "/";
    input.onchange = async (event) => {
      nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);

      let file = event.target.files[0];
      setDocName(file.name);
      await upload({ file });
    };
    input.click();
  };

  const [contentupload, setContentUpload] = useState(false);

  const handleContentManagement = async (cover_img_path, fileName) => {
    try {
      const { data: insertedData } = await insertuser({
        variables: {
          associated_event_id: props.id,
          link: cover_img_path,
          file_name: docName,
        },
      });
      // console.log("instered group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  
  const [docTest, setDocTest] = useState(true);
  if (isUploaded && docTest) {
    console.log(docTest, "222we are checking value true ", name);

    if (docTest) {
      console.log(docTest, "we are checking value true ", name);
      handleContentManagement(cover_img_path);
    }
    setContent((prevContent) => [
      ...prevContent,
      { file_name: docName, link: cover_img_path }, // Pushing a new object to the content array
    ]);
    // console.log(content, "all cotents");
    setDocTest(false);
  }
  
  const [roles, setRoles] = useState();

  const nhost = useNhostClient();

  const [openpop, setOpenpop] = useState(false);
  const [imgPreview, setImgPreview] = useState();
  const [fileName, setFileName] = useState("");
  const onContentClick = async (id,name) => {
    setOpenpop(!openpop);
    setFileName(name);
    try {
      const item = await nhost.storage.getPublicUrl({
        fileId: id,
      });
      // console.log("Image URL:", item);

      setImgPreview(item);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  };

  const [deleteDocumentImage] = useMutation(DELETE_DOCUMENT_IMAGE, {
    onCompleted: (data) => {
      // console.log("document and image data updated");

      refetch();
    },
  });

  const deleteContent = async (id, name) => {
    const confirmation = window.confirm(
      `Are you sure you want to delete ${name}?`
    );
    console.log(id, "deleting id");
    if (confirmation) {
      // Perform the deletion logic
      try {
        const { data: insertedData } = await deleteDocumentImage({
          variables: {
            _eq: id,
          },
        });
        // console.log("instered group header info", insertedData);
        alert(`Content ${name} Deleted successfully`);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };
  useEffect(()=>{
    // setRoles(roledata?.users[0]?.defaultRole)
    setContent(contentdata?.content_master);
        // setIsLoading(false);
        const images = contentdata?.content_master?.map((item) => ({
          link: item.link,
          fileName: item.file_name,
        }));
        setImages(images);

        // console.log("images",images);

        const imageUrlsData = images?.map((item) => {
          if (
            item.fileName.endsWith(".jpeg") ||
            item.fileName.endsWith(".jpg") ||
            item.fileName.endsWith(".png")
          ) {
            return item.link;
          }
          return null;
        });

        // console.log("images urls ",imageUrlsData);
        setImageUrls(imageUrlsData);

  },[contentdata])

  useEffect(() => {
    const imageIds = imageUrls;
    const convertedUrls = [];
    const fetchImageUrls = async () => {
      for (let i = 0; i < imageIds?.length; i++) {
        const imageId = imageIds[i];
        if (imageId) {
          const publicUrl = await nhost.storage.getPresignedUrl({
            fileId: imageId,
          });
          convertedUrls.push(publicUrl.presignedUrl.url);
        } else {
          convertedUrls.push(null);
        }
      }
      setUrls(convertedUrls);
    };
    fetchImageUrls();
  }, [imageUrls]);

  useEffect(() => {
    mapUrlsToImages();
  }, [urls]);

  const mapUrlsToImages = () => {
    const mappedImages = urls.map((url, index) => {
      if (url) {
        const fileExtension = url.split('.').pop().toLowerCase();
        const imageSource = getFileImage(fileExtension, index);
        return {
          link: url,
          fileName: imageSource,
        };
      } else {
        return {
          link: null,
          fileName: null,
        };
      }
    });
    setImages1(mappedImages);
    // setIsLoading(false); 
  };

  const getFileImage = (fileExtension, index) => {
    if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
      return images1[index]?.link; // Return the URL directly
    } else if (fileExtension === "pdf") {
      return require("../../images/PDF.png"); // Use require for local images
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return require("../EventManagementEventCreation/Doc1.png"); // Use require for local images
    } else {
      return require("../../images/blankContent.jpeg"); // Use require for local images
    }
  };
  

  return (
    <Box sx={Styles.box}>
      {(roles === "admin" || roles === "staff") && (
        <Box sx={Styles.addbox}>
          <img
            style={Styles.add}
            src={require("../../images/plus.png")}
            onClick={handleImageClick}
          />
        </Box>
      )}
      {content?.map((item, index) => (
        <Box
          sx={Styles.uploadedImgDiv}
          key={item.id}
          onClick={() => {
            onContentClick(item.link,item.file_name);
          }}
        >
          {defaultRole !== "user" &&
          <Box sx={Styles.filebox1}>
            <img
              src={require("../../images/cross.png")}
              style={{ height: 20, width: 20, cursor: "pointer" ,backgroundColor:"white"}}
              onClick={(event) => {
                event.stopPropagation(); // Stop event propagation
                deleteContent(item.id, item.file_name);
              }}
            />
          </Box>
}
          <Box sx={Styles.filebox}>
            <img
              style={Styles.uploadedImage}
              // src={require("../../images/blankContent.jpeg")}
              // src={getFileImage(item.file_name.split(".").pop(), index)}
              src={getFileImage(item.file_name.split(".").pop(), index)}

              alt="uploaded content preview"
            />
            {/* <video  style={Styles.uploadedImage} src={url}/> */}
          </Box>
          <Box style={Styles.filename}>
            <Typography
              sx={Styles.fileText}
            >{`${item.file_name}`}</Typography>
          </Box>
        </Box>
      ))}
      {openpop && (
        <ContentBoxPopup
          name={fileName}
          open={openpop}
          setOpenpop={setOpenpop}
          imgPreview={imgPreview}
        />
      )}
    </Box>
  );
}

const Styles = {
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    minWidth: "200px ",
    "@media (max-width:768px)": {
      // flexDirection:"column",
      overflowX: "scroll",
    },
  },
  addbox: {
    display: "flex",
    position: "relative",
    // backgroundColor:"red",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  add: {
    // margin: "20px",
    width: 25,
    height: 25,
  },
  uploadedImgDiv: {
    margin: "10px",
    width: "10%",
    height: "50%",
    // backgroundColor:"red",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  filebox: {
    width: "100%",
    height: "100%",
  },
  uploadedImage: {
    width: "100%",
    height: "130px",
    //resizeMode:"content",
  },
  filename: {
    display: "flex",
    // justifyContent:"end",
    alignItems: "flex-end",
    textAlign: "center",
    width: "100%",
    backgroundColor: "#F1F1F1",
    color: "#252525",
    overflow: "hidden",
  },
  fileText: {
    fontSize: "12px",
    fontFamily: "MontserratRegular",
    color: "#252525",
  },
  filebox1: {
    width: "100%",

    height: "100%",

    display: "flex",

    justifyContent: "flex-end",

    position: "relative",

    top: 20,
  },
};