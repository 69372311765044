import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import styles from "../../styles/global";
import logolight from "../../images/logolight.png";
import { useState } from "react";
import { Typography } from "@mui/material";
import { useEffect } from "react";
// import { useResetPassword } from "@nhost/react";

const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontSize: "10px",
    fontWeight: "bold",
  },
});

export default function ConfirmPasswordPopUp(props) {
  const { onClose, selectedValue, open } = props;
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  const [errors, setErrors] = useState({
    capitalLetter: "",
    specialCharacter: "",
    numericCharacter: "",
    minLength: "",
  });

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  console.log("email", localStorage.getItem("email"));

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });

    validatePassword(key, value);

    if (key === "confirmpassword" && value !== data.newpassword) {
      setError("Password does not match");
    } else {
      setError("");
    }

    if(key === "oldpassword" && value === oldPassword) {
      setShow(true);
    }
  };

  const validatePassword = (key, value) => {
    const updatedErrors = { ...errors };

    if (key === "newpassword") {
      const hasCapitalLetter = /[A-Z]/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*]/.test(value);
      const hasNumericCharacter = /[0-9]/.test(value);
      const hasMinLength = value.length >= 8;

      updatedErrors.capitalLetter = hasCapitalLetter
        ? ""
        : "Password must contain at least one capital letter";
      updatedErrors.specialCharacter = hasSpecialCharacter
        ? ""
        : "Password must contain at least one special character (!@#$%^&*)";
      updatedErrors.numericCharacter = hasNumericCharacter
        ? ""
        : "Password must contain at least one numeric character";
      updatedErrors.minLength = hasMinLength
        ? ""
        : "Password must be at least 8 characters long";

      setErrors(updatedErrors);
    }
  };

  console.log(data);

  const handleSave = async (id) => {
    // .....................api starts here........................................
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );

    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    // console.log(data['description'])

    var graphql = JSON.stringify({
      query: `mutation ChangePassword($passwordHash: String, $email: citext, $newPassword: String) {
        updateUsers(where: {passwordHash: {_eq: $passwordHash}, email: {_eq: $email}}, _set: {passwordHash: $newPassword}) {
          returning {
            email
          }
        }
      }`,
      variables: {
        passwordHash: data["oldpassword"],
        newPassword: data["newpassword"],
        email: localStorage.getItem("email"),
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data1 = await res.json();

    props.setOpen(false);

    // .....................api ends here........................................
  };

  // ..............................fetch old pasword..........................................
  const [oldPassword, setOldPassword] = useState("");
  const [show, setShow] = useState(false);

  

  console.log("old password: ", oldPassword);
  const fetchPassword = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-hasura-admin-secret",
      process.env.REACT_APP_NHOST_SECREATEKEY
    );
    myHeaders.append("Access-Control-Allow-Credentials", true);
    myHeaders.append("Access-Control-Allow-Origin", "*");

    myHeaders.append(
      "Access-Control-Allow-Methods",
      "GET,OPTIONS,PATCH,DELETE,POST,PUT"
    );
    myHeaders.append(
      "Access-Control-Allow-Headers",
      "X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version"
    );

    var graphql = JSON.stringify({
      query: `query getOldPassword($_eq: citext = "") {
        users(where: {email: {_eq: $_eq}}) {
          displayName
          passwordHash
        }
      }
      `,
      variables: {
        _eq: localStorage.getItem("email"),
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
      // mode: "no-cors",
    };

    const res = await fetch(process.env.REACT_APP_URL, requestOptions);
    const data2 = await res.json();
    // console.log("All recurring events api", res);
    // console.log("second", data2);
    setOldPassword(data2.data.users[0].passwordHash);
  };
  useEffect(() => {
    // Calling fetchData Method
    // fetchData(props.id);
    fetchPassword();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "543px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50px",
              backgroundColor: "#FF0000",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img style={{ marginLeft: "10px" }} src={logolight} alt="img" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "543px",
              justifyContent: "center",
              "@media (max-width:768px)": {
                justifyContent: "center",
                width: "480px",
              },
            }}
          >
            <TextField
              variant="outlined"
              sx={Styles.textField}
              label="Old Password"
              id="oldpassword"
              value={data.oldpassword}
              inputProps={{
                style: {
                  height: "10px",
                  marginTop: "10px",
                },
              }}
              onChange={(e) => handleChange("oldpassword", e.target.value)}
            />

            {show ? (
              <>
                <TextField
                  sx={Styles.textField}
                  label="New Password"
                  id="newpassword"
                  value={data.newpassword}
                  inputProps={{
                    style: {
                      height: "10px",
                      marginTop: "10px",
                    },
                  }}
                  onChange={(e) => handleChange("newpassword", e.target.value)}
                />

{errors.capitalLetter && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                  {errors.capitalLetter}
                </Typography>
              </Box>
            )}
            {errors.specialCharacter && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                  {errors.specialCharacter}
                </Typography>
              </Box>
            )}
            {errors.numericCharacter && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                  {errors.numericCharacter}
                </Typography>
              </Box>
            )}
            
            {errors.minLength && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                  {errors.minLength}
                </Typography>
              </Box>
            )}


                <TextField
                  sx={Styles.textField}
                  label="Confirm Password"
                  id="confirmpassword"
                  value={data.confirmpassword}
                  inputProps={{
                    style: {
                      height: "10px",
                      marginTop: "10px",
                    },
                  }}
                  onChange={(e) =>
                    handleChange("confirmpassword", e.target.value)
                  }
                />
              </>
            ) : (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "#FF0000", fontSize: "14px" }}>
                  Old password doesn't match
                </Typography>
              </Box>
            )}
            
            {error && (
              <Box sx={Styles.errorBox}>
                <Typography sx={{ color: "FF0000" }}>{error}</Typography>
              </Box>
            )}
          </Box>

          <Box sx={Styles.buttonBox}>
            <Box
              sx={[
                styles.btnDashboard,
                {
                  width: "160px",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  backgroundColor: "#999999",
                  backgroundColor:
                    error || data.newpassword !== data.confirmpassword
                      ? "#999999"
                      : "#FF0000",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              disabled={
                error ||
                data.newpassword !== data.confirmpassword ||
                data.newpassword === null ||
                data.newpassword === "" ||
                data.confirmpassword === null ||
                data.oldpassword === null ||
                data.confirmpassword === ""
              }
              onClick={handleSave}
            >
              Change Password
            </Box>
            <Box
              sx={[
                styles.btnDashboard,
                {
                  backgroundColor: "#ffffff",
                  color: "#999999",
                  border: "1px solid #999999",
                  fontSize: "14px",
                  margin: "4px",
                  height: "40px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
              onClick={handleClose}
            >
              Cancel
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

const Styles = {
  textField: {
    margin: "8px",
    width: "400px",
    justifyContent: "center",
    "& fieldset": { border: "none" },
    ".MuiInputBase-root": {
      border: "1px solid #999999",
      borderRadius: "10px",
    },
    ".MuiOutlinedInput-root": {
      height: "50px",
    },
    "@media (max-width:768px)": {
      width: "320px",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    margin: "10px",
    width: "",
    width: "465px",

    justifyContent: "flex-end",
    "@media (max-width:768px)": {
      width: "400px",
    },
  },
  errorBox: {
    width: "72%",
    display: "flex",
    justifyContent: "flex-start",
  },
};
