import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";

const INSERT_GROUP_TYPE = gql`
  mutation InsertGroupType($created_by: String!, $description: String!, $name: String!) {
    insert_group_type_one(object: {created_by: $created_by, description: $description, name: $name}) {
      created_by
      created_date
      description
      id
      name
    }
  }
`;

const UPDATE_GROUP_TYPE = gql`
  mutation UpdateGroupType($id: bigint!, $name: String!, $description: String!, $created_by: String!) {
    update_group_type(where: {id: {_eq: $id}}, _set: {name: $name, description: $description, created_by: $created_by}) {
      returning {
        name
        id
        description
        created_date
        created_by
      }
    }
  }
`;

export default function AddGroupType(props) {
  const [open, setOpen] = React.useState(false);
  const [insertGroupType] = useMutation(INSERT_GROUP_TYPE);
  const [updateGroupType] = useMutation(UPDATE_GROUP_TYPE);

  const [data, setData] = React.useState(props);
  const [error, setError] = React.useState();

  const handleClickOpen = () => {
    props.setNewTaskOpen(true);
  };

  const handleClose = () => {
    props.setNewTaskOpen(false);
  };

  const handleSave = async () => {
    if (props.isforUpdate) {
      try {
        const { data: updateData } = await updateGroupType({
          variables: {
            id: props.id,
            description: data["description"],
            name: data["group_type"],
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(updateData);
        props.setNewTaskOpen(false);
        props.setUpdated(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const { data: insertData } = await insertGroupType({
          variables: {
            description: data["description"],
            name: data["group_type"],
            created_by: localStorage.getItem("email"),
          },
        });
        console.log(insertData);
        props.setgroupType((prevData) => {
          return [...prevData, insertData.insert_group_type_one];
        });
        props.setNewTaskOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };

  const resetFields = () => {
    setData({
      group_type: "", // Reset group_type field
      description: "", // Reset description field
    });
    setError("");
  };

  React.useEffect(() => {
    if (!props.isforUpdate) {
      resetFields(); // Reset fields when opening the popup for adding a new group type
    }
  }, [props.isforUpdate]);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog open={props.open} onClose={handleClose}>
        <Box sx={{ backgroundColor: "#999999", height: "100%" }}>
          <Typography sx={{ m: 1, color: "#FFFFFF" }}>
            Add Group Type
          </Typography>
        </Box>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-textarea"
            size="small"
            placeholder="Group Type"
            multiline
            variant="outlined"
            sx={{ width: 360 }}
            value={data.group_type}
            onChange={(e) => {
              handleChange("group_type", e.target.value);
            }}
          />
        </Box>
        {error && (<Typography sx={{color:'red',marginLeft:5}} >Please Fill the values</Typography>)}
        <Box sx={{ mx: 3, my: 1 }}>
          <TextField
            id="outlined-multiline-static"
            placeholder="Description"
            sx={{ width: 360 }}
            multiline
            rows={4}
            variant="outlined"
            value={data.description}
            onChange={(e) => {
              handleChange("description", e.target.value);
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1 }} >
          <Button sx={{ border: 1, mx: 1, borderColor: '#999999', color: '#999999' }} onClick={handleClose}>Cancel</Button>
          <Button sx={{ backgroundColor: '#FF0000', color: '#FFFFFF', width: 30 }} onClick={handleSave}>Save</Button>
        </Box>
      </Dialog>
    </div>
  );
}
