import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import dashboard from "../../images/dashboard.png";
import edit from "../../images/editGray.png";
import deleteimg from "../../images/delete.png";
import EditEvent from "../Popup/EventManagement/EditEvent";
import { useState } from "react";
import { useEffect } from "react";
import { Api } from "@mui/icons-material";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { useUserDisplayName, useUserEmail } from "@nhost/react";

const theme = createTheme({
  typography: {
    fontFamily: "MontserratRegular",
    marginLeft: "4px",
    color: "#999999",
    fontWeight: "bold",
  },
});

const GET_EVENTS_QUERY = gql`
  query GetAllEventInfo($id: bigint) {
    event_master(where: { id: { _eq: $id } }) {
      activation_date
      closure_date
      created_at
      deactivation_date
      description
      id
      location
      metadata
      name
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      type_id
      cover_img_path
      updated_at
      start_time
      publish_status
      end_time
      event_category {
        created_by
        created_date
        description
        id
        name
      }
      event_faculties {
        event_id
        faculty_type_id
        id
        user_id
      }
      event_type {
        created_by
        created_date
        description
        id
        name
      }
    }
  }
`;
const DELETE_EVENTQUERY = gql`
  mutation MyMutation2($_eq: bigint = "", $status: String = "") {
    update_event_master(
      where: { id: { _eq: $_eq } }
      _set: { status: $status }
    ) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_EVENT_STATUS = gql`
  mutation updateStatus($publish_status: String = "", $_eq: bigint = "") {
    update_event_master(
      where: { id: { _eq: $_eq } }
      _set: { publish_status: $publish_status }
    ) {
      returning {
        id
        publish_status
        name
      }
    }
  }
`;

const INSERT_LOGS_MASTER = gql`
  mutation MyMutation5(
    $content_type: String = ""
    $deleted_by: String = ""
    $deleted_frmtable: String = ""
    $deleted_from: String = ""
    $deleted_ip: String = ""
    $deleted_region: String = ""
    $deleted_time: timestamptz = ""
  ) {
    insert_logs_master(
      objects: {
        content_type: $content_type
        deleted_by: $deleted_by
        deleted_frmtable: $deleted_frmtable
        deleted_from: $deleted_from
        deleted_ip: $deleted_ip
        deleted_region: $deleted_region
        deleted_time: $deleted_time
      }
    ) {
      returning {
        content_type
        deleted_by
        deleted_frmtable
        deleted_time
      }
    }
  }
`;

export default function PublishEventPopUp(props) {
  const { onClose, open, setfirst } = props;
  const userName = useUserEmail();
  const currentTime = new Date();
  // console.log("this is user nsame: " , userName);
  const navigate = useNavigate();
  const [publishStatus, setPublishStatus] = useState("");
  const [openpop, setOpenpop] = useState(false);
  const editEvents = () => {
    setOpenpop(!openpop);
  };

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const attendanceData = {
    // Your data here...
    // For example:
    // eventId: props.id,
    // eventLocation: Events?.location,
    // hi:"hi",
    id: props.id,
    // Add more data as needed.
  };
  // console.log('teste props', props)
  const [role, setRole] = useState("");
  // console.log("Role by api", role);
  const [UserNameId, setUserNameId] = useState("");

  // .................update status starts .............
  const [updateStatus, { loading: statusLoading, error: statusErr }] =
    useMutation(UPDATE_EVENT_STATUS, {
      onCompleted: (data) => {
        props.setOpen(false);
      },
    });
  const [insertLogs] = useMutation(INSERT_LOGS_MASTER);
  
  const updateEventStatus = async () => {
   
    try {
      const { data: insertedData } = await updateStatus({
        variables: {
          _eq: props.id,
          publish_status: "published",
        },
      });
      
      // console.log("instered group header info", insertedData);
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };
  // .................update status ends .............

  // ........................event details fetch................................
  const [Events, setEvents] = useState([]);
  // console.log("Events by api", Events);
  const { loading, error, data, refetch } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      id: props.id,
    },
    onCompleted: (data) => {
      // console.log("fetched data",data)
      setEvents(data?.event_master);
    },
  });

  useEffect(() => {
    if (data) {
      setEvents(data?.event_master);
      setPublishStatus(data?.event_master[0]?.publish_status);
    }
    // console.log("eventssssssssssSDcszvczvv", data?.event_master);
  }, [data]);
  // useEffect(() => {
  //   setEvents(data?.event_master[0]);
  //   // console.log("eventssssssssss", data?.event_master);
  // }, []);
  // console.log("published wdajfcnsjdc",publishStatus)
  // ........................event details fetch................................
  const [deleteevent] = useMutation(DELETE_EVENTQUERY, {
    onCompleted: (data) => {
      navigate("/eventmanagement1", { state: { isdeleted: true } });
      refetch();
    },
    onError: (error) => {
      console.error("Mutation Error:", error.message);
    },
    refetchQueries: [{ query: GET_EVENTS_QUERY }],
  });

  const deletevent = async () => {
    const confirmation = window.confirm(`Are you sure you want to delete ?`);
    if (confirmation) {
      try {
        const { data: insertedData } = await deleteevent({
          variables: {
            _eq: props.id,
            status: "isnotactive",
          },
        });
        const { data: insertedDataw } = await insertLogs({
          variables: {
            content_type: props.name,
            deleted_by: userName,
            deleted_frmtable:"Event_master",
            deleted_from:"events",
            deleted_ip:"-",
            deleted_region:'-',
            deleted_time:currentTime,
          },
        });
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        sx={{
          marginTop: "0px",
          marginLeft: "78%",
          marginBottom: "18%",
        }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          {publishStatus !== "published" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                margin: "8px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={updateEventStatus}
            >
              <img src={dashboard} alt="dashboard" width="20px" height="20px" />
              <Typography sx={{ marginLeft: "4px" }}>Publish Event</Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={editEvents}
          >
            <img src={edit} alt="edit" width="20px" height="20px" />
            <Typography sx={{ marginLeft: "4px" }}>Edit Event</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={deletevent}
          >
            <img src={deleteimg} alt="delete" width="20px" height="20px" />
            <Typography sx={{ marginLeft: "4px" }}>Delete Event</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/attendance", {
                state: { attendanceData },
              });
            }}
          >
            <img src={dashboard} alt="dashboard" width="20px" height="20px" />
            <Typography sx={{ marginLeft: "4px" }}>Complete Event</Typography>
          </Box>
        </Box>
      </Dialog>
      <Box sx={{ height: "100%", width: "100%" }}>
        {openpop && (
          <EditEvent
            id={props.id}
            open={openpop}
            attendanceCapacity={Events[0]?.metadata?.max_attendance}
            duration={props?.duration}
            description={props?.description}
            event_type={props?.event_type}
            eventTypeId={props.eventTypeId}
            event_category={props?.event_category}
            eventCatId={props?.eventCatId}
            // event_category_id={props?.event_category_id}
            setOpenpop={setOpenpop}
            name={props.name}
            location={props?.location}
            sdate={props?.sdate}
            edate={props?.edate}
            event_faculties={Events?.event_faculties}
            image={Events[0]?.cover_img_path}
            startTime={Events[0]?.start_time}
            endTime={Events[0]?.end_time}
            setOpen={props.setOpen}
            urlImg={props.urlImg}
            vcMode={props.vcMode}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}
