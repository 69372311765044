import { Box, Typography } from '@mui/material'
import { useNhostClient } from '@nhost/react';
import React, { useEffect, useState } from 'react'

export default function MembersCard(props) {
    const nhost = useNhostClient();
    const [imgUrl ,setImgUrl] = useState("");
    // useEffect(() => {

    //     async function getImage() {
    
    //       nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY)
    //       nhost.storage.setAccessToken(props.img)
    
    //       const publicUrl = await nhost.storage.getPresignedUrl({
    //         fileId: props.img,
    //       })
    
    //       setImgUrl(publicUrl)
    
    //     }
    
    //     getImage()
    
    //   }, [])
      useEffect(() => {
        async function getImage() {
          try {
            const item = await nhost.storage.getPublicUrl({
              fileId: props.img,
            });
            console.log("Image URL:", item);
            setImgUrl(item);
          } catch (error) {
            console.error("Error retrieving image URL:", error);
          }
        }
        getImage();
      }, [props]);
  return (
    <div>
      <Box  sx={styles.container}>
          {/* .................img container */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={styles.imgContainer}>
              <img
                style={{ width: "25px", height: "25px" ,borderRadius:"50%" }}
                // src={imgUrl ? imgUrl : require('../../images/avatar.png')}
                src={ imgUrl && (!imgUrl.endsWith("null") || !imgUrl.endsWith("/") || !imgUrl.endsWith("blank"))
                ? imgUrl
                : require('../../images/avatar.png')}
                alt="img"
                width="250px"
              />
              <Typography sx={styles.titleTxt}>Name : {props.name}</Typography>
            </Box>
          </Box>
          <Box sx={styles.textContainer}>
            <Typography sx={styles.baseTxt}>
              Phone Number : {props.phone}
            </Typography>
            <Typography sx={styles.baseTxt}>Email ID : {props.email}</Typography>
            {/* <Typography sx={styles.baseTxt}>
              Staff ID : {item.user.id}
            </Typography> */}
          </Box>
        </Box>
    </div>
  )
}
const styles = {
    div: {
      width: "100%",
      marginTop: "0px",
      display: "flex",
      flexDirection: "row",
      overflowX: "scroll",
      "& .track-horizontal": {
        backgroundColor: 'blue',
        width: '100%',
        left: ' 0px',
        bottom: ' 0px',
        height: '10px !important',
      },
  
    },
    addbox: {
      display: "flex",
      position: "relative",
      // backgroundColor:"red",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    container: {
      width: "420px",
      minWidth: "420px",
      borderRadius: "10px",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      height: "130px",
      marginTop: "10px",
      margin: "10px",
      overflow: "hidden",
    },
    imgContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      margin: "10px",
        height:"30px",
        alignItems: "center",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "45px",
      width: "100%",
    },
    titleTxt: {
      marginLeft: "10px",
      fontFamily: "MontserratRegular",
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "left",
      color: "#252525",
      lineHeight: "20px",
    },
    desc: {
      fontFamily: "MontserratRegular",
      fontSize: "9px",
      textAlign: "left",
      fontWeight: "400",
      color: "#414042",
      lineHeight: "14.63px",
    },
    dateTime: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    baseTxt: {
      marginBottom: "10px",
      fontFamily: "MontserratRegular",
      fontSize: "15px",
      // fontWeight: "500",
      color: "#999999",
      lineHeight: "18px",
    },
  };
  