import React, { useEffect } from "react";
// import { Box, Typography, StyleSheet, img, TouchableOpacity, ScrollBox, TypographyField } from 'react-native'
import { useState } from "react";
import search from "../../images/search.png";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Innerchat from "./Innerchat";
import { useNavigate } from "react-router";
import { gql, useQuery, useSubscription } from "@apollo/client";
import ChatCard from "./ChatCard";
import { useUserId } from "@nhost/react";
import AddNewChat from "./AddNewChat";
import Temp from "./Temp";
import Temp2 from "./Temp2";
import Navbar from "../../components/Navbar";
const GET_ALL_USER = gql`
  query MyQuery13($_eq: uuid = "") {
    group_chatn(where: { sender_id: { _eq: $_eq } }, distinct_on: participent) {
      id
      participent
    }
  }
`;

const GET_RECIVED_USER = gql`
  query MyQuery199($_eq: uuid = "") {
    group_chatn(
      where: {
        participent: { _eq: $_eq }
        _and: {
          chat_content: { _is_null: false }
          participent: { _is_null: false }
        }
      }
      distinct_on: participent
    ) {
      sender_id
      chat_content
    }
  }
`;

const GET_GROUP_CHAT = gql`
query MyQuery3222as($_eq: uuid = "") {
  user_groups(where: {user_id: {_eq: $_eq}, _and: {status: {_is_null: true}, _and: {group_master: {status: {_eq: "isactive"}}}}}, distinct_on: group_id) {
    group_id
    user_id
    id
    notification
    time_stamp
    group_master {
      name
      cover_img_path
      status
    }
    status
  }
}
`;

const GROUP_NOTIFICATION_COUNT = gql`
  query MyQuery11($_eq: uuid = "", $_eq1: bigint = 1) {
    group_chatn(
      where: {
        users: { id: { _eq: $_eq } }
        _and: { notification: { _eq: $_eq1 } }
      }
    ) {
      group_id
      id
      notification
      sender_id
      group_master {
        name
        cover_img_path
      }
    }
  }
`;

const EVENT_GROUP_QUERY = gql`
query MyQuery1acsascas23($_eq: uuid = "") {
  user_events(where: {userid: {_eq: $_eq}, _and: {status: {_is_null: true}, _and: {event_master: {status: {_eq: "isactive"}}}}}, distinct_on: event_id) {
    event_id
    notification
    status
    userid
    time_stamp
    event_master {
      name
      cover_img_path
    }
  }
}
`;

const ALL_STAFF_LIST = gql`
  query MyQuery21 {
    profile_details(
      where: { category: { _eq: "staff" } }
      distinct_on: user_id
    ) {
      first_name
      user_id
    }
  }
`;
const GET_DEFAULT_ROLE = gql`
  query MyQuery22($_eq: uuid = "") {
    users(where: { id: { _eq: $_eq } }) {
      defaultRole
      id
    }
  }
`;

export default function UserChat() {
  const [input, setInput] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [chatUsers, setChatUsers] = useState([]);
  const [cId, setcId] = useState("");
  const navigate = useNavigate();
  const [defRoles, setdefRole] = useState(false);
  const [unRead, setUnread] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [thisIsRes, setthisIsRes] = useState(false);
  const uId = useUserId();
  const [users, setusers] = useState([]);
  const [notification, setNotification] = useState([]);
  const [userGroup, setuserGroup] = useState([]);

  const { data: defRole } = useQuery(GET_DEFAULT_ROLE, {
    variables: {
      _eq: uId,
    },
  });

  const { data: chat } = useQuery(GET_ALL_USER, {
    variables: {
      _eq: localStorage.getItem("id"),
    },
    onCompleted: (data) => {
      setthisIsRes(false);
      setChatUsers((prevChatUsers) => {
        // Merge the new data with the existing ChatUsers array
        return [...prevChatUsers, ...data?.group_chatn];
      });
    },
  });

  const { data: recchat } = useQuery(GET_RECIVED_USER, {
    variables: {
      _eq: localStorage.getItem("id"),
    },
    onCompleted: (data) => {
      // const extractedParticipents = data?.group_chatn.map(user => user.participent);
      // setParticipents(extractedParticipents);
      // setChatUsers(data?.group_chatn);
      setthisIsRes(true);
      setChatUsers((prevChatUsers) => {
        // Merge the new data with the existing ChatUsers array
        return [...prevChatUsers, ...data?.group_chatn];
      });
    },
  });

  const {
    loading: grpLoad,
    error: grpErr,
    data: grpChat,
  } = useQuery(GET_GROUP_CHAT, {
    variables: {
      _eq: uId,
    },
  });
  const [userEvents, setUserEvents] = useState([]);
  const {
    loading: evntLoad,
    error: evntErr,
    data: evntChat,
  } = useQuery(EVENT_GROUP_QUERY, {
    variables: {
      _eq: uId,
    },
  });
  // console.log("this is w=events group query",evntChat)

  const { data: notifyCount } = useQuery(GROUP_NOTIFICATION_COUNT, {
    variables: {
      _eq: uId,
    },
  });

  // console.log("All notifications", notifyCount?.group_chatn);
  const [staffs, setStaffs] = useState([]);
  const { data: staffChat } = useQuery(ALL_STAFF_LIST);

  useEffect(() => {
    setStaffs(staffChat?.profile_details);
    setdefRole(defRole?.users[0].defaultRole);
  }, [defRole, staffChat]);
  // console.log("this is all sataff", staffs);
  useEffect(() => {
    // setusers(chat?.users);
    setuserGroup(grpChat?.user_groups);
    setNotification(notifyCount?.group_chatn);
    setUserEvents(evntChat?.user_events);
    setIsLoading(false);
  }, [grpChat, notifyCount, evntChat]);

  // console.log("this is all users in chat new", chatUsers);
  const handleKeyPress = (e) => {
    
  };

  const openNewTask = () => {
    setNewTaskOpen(!newTaskOpen);
  };

  return (
    <>
      <Navbar />
      <Box style={styles.container} onKeyDown={handleKeyPress}>
        <Box style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          {/* <Box> */}
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box style={styles.searchbar} onClick={openNewTask}>
              <img src={search} style={styles.searchicon} />
              <Typography
                style={{
                  fontFamily: "MontserratRegular",
                  fontSize: "14px",
                  color: "#999999",
                }}
              >
                Add Members
              </Typography>
              {/* <TextField
              style={{fontFamily:"MontserratRegular"}}
              variant="standard"
              onChangeText={(text) => {
                setInput(text);
              }}
              placeholder="Search Members"
            /> */}
            </Box>

            {/* <Box>
              <img src={sort} style={styles.sorticon} />
            </Box> */}
            {/* </Box> */}

            {/* <Box style={styles.notifiycontainer}>
            <img src={Notifications} style={styles.notificationicon} />
            <Typography style={styles.notificationTypography}>
              Notifications
            </Typography>
            <Box style={styles.messagecountcircle1}>
              <Typography style={styles.notificationcount}>12</Typography>
            </Box>
          </Box> */}
            <Box
              style={{
                overflowY: "scroll",
                height: "85vh",
                width: "100%",
                // overflowY: "scroll",
                scrollbarWidth: "auto",
                scrollbarRadius: "10px",
                "&::-webkit-scrollbar": {
                  width: "0.7em",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#E1E5F8",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              {chatUsers?.map((item) => (
                <Temp userIds={item.participent} setusers={setusers} />
              ))}
              {chatUsers?.map((item) => (
                <Temp2 userIds={item.sender_id} setusers={setusers} />
              ))}

              {notification?.map((item) => (
                <ChatCard
                  isNotification={true}
                  id={item.group_id}
                  // grpId={item.grp_id}
                  // image={item.avatarUrl}
                  title={item?.group_master?.name}
                  content={item.content}
                  date={item.date}
                  time={item.time}
                  messagecount={unRead}
                  input={input}
                  setSelectedCard={setSelectedCard}
                />
              ))}

              {userGroup?.map((item) => (
                <ChatCard
                  isGroup={true}
                  id={item.group_id}
                  // grpId={item.grp_id}
                  image={item.group_master?.cover_img_path}
                  title={item?.group_master?.name}
                  content={item.content}
                  date={item.date}
                  time={item.time}
                  messagecount={unRead}
                  input={input}
                  setSelectedCard={setSelectedCard}
                  setcId={setcId}
                />
              ))}
              {userEvents?.map((item) => (
                <ChatCard
                  // isGroup={true}
                  isEvent={true}
                  id={item.event_id}
                  // grpId={item.grp_id}
                  image={item?.event_master?.cover_img_path}
                  title={item?.event_master?.name}
                  content={item.content}
                  date={item.date}
                  time={item.time}
                  messagecount={unRead}
                  input={input}
                  setSelectedCard={setSelectedCard}
                  setcId={setcId}
                />
              ))}
              {users?.map((item) => (
                <ChatCard
                  id={item.id}
                  image={item.avatarUrl}
                  title={item.displayName}
                  content={item.content}
                  date={item.date}
                  time={item.time}
                  // messagecount={unRead}
                  input={input}
                  setSelectedCard={setSelectedCard}
                  setcId={setcId}
                />
              ))}
              {defRoles === "admin" &&
                staffs?.map((item) => (
                  <ChatCard
                    id={item.user_id}
                    image={item.avatarUrl}
                    title={item.first_name}
                    content={item.content}
                    date={item.date}
                    time={item.time}
                    // messagecount={unRead}
                    input={input}
                    setSelectedCard={setSelectedCard}
                    setcId={setcId}
                    isAdmin={true}
                  />
                ))}
            </Box>
          </Box>
          <Box style={{ width: "100%", height: "80vh" }}>
            <Innerchat selectedCard={selectedCard} id={selectedCard?.id} />
          </Box>
        </Box>
        {newTaskOpen && (
          <AddNewChat open={newTaskOpen} setNewTaskOpen={setNewTaskOpen} />
        )}
      </Box>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    backgroundColor: "#FFFFFF",
    height: "97%",
    // width: '100%',
  },
  searchbar: {
    marginTop: "5%",
    marginBottom: "5%",
    marginLeft: "5%",
    width: "90%",
    height: "30px",
    overflow:"hidden",
    borderColor: "#999999",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #999999",
  },
  searchicon: {
    width: 15,
    height: 15,
    margin: 15,
  },
  searchTypography: {
    color: "#999999",
    fontFamily: "MontserratRegular",
    backgroundColor: "#FFFFFF",

    width: "80%",
  },

  sorticon: {
    width: 35,
    height: 35,
    marginLeft: "10%",
  },
  notifiycontainer: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    elevation: 0.5,
    height: 50,
    width: "auto",
    alignItems: "center",

    backgroundColor: "rgba(238, 64, 54, 0.1)",
  },
  notificationicon: {
    width: 30,
    height: 30,
    marginLeft: 10,
  },
  notificationTypography: {
    fontSize: 18,
    fontFamily: "MontserratRegular",
    color: "#414042",
    marginLeft: "5%",
  },
  messagecountcircle1: {
    width: 25,
    height: 25,
    marginLeft: "45%",
    borderRadius: 50,
    backgroundColor: "#EE4036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notificationcount: {
    fontFamily: "MontserratBold",
    color: "#FFFFFF",
  },
  card: {
    display: "flex",
    flexDirection: "row",

    height: 70,
    width: "auto",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
  },
  cardcircle: {
    width: 50,
    height: 50,
    marginLeft: 10,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  cardimage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  title: {
    fontFamily: "MontserratBold",
    fontSize: 15,
    color: "#414042",
    marginLeft: "6%",
    marginTop: "5%",
  },
  time: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: "#414042",
    marginBottom: "6%",
    marginRight: "3%",
  },
  content: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: "#888888",
    marginLeft: "6%",
    marginBottom: "2%",
  },
  messagecountcircle2: {
    width: 22,
    height: 22,
    borderRadius: 22,
    backgroundColor: "#EE4036",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "2%",
    marginBottom: "2%",
  },
};
