import { Box ,Typography} from '@mui/material'
import React, { useEffect, useState } from 'react'
import calender from '../../../../images/EventManagement1/Calender.png'
import { useNhostClient } from '@nhost/react';


export default function MyGroupCard(props) {
  const {img,title,desc,startDate,endDate} = props;
  const dateObject = new Date(startDate);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const utcString = dateObject.toISOString();
  const formattedStartDate = `${formattedDate}`;

  const dateObjecte = new Date(endDate);
  const daye = dateObjecte.getDate();
  const monthe = dateObjecte.getMonth() + 1; // Month is zero-based, so add 1
  const yeare = dateObjecte.getFullYear();
  const formattedDatee = `${daye < 10 ? '0' : ''}${daye}/${monthe < 10 ? '0' : ''}${monthe}/${yeare}`;
  const utcStringe = dateObjecte.toISOString();
  const formattedEndDate = `${formattedDatee}`
  const nhost = useNhostClient();
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {

    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: img,
        });
        // console.log("Image URL:", item);
  setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage()
  }, [])

  return (
    <Box sx={styles.container}>
      {/* .................img container */}
        <Box sx={styles.imgContainer}>
            <img  src={imgUrl}  alt="img" style={{width:"100%",height:"130px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}} />
        </Box>
      {/* .................img container ends */}
      {/*.................text container */}
      <Box sx={styles.textContainer}>
        <Typography sx={styles.titleTxt}>{title}</Typography>
        <Typography sx={styles.desc}>{desc}</Typography>
        <Box sx={styles.dateTime}>
          <img style={{width:'15px',height:'15px',marginRight:'4px'}} src={calender} alt="calender"/>
          <Typography sx={[styles.baseTxt,{marginRight:'20px'}]}>{formattedStartDate} - {formattedEndDate} CET</Typography>
        </Box>    
      </Box>
    </Box>
  )
}

const styles = {
container:{
    width:'280px',
    borderRadius:'10px',
    boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.25)',
    height:'60%',
    margin:'16px',
    marginTop:'10px',
    minHeight:'210px',
    

},
imgContainer:{
  width:'280px',

  
},
textContainer:{
  display:'flex',
  flexDirection:'column',
  marginLeft:'2%',
  width:'100%',
  
},
titleTxt:{
  fontFamily:"MontserratRegular",
  fontSize:'12px',
  fontWeight:'bold',
  textAlign:'left',
  color:'#252525',
  lineHeight:'20px',
},
desc:{
  height:"30px",
  fontFamily:"MontserratRegular",
  fontSize:'10px',
  textAlign:'left',
  fontWeight:'400',
  color:'#414042',
  lineHeight:'14.63px',
  overflowY:'hidden',
},
dateTime:{
  display:'flex',
  flexDirection:'row',
  // justifyContent:'space-between',
  alignItems:'center',
  width:'100%',
  // margin:'2px auto 2px auto',
},
baseTxt:{
  fontFamily:"MontserratRegular",
  fontSize:'10px',
  fontWeight:'500',
  color:'#414042',
  lineHeight:'12.19px',
  margin:'4px',
}
}