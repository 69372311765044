import { Box, Button, Typography, Grid, Checkbox } from "@mui/material";
import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import BtnRed from "../components/AdminFeed/BtnRed";
import BtnWhite from "../components/AdminFeed/BtnWhite";
import Styles from "../styles/AdminFeed/FeedManagement";
import FeedCardComponent from "../components/FeedCardComponent";
import { useDispatch } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useFileUpload} from "@nhost/react";

const GET_FEED_QUERY = gql`
  query GetAllFeed {
    feed_management(
      order_by: [{ default_feed: desc }, { id: desc }]
      where: { status: { _eq: true } }
    ) {
      cover_img_path
      default_feed
      description
      end_date
      header_name
      start_date
      id
    }
  }
`;
const INSERT_FEED = gql`
  mutation InsertFeed(
    $description: String
    $header_name: String
    $start_date: date
    $default_feed: Boolean = false
    $end_date: date
    $cover_img_path: String
    $created_by: String
  ) {
    insert_feed_management(
      objects: {
        description: $description
        header_name: $header_name
        start_date: $start_date
        default_feed: $default_feed
        end_date: $end_date
        cover_img_path: $cover_img_path
        created_by: $created_by
      }
    ) {
      returning {
        start_date
        id
        header_name
        end_date
        description
        default_feed
        cover_img_path
        created_by
      }
    }
  }
`;

const UPDATE_DEFAULT_ROLE = gql`
  mutation update_feed(
    $id: bigint = ""
    $cover_img_path: String = ""
    $default_feed: Boolean = false
    $description: String = ""
    $end_date: date = ""
    $header_name: String = ""
    $start_date: date = ""
  ) {
    update_feed_management(
      where: { id: { _eq: $id } }
      _set: {
        cover_img_path: $cover_img_path
        default_feed: $default_feed
        description: $description
        end_date: $end_date
        header_name: $header_name
        start_date: $start_date
      }
    ) {
      returning {
        cover_img_path
        default_feed
        description
        end_date
        header_name
        start_date
        status
      }
    }
  }
`;
const GET_PERMISSION_QUERY = gql`
  query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
    role_master(
      where: {
        access_name: { _eq: $_eq }
        _and: {
          roleprofile: {
            user_id: { _eq: $_eq1 }
            _and: { user: { defaultRole: { _eq: $_eq2 } } }
          }
        }
      }
    ) {
      access_name
      description
      id
      roles
      status
      roleprofile {
        user {
          defaultRole
        }
      }
    }
  }
`;
export default function FeedManagement() {
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id: cover_img_path,
    bucketId,
    name,
  } = useFileUpload();

  const [data, setData] = useState({});
  const { data: feeddata1, refetch } = useQuery(GET_FEED_QUERY);
  useEffect(() => {
    setFeedData(feeddata1?.feed_management);
  }, [feeddata1]);

  //updating default role
  const [updateDefaultRole] = useMutation(UPDATE_DEFAULT_ROLE, {
    onCompleted: (data) => {
      // console.log('object after succefull update',data )
    },
    onError: (error) => {
      // console.log(error,"inseting error")
    },
  });
  const [roleId, setRoleId] = useState(false);
  const [idis, setIdis] = "";
  let defaulRoleId;
  const [insertFeed] = useMutation(INSERT_FEED, {
    onCompleted: (data) => {
      // console.log('object of the feed',data)
      defaulRoleId = data?.insert_feed_management?.returning[0].id;
      setRoleId(true);
      updateRole(data?.insert_feed_management?.returning[0].id);
      // console.log("id in the new variable",defaulRoleId)
    },
    onError: (error) => {
      // console.log(error,"inseting error")
    },
  });

  const updateRole = async (id) => {
    // console.log(id,"update role")
    if (data["default_feed"] === true) {
      // console.log("id we required",defaulRoleId)
      try {
        const { data: newData } = await updateDefaultRole({
          variables: {
            id: id,
          },
        });
        //  console.log("last",updatenew)
      } catch (error) {
        // console.error('GraphQL Mutation Error:', error.message);
      }
    }
  };
  const { data: permsission } = useQuery(GET_PERMISSION_QUERY, {
    variables: {
      _eq: localStorage.getItem("roleaccess"),
      _eq1: localStorage.getItem("id"),
    },
    onError: (error) => {
      console.log(error, "this is error");
    },
    onCompleted: (data) => {
      console.log("permission status :", data);
    },
  });
  const roll = localStorage.getItem("role");
  const handleChange = (key, value) => {
    setData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  console.log(data);
  const [image, setImage] = useState(null);
  const [coverImageId, setCoverImageId] = useState("");
  const [coverImageId1, setCoverImageId1] = useState("");

  const handleImageClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      // nhost.storage.setAdminSecret(process.env.REACT_APP_NHOST_SECREATEKEY);
      let file = event.target.files[0];
      console.log("feed image upload link",file)
      setImage(URL.createObjectURL(file))
      await upload({ file });
    };
    input.click();
  };
  const [imageFeed, setImageFeed] = useState(true);
  if (isUploaded && imageFeed) {
    setCoverImageId1(cover_img_path);
    setImageFeed(false);
  }
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setDate((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };
  const handleCheckboxChange = (event) => {
    handleChange("default_feed", event.target.checked);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [errors, setErrors] = useState({});
  //inserting data into feed managment

  const post = async () => {
    console.log("feed management image ", coverImageId1);
    try {
      const { data: newData } = await insertFeed({
        variables: {
          start_date: date["startDate"],
          header_name: data["name"],
          description: data["description"],
          end_date: date["endDate"],
          default_feed: data["default_feed"],
          cover_img_path: cover_img_path,
          created_by: localStorage.getItem("name"),
        },
      });
      // After successful post, refetch the data to update the UI
      refetch();
    } catch (error) {
      console.error("GraphQL Mutation Error:", error.message);
    }
  };

  // ..........................fetch api...............................
  const [feedData, setFeedData] = useState([]);
  const [feed, setFeed] = useState();

  return (
    <Box>
      <Navbar />
      <Box sx={Styles.topContainer}>
        <Typography sx={Styles.largeTxt}>Create Feed</Typography>
      </Box>

      <div style={Styles.mainContainer}>
        {/* <Grid sx={Styles.grid1} item > */}
        {/* First grid with width of 700px */}
        {/* <Typography sx={Styles.largeTxt}>Create Feed</Typography> */}
        {((permsission?.role_master[0]?.roles?.addfeed && roll === "staff") ||
          roll === "admin") && (
          <div style={Styles.feedDetails}>
            <p style={Styles.feedDetailsHeader}>Feed Details</p>

            <div style={Styles.feedDetails1}>
              <div style={Styles.inputDiv}>
                <p>Header Name</p>

                <input
                  style={Styles.headerInput}
                  type="text"
                  placeholder="Text Field"
                  value={data.name}
                  onChange={(e) => {
                    handleChange("name", e.target.value);
                  }}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <p>Description</p>

                <textarea
                  style={Styles.descriptionInput}
                  type="text"
                  placeholder="Write your Text Here"
                  value={data.description}
                  onChange={(e) => {
                    handleChange("description", e.target.value);
                  }}
                />
              </div>

              {/* Cover Image div */}
              <div style={Styles.uploadImgDiv}>
                <p>Feed Cover Image</p>

                <div
                  style={{
                    width: "95%",
                    height: "412px",
                    border: "1px solid #999999",
                    borderRadius: "10px",
                    display: "flex",
                    // justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <button style={Styles.uploadInput} onClick={handleImageClick}>
                    Upload Image
                  </button>
                  {image && (
                    <div style={Styles.uploadedImageDiv}>
                      <img
                        style={Styles.uploadedImage}
                        src={image}
                        alt="uploaded image"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {((permsission?.role_master[0]?.roles?.addfeed && roll === "staff") ||
          roll === "admin") && (
          <div style={Styles.feedConfig}>
            <p style={Styles.feedDetailsHeader}>Feed Configuration</p>

            <div
              style={{
                width: "100%",
                // width:"100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <img
              style={{
                position: "absolute",
                marginTop: "25px",
                marginLeft: "210px",
              }}
              src={require("../images/dateTime.png")}
            /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // inputFormat="MM/DD/YYYY"
                  format="YYYY/MM/DD"
                  size="small"
                  label="Enter Start Date"
                  sx={{
                    width: "275px",
                    marginBottom: "30px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",
                      height: "40px",
                    },
                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",
                      color: "#999999",
                      fontSize: "14px",
                      lineHeight: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleDateChange("startDate", e);
                  }}
                  value={data.startDate}
                  error={!!errors.startDate}
                  helperText={errors.startDate}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // inputFormat="MM/DD/YYYY"
                  format="YYYY/MM/DD"
                  size="small"
                  label="Enter End Date"
                  sx={{
                    width: "275px",
                    marginBottom: "30px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",
                      height: "40px",
                    },
                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",
                      color: "#999999",
                      fontSize: "14px",
                      lineHeight: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleDateChange("endDate", e);
                  }}
                  value={data.endDate}
                  error={!!errors.endDate}
                  helperText={errors.endDate}
                />
              </LocalizationProvider>

              <div>
                <Checkbox
                  id="check"
                  checked={data.default_feed}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#49454F",

                    "&.Mui-checked": {
                      color: "#FF0000",
                    },
                  }}
                />
                <label
                  for="check"
                  style={{
                    fontFamily: "MontserratLite",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  Make this feed as default
                </label>
              </div>
              <div
                style={{
                  marginTop: "180px",
                  position: "relative",
                  // top:"10%",
                  justifyContent: "space-between",
                }}
              >
                <BtnWhite btn_title="Cancel" />
                <BtnRed btn_title="Post" click={post} />
              </div>
            </div>
          </div>
        )}{" "}
        {/* End of Feed Config */}
        <div style={Styles.feedHistory}>
          <p style={Styles.feedDetailsHeader}>Feed History</p>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: 0,
              margin: 0,
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                const query = e.target.value;
                setSearchQuery(query);
                const filtered = feedData.filter((item) =>
                  item.header_name.toLowerCase().includes(query.toLowerCase())
                );
                setFilteredData(filtered);
              }}
              style={{
                width: "80%",
                paddingLeft: "15px",
                height: "30px",
                fontfamily: "MontserratLite",
                border: "1px solid #999999",
                borderRadius: "5px",
              }}
            />
            <div style={{ width: "95%", marginTop: "5px" }}>
              {/* <FeedCards/> */}
              {filteredData.length > 0
                ? filteredData.map((item, index) => (
                    <FeedCardComponent
                      key={index}
                      id={item.id}
                      name={item.header_name}
                      desc={item.description}
                      img={item.cover_img_path}
                      sdate={item.start_date}
                      edate={item.end_date}
                      default_feed={item.default_feed}
                      status={item.status}
                    />
                  ))
                : feedData?.map((item, index) => (
                    <FeedCardComponent
                      key={index}
                      id={item.id}
                      name={item.header_name}
                      desc={item.description}
                      img={item.cover_img_path}
                      sdate={item.start_date}
                      edate={item.end_date}
                      default_feed={item.default_feed}
                      status={item.status}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
