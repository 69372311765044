import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import UserNavbar from "./Components/UserNavbar";
import Sidebar from "./Sidebar";
import AllExperiencesComponent from "./Components/UserDashboard/AllExperiencesComponent";
import GroupComponent from "./Components/UserDashboard/GroupComponent";
import FeedComponent from "./Components/FeedComponent";
import ScheduleComponent from "./Components/UserDashboard/ScheduleComponent";
import ChatComponent from "./Components/Chat/UserChat";
import Navbar from "../components/Navbar";


export default function UserDashboard(props) {
    const [active, setActive] = useState(1);

    const handleClick = (key) => {
      setActive(key);
    };

let content = null;
  if (active === 1) {
    content = <FeedComponent/>
  } else if (active === 2) {
    content = <AllExperiencesComponent/>;
  } else if (active === 3) {
    content = <GroupComponent/>;
  } else if (active === 4) {
    content =<ScheduleComponent/>;
  } else if (active === 5) {
    content = <ChatComponent/>;
  }
  else {
    content = <AllExperiencesComponent/>
  }
  return (
    <>
      <Box sx={styles.mainContainer}>
        {/* ..............navbar */}
        <Box>
          {/* <UserNavbar /> */}
          <Navbar/>
        </Box>
        {/* ..................navbar ends here */}

        <Box sx={styles.bottomContainer}>

          {/* ....................sidebar starts here */}
          <Box sx={styles.sidebar}>
            <Sidebar active={active} setActive={setActive} handleClick={handleClick} />
          </Box>
          {/* ...................sidebar ends here */}

          {/* .................center  start here */}
          <Box sx={styles.centerPart}>
          {content}

          </Box>
          {/* ...................center ends here */}
           
        </Box>
      </Box>
    </>
  );
}

const styles = {
  mainContainer: {
    width: "100%",
    overflowY: "hidden",
    height: "100%"
    // display:"flex",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",

    '@media (max-width: 768px)':{
      flexWrap:"wrap",
    }
  },
  sidebar: {
    width: "15%",
    '@media (max-width: 768px)':{
      height:'auto',
    }
  },
  centerPart: {
    width: "85%",
    overflowX:'hidden',

  },
};
