import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { addTask } from "../../Redux/features/UserSlice";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_All_groups = gql`
  query getAllGroups {
    group_master(where: { status: { _eq: "isactive" } }) {
      id
      name
    }
  }
`;

const GET_All_EVENTS = gql`
  query getEvents {
    event_master(
      where: { parent_id: { _is_null: true }, status: { _eq: "isactive" } }
    ) {
      id
      name
    }
  }
`;

const GET_All_STAFF = gql`
  query getAllStaff {
    profile_details(where: { user: { defaultRole: { _eq: "staff" } } }) {
      bio
      birth_date
      category
      first_name
      last_name
      profile_pic_path
      second_name
      user_id
      user {
        defaultRole
        displayName
        email
        emailVerified
        id
        phoneNumber
      }
    }
  }
`;
const INSERT_NEW_TASK = gql`
  mutation InsertNewTask(
    $description: String
    $event_id: bigint
    $file_path: String
    $group_id: bigint
    $staff_id: uuid
    $target_date: date
    $task_name: String
    $status: String = ""
  ) {
    insert_task_master(
      objects: {
        description: $description
        event_id: $event_id
        file_path: $file_path
        group_id: $group_id
        staff_id: $staff_id
        target_date: $target_date
        task_name: $task_name
        status: $status
      }
    ) {
      returning {
        description
        event_id
        file_path
        group_id
        id
        staff_id
        target_date
        task_name
      }
    }
  }
`;

const UPDATE_TASK_MASTER = gql`
  mutation MyMutation2(
    $description: String = ""
    $event_id: bigint = ""
    $staff_id: uuid = ""
    $target_date: date = ""
    $task_name: String = ""
    $_eq: bigint = ""
    $status: String = ""

  ) {
    update_task_master(
      where: { id: { _eq: $_eq } }
      _set: {
        description: $description
        event_id: $event_id
        staff_id: $staff_id
        target_date: $target_date
        task_name: $task_name
        status: $status

      }
    ) {
      returning {
        description
        event_id
        task_name
        staff_id
        id
      }
    }
  }
`;

const UPDATE_TASK_MASTER1 = gql`
  mutation MyMutation2(
    $description: String = ""
    $staff_id: uuid = ""
    $target_date: date = ""
    $task_name: String = ""
    $_eq: bigint = ""
    $group_id: bigint = ""
    $status: String = ""

  ) {
    update_task_master(
      where: { id: { _eq: $_eq } }
      _set: {
        description: $description
        staff_id: $staff_id
        target_date: $target_date
        task_name: $task_name
        group_id: $group_id
        status: $status

      }
    ) {
      returning {
        description
        event_id
        task_name
        staff_id
        id
      }
    }
  }
`;


const DELETE_TASK_MUTATION = gql`
  mutation MyMutation($_eq: bigint = "") {
    update_task_master(
      where: { id: { _eq: $_eq } }
      _set: { status: "deleted" }
    ) {
      returning {
        description
        task_name
      }
    }
  }
`;


// const GET_TASK_QUERY = gql`
//   query GetAllTasks {
//     task_master {
//       description
//       event_id
//       file_path
//       group_id
//       id
//       staff_id
//       target_date
//       task_name
//       user {
//         displayName
//         profile_detail {
//           first_name
//         }
//       }
//     }
//   }
// `;
export default function NewTask(props) {
  const [open, setOpen] = React.useState(false);
  const [drop, setDrop] = React.useState();
  const [drop2, setDrop2] = React.useState();
  const [groupType, setgroupType] = React.useState([]);
  const [Events, setEvents] = React.useState([]);
  const [staff, setStaff] = React.useState([]);
  const [chosen, setChosen] = useState([]);
  const [category, setCategory] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Not Yet Completed");

  console.log("staff data", props);
  // console.log("thisiscsdvsdv", props.item.group_id)

  const {
    loading: grpTypeLoading,
    error: grpTypeError,
    data: allGroups,
  } = useQuery(GET_All_groups);
  const {
    loading: eventTypeLoading,
    error: evntTypeError,
    data: allEvents,
  } = useQuery(GET_All_EVENTS);
  const {
    loading: staffTypeLoading,
    error: staffTypeError,
    data: allStaff,
  } = useQuery(GET_All_STAFF);
  // console.log("all staff data", allStaff?.profile_details);
  const [insertNewTask] = useMutation(INSERT_NEW_TASK);

  const [update_task_master, {  error }] = useMutation(UPDATE_TASK_MASTER);
  const [update_task_master1, {  error:error1 }] = useMutation(UPDATE_TASK_MASTER1);

  console.log("this is all error ",error,error1)
  useEffect(() => {
    setgroupType(allGroups?.group_master);
    setStaff(allStaff?.profile_details);

    setEvents(allEvents?.event_master);
  }, [allGroups, allStaff]);

  const openpop = () => {
    setDrop(true);
    // setDrop2(false);
    // console.log("i am called");
  };
  const openpop2 = () => {
    setDrop2(true);
    // setDrop(false);
  };

  const closepop = () => {
    setDrop(false);
    setDrop2(false);
  };
  useEffect(() => {
    // console.log("cksdcnskjc ksjncds", props.item.event_id);
    if (props?.item?.group_id) {
      openpop2();
      // console.log("evente true");
      setCategory(true);
    }
    if (props?.item?.event_id) {
      // setCategory("event");
      openpop();
      setCategory(false);
      // openpop();
    }
  }, [props]);

  console.log("thisis category", props?.item?.staff_id);

  useEffect(() => {
    // Initialize form fields with data from props.item
    if (props.item) {
      setData({
        task_name: props?.item?.task_name || "", // Provide default values
        description: props?.item?.description || "",
        target_date: props?.item?.target_date || "",
        event: props?.item?.event_id || "",
        group: props?.item?.group_id || "",
      });
      setPersonName(props?.item?.staff_id);
      setSelectedValue(props?.item?.status);
      // Determine whether to open drop or drop2 based on category
      // if (category) {
      //   console.log("drop 1")
      //   openpop();
      // } else {
      //  console.log("drop2")
      //  openpop2();
      // }
    }
  }, [props]);

  const handleClickclose = () => {
    props.setNewTaskOpen(false);
  };
  const [errors, setErrors] = useState({});
  const handleClose = async () => {
    const newErrors = {};
    // Validate displayName
    if (!data.task_name) {
      newErrors.task_name = "Task_name is required";
    }

    if (personName.length === 0) {
      newErrors.personName = "staff  is required";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (props.isForUpdate) {
        try {
          console.log("these are all datas inputed", data, props.item.staff_id, personName[0]);

          if (props?.item?.event_id) {
            const { data: updateData1 } = await  update_task_master({
              variables: {
                _eq: props.id,
                description: data["description"]?data["description"]:props?.item?.description ,
                task_name: data["task_name"]?data["task_name"]: props?.item?.task_name,
                target_date:  date["target_date"]? date["target_date"]: props?.item?.target_date,
                event_id: data["event"]?data["event"]: props?.item?.event_id,
                staff_id: personName?personName :props?.item?.staff_id,
                status: selectedValue?selectedValue:props?.item?.status,
              },
            });
          } else {
            const { data: updateData } = await  update_task_master1({
              variables: {
                _eq: props.id,
                description: data["description"]?data["description"]:props?.item?.description ,
                task_name: data["task_name"]?data["task_name"]: props?.item?.task_name,
                target_date:  date["target_date"]? date["target_date"]: props?.item?.target_date,
                group_id: data["group"]?data["group"]: props?.item?.group_id,
                staff_id:  personName?personName :props?.item?.staff_id,
                status: selectedValue?selectedValue:props?.item?.status,
              },
            });
          }
          // console.log("updated group header info", updatedData);
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }

        props.setNewTaskOpen(false);
      props.setRefreshTask(true);

      } else {
        try {
          // console.log("these are all datas inputed", data, date, personName[0]);
          const { data: insertedData } = await insertNewTask({
            variables: {
              description: data["description"],
              task_name: data["task_name"],
              target_date: date["target_date"],
              event_id: data["event"],
              group_id: data["group"],
              staff_id: personName[0],
              status: selectedValue?selectedValue:"null",
            },
          });
          console.log("instered group header info", insertedData);
        } catch (error) {
          console.error("GraphQL Mutation Error:", error.message);
        }
      }

      props.setNewTaskOpen(false);
      props.setRefreshTask(true);

    }
  };

  // const [image, setImage] = React.useState(null);
  // const handleImageClick = () => {
  //   const input = document.createElement("input");
  //   input.type = "file";
  //   input.accept = "image/*";
  //   input.onchange = (event) => {
  //     setImage(URL.createObjectURL(event.target.files[0]));
  //   };
  //   input.click();
  // };

  const [personName, setPersonName] = React.useState([]);
console.log("first person", personName);
  const handleChangeMember = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(personName);
  };

  const dispatch = useDispatch();
  const newData = props.item;
  const [data, setData] = React.useState({});

  const handleChange = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
  };
  console.log("data", data);
  const newDate = props.date;
  const [date, setDate] = React.useState(newDate);
  const handleDateChange = (key, value) => {
    let formattedDate = value.format("YYYY/MM/DD");
    console.log(formattedDate);
    setDate((prevData) => {
      return {
        ...prevData,
        [key]: formattedDate,
      };
    });
  };

  const [deleteMutation] = useMutation(DELETE_TASK_MUTATION,{
    onCompleted: () =>{
      // refetch();
      props.setNewTaskOpen(false);
      props.setRefreshTask(true);
    }
  });

  const deleteTask = async (id) => {
    // Ask the user for confirmation
    const confirmation = window.confirm("Are you sure you want to delete this task?");
  
    if (confirmation) {
      try {
        // Proceed with the deletion
        const deleteTaskRequest = await deleteMutation({
          variables: {
            _eq: props?.item?.id,
          },
        });
      } catch (error) {
        console.log("Error deleting task: ", error);
      }
    } else {
      console.log("Task deletion canceled.");
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="xl"
        sx={Styles.popup}
        open={props.open}
        onClose={handleClickclose}
      >
        <Box sx={Styles.header}>
          <img
            style={{ width: "79px", height: "25", marginLeft: 25 }}
            src={require("../../images/navLogo.png")}
          />
          <DialogActions>
            <Button
              onClick={handleClickclose}
              sx={{ font: 30, color: "#FFFFFF", marginLeft: 50 }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>

        <DialogContent>
          <Box sx={Styles.mainBox}>
            <Box sx={Styles.firstBox}>
              <TextField
                size="small"
                fullWidth
                style={Styles.input}
                id="outlined-basic"
                label="Task Name"
                variant="outlined"
                value={data.task_name}
                onChange={(e) => {
                  handleChange("task_name", e.target.value);
                }}
                error={!!errors.task_name}
                helperText={errors.task_name}
              />

              <FormControl
                sx={{
                  width: "465px",
                  mt: "25px",
                  fontFamily: "MontserratRegular",
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      fontFamily: "MontserratRegular",
                    },
                  }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  // style={Styles.input}
                  label="Task Type"
                  value={data.category}
                  onChange={(e) => {
                    handleChange("category", e.target.value);
                  }}
                  error={!!errors.category}
                  helperText={errors.category}
                >
                  <MenuItem onClick={openpop} value={"Event"}>
                    Event
                  </MenuItem>
                  <MenuItem onClick={openpop2} value={"Group"}>
                    Group
                  </MenuItem>
                  <MenuItem onClick={closepop} value={"Global"}>Global</MenuItem>
                </Select>
              </FormControl>
              {drop && (
                <>
                  <InputLabel id="demo-simple-select-sublabel">
                    Select Event
                  </InputLabel>
                  <Select
                    sx={Styles.input}
                    labelId="demo-simple-select-sublabel"
                    id="demo-simple-select-sub"
                    value={data.event}
                    onChange={(e) => {
                      handleChange("event", e.target.value);
                    }}
                  >
                    {Events?.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              )}
              {drop2 && (
                <>
                  <InputLabel id="demo-simple-select-sublabel">
                    Select Group
                  </InputLabel>
                  <Select
                    sx={Styles.input}
                    labelId="demo-simple-select-sublabel"
                    id="demo-simple-select-sub"
                    value={data.group}
                    onChange={(e) => {
                      handleChange("group", e.target.value);
                    }}
                  >
                    {groupType?.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                    {/* <MenuItem disabled value="">
                      <em>Select Group</em>
                    </MenuItem>
                    <MenuItem value={'Group 1'}>Group 1</MenuItem>
                    <MenuItem value={'Group 2'}>Group 2</MenuItem>
                    <MenuItem value={'Group 3'}>Group 3</MenuItem> */}
                  </Select>
                </>
              )}
                  
                <FormControl  sx={Styles.input} fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    size="small"
                    sx={{ width: "100%" }}
                    lable="Status"
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    lable="Status"
                    value={selectedValue}
                    onChange={(event) => setSelectedValue(event.target.value)}
                  >
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                    <MenuItem value={"Not Yet Completed"}>Not Yet Completed</MenuItem>
                    <MenuItem value={"In progress"}>In progress</MenuItem>
                  </Select>
                </FormControl>
            </Box>
            <Box sx={Styles.secondBox}>
              <TextField
                sx={Styles.description}
                // id="outlined-multiline-static"
                label="Description"
                multiline
                rows={3}
                value={data.description}
                onChange={(e) => {
                  handleChange("description", e.target.value);
                }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // inputFormat="MM/DD/YYYY"
                  size="small"
                  label={
                    data.target_date ? data.target_date : "Target Date"
                  }
                  onChange={(e) => {
                    handleDateChange("target_date", e);
                  }}
                  value={data.targetDate}
                  error={!!errors.target_date}
                  helperText={errors.target_date}
                  sx={{
                    width: "465px",
                    marginTop: "20px",
                    marginBottom: "15px",
                    "& .MuiInputBase-root": {
                      fontFamily: "MontserratRegular",
                      height: "40px",
                    },
                    "& .MuiFormControl-root MuiTextField-root css-kiy1u2-MuiFormControl-root-MuiTextField-root":
                      {
                        // height: "20px",
                        backgroundColor: "red",
                      },
                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                      fontFamily: "MontserratRegular",
                      color: "#999999",
                      fontSize: "14px",
                      lineHeight: "10px",
                    },
                  }}
                />
              </LocalizationProvider>
              <Box>
                <InputLabel id="demo-multiple-checkbox-label">
                  Select Staff {chosen}
                </InputLabel>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  sx={Styles.input}
                  size="small"
                  value={personName}
                  onChange={handleChangeMember}
                  error={!!errors.personName}
                  helperText={errors.personName}
                  input={<OutlinedInput label="Tag" />}
                  // renderValue={(selected) => selected.join(", ")}
                >
                  {staff
                    ?.map((value) => ({
                      id: value.user_id,
                      name: value.user.displayName,
                      defaultRole: value.user.defaultRole,
                    }))
                    .map((staffMember) => (
                      <MenuItem
                        sx={{ fontFamily: "MontserratRegular" }}
                        key={staffMember.id}
                        value={staffMember.id}
                        // setChosen={staffMember.name}
                      >
                        {staffMember.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {props.isForUpdate &&
           <Button sx={Styles.assignBtn} onClick={deleteTask}>
           Delete Task
         </Button>
          }
          <Button sx={Styles.assignBtn} onClick={handleClose}>
            Assign Task
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Styles = {
  header: {
    // width:"100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FF0000",
  },
  popup: {
    borderRadius: "15px",
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems:"center",
    width: "950px",
  },
  input: {
    width: "100%",
    marginTop: "18px",

    height: "40px",
    // backgroundColor:"#FF0000",
    borderRadius: "5px",
    fontFamily: "MontserratRegular",
  },
  firstBox: {
    width: "49%",
  },
  secondBox: {
    width: "49%",
  },
  description: {
    width: "100%",
    marginTop: "15px",
    fontFamily: "MontserratRegular",
  },
  fileUpload: {
    marginLeft: "10px",
    backgroundColor: "#D9D9D9",
    border: "1px solid #888888",
    borderRadius: "5px",
    fontFamily: "MontserratRegular",
  },
  date: {
    textAlign: "start",
    width: "100%",
    height: "40px",
    marginTop: "15px",
    color: "#999999",
    background: "#FFFFFF",
    border: " 1px solid #999999",
    borderRadius: "5px",
  },
  assignBtn: {
    // width: "100%",
    height: "40px",
    marginTop: "15px",
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    fontFamily: "MontserratRegular",
    fontWeight:"bold"
  },
};
