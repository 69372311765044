const Styles = {
  mainContainer: {
    width: "100%",
    // height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minWidth: "336px",
    margin: "auto",
    // height: "8vh",
  },
  largeTxt: {
    fontFamily: "MontserratRegular",
    fontWeight: "500",
    fontSize: "20px",
    color: "#414042",
    margin: "auto 0px auto 4px",
    ml: "2%",
    lineHeight:2.5,
  },
  container1: {
    width: "47%",
    minWidth:"320px",

    border: "1px solid #999999",
    borderRadius: "10px",
    height: "80vh",
    margin: "24px 8px 10px 20px",
    "@media (max-width:768px)": {
      margin:"auto",
      marginY:"4px",

  },
    display: "flex",
    flexDirection: "column",
  },
  container2: {
    width: "47%",
    minWidth:"320px",
    border: "1px solid #999999",
    borderRadius: "10px",
    height: "430px",
    margin: "24px 20px 10px 0px",
    "@media (max-width:768px)": {
      margin:"auto",
      // marginLeft:"90px",
      overflowX:"hidden",
      marginY:"4px",
  },
},
  cardContainer: {
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "auto",
    scrollbarRadius: "10px",
    
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },

    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
};

export default Styles;
