import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import calender from "../../images/EventManagement1/Calender.png";
import location from "../../images/EventManagement1/Location.png";
import { useNhostClient } from "@nhost/react";
import nullimage from '../../images/nullImage.jpeg'


export default function GroupEventCard(props) {
  console.log("Which id is this", props.id);
  const nhost = useNhostClient();

  const [eventupdate, setEventUpdate] = useState();
  const navigate = useNavigate();
  const [img, setImg] = useState("");

  useEffect(() => {
    async function getImage() {
      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: props.imgUrl,
        });
        console.log("Image URL:", item);
        setImg(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
    }
    getImage();
  }, [props]);

  const dateObject = new Date(props.recurrance_start_date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const utcString = dateObject.toISOString();
  const formattedStartDate = `${formattedDate}`;

  return (
    <div>
      <Box
        sx={styles.container}

      >
        {/* .................img container */}
        <Box sx={styles.imgContainer}>
          {/* <getUrl url={events.event_master.cover_img_path}/> */}
          <img
            // src={img}
            src={
              img && !img.endsWith("files/")
              ? img
              : nullimage
        
             } 
            alt="cover image"
            height={"100%"}
            width={"100%"}
            style={{ borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
          />
       
        </Box>
        {/* .................img container ends */}

        {/*.................text container */}
        <Box sx={styles.textContainer}>
          <Typography sx={styles.titleTxt}>{props.name}</Typography>
          <Typography sx={styles.desc}> {props.description} </Typography>

          <Box sx={styles.dateTime}>
            <img
              style={{ width: "15px", height: "15px" }}
              src={calender}
              alt="calender"
            />
            <Typography
              sx={[styles.baseTxt, { marginRight: "20px", marginLeft: "2px" }]}
            >
              {formattedStartDate}
            </Typography>
            {/* <Typography sx={styles.baseTxt}>
              {props.recurrance_end_date}
            </Typography> */}
          </Box>

          <Box sx={styles.dateTime}>
            <img
              style={{ width: "17px", height: "17px" }}
              src={location}
              alt="location"
            />
            <Typography sx={styles.baseTxt}>{props.location}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const styles = {
  div: {
    width: "100%",
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    scrollbarWidth: "auto",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  img: {
    height: "100%",
    width: "100%",
  },
  container: {
    width: "300px",
    backGroundColor: "red",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "215px",
    margin: "10px",
  },
  imgContainer: {
    width: "300px",
    height: "100px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "2px",
    width: "100%",
  },
  titleTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#252525",
    lineHeight: "20px",
  },
  desc: {
    fontFamily: "MontserratRegular",
    fontSize: "9px",
    textAlign: "left",
    fontWeight: "400",
    color: "#414042",
    lineHeight: "14.63px",
    height: "54px",
  },
  dateTime: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  baseTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "8px",
    fontWeight: "500",
    color: "#414042",
    lineHeight: "12.19px",
  },
  filebox1: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    top: -20,
  },
};
