const Styles = {
    mainContainer: {
        width: "100%",
        // height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      },
      topContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        minWidth:'336px',
        margin: "auto",
        height: "8vh",
      },
      largeTxt: {
        fontFamily: "MontserratRegular",
        fontWeight: "500",
        fontSize: "20px",
        color: "#414042",
        margin: "auto 0px auto 4px",
        ml:'2%',
      },
      cardContainer:{
        width: "95%",
        height: "75vh",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "left",
        flexDirection: "row",
        border: "1px solid #999999",
        borderRadius: "10px",
        margin: "auto",
        marginTop: "8px",
        marginBottom: "8px",
        overflowY:'scroll',
        scrollbarWidth: "auto",
        scrollbarRadius: "10px",
      "&::-webkit-scrollbar": {
        width: "0.2em",

      },
      
      
      "&::-webkit-scrollbar-track": {
        background: "#E1E5F8",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },

      }

}

export default Styles;