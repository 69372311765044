import { Box, Button, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
} from "@material-ui/core";
import { useNavigate } from "react-router";
import AddGroupCategory from "../Popup/AddGroupCategory";

import { gql, useMutation, useQuery } from "@apollo/client";

const GET_GROUP_CATEGORY_QUERY = gql`
  query GetGroupCategory {
    group_category(order_by: { id: desc }) {
      created_by
      created_date
      description
      id
      name
    }
  }
`;
const DELETE_GROUP_CATEGORY_MUTATION = gql`
  mutation DeleteGroupCategory($id: bigint!) {
    delete_group_category(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_PERMISSION_QUERY = gql`
query MyQuery9($_eq: String = "", $_eq1: uuid = "", $_eq2: String = "staff") {
  role_master(where: {access_name: {_eq: $_eq}, _and: {roleprofile: {user_id: {_eq: $_eq1}, _and: {user: {defaultRole: {_eq: $_eq2}}}}}}) {
    access_name
    description
    id
    roles
    status
    roleprofile {
      user {
        defaultRole
      }
    }
  }
}
`;
const useStyles = makeStyles({
  table: {
    minWidth: 600,
    width: "100%",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    //   borderRadius:"10px"
  },
  tableRow: {
    height: 10,
  },
  tableCell: {
    padding: "0px 16px",
    height: 5,
    fontFamily: "MontserratRegular",
  },
  tableCell1: {
    padding: "0px 16px",
    height: 5,
    fontFamily: "MontserratSemibold",
  },
  header: {
    height: 30,
  },
  headerCell: {
    padding: "5px 16px",
    height: 5,
    color: "#252525",
    fontFamily: "MontserratBold",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#999999",
    },
  },
});

export default function Tab4() {
  const navigate = useNavigate();
  function handleNav() {
    navigate("/adduser");
  }
  const {  data:permsission  } = useQuery(GET_PERMISSION_QUERY, {
    variables: {
      _eq:localStorage.getItem("roleaccess"),
      _eq1:localStorage.getItem("id")
    },
    onError:(error) =>{
      console.log(error , "this is error")
    },
    onCompleted:(data)=>{
      console.log("permission status :", data)
    }
  }); 
  const roll = localStorage.getItem('role')
  const [eventCategory, seteventCategory] = useState([]);
  const [updated, setUpdated] = useState(false);

  const { loading, error, data ,refetch} = useQuery(GET_GROUP_CATEGORY_QUERY);

  console.log("Event CAT", data);

  // useEffect(() => {
  //   seteventCategory(data?.group_category);
  // });
  useEffect(()=>{
    if (data?.group_category) {
      seteventCategory(data?.group_category)

  }
   // After successful deletion, refetch the data to update the UI
   refetch();
  }, [data]);

  const classes = useStyles();
  // const [data, setData] = useState(rows);
  const [DeleteGroupCategory] = useMutation(DELETE_GROUP_CATEGORY_MUTATION);

  const handleDeleteRow = async (id) => {
    try {
      await DeleteGroupCategory({ variables: { id } });
      seteventCategory(eventCategory.filter((row) => row.id !== id));
    } catch (error) {
      console.error('Error deleting event type:', error);
    }
   
  };

  const [newTaskOpen, setNewTaskOpen] = useState(false);

  const openNewTask = () => {
    setNewTaskOpen(!newTaskOpen);
    setIsforUpdate(false);
  };

  const [isforUpdate, setIsforUpdate] = useState(false);
  const [id, setId] = useState();
  const [description, setDescription] = useState();
  const [name, setName] = useState();
  const handleEditRow = (id, description, name) => {
    setIsforUpdate(true);

    setNewTaskOpen(!newTaskOpen);
    setId(id);
    setDescription(description);
    setName(name);
  };

  return (
    <Box>
      <Box sx={Styles.tableBox}>
        <Box
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
                {
  (    (permsission?.role_master[0]?.roles?.addgroupcategory && roll === "staff")   || roll==="admin") &&
         
          <Box onClick={openNewTask} sx={Styles.btnDashboard} > + Add New</Box>
                }
        </Box>
        <Box style={Styles.tableBox2}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                className={classes.header}
                style={{
                  backgroundColor: "#FFFFFF",
                  height: "20px",
                  color: "#252525",
                }}
              >
                <TableRow>
                  <TableCell className={classes.headerCell} align="center">
                    Name
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Description
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Created/Edited by
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Created Date
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Edit
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventCategory?.map((row) => (
                  <TableRow className={classes.tableRow} key={row.id}>
                    <TableCell
                      className={classes.tableCell1}
                      align="center"
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.description}
                    </TableCell>
                    <TableCell className={classes.tableCell1} align="center">
                      {row.created_by}
                    </TableCell>
                    <TableCell className={classes.tableCell1} align="center">
                      {row.created_date}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <IconButton  onClick={() => handleEditRow(row.id,row.description,row.name)} >
                        <img src={require("../../images/editGray.png")} />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <IconButton onClick={() => handleDeleteRow(row.id)}>
                        <img src={require("../../images/delete.png")} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {newTaskOpen && (
        <AddGroupCategory
        isforUpdate={isforUpdate} id={id} description={description} group_category={name}
          open={newTaskOpen}
          setNewTaskOpen={setNewTaskOpen}
          eventCategory={eventCategory}
          seteventCategory={seteventCategory}
          setUpdated={setUpdated}
        />
      )}
    </Box>
  );
}
const Styles = {
  tableBox: {
    width: "100%",
    height: "500px",
    // border: "1px solid #999999",
    // borderRadius: " 10px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  tableBox2: {
    width: "100%",
  },
  btnDashboard: {
    width: "85px",
    borderWidth: "1px",
    height: "30px",
    borderRadius: "10px",
    fontFamily: "MontserratSemibold",
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: "12px",
    display: "flex",
    lineHeight: "20px",
    letterSpacing: "0.5px",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "10px",
    color: "#252525",
    border: "1px solid #252525",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "FF0000",
      color: "white",
      borderColor: "FF0000",
    },
  },
};