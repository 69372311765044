import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddeEventToGroup from "../Popup/AddEventToGroup";
import GroupEventCard from "./GroupEventCard";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const REMOVE_EVENTS_FROM_GROUPS = gql`
  mutation MyMutation2($_eq: bigint = "", $_eq1: bigint = "") {
    delete_group_event(
      where: { group_id: { _eq: $_eq }, _and: { event_id: { _eq: $_eq1 } } }
    ) {
      returning {
        event_id
        group_id
        group_master {
          name
        }
        event_master {
          name
        }
      }
    }
  }
`;
const GET_EVENTS_QUERY = gql`
  query MyQuery3($_eq: bigint = "") {
    group_event(where: { group_id: { _eq: $_eq } }) {
      event_id
      event_master {
        cover_img_path
        description
        end_time
        event_category {
          description
          name
          id
        }
        id
        is_recurring
        location
        name
        parent_id
        publish_status
        publish_to
        recurrance_end_date
        recurrance_end_time
        recurrance_start_time
        start_time
        recurrance_start_date
        status
        type_id
      }
    }
  }
`;

export default function EventsBox(props) {
  const [events, setEvents] = useState([]);
  // console.log("data for events",props?.group[0]?.group_id);
  //const group = props.group;
  // console.log("group id for events ",events.group_id);
  const navigate = useNavigate();
  const [eventRefetch, seteventRefetch] = useState(false);
  //console.log("group  id is set",props.group );
  const [eventupdate, setEventUpdate] = useState();
  const [openpop, setOpenpop] = useState(false);
  const [isUser, setisUser] = useState(true);
  const [EventName, setEventName] = useState("");
  const handleEvents = async () => {
    // console.log("only for popup");
    setOpenpop(!openpop);
  };
  const [imgUrl, setImgUrl] = React.useState("");
  const [roles, setRoles] = useState();

  const handleEventClick = (
    id,
    name,
    description,
    recurrance_start_date,
    recurrance_end_date,
    location,
    deac,
    imgUrl,
    edate,
    event_category,
    start_time,
    end_time,
    recurrance_frequency
  ) => {
    setisUser(true);
    let membership1 = true;
    console.log("it is calendarsdfvzsvsv id ::",   id,
    name,
    description,
    recurrance_start_date,
    recurrance_end_date,
    location,
    deac,
    imgUrl,
    edate,
    event_category,
    start_time,
    end_time,
    recurrance_frequency);
    navigate("/eventmanagement2", {
      state: {
        isUser: isUser,
        id,
        membership1,
        name,
        description,
        recurrance_start_date,
        recurrance_end_date,
        location,
        deac,
        imgUrl,
        edate,
        event_category,
        start_time,
        end_time,
        recurrance_frequency,
      },
    });
    // console.log('handleEventCllick',CalEvents)
    // fetchCalEvents(name).then((calEvents) => {
    //   navigate('/eventmanagement2', { state: { isUser: isUser,CalendarId} });
    // });
    // const eventName = event.title;
    setEventName(name);
    //  console.log('evnet event click : ', EventName)
    //  fetchCalEvents(name);
  };

  const { data: eventsdata, refetch } = useQuery(GET_EVENTS_QUERY, {
    variables: {
      _eq: props?.grpId,
    },
  });
  useEffect(() => {
    setEvents(eventsdata?.group_event);
  }, [eventsdata]);
  // console.log("data ++",eventsdata);
  // console.log("data ++1",events);

  useEffect(() => {
    if (eventRefetch) {
      refetch();
    }
  }, [eventRefetch]);

  const [removeEventGroups] = useMutation(REMOVE_EVENTS_FROM_GROUPS, {
    onCompleted: (data) => {
      // console.log("group infor updated : ", data)
      alert(`Removed  Event`);

      refetch();
    },
    onError: (error) => {
      console.log("group infor errorrr : ", error);
    },
    refetchQueries: [{ query: GET_EVENTS_QUERY }],
  });

  const deleteEvents = async (eid, name) => {
    const confirmation = window.confirm(
      `Are you sure want to remove ${name} Event?`
    );

    if (confirmation) {
      try {
        const { data: insertedData } = await removeEventGroups({
          variables: {
            _eq: props.group[0].group_id,
            _eq1: eid,
          },
        });

        refetch();
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };

  return (
    <>
      {(props.user === "admin" || roles === "staff") && (
        // <Button  onClick={handleEvents}>
        <Box sx={styles.addbox}>
          <img
            src={require("../../images/plus.png")}
            onClick={handleEvents}
            style={{ width: 25, height: 25, cursor: "pointer" }}
          />
          {/* </Button> */}
        </Box>
      )}
      <div style={styles.div}>
        {events?.map((events, index) => (
          <>
            {(props.user === "admin" || roles === "staff") && (
              <img
                src={require("../../images/cross.png")}
                onClick={() =>
                  deleteEvents(events?.event_id, events?.event_master.name)
                }
                style={{ width: 25, height: 25, cursor: "pointer" }}
              ></img>
            )}
            <div
              onClick={() =>
                handleEventClick(events?.event_id, events?.event_master.name, events?.event_master.description,
                  events?.event_master.recurrance_start_date,
                  events?.event_master.recurrance_end_date,
                  events?.event_master.location,
                  events?.event_master.description,
                  events?.event_master.cover_img_path,
                  events?.event_master.edate,
                  events?.event_master.event_category,
                  events?.event_master.start_time,
                  events?.event_master.end_time,
                  events?.event_master.recurrance_frequency)
              }
            >
              <GroupEventCard
                id={events?.id}
                // group_id={events?.group_events?.group_id}
                name={events?.event_master?.name}
                description={events?.event_master?.description?.substring(
                  0,
                  190
                )}
                recurrance_start_date={
                  events?.event_master?.recurrance_start_date
                }
                event_category={events?.event_master?.event_category}
                event_type={events?.event_master?.event_type}
                recurrance_end_date={events?.event_master?.recurrance_end_date}
                location={events?.event_master?.location}
                metadata={events?.event_master?.metadata}
                imgUrl={events?.event_master?.cover_img_path}
              />
            </div>
          </>
        ))}
      </div>
      {openpop && (
        <AddeEventToGroup
          open={openpop}
          seteventRefetch={seteventRefetch}
          setOpenpop={setOpenpop}
          id={props?.id}
          gid={props?.group[0]?.group_id}
        />
      )}
    </>
  );
}

const styles = {
  div: {
    width: "100%",
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    scrollbarWidth: "auto",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  container: {
    width: "300px",
    backGroundColor: "red",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    height: "215px",
    margin: "10px",
  },
  imgContainer: {
    width: "250px",
    height: "100px",
  },
  addbox: {
    display: "flex",
    position: "relative",
    // backgroundColor:"red",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "2px",
    width: "100%",
  },
  titleTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#252525",
    lineHeight: "20px",
  },
  desc: {
    fontFamily: "MontserratRegular",
    fontSize: "9px",
    textAlign: "left",
    fontWeight: "400",
    color: "#414042",
    lineHeight: "14.63px",
  },
  dateTime: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  baseTxt: {
    fontFamily: "MontserratRegular",
    fontSize: "8px",
    fontWeight: "500",
    color: "#414042",
    lineHeight: "12.19px",
  },
};
