const Styles = {
  headerBox: {
    height: "8vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  },
  header: {
    margin: "auto 2% auto 2%",
    fontSize: " 18px",
    color: " #414042",
    fontFamily: "MontserratRegular",
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
  },
  card: {
    width: "93.9%",
    // height: "240px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "row",
    borderWidth: "1px 1px 0px 1px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px 10px 0px 0px",
    "@media (max-width:768px)": {
      flexDirection: "column",
      overflow:"scroll",
    },
  },
  jitsi: {
    width: "93.9%",
    height: "640px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "row",
    borderWidth: "1px 1px 0px 1px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px 10px 0px 0px",
    "@media (max-width:768px)": {
      flexDirection: "column",
      overflow:"scroll",
    },

  },
  container: {
    width: "100%",
    display: "flex",
    marginTop: "15px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  imgBox: {
    width: "500px",
    height:"50%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  img: {
    width: "100%",
    height: "300px",
    // borderRadius: " 10px 0px 0px 0px",
    // objectFit: "cover",
    
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  info: {
    width: "50%",
    margin:"25px",
    marginTop:"35px",
    height:"auto",
    alignItems:"center",
    // overflow:"scroll",
    "@media (max-width:768px)": {
      flexDirection: "column",
      width: "90%",
      overflow:"scroll",
    },
  },
  cardHeader: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: " 35px",
    color: "#252525",
    "@media (max-width:768px)": {
      fontSize: "21px",
      lineHeight: "25px",
      width:"100%"
    },
  },
  text: {
    marginLeft: "30px",
    marginTop: "5px",
    fontSize: "12px",
    fontFamily: "MontserratRegular",
    lineHeight: "15px",
    color: "#252525",
    overflow: "hidden",
  },
  textCard: {
    marginLeft: "10px",
    marginTop: "5px",
    fontSize: "12px",
    fontFamily: "MontserratMedium",
    color: "#252525",
    "@media (max-width:768px)": {
      fontSize: "10px",
    },
  },
  eventStatus:{
    marginTop:"11px",
    // marginRight:"45px",
    display:"flex",
    flexWrap:"wrap",
    width: "260px",
    height:"30px",
    alignItems:"center",
    border:"1px solid #252525",
    borderRadius: "10px",
    border:1
  },
  menu: {
    // top: "10px",
    // left: "10px",
    // right: "15px",
    marginTop: "10px",
    // marginLeft: "45px",
    width: "25px",
    height: "25px",
    
  },
  threeDots:{
    top: "10px",
    // left: "0px",
    right: 0,
    ":hover":{
      cursor:"pointer",
    },
    // "@media (max-width:768px)": {
    //    display:"none"
    //   },
  },
  nav: {
    marginTop: "15px",
    width: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    cursor:"pointer",
    "& button:hover": {
      backgroundColor: "#999999",
      color: "white",
    },
  },
  navBox: {
    width: "94%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    backgroundColor: "#FEFEFE",
    border: " 1px solid #999999",
    borderRadius: "0px 0px 10px 10px",
  },
  navName: {
    fontSize: "12px",
    color: "#252525",
    fontFamily: "MontserratRegular",
    cursor:"pointer"
  },
  lowerHeader: {
    fontSize: "14px",
    marginLeft: "5px",
    color: "#252525",
    fontFamily: "MontserratBold",
   
  },
  eventCards: {
    width: "80%",
    margin: "10px",
    display: "flex",
    // overflow:"hidden",
    flexDirection: "row",
  },
  detailsBox: {
    width: "100%",
    height:"305px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow:"hidden",
    "@media (max-width:768px)": {
      overflow:"scroll"
    },
  },
  firstLeftBox: {
    width: "40%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    // backgroundColor: "red",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  facilitators: {
    // margin: "15px",
    marginLeft: "40px",
    marginRight: "0px",
    width: "90%",
  },
  descriptionBox: {
    margin: "15px",
    marginLeft: "40px",
    // minWidth: "400px",
    overflowY: "auto",
    width: "480px",
    height: "70px",
    "&::-webkit-scrollbar": {
      width: "0.3em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#777777",
    },
    // textOverflow:"ellipsis",
    // height:'50px',
  },
  Attendance: {
    margin: "15px",
    marginLeft: "40px",
    width: "100%",
    "@media (max-width:768px)": {
      width: "100%",
      flexDirection: "column",
    },
  },
  firstRightBox: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "@media (max-width:768px)": {
      width: "100%",
      // overflow:"scroll"
    },
  },
  horizontal: {
    width: "100%",
    //   minWidth:"320px",
    marginTop: "15px",
    marginLeft:"10px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    //   overflow:"scroll",
    "@media (max-width:768px)": {
    //   flexDirection: "column",
      justifyContent:"space-between",
      marginTop:"5%",
      marginLeft:"10%",
      width:"80%"
    },
  },
  cardBox: {
    width: "43vw",
    // backgroundColor: "red",
    // display: "flex",
    overflowX: "auto",
    marginTop: "0px",
    margin:"auto",
    marginLeft:"20px",
    "@media (max-width:768px)": {
      width: "90%",
      marginLeft:"5%"
      // overflowX: "scroll",
    },
  },
  navMenu: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #999999",
    // cursor:"pointer"
  },
  contentBox:{
    height:"305px"
  },
  scheduleBox:{
    height:"305px",
    overflowY:"scroll",
  },
  checklist:{
    height:"305px",
    display:"flex",
    // justifyContent:"space-between",
    overflowY:"scroll",
  },
  leftArrow: {
    width: "25px",
    height: "25px",
    // marginTop: "4%",
    top:"27%",
    right:"2%",
    position:"relative",
    cursor:"pointer",
    "@media (max-width:768px)": {
      display:"none",
    },
  },
  rightArrow: {
    width: "25px",
    height: "25px",
    // marginTop: "4%",
    top:"27%",
    left:"86%",
    position:"relative",
    cursor:"pointer",
    "@media (max-width:768px)": {
      display:"none",
    },
  }
};
export default Styles;