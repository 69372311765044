import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import GroupManagementStepperCard from "./GroupManagementStepperCard";
import GroupManagementStepperCard2 from "./GroupManagementStepperCard2";
import searchbar from "../../images/searchbar.png";
import avatar from "../../images/avatar.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addgroupData, groupData } from "../../Redux/features/UserSlice";
import { Navigate, useNavigate, useNavigation } from "react-router-dom";
import img1 from "../../images/img3.jpg";
import { gql, useQuery, useMutation } from "@apollo/client";

const GET_ALL_EVENTS = gql`
  query getEvents {
    event_master(
      where: { parent_id: { _is_null: true }, status: { _eq: "isactive" } }
    )  {
      activation_date
      category_id
      closure_date
      created_at
      cover_img_path
      deactivation_date
      description
      id
      name
      recurrance_end_date
      recurrance_frequency
      recurrance_start_date
      type_id
      updated_at
      location
    }
  }
`;

const GET_ALL_MEMBERS = gql`
  query getAllUsers {
    profile_details {
      bio
      birth_date
      category
      first_name
      last_name
      profile_pic_path
      second_name
      user_id
      user {
        defaultRole
        displayName
        email
        emailVerified
        id
        avatarUrl
        phoneNumber
      }
    }
  }
`;

const INSERT_EVENTS_TO_GROUP_MUTATION = gql`
  mutation MapEventsGrps($event_id: bigint, $group_id: bigint) {
    insert_group_event(objects: { event_id: $event_id, group_id: $group_id }) {
      returning {
        id
        group_id
        event_id
      }
    }
  }
`;

const DELETE_EVENTS_FROM_GROUP_MUTATION = gql`
  mutation RemoveMapEventGrps($event_id: bigint, $group_id: bigint = "") {
    delete_group_event(
      where: {
        _and: { event_id: { _eq: $event_id }, group_id: { _eq: $group_id } }
      }
    ) {
      returning {
        event_id
        group_id
        id
      }
    }
  }
`;
const ADMIN_INSERT_GROUP = gql`
query MyQuery6 {
  users(where: {defaultRole: {_eq: "admin"}}) {
    email
    id
  }
}
`;
const INSERT_USER_TO_GROUP_MUTATION = gql`
  mutation MapUserGrps($group_id: bigint, $user_id: uuid) {
    insert_user_groups(objects: { group_id: $group_id, user_id: $user_id }) {
      returning {
        group_id
        id
        user_id
      }
    }
  }
`;
const DELETE_USER_FROM_GROUP_MUTATION = gql`
  mutation RemoveMapUsrGrps($group_id: bigint = "", $user_id: uuid = "") {
    delete_user_groups(
      where: {
        _and: { group_id: { _eq: $group_id }, user_id: { _eq: $user_id } }
      }
    ) {
      returning {
        group_id
        id
        user_id
      }
    }
  }
`;
export default function EventMemberConfiguration() {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState([]);
  const [memberId, setMemberId] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const {
    loading: grpTypeLoading,
    error: grpTypeError,
    data: allEvents,
  } = useQuery(GET_ALL_EVENTS);
  const {data: adminMem} = useQuery(ADMIN_INSERT_GROUP);
  console.log("adminmember", adminMem);
  const {
    loading: memberTypeLoading,
    error: memberTypeError,
    data: allMembers,
  } = useQuery(GET_ALL_MEMBERS);

  // useEffect(() => {
  //   if (adminMem && adminMem.users) {
  //     const adminIds = adminMem.users.map(admin => admin.id);
  //     setMemberId(adminIds);
  //   }
  // }, [adminMem]);

  useEffect(() => {
    if (adminMem && adminMem?.users) {
      const adminIds = adminMem?.users?.map(admin => admin.id);
      setMemberId(adminIds);
      console.log("these are checking admin users",adminIds);
      // Call handleMembers to add admin IDs automatically
      adminIds.forEach(adminId => {
        handleMembers(adminId, false); // Assuming 'true' is the default status for admins
      });
    }
  }, [adminMem]); //

  // console.log("object members", allMembers);
  // console.log("object members error", memberTypeError);


  useEffect(() => {
    setEvents(allEvents?.event_master);
    setMembers(allMembers?.profile_details);
  }, [allEvents, allMembers]);

  useEffect(() => {
    let arr = selectedId;
    setSelectedId(arr);
  }, [selectedId]);
  const [Events, setEvents] = useState([]);
  const [members, setMembers] = useState([]);
  const [insertEventToGroup] = useMutation(INSERT_EVENTS_TO_GROUP_MUTATION);
  const [deleteEventFromGroup] = useMutation(DELETE_EVENTS_FROM_GROUP_MUTATION);
  const [insertUserToGroup] = useMutation(INSERT_USER_TO_GROUP_MUTATION);
  const [deleteUserFromGroup] = useMutation(DELETE_USER_FROM_GROUP_MUTATION);

  const handleEvent = async (id, status) => {
    console.log(id, "data", status);
    setSelectedId((selectedId) =>
      selectedId.includes(id)
        ? selectedId.filter((cardId) => cardId !== id)
        : [...selectedId, id]
    );
    // console.log(data['description'])

    if (!status) {
      try {
        const { data: insertedEvent } = await insertEventToGroup({
          variables: {
            group_id: localStorage.getItem("group_id"),
            event_id: id,
          },
        });
        console.log("instered event", insertedEvent);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    } else {
      try {
        const { data: deletedEvent } = await deleteEventFromGroup({
          variables: {
            group_id: localStorage.getItem("group_id"),
            event_id: id,
          },
        });
        console.log("instered event", deletedEvent);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };

  // ..................................member......................
  useEffect(() => {
    let arr = memberId;
    setMemberId(arr);
  }, [memberId]);

  // const handleEvent = async (id, status) => {
  //   console.log(status);
  //   setSelectedId(selectedId =>
  //     selectedId.includes(id)
  //       ? selectedId.filter(cardId => cardId !== id)
  //       : [...selectedId, id]

  //   );

  const handleMembers = async (id, status) => {
    console.log(status);
    setMemberId((memberId) =>
      memberId.includes(id)
        ? memberId.filter((cardId) => cardId !== id)
        : [...memberId, id]
    );
    console.log('HANDLE MEMBER CALLED')
    if (!status) {
      try {
        const { data: insertedEvent } = await insertUserToGroup({
          variables: {
            group_id: localStorage.getItem("group_id"),
            user_id: id,
          },
        });
        console.log("instered event", insertedEvent);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    } else {
      try {
        const { data: deletedUser } = await deleteUserFromGroup({
          variables: {
            group_id: localStorage.getItem('group_id'),
            user_id: id,
          },
        });
        console.log("instered event", deletedUser);
      } catch (error) {
        console.error("GraphQL Mutation Error:", error.message);
      }
    }
  };

  // const navigation = useNavigation();
  const dispatch = useDispatch();
  const groupHeaderInfo = useSelector((state) => state.user.groupHeaderInfo);
  // const { grpName, startDate, endDate, desc, cover } = groupHeaderInfo;
  const addData = () => {
    dispatch(addgroupData(groupHeaderInfo));
    navigate("/groupmanagement", { state: { isPublished: true } });
  };

  return (
    <Box>
      <Typography sx={styles.title}>Map Events</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #999999",
          borderRadius: "10px",
          margin: "0px",
        }}
      >
        <Box sx={styles.searchbar}>
          <img sx={styles.searchbarIcon} src={searchbar} alt="searchIcon" />
          <input
            style={{ borderWidth: "0px", outline: "none", width: "350px" }}
            type="text"
            placeholder=" Search here "
            onChange={(e) => {
              const query = e.target.value;
              setSearchQuery(query);
              const filtered = Events.filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase())
              );
              setFilteredEvents(filtered);
            }}
          />
        </Box>

        {/* ........................................events mapping.................................... */}
        <Box sx={styles.container}>
          {filteredEvents.length > 0
            ? filteredEvents?.map((item, index) => (
                <Box
                  // key={index}
                  onClick={() => {
                    handleEvent(item.id, selectedId.includes(item.id));
                  }}
                  sx={[
                    {
                      m: 2,
                      width: 250,
                      boxShadow: 1,
                      borderRadius: "10px",
                      height: 240,
                    },
                    selectedId.includes(item.id) && {
                      border: "2px solid red",
                    },
                  ]}
                >
                  <GroupManagementStepperCard
                    img={item?.cover_img_path}
                    title={item?.name}
                    desc={item?.description}
                    date={item?.recurrance_start_date}
                    // time={item.time}
                    address={item?.location}
                  />
                </Box>
              ))
            : Events?.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    handleEvent(item.id, selectedId.includes(item.id));
                  }}
                  sx={[
                    {
                      m: 2,
                      width: 250,
                      boxShadow: 1,
                      borderRadius: "10px",
                      height: 240,
                    },
                    selectedId.includes(item.id) && {
                      border: "2px solid red",
                      elavation: 2,
                    },
                  ]}
                >
                  <GroupManagementStepperCard
                    img={item?.cover_img_path}
                    title={item.name}
                    desc={item.description}
                    date={item.recurrance_start_date}
                    // time={item.time}
                    address={item.location}
                  />
                </Box>
              ))}
        </Box>
        {/* ........................................events mapping.................................... */}
      </Box>

      <Typography sx={[styles.title, { marginTop: "20px" }]}>
        Map Members
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #999999",
          borderRadius: "10px",
          margin: "0px",
        }}
      >
        <Box sx={styles.searchbar}>
          <img sx={styles.searchbarIcon} src={searchbar} alt="searchIcon" />
          <input
            style={{ borderWidth: "0px", outline: "none", width: "350px" }}
            type="text"
            placeholder=" Search here "
            onChange={(e) => {
              const query = e.target.value;
              setSearchQuery1(query);
              const filtered = members?.filter((item) =>
                Object.values(item).some((value) =>
                  String(value).toLowerCase().includes(query.toLowerCase())
                )
              );
              setFilteredMembers(filtered);
            }}
          />
        </Box>

        {/* ........................................members mapping.................................... */}

        <Box sx={styles.container}>
          {filteredMembers.length > 0
            ? filteredMembers?.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    handleMembers(
                      item.user.id,
                      memberId.includes(item.user.id)
                    );
                  }}
                  sx={[
                    {
                      minWidth: "441px",
                      height: "120px",
                      margin: "12px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                    },
                    memberId.includes(item.user.id) && {
                      border: "2px solid red",
                      elavation: 4,
                    },
                  ]}
                >
                  <GroupManagementStepperCard2
                  img={item.user?.avatarUrl}
                    name={item?.displayName}
                    phoneNo={item.user?.phoneNumber}
                    email={item.user?.email}
                  />
                </Box>
              ))
            : members?.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    handleMembers(
                      item.user?.id,
                      memberId.includes(item.user?.id)
                    );
                  }}
                  sx={[
                    {
                      minWidth: "441px",
                      height: "120px",
                      margin: "12px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                    },
                    memberId.includes(item.user?.id) && {
                      border: "2px solid red",
                    },
                  ]}
                >
                  <GroupManagementStepperCard2
                  img={item.user?.avatarUrl}
                    name={item?.first_name}
                    phoneNo={item.user?.phoneNumber}
                    email={item.user?.email}
                  />
                </Box>
              ))}
        </Box>
        {/* ........................................members mapping.................................... */}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Button
          onClick={addData}
          variant="contained"
          sx={{
            ml: 2,
            marginRight: 5,
            width: "160px",
            color: "#FFFFFF",
            fontFamily: "MontserratRegular",
            backgroundColor: "#FF0000",
            "&:hover": {
              backgroundColor: "#FF0000",
            },
            height: 40,
          }}
        >
          Publish Group
        </Button>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    width: "100%",

    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    overflowY: "hidden",
    scrollbarWidth: "2px",
    scrollbarRadius: "10px",
    "&::-webkit-scrollbar": {
      width: "0.2em",
      borderRadius: "10px",
      marginBottom: "0px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E5F8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  title: {
    fontFamily: "MontserratRegular",
    color: "#414042",
    fontWeight: "bold",
    fontWeight: "10px",
    margin: "2px",
  },
  searchbar: {
    height: "25px",
    width: "350px",
    display: "flex",
    margin: "4px",
    marginLeft: "14px",
    flexDirection: "row",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#999999",
    borderRadius: "10px",
    alignItems: "center",
    fontFamily: "MontserratRegular",
    padding: "2px",
    color: "#999999",
  },
  searchbarIcon: {
    width: "20px",
    height: "20px",
    margin: "auto 2% auto 3%",
  },
};
