import { Box, Typography } from "@mui/material";
import { React, useEffect, useState, TouchableOpacity } from "react";
import Styles from "../styles/GroupMangement2";
import Navbar from "../components/Navbar";
import Checklist from "../components/GroupManagement/Checklist";
import DetailsBox from "../components/GroupManagement/DetailsBox";
import EventsBox from "../components/GroupManagement/EventsBox";
import Members from "../components/GroupManagement/Members";
import ContentBox from "../components/GroupManagement/ContentBox";
import EditDeletePopUp from "../components/GroupManagement/EditDeletePopUp";
import styles from "../../src/styles/global";
import { useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import groups from "../images/img3.jpg";
import { NhostClient, useNhostClient } from "@nhost/react";
import { useNavigate } from "react-router";
import PopupEditGroup from "../components/Popup/GroupManagementEditPopups/PopupEditGroup";
import nullimage from '../images/nullImage.jpeg'
import { gql, useQuery } from "@apollo/client";

const GET_ALL_GROUP_QUERY = gql`
  query MyQuery15($_eq: bigint = "") {
    group_master(where: { id: { _eq: $_eq } }) {
      activation_date
      attendance_capacity
      category_id
      closure_date
      deactivation_date
      decription
      duration
      group_category {
        created_by
        created_date
        description
        id
        name
      }
      group_events {
        event_id
        group_id
        id
        event_master {
          activation_date
          category_id
          closure_date
          cover_img_path
          created_at
          deactivation_date
          description
          end_time
          id
          is_recurring
          location
          metadata
          name
          parent_id

          recurrance_end_date
          recurrance_end_time
          recurrance_frequency
          recurrance_start_time
          recurrance_start_date
          start_time
          type_id
          updated_at
          event_category {
            name
            id
          }
          event_type {
            name
            id
          }
        }
      }
      group_type {
        created_by
        created_date
        description
        id
        name
      }
      id
      level_up_group
      name
      price
      task_masters {
        description
        event_id
        file_path
        group_id
        id
        target_date
        staff_id
        task_name
        user {
          id
          displayName
          defaultRole
          email
          profile_detail {
            first_name
          }
        }
      }
      type
      user_groups {
        group_id
        id
        user_id
        users {
          displayName
          phoneNumber
          email
        }
      }
    }
  }
`;
 
export default function GroupManagement2(props, routes) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isUser,
    name,
    membership,
    id,
    description,
    activation_date,
    deactivation_date,
    cover_img_path,
  } = location.state;
  console.log("all data " , location.state)

  const [user, setUser] = useState(
    location.state && location.state.isUser ? "user" : "admin"
  );
  // console.log("cover images are", cover_img_path);
  const [membership1, setMembership] = useState(
    location.state && location.state.membership
      ? "nogroupmember"
      : "groupmember"
  );
  const [group, setGroup] = useState({});

  const { loading, error, data } = useQuery(GET_ALL_GROUP_QUERY, {
    variables: {
      _eq: id,
    },
    skip: !id,
    onError: (error) => {
      // console.log("error for data", error);
    },
  });
  // console.log("all grp data is", data?.group_master);
  const nhost = useNhostClient();

  const [imgUrl, setImgUrl] = useState("");
  useEffect(() => {
    const getImageUrl = async () => {
      // console.log("tjis is new url", cover_img_path);

      try {
        const item = await nhost.storage.getPublicUrl({
          fileId: cover_img_path,
        });
        // console.log("Image URL:", item);
        // setImage(item)
        // setImgUrl(item);
        setImgUrl(item);
      } catch (error) {
        console.error("Error retrieving image URL:", error);
      }
      // try {
      //   const response = await nhost.storage.getFile(cover_img_path);
      //   console.log(" to get image URL", response);
      //   setImgUrl(response.url);
      // } catch (error) {
      //   console.error("Failed to get image URL", error);
      // }
    };

    getImageUrl();
  }, [cover_img_path]);

  // const nhostSubdomain = process.env.REACT_APP_NHOST_SUBDOMAIN;
  // const nhostRegion = process.env.REACT_APP_NHOST_REGION;
  // const nhostStorageBaseUrl = `https://${nhostSubdomain}.nhost.${nhostRegion}.app/storage/`;
  // const imageUrl = `${nhostStorageBaseUrl}${cover_img_path}`;
  // console.log("i think image url is coming", imageUrl);
  useEffect(() => {
    setGroup(data?.group_master[0]);
  }, [data]);
  const [activeTab, setActiveTab] = useState(1);
  const [openpop, setOpenpop] = useState(false);
  // const [user, setUser] = useState("admin");
  const [activeOption, setActiveOption] = useState("null");
  function handleUserTypeChange(event) {
    setUser(event.target.value);
  }
  const openPopUp = () => {
    setOpenpop(!openpop);
  };
  const handleButtonClick = (tabIndex, option) => {
    setActiveTab(tabIndex);
    setActiveOption(option);
  };

  const dateObject = new Date(activation_date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const year = dateObject.getFullYear();
  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const utcString = dateObject.toISOString();
  const formattedStartDate = `${formattedDate}`;

  const dateObjecte = new Date(deactivation_date);
  const daye = dateObjecte.getDate();
  const monthe = dateObjecte.getMonth() + 1; // Month is zero-based, so add 1
  const yeare = dateObjecte.getFullYear();
  const formattedDatee = `${daye < 10 ? '0' : ''}${daye}/${monthe < 10 ? '0' : ''}${monthe}/${yeare}`;
  const utcStringe = dateObjecte.toISOString();
  const formattedEndDate = `${formattedDatee}`;

  return (
    <Box>
      {/* Header */}
      <Navbar user={user} />
      {/* Nav Box */}
      <Box sx={Styles.headerBox}>
        {user === "user" ? (
          <Typography sx={Styles.header}> Groups</Typography>
        ) : (
          <Typography
            sx={[Styles.header, { cursor: "pointer" }]}
            onClick={() => {
              navigate("/groupmanagement");
            }}
          >
            Group Management - {name}
          </Typography>
        )}
      </Box>
      <Box sx={Styles.container}>
        <Box sx={Styles.card}>
          <Box sx={Styles.imgBox}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fit",
              }}
              src={  imgUrl && !imgUrl.endsWith("null") 
              ? imgUrl
              : nullimage}
            />
          </Box>
          <Box sx={Styles.info}>
            <Typography sx={Styles.cardHeader}>{name}</Typography>
            <Box sx={{ marginTop: "20px", display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <img
                  style={Styles.icon}
                  src={require("../images/Calender.png")}
                />
                <Typography sx={Styles.textCard}>
                  {formattedStartDate} CET - {formattedEndDate} CET
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", marginTop: "20px" }}>
              <Box sx={Styles.descriptionBox}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <img
                    style={Styles.icon}
                    src={require("../images/Description.png")}
                  />
                  <Typography sx={Styles.lowerHeader}>Description</Typography>
                </Box>
                <Typography sx={Styles.text}>{description}</Typography>
              </Box>
            </Box>
          </Box>
          {/* 3 dot Menu */}

          {user === "user" && membership1 === "groupmember" && (
            <Box sx={[Styles.threeDots]}>
              <Box
                sx={[
                  styles.btnDashboard,
                  {
                    margin: "4px",
                    fontSize: "12px",
                    backgroundColor: "FF0000",
                  },
                ]}
              >
                Book Now
              </Box>
            </Box>
          )}

          {/* {openpop &&<DetailsEdit open={openpop}  setOpenpop={setOpenpop} /> } */}

          {user === "admin" && (
            <Box sx={Styles.threeDots} onClick={openPopUp}>
              <img
                alt=""
                style={Styles.menu}
                src={require("../images/menu.png")}
              />
            </Box>
          )}


          {/* {openpop && <EditDeletePopUp open={openpop} setOpen={setOpenpop} />} */}
        </Box>
        {/* Navigation Menu */}
        <Box sx={Styles.navBox}>
          <Box sx={Styles.navMenu}>
            <Box
              sx={[Styles.nav, activeTab === 1 && styles.activeTab]}
              onClick={() => handleButtonClick(1, "DetailsBox")}
            >
              <img
                style={{ width: "24px", height: "24px" }}
                src={
                  activeTab === 1
                    ? require("../images/edit.png")
                    : require("../images/editGray.png")
                }
              />

              <Typography sx={Styles.navName}>Details</Typography>
            </Box>

            <Box
              sx={[Styles.nav, activeTab === 2 && styles.activeTab]}
              onClick={() => handleButtonClick(2, "ContentBox")}
            >
              <img
                style={{ width: "24px", height: "24px" }}
                src={
                  // require("../images/Share.png")
                  activeTab === 2
                    ? require("../images/ShareRed.png")
                    : require("../images/Share.png")
                }
              />
              <Typography sx={Styles.navName}>Contents</Typography>
            </Box>

            <Box
              sx={[Styles.nav, activeTab === 3 && styles.activeTab]}
              onClick={() => handleButtonClick(3, "EventsBox")}
            >
              <img
                style={{ width: "24px", height: "24px" }}
                src={
                  activeTab === 3
                    ? require("../images/eventsRed.png")
                    : require("../images/Union.png")
                }
              />
              <Typography sx={Styles.navName}>Events</Typography>
            </Box>

            <Box
              sx={[Styles.nav, activeTab === 4 && styles.activeTab]}
              onClick={() => handleButtonClick(4, "Members")}
            >
              <img
                style={{ width: "24px", height: "24px" }}
                src={
                  activeTab === 4
                    ? require("../images/MembersRed1.png")
                    : require("../images/NotificationsGray.png")
                }
              />
              <Typography sx={Styles.navName}>Members</Typography>
            </Box>

            {user === "admin" && (
              <Box
                sx={[Styles.nav, activeTab === 5 && styles.activeTab]}
                onClick={() => handleButtonClick(5, "Checklist")}
              >
                <img
                  style={{ width: "24px", height: "24px" }}
                  src={
                    activeTab === 5
                      ? require("../images/checklistRed.png")
                      : require("../images/Checklist.png")
                  }
                />
                <Typography sx={Styles.navName}>Checklist</Typography>
              </Box>
            )}
          </Box>
          {/* Lower main Box */}
          {/* Details Box */}

          {activeTab === 1 && (
            <DetailsBox
              user={user}
              groupcat={group?.group_category}
              grouptype={group?.group_type}
              group={group}
              grpImg={imgUrl}
              coverImgId={cover_img_path}
            />
          )}
          {/* Content Box */}
          {activeTab === 2 && (
            <Box sx={Styles.contentBox}>
              <ContentBox id={id} user={user} />
            </Box>
          )}
          {/* Event Box */}
          {activeTab === 3 && (
            <Box sx={Styles.scheduleBox}>
              <EventsBox
                imgUrl={imgUrl}
                user={user}
                group={group?.group_events}
                grpId={group?.id}
                id={id}
              />
            </Box>
          )}
          {/* Members Box */}
          {activeTab === 4 && (
            <Box sx={Styles.checklist}>
              <Members members={group?.user_groups} id={id} user={user} />
            </Box>
          )}
          {/* Checklist box */}
          {user === "admin" && activeTab === 5 && (
            <Box sx={Styles.checklist}>
              <Checklist id={id} />
            </Box>
          )}

          {openpop && (
            <PopupEditGroup open={openpop} setOpen={setOpenpop} id={id} name={name} urlImage = {imgUrl} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
