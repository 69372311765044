import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { gql, useQuery ,useMutation} from "@apollo/client";

const GROUP_FIND_QUERY = gql`
  query MyQuery($_eq: uuid = "") {
    user_groups(where: { user_id: { _eq: $_eq } },distinct_on:user_id) {
      group_id
      user_id
    }
  }
`;

const GROUP_MEMBERS_QUERY = gql`
query MyQuery9($_eq: bigint, $_neq: uuid = "") {
  user_groups(where: {group_id: {_eq: $_eq}, _and: {user_id: {_neq: $_neq}, _and: {users: {defaultRole: {_neq: "admin"}}}}}) {
    user_id
    group_id
    id
    users {
      email
      displayName
      id
    }
  }
}
`;
const EVENT_FIND_QUERY = gql`
  query MyQuery3($_eq: uuid = "") {
    user_events(where: { userid: { _eq: $_eq } }) {
      event_id
    }
  }
`;

const EVENT_MEMBERS_QUERY = gql`
query MyQucdsery4($_eq: bigint) {
  user_events(where: {event_id: {_eq: $_eq}, _and: {users: {defaultRole: {_neq: "admin"}}}}, distinct_on: userid) {
    userid
    users {
      displayName
      id
      email
    }
  }
}

`;

const ADD_MEMBERS_MUTATION = gql`
mutation MyMutation($participent: uuid = "", $sender_id: uuid = "") {
    insert_group_chatn(objects: {participent: $participent, sender_id: $sender_id}) {
      returning {
        participent
        sender_id
        id
      }
    }
  } 
`;

const emails = ["username@gmail.com", "user02@gmail.com"];

export default function AddNewChat(props) {
  const { onClose, selectedValue, open } = props;
  const [grpId, setGrpId] = React.useState([]);
  const [eventId, seteventId] = React.useState([]);
  const [usersId, setUsersId] = React.useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(null); // Track the selected user
  const [searchText, setSearchText] = React.useState(''); // Track the search input


  const {
    loading: grpLoad,
    error: grpErr,
    data: grpChat,
  } = useQuery(GROUP_FIND_QUERY, {
    variables: {
      _eq: localStorage.getItem("id"),
    },
    onCompleted: (data) => {
      // console.log("this is grp find query", data?.user_groups[0]?.group_id);
      setGrpId(data?.user_groups[0]?.group_id);
    },
  });

  const {
    loading: memberGrpLoad,
    error: memberGrpErr,
    data: memberGrpChat,
  } = useQuery(GROUP_MEMBERS_QUERY, {
    variables: {
      _eq: grpId,
      _neq:localStorage.getItem("id")
    },
    onCompleted: (data) => {
      // console.log("this is grp member query", data?.user_groups);
      setUsersId(data?.user_groups);
      // setGrpId(data?.user_groups[0]?.group_id)
    },
  });

  const {
    loading: eventLoad,
    error: eventErr,
    data: eventChat,
  } = useQuery(EVENT_FIND_QUERY, {
    variables: {
      _eq: localStorage.getItem("id"),
    },
    onCompleted: (data) => {
      // console.log("this is grp member query", data?.user_events);
      seteventId(data?.user_events[0].event_id);
      // setGrpId(data?.user_groups[0]?.group_id)
    },
  });

  const {
    loading: memberevntLoad,
    error: memberevntErr,
    data: memberevntChat,
  } = useQuery(EVENT_MEMBERS_QUERY, {
    variables: {
      _eq: eventId,
    },
    onCompleted: (data) => {
      // console.log("this is grp member query", data?.user_events);
    //   setUsersId(data?.user_events);
    // setUsersId((prevUsers) => prevUsers.concat(data?.user_events));

    // const filteredUserEvents = memberGrpChat?.user_events.filter((eventUser) => {
    //     return !usersId.some((groupUser) => groupUser.userId === eventUser.userid);
    //   });

    //   setUsersId((prevUsers) => prevUsers.concat(filteredUserEvents));

    const filteredUserEvents = data?.user_events.filter((eventUser) => {
        const isLoggedInUser = localStorage.getItem("id") === eventUser.userid;
        const isExistingUser = usersId.some((groupUser) => groupUser.user_id === eventUser.userid);
        return !isLoggedInUser && !isExistingUser;
      });

      setUsersId((prevUsers) => prevUsers.concat(filteredUserEvents));


      // setGrpId(data?.user_groups[0]?.group_id)
    },
  });

  const [addMembersMutation, { loading: addMembersLoading, error: addMembersError }] = useMutation(ADD_MEMBERS_MUTATION);

  const handleAddMember=async()=>{
    try {
        const { data:insertedData } = await addMembersMutation({
          variables: {
            participent:selectedUserId,
            sender_id: localStorage.getItem("id"),
          },
        });
        // console.log("instered group header info",insertedData)
        props.setNewTaskOpen(false);
        window.location.reload();
      } catch (error) {
        // console.error("GraphQL Mutation Error:", error.message);
      }
  }

  // console.log("users all", usersId);

  const filteredUsers = usersId?.filter((item) =>
    item?.users?.displayName.toLowerCase().includes(searchText.toLowerCase())
  );


  const handleClose = () => {
    props.setNewTaskOpen(false);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };
  const handleRadioChange = (event) => {
    setSelectedUserId(event.target.value); // Update the selected user
  };
  // console.log("this is selected userId", selectedUserId);

  return (
    <Dialog fullWidth="xl" onClose={handleClose} open={props.open}>
        <Box style={{width:"100%",backgroundColor:"red"}}>
            <img style={{width:"90px",height:"35px",margin:"10px"}} src={require('../../images/navLogo.png')}/>
        </Box>
      <DialogTitle> All Members</DialogTitle>
      <DialogContent
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        {/* <DialogContentText>
            All Chat
          </DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Search Here"
          type="text"
          //   width="70%"
          style={{ width: "40%" }}
          variant="standard"

          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </DialogContent>
      <List sx={{ pt: 0 }}>
      {filteredUsers.map((item) => (
          <Box
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              style={{
                width: "80%",
                height: "40px",
                border: "1px solid #999999",
                borderRadius: "10px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
              }}
            >
              <FormControlLabel
              style={{width:"100%", display: "flex", justifyContent: "center","& .MuiFormControlLabel-label": {
                fontFamily: "MontserratRegular",
              },
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked":
                { color: "red" },}}
                control={
                  <Radio
                    // Assuming `item.userId` is unique
                    checked={selectedUserId === item.user_id}
                    onChange={handleRadioChange}
                    value={item.user_id}
                    style={{"& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked ":{ color: "red" },}}
                  />
                }
                label={item.users.displayName}
              />
            </Box>
          </Box>
        ))}
      </List>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddMember}>Chat</Button>
      </DialogActions>
    </Dialog>
  );
}
